/* eslint-disable no-return-assign */
/* eslint-disable no-const-assign */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable new-cap */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
import React, { useState } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  makeStyles,
  withStyles,
  Grid,
  Card,
  Container,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { FaSolarPanel, FaCloudMeatball, FaCalendarPlus, FaMoneyBill } from 'react-icons/fa';
import { CgTrees } from 'react-icons/cg';
import { ImPower } from 'react-icons/im';
import { GiReceiveMoney } from 'react-icons/gi';
import { IconContext } from 'react-icons/lib';
// import { useTranslation } from 'react-i18next';
import 'reactjs-popup/dist/index.css';
import { format } from 'date-fns';
import axiosInstance from 'src/utils/axiosApi';
import MixedBarGraph from 'src/views/designer/graphs/MixedBarGraph';
import PieChart from 'src/views/designer/graphs/PieChart';
import NivoLine from 'src/views/designer/graphs/NivoLine';
import NivoBar from 'src/views/designer/graphs/NivoBar';
import Spinner from 'src/components/Spinner/Spinner';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: theme.spacing(3)
  },
  formControl: {
    margin: 0,
    width: '80%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    maxWidth: 500,
    minWidth: 400,
  },
}));

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 12,
    fontFamily: 'Poppins'
  },
}))(TableCell);

const StyledTableCellStrong = withStyles(() => ({
  root: {
    padding: 12,
    fontFamily: 'Poppins',
    fontSize: 18
  },
}))(TableCell);

const StyledTableCellBold = withStyles(() => ({
  root: {
    padding: 12,
    fontFamily: 'Poppins',
    fontWeight: 'bold'
  },
}))(TableCell);

const ReportPage = ({ className, projectId, printRef, purpose, printState }) => {
  // const { t, } = useTranslation();

  const productionColor = '#21cc7263'; // soft: #21cc7263 / strong: rgb(33, 204, 114)
  const consumptionColor = '#f4747363'; // soft: #f4747363 / strong: rgb(244, 116, 115)
  const selfConsColor = '#5c9df563'; // soft: #5c9df563 / strong: rgb(92, 157, 245)

  const totalProdColor = 'rgb(33, 204, 114)';
  const totalConsColor = 'rgb(244, 116, 115)';
  const totalSelfConsColor = 'rgb(92, 157, 245)';

  const StyledDiv = styled.div`
    bottom: 0;
    height: auto;
    left: calc(6rem + 256px);
    padding: 0;
    position: absolute;
    right: 0;
    top: 88px;
    overflow-x: auto;

    @media print {
      overflow-x: auto;
      display: block;
      height: auto;
      position: unset;
    }
  `;

  const StyledGraphWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 300px;
  `;

  const StyledPieWrapper = styled.div`
    width: 50%;
    height: 270px;
  `;

  const StyledThumb = styled.img`
    width: 400px;
    height: 400px;

    @media print {
      width: 100%;
      height: 100%;
    }
  `;

  const StyledPageSpace = styled.div`
    height: 1px;
    
    @media print {
      height: ${(props) => props.space};
    }
  `;

  const StyledReverseSpace = styled.div`
    height: ${(props) => props.space};
    
    @media print {
      height: 1px;
    }
  `;

  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [showContent, setShowContent] = React.useState(true);

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [barData, setBarData] = React.useState([]);

  const [invoiceLineData, setInvoiceLineData] = React.useState({
    monthlySPPInvoice: [],
    monthlyNormalInvoice: [],
    monthList: [],
    monthlySavings: []
  });

  const [invoiceTable, setInvoiceTableData] = React.useState({
    yearlySPPInvoice: 0,
    yearlyNormalInvoice: 0,
    yearlySavings: 0
  });

  const [productionPieData, setProdPieData] = React.useState({});
  const [consumptionPieData, setConsPieData] = React.useState({});

  const [malzemeler, setMalzemeler] = React.useState([]);

  const [gmapsImage, setGmapsImage] = React.useState({
    preview: '',
    name: ''
  });

  const [companyLogo, setCompanyLogo] = React.useState({
    preview: '',
    name: ''
  });

  const [fundamentalProjectInfo, setFundamentalProjectInfo] = React.useState({
    projectName: '',
    address: '',
    customerName: '',
    date: ''
  });

  const [systemOverview, setSystemOverview] = React.useState({
    panel: '',
    totalCost: '',
    roi: ''
  });

  const [simulationResults, setSimulationResults] = React.useState({
    dcPower: '',
    acPower: '',
    yearlyProduction: '',
    co2: '',
    trees: ''
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarState(false);
  };

  const SimulationItem = (props) => {
    const { icon, description, value, unit } = props;

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: 8 }}>
          <IconContext.Provider value={{ color: '#545454', size: 40 }}>
            {icon}
          </IconContext.Provider>
        </div>
        <span style={{ fontSize: '1rem', color: '#7c7c7c' }}>{description}</span>
        <div>
          <span style={{ fontSize: '1.5rem' }}>{`${value} `}</span>
          <span>{unit}</span>
        </div>
      </div>
    );
  };

  const makeFeasibility = async () => {
    setLoading(true);
    let dcPower = 0;
    await axiosInstance.get(`designer/make_feasibility?project_id=${projectId}&format=json`)
      .then((response) => {
        if (response.data.status) {
          const graphData = response.data.data.graph_data;
          const feasibilityResults = response.data.data;
          dcPower = feasibilityResults.guc;
          const fundProjectInfo = response.data.data.fund_project_info;

          setBarData(graphData.consumption_production_bar_data);
          setProdPieData(graphData.yearly_donut_data.production);
          setConsPieData(graphData.yearly_donut_data.consumption);
          setFundamentalProjectInfo({
            projectName: fundProjectInfo.project_name,
            address: fundProjectInfo.address,
            customerName: fundProjectInfo.customer_name,
            date: String(format(new Date(Date.parse(fundProjectInfo.created_at)), 'dd/MM/yyyy'))
          });
          setSystemOverview({
            panel: feasibilityResults.panel_sayisi,
            totalCost: feasibilityResults.kdvli_usd_maliyet,
            roi: feasibilityResults.roi
          });
          setSimulationResults({
            dcPower: feasibilityResults.guc,
            acPower: feasibilityResults.ac_guc,
            yearlyProduction: graphData.yearly_donut_data.production.total_production,
            co2: feasibilityResults.co2,
            trees: feasibilityResults.agac,
          });
          setMalzemeler(feasibilityResults.malzemeler);
          setInvoiceLineData({
            monthlySPPInvoice: feasibilityResults['aylik_ges_fatura'],
            monthlyNormalInvoice: feasibilityResults['aylik_normal_fatura'],
            monthList: feasibilityResults['aylik_zaman'],
            monthlySavings: feasibilityResults['aylik_tasarruf']
          });

          const tempYearlySPPInvoice = feasibilityResults['aylik_ges_fatura'].slice(0, 12).reduce((a, b) => Number(String(a).replace('.', '')) + Number(String(b).replace('.', '')), 0);
          const tempYearlyNormalInvoice = feasibilityResults['aylik_normal_fatura'].slice(0, 12).reduce((a, b) => Number(String(a).replace('.', '')) + Number(String(b).replace('.', '')), 0);
          const tempYearlySavings = feasibilityResults['aylik_tasarruf'].slice(0, 12).reduce((a, b) => Number(String(a).replace('.', '')) + Number(String(b).replace('.', '')), 0);

          setInvoiceTableData({
            yearlySPPInvoice: tempYearlySPPInvoice,
            yearlyNormalInvoice: tempYearlyNormalInvoice,
            yearlySavings: tempYearlySavings
          });
          setShowContent(true);
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
          setShowContent(false);
          setLoading(false);
        }
      })
      .catch((error) => console.log(error));

    await axiosInstance.get(`designer/fetch_project_image?project_id=${projectId}&format=json`, {
      responseType: 'blob'
    })
      .then((response) => {
        const file = new Blob(
          [response.data],
          { type: 'image/*' }
        );
        // console.log(response);
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        setGmapsImage({ preview: fileURL, name: 'Firma Logosu' });
        return fileURL;
      })
      .catch((error) => console.log(error));

    await axiosInstance.get('logo/fetch?format=json', {
      responseType: 'blob'
    })
      .then((response) => {
        const file = new Blob(
          [response.data],
          { type: 'image/*' }
        );
        // console.log(response);
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        setCompanyLogo({ preview: fileURL, name: 'Firma Logosu' });
        return fileURL;
      })
      .catch((error) => console.log(error));

    setLoading(false);
  };

  const generateRows = () => {
    return malzemeler.map((row) => {
      return (
        <TableRow key={row[0]}>
          <StyledTableCell component="th" scope="row">{row[0]}</StyledTableCell>
          <StyledTableCell align="left">{row[1]}</StyledTableCell>
          <StyledTableCell align="left">{row[2]}</StyledTableCell>
          <StyledTableCell align="right">{row[3]}</StyledTableCell>
        </TableRow>
      );
    });
  };

  const thumb = (
    <>
      <StyledThumb
        src={gmapsImage.preview}
        alt={gmapsImage.name}
      />
    </>
  );

  const logoThumb = (
    <>
      <img
        style={{
          width: '100%',
          height: '100%'
        }}
        src={companyLogo.preview}
        alt={companyLogo.name}
      />
    </>
  );

  const willMount = React.useRef(true);
  if (willMount.current) {
    makeFeasibility();
    willMount.current = false;
  }

  if (loading) {
    return (
      <>
        <Spinner text="Fizibilite yapılıyor.." />
      </>
    );
  }

  if (!showContent) {
    return (
      <>
        <div />
      </>
    );
  }

  return (
    <StyledDiv
      print={purpose === 'print'}
      display={purpose === 'display'}
      ref={printRef}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <StyledReverseSpace space="30px" />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card
              className={clsx(classes.cardRoot, className)}
            >
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={9}
                    lg={9}
                    xs={9}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <h3 style={{ padding: '5px 0px' }}>{fundamentalProjectInfo.projectName}</h3>
                      <span style={{ color: 'rgb(122, 122, 122)', fontSize: '0.85rem' }}>{`${fundamentalProjectInfo.address} | ${fundamentalProjectInfo.customerName} | ${fundamentalProjectInfo.date}`}</span>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    lg={3}
                    xs={3}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div style={{ height: '100%', width: '200px' }}>
                        {logoThumb}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card
              className={clsx(classes.cardRoot, className)}
            >
              <CardContent>
                <Grid
                  container
                  spacing={3}
                  justify="center"
                >
                  <Grid
                    item
                    md={12}
                    lg={12}
                    xs={12}
                    justify="center"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    {thumb}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <StyledPageSpace space="180px" />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card
              className={clsx(classes.cardRoot, className)}
            >
              <CardContent>
                <Grid
                  container
                  spacing={3}
                  justify="center"
                >
                  <Grid
                    item
                    md={12}
                    lg={12}
                    xs={12}
                  >
                    <h3 style={{ paddingBottom: '10px' }}>Sistem Özeti</h3>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    lg={3}
                    xs={3}
                  >
                    <SimulationItem
                      icon={<FaSolarPanel />}
                      description="Panel Sayısı"
                      value={systemOverview.panel}
                      unit=""
                    />
                  </Grid>
                  {/* <Grid
                    item
                    md={3}
                    lg={3}
                    xs={3}
                  >
                    <SimulationItem
                      icon={<GiReceiveMoney />}
                      description="İnvertor Sayısı"
                      value={systemOverview.inverter}
                      unit=""
                    />
                  </Grid> */}
                  <Divider />
                  <Grid
                    item
                    md={3}
                    lg={3}
                    xs={3}
                  >
                    <SimulationItem
                      icon={<FaMoneyBill />}
                      description="Proje Maliyeti"
                      value={systemOverview.totalCost}
                      unit="$"
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    lg={3}
                    xs={3}
                  >
                    <SimulationItem
                      icon={<GiReceiveMoney />}
                      description="Amortisman Süresi"
                      value={systemOverview.roi}
                      unit="Yıl"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card
              className={clsx(classes.cardRoot, className)}
            >
              <CardContent>
                <Grid
                  container
                  spacing={3}
                  justify="center"
                >
                  <Grid
                    item
                    md={12}
                    lg={12}
                    xs={12}
                  >
                    <h3 style={{ paddingBottom: '10px' }}>Simülasyon Sonuçları</h3>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    lg={4}
                    xs={4}
                  >
                    <SimulationItem
                      icon={<FaSolarPanel />}
                      description="DC Kurulu Güç"
                      value={simulationResults.dcPower}
                      unit="kWp"
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    lg={4}
                    xs={4}
                  >
                    <SimulationItem
                      icon={<ImPower />}
                      description="AC Kurulu Güç"
                      value={simulationResults.acPower}
                      unit="kWe"
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    lg={4}
                    xs={4}
                  >
                    <SimulationItem
                      icon={<FaCalendarPlus />}
                      description="Yıllık Enerji Üretimi"
                      value={simulationResults.yearlyProduction}
                      unit="MWh"
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    lg={4}
                    xs={4}
                  >
                    <SimulationItem
                      icon={<FaCloudMeatball />}
                      description="Önlenen CO2"
                      value={simulationResults.co2}
                      unit="tn"
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    lg={4}
                    xs={4}
                  >
                    <SimulationItem
                      icon={<CgTrees />}
                      description="Kurtarılan Ağaç"
                      value={simulationResults.trees}
                      unit=""
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card
              className={clsx(classes.cardRoot, className)}
            >
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={12}
                    lg={12}
                    xs={12}
                  >
                    <StyledGraphWrapper
                      print={purpose === 'print'}
                      display={purpose === 'display'}
                    >
                      <h3 style={{ padding: '10px 0px' }}>Öngörülen Aylık Enerji Grafiği</h3>
                      <div
                        style={{ width: '70%', height: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                      >
                        <MixedBarGraph barData={barData} />
                      </div>
                    </StyledGraphWrapper>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <StyledPageSpace space="100px" />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card
              className={clsx(classes.cardRoot, className)}
            >
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={12}
                    lg={12}
                    xs={12}
                  >
                    <div style={{ padding: '0px 60px' }}>
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCellBold>Ay</StyledTableCellBold>
                              <StyledTableCellBold align="center">Üretim (kWh)</StyledTableCellBold>
                              <StyledTableCellBold align="center">Tüketim (kWh)</StyledTableCellBold>
                              <StyledTableCellBold align="center">Öz Tüketim(kWh)</StyledTableCellBold>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {barData.map((row) => (
                              <TableRow key={row.month}>
                                <StyledTableCell component="th" scope="row">{row.month}</StyledTableCell>
                                <StyledTableCell align="center" style={{ color: totalProdColor }}>{row.solar_production}</StyledTableCell>
                                <StyledTableCell align="center" style={{ color: totalConsColor }}>{row.consumption}</StyledTableCell>
                                <StyledTableCell align="center" style={{ color: totalSelfConsColor }}>{row.self_consumption}</StyledTableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card
              className={clsx(classes.cardRoot, className)}
            >
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={12}
                    lg={12}
                    xs={12}
                  >
                    <h3 style={{ padding: '10px 0px', paddingLeft: '20px' }}>Enerji Üretimi</h3>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    lg={12}
                    xs={12}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <div style={{ width: '100%', height: 'fit-content', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'space-evenly' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '10px' }}>
                            <div style={{
                              backgroundColor: totalProdColor, width: '10px', height: '100%', marginRight: '10px'
                            }}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ color: '#7a7a7a', fontSize: '0.85rem' }}>Toplam Üretim - 100%</span>
                              <span style={{ fontSize: '1.3rem' }}>{`${productionPieData.total_production} MWh`}</span>
                            </div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '10px' }}>
                            <div style={{
                              backgroundColor: selfConsColor, width: '10px', height: '100%', marginRight: '10px'
                            }}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ color: '#7a7a7a', fontSize: '0.85rem' }}>{`Öz Tüketim - ${productionPieData.self_consumption_rate}%`}</span>
                              <span style={{ fontSize: '1.3rem' }}>{`${productionPieData.self_consumption} MWh`}</span>
                            </div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '10px' }}>
                            <div style={{
                              backgroundColor: productionColor, width: '10px', height: '100%', marginRight: '10px'
                            }}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ color: '#7a7a7a', fontSize: '0.85rem' }}>{`Şebekeye Verilen - ${productionPieData.export_rate}%`}</span>
                              <span style={{ fontSize: '1.3rem' }}>{`${productionPieData.export} MWh`}</span>
                            </div>
                          </div>
                        </div>
                        <StyledPieWrapper>
                          <PieChart
                            data={[
                              { name: 'Öz Tüketim', value: productionPieData.self_consumption },
                              { name: 'Şebekeye Verilen', value: productionPieData.export },
                            ]}
                            total={[
                              { name: 'Toplam Üretim', value: productionPieData.total_production }
                            ]}
                            purpose="production"
                          />
                        </StyledPieWrapper>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <StyledPageSpace space="15px" />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card
              className={clsx(classes.cardRoot, className)}
            >
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={12}
                    lg={12}
                    xs={12}
                  >
                    <h3 style={{ padding: '10px 0px', paddingLeft: '20px' }}>Enerji Tüketimi</h3>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    lg={12}
                    xs={12}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <div style={{ width: '100%', height: 'fit-content', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'space-evenly' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '10px' }}>
                            <div style={{
                              backgroundColor: totalConsColor, width: '10px', height: '100%', marginRight: '10px'
                            }}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ color: '#7a7a7a', fontSize: '0.85rem' }}>Toplam Tüketim - 100%</span>
                              <span style={{ fontSize: '1.3rem' }}>{`${consumptionPieData.total_consumption} MWh`}</span>
                            </div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '10px' }}>
                            <div style={{
                              backgroundColor: selfConsColor, width: '10px', height: '100%', marginRight: '10px'
                            }}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ color: '#7a7a7a', fontSize: '0.85rem' }}>{`Öz Tüketim - ${consumptionPieData.self_consumption_rate}%`}</span>
                              <span style={{ fontSize: '1.3rem' }}>{`${consumptionPieData.self_consumption} MWh`}</span>
                            </div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '10px' }}>
                            <div style={{
                              backgroundColor: consumptionColor, width: '10px', height: '100%', marginRight: '10px'
                            }}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ color: '#7a7a7a', fontSize: '0.85rem' }}>{`Şebekeden Çekilen - ${consumptionPieData.import_rate}%`}</span>
                              <span style={{ fontSize: '1.3rem' }}>{`${consumptionPieData.import} MWh`}</span>
                            </div>
                          </div>
                        </div>
                        <StyledPieWrapper>
                          <PieChart
                            data={[
                              { name: 'Öz Tüketim', value: consumptionPieData.self_consumption },
                              { name: 'Şebekeden Çekilen', value: consumptionPieData.import },
                            ]}
                            total={[
                              { name: 'Toplam Tüketim', value: consumptionPieData.total_consumption }
                            ]}
                            purpose="consumption"
                          />
                        </StyledPieWrapper>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card
              className={clsx(classes.cardRoot, className)}
            >
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={12}
                    lg={12}
                    xs={12}
                  >
                    <h3 style={{ paddingBottom: '10px' }}>Malzeme Bilgileri</h3>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    lg={12}
                    xs={12}
                  >
                    <div style={{ padding: '0px 60px' }}>
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCellBold>Kalem</StyledTableCellBold>
                              <StyledTableCellBold align="left">Marka-Model</StyledTableCellBold>
                              <StyledTableCellBold align="left">Birim</StyledTableCellBold>
                              <StyledTableCellBold align="right">Miktar</StyledTableCellBold>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {generateRows()}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <StyledPageSpace space="30px" />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card
              className={clsx(classes.cardRoot, className)}
            >
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={12}
                    lg={12}
                    xs={12}
                  >
                    <h3 style={{ paddingBottom: '10px' }}>Ay Bazlı Fatura Tutarları</h3>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    lg={12}
                    xs={12}
                  >
                    <div style={{ height: '270px', width: '100%' }}>
                      <NivoLine
                        monthlySPPInvoice={invoiceLineData.monthlySPPInvoice}
                        monthlyNormalInvoice={invoiceLineData.monthlyNormalInvoice}
                        monthList={invoiceLineData.monthList}
                      />
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card
              className={clsx(classes.cardRoot, className)}
            >
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={12}
                    lg={12}
                    xs={12}
                  >
                    <div style={{ padding: '0px 20px' }}>
                      <TableContainer component={Paper}>
                        <Table aria-label="invoice table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCellBold align="left">Zaman</StyledTableCellBold>
                              <StyledTableCellBold align="center">GES&apos;ten Önce Fatura</StyledTableCellBold>
                              <StyledTableCellBold align="center">GES&apos;ten Sonra Fatura</StyledTableCellBold>
                              <StyledTableCellBold align="center">Aylık Tasarruf</StyledTableCellBold>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {invoiceLineData.monthList.slice(0, 12).map((month, idx) => (
                              <TableRow key={month}>
                                <StyledTableCell component="th" scope="row">{month}</StyledTableCell>
                                <StyledTableCell align="center">{invoiceLineData.monthlyNormalInvoice[idx]} $</StyledTableCell>
                                <StyledTableCell align="center">{invoiceLineData.monthlySPPInvoice[idx]} $</StyledTableCell>
                                <StyledTableCell align="center">{invoiceLineData.monthlySavings[idx]} $</StyledTableCell>
                              </TableRow>
                            ))}
                            <TableRow key="toplam">
                              <StyledTableCellStrong component="th" scope="row">Yıllık Toplam</StyledTableCellStrong>
                              <StyledTableCellStrong align="center">{invoiceTable.yearlyNormalInvoice} $</StyledTableCellStrong>
                              <StyledTableCellStrong align="center">{invoiceTable.yearlySPPInvoice} $</StyledTableCellStrong>
                              <StyledTableCellStrong align="center">{invoiceTable.yearlySavings} $</StyledTableCellStrong>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledDiv>
  );
};

ReportPage.propTypes = {
  className: PropTypes.string
};

export default ReportPage;

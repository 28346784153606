import React from 'react';
import classes from './Spinner.module.css';

const spinner = (props) => (
  <>
    <div className={classes.Loader}>
      Loading...
    </div>
    <div className={classes.SpinnerText}>
      <p style={{ color: '#07bbf2' }}>
        {props.text}
      </p>
    </div>

  </>
);

export default spinner;

/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import i18n from 'src/i18n';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Badge,
  Popover,
  Divider
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Logo from 'src/components/Logo';
import { useDispatch } from 'react-redux';
import { resetStateAuth } from 'src/services/users/userReducer';
import axiosInstance from 'src/utils/axiosApi';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  productName: {
    color: 'white',
    fontSize: 26,
    marginLeft: 5
  },
  selectOption: {
    backgroundColor: 'white',
    marginLeft: 50
  },
  iconButton: {
    color: 'white',
    backgroundColor: 'transparent'
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [notifications, setNotifications] = React.useState([]);
  const [unseenNotifCount, setUnseenNotifCount] = React.useState(0);

  const renderNotifications = async () => {
    await axiosInstance.get('activity/render_notification_feed_API', {
      params: {
        language: (localStorage.getItem('language') === 'tr' || String(i18n.language).split('-')[0] === 'tr') ? 'TR' : 'EN',
        notification_page_no: 0,
        ignored_descriptive_activity_tags: ''
      }
    })
      .then((response) => {
        const { status_code } = response.data;
        if (status_code) {
          const { final_list } = response.data;
          setNotifications(final_list);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleClick = (event) => {
    renderNotifications();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (href_tag_1, href_id_1) => {
    if (href_tag_1 === 'TO_CREDIT_APPLICTAION') {
      navigate(`/credit-applications/${href_id_1}`);
    } else {
      navigate(`/projects/${href_id_1}`);
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  React.useEffect(() => {
    const timeoutID = window.setInterval(() => {
      axiosInstance.get('activity/get_unseen_notifications_count_API')
        .then((response) => {
          const { status_code } = response.data;
          if (status_code) {
            const { count } = response.data;
            setUnseenNotifCount(count);
          }
        })
        .catch((error) => console.log(error));
    }, 120000);

    return () => window.clearTimeout(timeoutID);
  }, []);

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/crm/status-all">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Logo />
          </Box>
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton className={classes.iconButton} onClick={handleClick}>
            <Badge variant="dot" invisible={!unseenNotifCount} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <RouterLink
            onClick={() => {
              dispatch(resetStateAuth({ loggedIn: false }));
              axiosInstance.defaults.headers.Authorization = null;
              localStorage.removeItem('access');
              localStorage.removeItem('refresh');
            }}
            to="/login"
          >
            <IconButton className={classes.iconButton}>
              <InputIcon />
            </IconButton>
          </RouterLink>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div style={{
          padding: 15,
          maxWidth: 400,
          width: 400,
          maxHeight: 500,
          height: 'fit-content',
        }}
        >
          {notifications.length > 0 && notifications.map((item) => (
            <>
              <div onClick={() => handleNavigate(item.href_tag_1, item.href_id_1)} style={{ color: 'black', cursor: 'pointer' }}>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: item.is_seen_for_this_user ? '#ffffff' : '#eeeeee', padding: 10, borderLeft: `5px solid ${item.hexColor}` }}>
                  <p style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>{item.notification_in_app_title}</p>
                  <p style={{ fontSize: '0.875rem', margin: '5px 0px' }}>{item.notification_in_app_detailed_description}</p>
                  <p style={{ fontSize: '0.875rem', color: '#7f7f7f' }}>{item.since_created_at_value} {item.since_created_at_unit}</p>
                </div>
              </div>
              {item !== notifications[notifications.length - 1] && <Divider style={{ margin: '10px 0px' }} />}
            </>
          ))}
          {notifications.length === 0 && (<span>{t('SingleCustomerPage.noNotificationGeneral')}</span>)}
        </div>
      </Popover>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;

/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react';
import {
  makeStyles,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  withStyles,
  CardContent,
  CardHeader,
  Divider,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Tooltip,
  TextField,
  Snackbar
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import 'reactjs-popup/dist/index.css';
import 'date-fns';
import AddItemDialog from 'src/components/AddItemDialog';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
// import MaliyetDatabaseSaveDialog from 'src/components/MaliyetDatabaseSaveDialog';
import DeleteMalzemeDialog from 'src/components/DeleteMalzemeDialog';
import axiosInstance from 'src/utils/axiosApi';
import Spinner from 'src/components/Spinner/Spinner';
import MuiAlert from '@material-ui/lab/Alert';
// import DeleteDialog from 'src/components/DeleteDialog';
// import rows from './FakeData/dummyVars';
// import ProjectChips from './projectChips';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: theme.spacing(3)
  },
}));

const StyledTextField = withStyles(() => ({
  root: {
    width: '100px',
    fontFamily: 'Poppins',
  },
}))(TextField);

/* const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '90px'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase); */

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins'
  },
}))(TableCell);

const StyledDialogTitle = withStyles({
  root: {
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingBottom: 0
  }
})(DialogTitle);

const DBComponent = () => {
  const { t, } = useTranslation();
  const classes = useStyles();
  const [malzemeler, setMalzemeler] = useState({});
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const fetchMalzemeler = () => {
    setLoading(true);
    axiosInstance.get('/malzeme_veritabani/fetch?format=json')
      .then((response) => {
        setMalzemeler(response.data.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  const handleUpdate = () => {
    // http://api.localhost/settings/save?domain=localhost&variables=[mail_gonder|maliyet_x|maliyet_y|maliyet_z|mw_alan|panel_gucu|kayip_yuzdesi|co2_katsayi|agac_katsayi|komisyon_orani]
    axiosInstance.post('malzeme_veritabani/update?format=json', malzemeler)
      .then((response) => {
        setOpen(false);
        if (response.data.status) {
          // window.location.reload();
          fetchMalzemeler();
          setSnackbarMessage(response.data.message);
          setSnackbarType('success');
          setSnackbarState(true);
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
        }
      })
      .catch((error) => {
        setOpen(false);
        setSnackbarMessage(t('AlertMessage.generalError'));
        setSnackbarType('warning');
        setSnackbarState(true);
        console.log(error);
      });
  };

  const handleChangeInput = (event) => {
    // console.log(malzemeler);
    const newData = malzemeler.map((malzeme) => {
      if (malzeme.malzeme_id === Number(event.target.id)) {
        /* console.log(event.target.name, event.target.value);
        console.log(event.target.type); */
        return {
          ...malzeme,
          [event.target.name]: event.target.value
        };
      }
      return malzeme;
    });
    setMalzemeler(newData);
  };

  const willMount = useRef(true);
  if (willMount.current) {
    fetchMalzemeler();
    willMount.current = false;
  }

  const generateRows = () => {
    // console.log('malzemeler:', malzemeler);
    const tempSortedMalzemeler = malzemeler.slice().sort((a, b) => {
      const textA = a.artis.toUpperCase();
      const textB = b.artis.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    // console.log(tempSortedMalzemeler);
    return tempSortedMalzemeler.map((row) => {
      return (
        <TableRow key={row.malzeme_id}>
          <StyledTableCell component="th" scope="row">
            {row.kalem}
          </StyledTableCell>
          <StyledTableCell align="left">{row.marka_model}</StyledTableCell>
          <StyledTableCell align="left">{row.power_value}</StyledTableCell>
          <StyledTableCell align="left">
            {row.adet_fiyat !== '-'
            && (
            <FormControl>
              <StyledTextField
                name="adet_fiyat"
                type="number"
                // defaultValue={row.pieces}
                value={row.adet_fiyat}
                id={row.malzeme_id}
                inputProps={{ min: 0, step: 1 }}
                onChange={handleChangeInput}
                size="small"
                variant="outlined"
                error={row.adet_fiyat === ''}
                helperText={row.adet_fiyat === '' ? 'Boş bırakılamaz!' : ''}
                disabled={(row.material_type === 'solar_panel')}
              />
            </FormControl>
            )}
            {row.adet_fiyat === '-' && <span>-</span>}
          </StyledTableCell>
          <StyledTableCell align="left">
            {row.wattpeak !== '-'
            && (
            <FormControl>
              <StyledTextField
                name="wattpeak"
                type="number"
                disabled={(row.material_type === 'inverter')}
                value={row.wattpeak}
                id={String(row.malzeme_id)}
                inputProps={{ min: 0, step: 0.01 }}
                onChange={handleChangeInput}
                size="small"
                variant="outlined"
                error={row.wattpeak === ''}
                helperText={row.wattpeak === '' ? 'Boş bırakılamaz!' : ''}
              />
            </FormControl>
            )}
            {row.wattpeak === '-' && <span>-</span>}
          </StyledTableCell>
          <StyledTableCell align="left">{t(`MaterialsPage.${row.artis}`)}</StyledTableCell>
          <StyledTableCell align="left">
            <DeleteMalzemeDialog malzeme_id={row.malzeme_id} />
          </StyledTableCell>
        </TableRow>
      );
    });
  };

  if (loading) {
    return (
      <>
        <Spinner text={t('Loader.materialsLoading')} />
      </>
    );
  }

  return (
    <>
      <CardHeader
        title={t('MaterialsPage.databaseTab')}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={12}
            lg={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{t('MaterialsPage.category')}</StyledTableCell>
                    <StyledTableCell align="left">
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <span style={{ marginRight: '5px' }}>{t('MaterialsPage.brandModel')}</span>
                        <Tooltip placement="top" title={t('MaterialsPage.brandModelDescription')}>
                          <InfoIcon style={{ color: '#b1b1b1' }} />
                        </Tooltip>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <span style={{ marginRight: '5px' }}>{t('MaterialsPage.powerValue')}</span>
                        <Tooltip placement="top" title={t('MaterialsPage.powerValueDescription')}>
                          <InfoIcon style={{ color: '#b1b1b1' }} />
                        </Tooltip>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <span style={{ marginRight: '5px' }}>{`${t('MaterialsPage.pcsPrice')} ($)`}</span>
                        <Tooltip placement="top" title={t('MaterialsPage.pcsPriceDescription')}>
                          <InfoIcon style={{ color: '#b1b1b1' }} />
                        </Tooltip>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <span style={{ marginRight: '5px' }}>/Wp ($)</span>
                        <Tooltip placement="top" title={t('MaterialsPage.wpDescription')}>
                          <InfoIcon style={{ color: '#b1b1b1' }} />
                        </Tooltip>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <span style={{ marginRight: '5px' }}>{t('MaterialsPage.incerement')}</span>
                        <Tooltip placement="top" title={t('MaterialsPage.incerementDescription')}>
                          <InfoIcon style={{ color: '#b1b1b1' }} />
                        </Tooltip>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left"> </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!(_.isEmpty(malzemeler)) && generateRows()}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            item
            md={6}
            lg={6}
            xs={6}
          >
            <AddItemDialog />
          </Grid>
          <Grid
            item
            md={6}
            lg={6}
            xs={6}
          >
            <Box
              display="flex"
              justifyContent="flex-end"
            >
              {/* <MaliyetDatabaseSaveDialog
                maliyetTablosu={malzemeler}
              /> */}
              <div>
                <Button
                  color="primary"
                  endIcon={<ArrowRightIcon />}
                  size="small"
                  variant="contained"
                  onClick={handleClickOpen}
                >
                  {t('Button.update')}
                </Button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <StyledDialogTitle id="alert-dialog-title" disableTypography>{t('ConfigurationPage.ayarlarıDegistirmeSorusu')}</StyledDialogTitle>
                  <DialogContent>
                    <p style={{ padding: '8px 0' }}>
                      {t('MaterialsPage.infoUpdateMaterials')}
                    </p>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      {t('ConfigurationPage.geriDonButonu')}
                    </Button>
                    <Button onClick={handleUpdate} color="primary" variant="contained">
                      {t('ConfigurationPage.kaydetButon')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DBComponent;

/* eslint-disable max-len */
/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  makeStyles,
  CardContent,
  Grid,
  Container,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import Page from 'src/components/Page';
import 'date-fns';
import { changeMaliyetTabNo } from 'src/services/products/maliyetReducer';
import classes from './maliyetWrapper.module.css';
import MaliyetVeriTabanı from './materials/materialDatabase';
import SıfırOnkW from './materials/selectedMaterials';
import MaliyetTest from './maliyetTest';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: 0,
    paddingBottom: 0
  },
  /* cardContentRoot: {
    paddingBottom: 0
  } */
}));
const FirmaBilgileri = ({ className, ...rest }) => {
  const { t, } = useTranslation();
  const MUIclasses = useStyles();
  const dispatch = useDispatch();
  const { maliyetTabNo } = useSelector((state) => state.maliyet);

  const handleChangeTab = (newValue) => {
    dispatch(changeMaliyetTabNo({ maliyetTabNo: newValue }));
  };

  // const largeYerlesim = 3;

  return (
    <Page
      className={MUIclasses.root}
      title={`solarVis | ${t('MaterialsPage.databaseTab')}`}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card
              className={clsx(MUIclasses.cardRoot, className)}
              {...rest}
            >
              <CardContent
                className={clsx(MUIclasses.cardContentRoot, className)}
                style={{ display: 'flex', justifyContent: 'space-around', alignContent: 'center',flexWrap: 'wrap'}}
              >
                <button type="button" index={0} onClick={() => handleChangeTab(0)} className={classes.stepperButton}><span className={maliyetTabNo === 0 ? classes.buttonTextUnderline : classes.buttonText}>{t('MaterialsPage.database')}</span></button>
                <button type="button" index={1} onClick={() => handleChangeTab(1)} className={classes.stepperButton}><span className={maliyetTabNo === 1 ? classes.buttonTextUnderline : classes.buttonText}>0-25 kWp</span></button>
                <button type="button" index={2} onClick={() => handleChangeTab(2)} className={classes.stepperButton}><span className={maliyetTabNo === 2 ? classes.buttonTextUnderline : classes.buttonText}>25-100 kWp</span></button>
                <button type="button" index={3} onClick={() => handleChangeTab(3)} className={classes.stepperButton}><span className={maliyetTabNo === 3 ? classes.buttonTextUnderline : classes.buttonText}>100-500 kWp</span></button>
                <button type="button" index={4} onClick={() => handleChangeTab(4)} className={classes.stepperButton}><span className={maliyetTabNo === 4 ? classes.buttonTextUnderline : classes.buttonText}>500+ kWp</span></button>
                <button type="button" index={5} onClick={() => handleChangeTab(5)} className={classes.stepperButton}><span className={maliyetTabNo === 5 ? classes.buttonTextUnderline : classes.buttonText}>{t('MaterialsPage.test')}</span></button>
              </CardContent>
            </Card>
          </Grid>
          {!(maliyetTabNo === 5) && (
            <>
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <Alert severity="info">
                  {maliyetTabNo === 0 && <span>{t('MaterialsPage.tab1Info')}</span>}
                  {maliyetTabNo === 1 && <span>{t('MaterialsPage.tab2Info')}</span>}
                  {maliyetTabNo === 2 && <span>{t('MaterialsPage.tab3Info')}</span>}
                  {maliyetTabNo === 3 && <span>{t('MaterialsPage.tab4Info')}</span>}
                  {maliyetTabNo === 4 && <span>{t('MaterialsPage.tab5Info')}</span>}
                </Alert>
              </Grid>
            </>
          )}
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card
              className={clsx(MUIclasses.cardRoot, className)}
              {...rest}
            >
              {maliyetTabNo === 0 && <MaliyetVeriTabanı />}
              {maliyetTabNo === 1 && <SıfırOnkW gucAralıgı="sifir_on_kwp" title="0 - 25 kWp" />}
              {maliyetTabNo === 2 && <SıfırOnkW gucAralıgı="on_yuz_kwp" title="25 - 100 kWp" />}
              {maliyetTabNo === 3 && <SıfırOnkW gucAralıgı="yuz_besyuz_kwp" title="100 - 500 kWp" />}
              {maliyetTabNo === 4 && <SıfırOnkW gucAralıgı="besyuz_uzeri_kwp" title="500+ kWp" />}
              {maliyetTabNo === 5 && <MaliyetTest />}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

FirmaBilgileri.propTypes = {
  className: PropTypes.string
};

export default FirmaBilgileri;

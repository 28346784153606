import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { changeCurrentPage } from 'src/services/products/filterReducer';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const StyledPagination = withStyles({
  ul: {
    justifyContent: 'center'
  }
})(Pagination);

const PaginationButtons = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { totalPageNumbers, currentPage } = useSelector((state) => state.filters);

  const handleChange = (event, value) => {
    dispatch(changeCurrentPage({ currentPage: value }));
  };

  return (
    <div className={classes.root}>
      <StyledPagination
        count={totalPageNumbers} // sayfa sayısı
        size="large"
        onChange={handleChange}
        page={currentPage}
      />
    </div>
  );
};

export default PaginationButtons;

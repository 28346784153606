/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import axiosInstance from 'src/utils/axiosApi';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles,
  Fab
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import { useMediaQuery } from 'react-responsive';

const StyledFab = withStyles({
  root: {
    backgroundColor: '#ffffff',
    color: '#3f51b5',
    '&:hover': {
      color: '#ffffff',
    },
  }
})(Fab);

const StyledDialogTitle = withStyles({
  root: {
    fontSize: '1rem',
  }
})(DialogTitle);

const StyledDialogContent = withStyles({
  root: {
    marginBottom: '30px'
  }
})(DialogContent);

const Preview = ({ meta }) => {
  const {
    name,
    percent,
    previewUrl
  } = meta;
  return (
    <>
      <img style={{ width: '60%' }} src={previewUrl} alt={name} />
      <span style={{ margin: '10px 3%' }}>
        {name}
        ,
        {Math.round(percent)}
        %
      </span>
    </>
  );
};

const SubmitButton = (props) => {
  const { text } = props;
  return (
    <>
      <Button
        color="primary"
        size="small"
        variant="contained"
      >
        Yükle
      </Button>
    </>
  );
};

export default function PackageImageUploadDialog(props) {
  const {
    packageId,
    pageType,
    handleChangePackageImage,
    ...rest
  } = props;
  const { t, } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fileParams = ({ file, meta }) => {
    return { url: 'https://httpbin.org/post' };
  };

  const uploadAPI = async (uploadFile) => {
    await axiosInstance.post('solar_package/update_solar_package_image', uploadFile, {
      Headers: { contentType: 'multipart/form-data' }
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpload = (files, allFiles) => {
    files.map((file) => {
      const fileNameArray = file.file.name.split('.');
      const extension = fileNameArray[fileNameArray.length - 1];

      const body = new FormData();
      body.append('solar_package_image', file.file);
      body.append('solar_package_id', packageId);

      const fileURL = URL.createObjectURL(file.file);
      handleChangePackageImage(fileURL);

      uploadAPI(body);

      return file;
    });
    setOpen(false);
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const selectFileInput = ({
    accept, onFiles, files
  }) => {
    const textMsg = t('SolarPackagesPage.uploadImageButton');

    return (
      <>
        <label
          className="btn mt-4"
          style={{
            width: '50%', backgroundColor: '#cbddea', padding: '10px', cursor: 'pointer', borderRadius: '8px', textAlign: 'center'
          }}
        >
          {textMsg}
          <input
            style={{ display: 'none' }}
            type="file"
            accept={accept}
            multiple
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles);
              });
            }}
          />
        </label>
        <span style={{ color: '#a6a6a5', fontSize: '13px', marginTop: '10px' }}>{t('ConfigurationPage.fileExtensions')}</span>
      </>
    );
  };

  return (
    <>
      <div style={{ position: 'absolute', right: '3rem', bottom: '3rem' }}>
        <StyledFab color="primary" aria-label="edit" onClick={() => handleClickOpen()}>
          <EditIcon />
        </StyledFab>
      </div>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title" disableTypography>{t('SolarPackagesPage.uploadImageDialogText')}</StyledDialogTitle>
        <StyledDialogContent>
          <Dropzone
            onSubmit={handleUpload}
            PreviewComponent={Preview}
            // SubmitButtonComponent={SubmitButton}
            // onChangeStatus={onFileChange}
            InputComponent={selectFileInput}
            getUploadParams={fileParams}
            getFilesFromEvent={getFilesFromEvent}
            submitButtonContent={t('SolarPackagesPage.upload')}
            accept="image/*"
            maxFiles={1}
            inputContent={t('ConfigurationPage.logoYükle')}
            styles={{
              dropzone: {
                width: isDesktopOrLaptop ? 600 : '100%', height: 400, justifyContent: 'center', overflowX: 'hidden', overflowY: 'scroll'
              },
              dropzoneActive: { borderColor: 'green' },
              previewImage: { maxHeight: '100px', maxWidth: '300px' },
              preview: { minHeight: 'auto' }
            }}
          />
        </StyledDialogContent>
      </Dialog>
    </>
  );
}

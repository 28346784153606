import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessToken: localStorage.getItem('access') || null,
  refreshToken: localStorage.getItem('refresh') || null,
  tokenExpiry: localStorage.getItem('expiry') || null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeAccessToken: (state, action) => {
      state.accessToken = action.payload.accessToken;
      localStorage.setItem('access', action.payload.accessToken);
    },
    changeRefreshToken: (state, action) => {
      state.refreshToken = action.payload.refreshToken;
      localStorage.setItem('refresh', action.payload.refreshToken);
    },
    changeTokenExpiry: (state, action) => {
      state.tokenExpiry = action.payload.tokenExpiry;
      localStorage.setItem('expiry', action.payload.tokenExpiry);
    },
    resetStep: () => initialState,
  }
});

// Action creators are generated for each case reducer function
export const {
  changeAccessToken,
  changeRefreshToken,
  changeTokenExpiry,
  resetStep
} = authSlice.actions;

export default authSlice.reducer;

/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
/* eslint-disable array-callback-return */
/* eslint-disable no-lonely-if */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/self-closing-comp */
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import i18n from 'src/i18n';
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  withStyles,
  Card,
  CardMedia,
  Divider,
  CardContent,
  CardActions,
  IconButton,
  Checkbox,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import axiosInstance from 'src/utils/axiosApi';
import Page from 'src/components/Page';
// import Dialog from 'src/components/Dialog';
import BootstrapSelect from 'src/components/BootstrapSelect';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeTotalPageNumbers,
  resetState,
} from 'src/services/products/filterReducer';
import DeleteDialog from 'src/components/DeleteDialog';
import StarIcon from '@material-ui/icons/Star';
import CloseIcon from '@material-ui/icons/Close';
// import BackspaceIcon from '@material-ui/icons/Backspace';
import FilterListIcon from '@material-ui/icons/FilterList';
import PaginationButtons from 'src/components/Pagination';
import Filter from 'src/components/FilterListComp';
import { useMediaQuery } from 'react-responsive';
import { IconContext } from 'react-icons/lib';
import { FaFilePdf, FaFileExcel } from 'react-icons/fa';
import Spinner from 'src/components/Spinner/Spinner';
import download from 'downloadjs';
// Date Picker Related Libraries and Fuctions
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import tr from 'date-fns/locale/tr';

registerLocale('tr', tr);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  tabRoot: {
    width: 'fit-content'
  },
  list: {
    padding: '5px',
  },
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
    fontSize: 16,
    backgroundColor: '#f7e2e2',
    color: '#ad3737'
  },
  action: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  card: {
    width: '100%',
    position: 'relative'
  },
}));

const StyledTypography = withStyles({
  root: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    '@media (max-width:900px)': {
      fontSize: 14
    },
    textAlign: 'start'
  }
})(Typography);

const StyledOutlinedInput = withStyles({
  input: {
    padding: '12px 14px'
  }
})(OutlinedInput);

const StyledIconButton = withStyles({
  root: {
    position: 'absolute',
    top: 6,
    right: 6,
    color: '#f5a81e'
  }
})(IconButton);

const StyledIconButtonGray = withStyles({
  root: {
    position: 'absolute',
    top: 6,
    right: 6,
    color: '#a4a3a3'
  }
})(IconButton);

const StyledCardContent = withStyles({
  root: {
    position: 'relative'
  }
})(CardContent);

const StyledDivider = withStyles({
  root: {
    margin: '0px 6px'
  }
})(Divider);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins'
  },
}))(TableCell);

const StyledTableCellHead = withStyles(() => ({
  root: {
    padding: 10,
    fontFamily: 'Poppins',
    fontWeight: 'bold'
  },
}))(TableCell);

// http://solarvis.co/api/musteri/fetch?firma_kodu=electravis&talep=1

const Dashboard = (props) => {
  const {
    apiRequestURL,
    title,
    requestStatus
  } = props;
  const { t, } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    leadPerPage,
    currentPage
  } = useSelector((state) => state.filters);
  const {
    statusIds,
    availableModules
  } = useSelector((state) => state.module);
  const {
    company,
    loggedIn,
  } = useSelector((state) => state.users);

  const [proposalRequests, setProposalRequests] = useState([]);

  const [filterOn, setFilterOn] = useState(false);
  const [leadNumbers, setLeadNumbers] = useState();
  const [loadingReport, setLoadingReport] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [isSubconModule, setIsSubconModule] = useState(false);

  const [filters, setFilters] = useState({
    cities: [],
    starred: false,
    facilities: []
  });

  const [orderBy, setOrderBy] = useState('descCreatedAt');
  const [searchWord, setSearchWord] = useState('');

  // Static Variables
  const creditApplicationStatusLookup = {
    APPROVED: {
      colorCode: '#4caf50',
      text: 'Onaylandı'
    },
    REJECTED: {
      colorCode: '#f44336',
      text: 'Reddedildi'
    },
    APPLIED: {
      colorCode: '#ff9800',
      text: 'Teklif Bekleniyor'
    },
    OFFERED: {
      colorCode: '#2196f3',
      text: 'Teklif Verildi'
    },
    MISSED: {
      colorCode: '#f44336',
      text: 'Müşteri Kaçırıldı'
    },
    NOT_APPLIED: {
      colorCode: '#706f6f',
      text: 'Başvuru Yapılmadı'
    }
  };

  // Date Picker Related States and Component
  const today = new Date();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(today.setDate(today.getDate() + 1));
  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button style={{ padding: '5px', fontSize: '1rem' }} onClick={onClick} ref={ref}>
      {value ? value : t('Filtre.choose')}
    </button>
  ));

  // Hook
  function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }

  // Get the previous value (was passed into hook on last render)
  const prevSearchWord = usePrevious(searchWord);

  const changeFilter = (filterType, value) => {
    setFilters({
      ...filters,
      [filterType]: value
    });
  };

  // const [currentPage, setCurrentPage ] = useState(1);

  // ------------ DETERMINE MOBILE OR DESKTOP ---------- //

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' });

  // --------------------------------------------------- //

  const handleYildiz = (event) => {
    setFilters({
      ...filters,
      starred: event.target.checked
    });
  };

  function getProposals(searchBy) {
    setPageLoading(true);
    let statusId;

    axiosInstance.get(`${apiRequestURL}?order_by=${orderBy}&from=${(currentPage - 1) * leadPerPage}&to=${currentPage * leadPerPage}`)
      .then((response) => {
        const { data } = response.data;
        if (data.hasOwnProperty('credit_applications') && data.credit_applications.length > 0) {
          setLeadNumbers(data.length);
          setProposalRequests(data.credit_applications);
          dispatch(changeTotalPageNumbers({
            totalPageNumbers: Math.ceil(Number(data.length) / leadPerPage)
          }));
        } else {
          setLeadNumbers(0);
        }
      })
      .catch((error) => console.log(error));
    setPageLoading(false);
  }

  if (!loggedIn) {
    navigate('/login', { replace: true });
  }

  const willMount = useRef(true);

  useEffect(() => {
    if (willMount.current === true) {
      dispatch(resetState());
      getProposals(searchWord);
      willMount.current = false;
    } else {
      getProposals(searchWord);
    }
  }, [filters, currentPage, statusIds, orderBy, startDate, endDate]);

  useEffect(() => {
    if (searchWord.length > 2 || (prevSearchWord?.length > 0 && searchWord.length === 0)) {
      getProposals(searchWord);
    }
  }, [searchWord]);

  useEffect(() => {
    if (availableModules.length > 0 && availableModules.includes('subcon_detailed')) {
      setIsSubconModule(true);
    }
  }, [availableModules]);

  if (loadingReport) {
    return (
      <>
        <Spinner text={t('ProjeTalepleriSayfası.raporOlusturuluyor')} />
      </>
    );
  }

  if (pageLoading) {
    return (
      <>
        <Spinner text="" />
      </>
    );
  }

  if (!leadNumbers) {
    return (
      <Page
        className={classes.root}
        title={title}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            {location.state
              && (
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <h4 style={{ fontWeight: 'normal' }}>{`${location.state.data.title} - ${location.state.data.explanation}`}</h4>
                </Grid>
              )}
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              {filterOn && (
                <div style={{
                  alignItems: 'center',
                  display: 'flex',
                  backgroundColor: 'white',
                  borderRadius: '6px',
                  justifyContent: 'center',
                  flexDirection: isDesktopOrLaptop ? 'row' : 'column'
                }}
                >
                  <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    margin: '0px 10px'
                  }}
                  >
                    <Checkbox
                      checked={filters.starred}
                      onChange={(event) => handleYildiz(event)}
                      color="default"
                      inputProps={{ 'aria-label': 'checkbox with default color' }}
                    />
                    <span>{t('Filtre.yildiz')}</span>
                  </div>
                  <StyledDivider orientation="vertical" flexItem />
                  {!(['solarVis Germany', 'solarVis Florida', 'Skywells Energy', 'Lumen United'].includes(company))
                    && (
                      <Filter
                        changeFilterFunction={changeFilter}
                        filterType="cities"
                        filterValue={filters.cities}
                      />
                    )}
                  <Filter
                    changeFilterFunction={changeFilter}
                    filterType="facilities"
                    filterValue={filters.facilities}
                  />
                  <StyledDivider orientation="vertical" flexItem />
                  <span style={{ marginRight: 20, marginLeft: 20 }}>
                    {t('Filtre.toplam')}
                    :
                    <span>  </span>
                    <strong>{leadNumbers}</strong>
                  </span>
                  <div style={{
                    justifyContent: 'flex-end',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  >
                    <IconButton color="default" aria-label="Filtrelemeyi kapat" onClick={() => setFilterOn(false)}>
                      <CloseIcon />
                    </IconButton>
                    <span>{t('Filtre.kapat')}</span>
                  </div>
                </div>
              )}
              {!filterOn && (
                <div style={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: isDesktopOrLaptop ? 'row' : 'column'
                }}
                >
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                  >
                    <FormControl style={{ minWidth: '300px', backgroundColor: 'white' }} variant="outlined">
                      <StyledOutlinedInput
                        id="outlined-adornment-search"
                        type="text"
                        value={searchWord}
                        onChange={(e) => setSearchWord(e.target.value)}
                        placeholder={t('Filtre.searchPlaceholder')}
                        autoFocus={searchWord.length > 2}
                        endAdornment={(
                          <InputAdornment position="end">
                            <div>
                              {searchWord && (
                                <IconButton
                                  aria-label="search"
                                  edge="end"
                                  onClick={() => {
                                    setSearchWord('');
                                    setProposalRequests([]);
                                    getProposals('');
                                  }}
                                >
                                  <ClearIcon />
                                </IconButton>
                              )}
                              {!searchWord && (
                                <IconButton
                                  aria-label="search"
                                  edge="end"
                                  color="primary"
                                >
                                  <SearchIcon />
                                </IconButton>
                              )}
                            </div>
                          </InputAdornment>
                        )}
                      />
                    </FormControl>
                    {searchWord.length < 3 && searchWord.length > 0 && (
                      <span>{t('Filtre.minCharacterWarn')}</span>
                    )}
                  </div>
                  <div>
                    <span style={{ marginRight: 20 }}>
                      {t('Filtre.toplam')}
                      :
                      <span>  </span>
                      <strong>{leadNumbers}</strong>
                    </span>
                    <IconButton color="default" aria-label="Filtrele" onClick={() => setFilterOn(true)}>
                      <FilterListIcon />
                    </IconButton>
                    <span>{t('Filtre.filtre')}</span>
                  </div>
                </div>
              )}
            </Grid>
            <div style={{
              height: '40vh',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center'
            }}
            >
              <span style={{ fontSize: '1.2rem' }}>Bu aşamada henüz kredi başvurusu bulunmamaktadır.</span>
            </div>
          </Grid>
        </Container>
      </Page>
    );
  }

  return (
    <Page
      className={classes.root}
      title={title}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          {location.state
            && (
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <h4 style={{ fontWeight: 'normal' }}>{`${location.state.data.title} - ${location.state.data.explanation}`}</h4>
              </Grid>
            )}
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            {filterOn && (
              <div style={{
                alignItems: 'center',
                display: 'flex',
                backgroundColor: 'white',
                borderRadius: '6px',
                justifyContent: 'center',
                flexDirection: isDesktopOrLaptop ? 'row' : 'column'
              }}
              >
                <div style={{
                  alignItems: 'center',
                  display: 'flex',
                  margin: '0px 10px'
                }}
                >
                  <Checkbox
                    checked={filters.starred}
                    onChange={(event) => handleYildiz(event)}
                    color="default"
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                  />
                  <span>{t('Filtre.yildiz')}</span>
                </div>
                <StyledDivider orientation="vertical" flexItem />
                {!(['solarVis Germany', 'solarVis Florida', 'Skywells Energy', 'Lumen United'].includes(company))
                  && (
                    <Filter
                      changeFilterFunction={changeFilter}
                      filterType="cities"
                      filterValue={filters.cities}
                    />
                  )}
                <Filter
                  changeFilterFunction={changeFilter}
                  filterType="facilities"
                  filterValue={filters.facilities}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <div style={{ padding: '0px 10px', width: 'min-content' }}>
                    <span style={{ fontSize: '0.8rem' }}>{t('Filtre.beginning')}</span>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      locale={(localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') ? 'en' : 'tr'}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={<ExampleCustomInput />}
                    />
                  </div>
                  <div>
                    <span style={{ fontSize: '0.8rem' }}>{t('Filtre.ending')}</span>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      locale={(localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') ? 'en' : 'tr'}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={<ExampleCustomInput />}
                    />
                  </div>
                </div>
                <StyledDivider orientation="vertical" flexItem />
                <span style={{ marginRight: 20, marginLeft: 20 }}>
                  {t('Filtre.toplam')}
                  :
                  <span>  </span>
                  <strong>{leadNumbers}</strong>
                </span>
                <div style={{
                  justifyContent: 'flex-end',
                  display: 'flex',
                  alignItems: 'center',
                }}
                >
                  <IconButton color="default" aria-label="Filtrelemeyi kapat" onClick={() => setFilterOn(false)}>
                    <CloseIcon />
                  </IconButton>
                  <span>{t('Filtre.kapat')}</span>
                </div>
              </div>
            )}
            {!filterOn && (
              <div style={{
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'center',
                flexDirection: isDesktopOrLaptop ? 'row' : 'column'
              }}
              >
                <div style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
                >
                  <FormControl style={{ minWidth: '300px', backgroundColor: 'white' }} variant="outlined">
                    <StyledOutlinedInput
                      id="outlined-adornment-search"
                      type="text"
                      value={searchWord}
                      onChange={(e) => setSearchWord(e.target.value)}
                      placeholder={t('Filtre.searchPlaceholder')}
                      autoFocus={searchWord.length > 2}
                      endAdornment={(
                        <InputAdornment position="end">
                          <div>
                            {searchWord && (
                              <IconButton
                                aria-label="search"
                                edge="end"
                                onClick={() => {
                                  setSearchWord('');
                                  setProposalRequests([]);
                                  getProposals('');
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            )}
                            {!searchWord && (
                              <IconButton
                                aria-label="search"
                                edge="end"
                                color="primary"
                              >
                                <SearchIcon />
                              </IconButton>
                            )}
                          </div>
                        </InputAdornment>
                      )}
                    />
                  </FormControl>
                  {searchWord.length < 3 && searchWord.length > 0 && (
                    <span>{t('Filtre.minCharacterWarn')}</span>
                  )}
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: isDesktopOrLaptop ? 'row' : 'column',
                  alignItems: 'center'
                }}
                >
                  <div style={{ marginRight: isDesktopOrLaptop ? '40px' : '0px', padding: isDesktopOrLaptop ? '0px' : '15px 0px' }}>
                    <span>{`${t('OrderBy.orderBy')}:`}</span>
                    <BootstrapSelect
                      label="order"
                      name="leadsOrderBy"
                      id="leadsOrderBy"
                      value={orderBy}
                      changeFuction={setOrderBy}
                      selectItemValues={
                        ['descCreatedAt', 'ascCreatedAt', 'descCreditRequestAmount', 'ascCreditRequestAmount']
                      }
                      selectItemLabels={
                        ['En Yeni Başvuru', 'En Eski Başvuru', 'Kredi Tutarına Göre (Azalan)', 'Kredi Tutarına Göre (Artan)']
                      }
                      width={isDesktopOrLaptop ? 300 : 220}
                    />
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  >
                    <span style={{ marginRight: 20 }}>
                      {t('Filtre.toplam')}
                      :
                      <span>  </span>
                      <strong>{leadNumbers}</strong>
                    </span>
                    <div style={{
                      borderRadius: 4,
                      backgroundColor: '#007700',
                      padding: 5,
                      cursor: 'pointer',
                      marginRight: 20
                    }}
                    >
                      <div style={{ textDecoration: 'none' }}>
                        <IconContext.Provider value={{ color: '#ffffff', size: 20 }}>
                          <FaFileExcel />
                        </IconContext.Provider>
                      </div>
                    </div>
                    <IconButton color="default" aria-label="Filtrele" onClick={() => setFilterOn(true)}>
                      <FilterListIcon />
                    </IconButton>
                    <span>{t('Filtre.filtre')}</span>
                  </div>
                </div>
              </div>
            )}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table aria-label="solar-packages-table">
                <TableHead>
                  <TableRow>
                    <StyledTableCellHead align="left">
                      Durum
                    </StyledTableCellHead>
                    <StyledTableCellHead align="left">
                      Müşteri
                    </StyledTableCellHead>
                    <StyledTableCellHead align="left">
                      EPC
                    </StyledTableCellHead>
                    <StyledTableCellHead align="left">
                      Kredi Tutarı
                    </StyledTableCellHead>
                    <StyledTableCellHead align="left">
                      Kredi Başvuru Tarihi
                    </StyledTableCellHead>
                    {(requestStatus === 'OFFERED' || requestStatus === 'APPROVED')
                      && (
                        <StyledTableCellHead align="left">
                          Teklif Verilme Tarihi
                        </StyledTableCellHead>
                      )}
                    {requestStatus === 'APPROVED'
                      && (
                        <StyledTableCellHead align="left">
                          Teklif Onaylanma Tarihi
                        </StyledTableCellHead>
                      )}
                    <StyledTableCellHead align="center"> </StyledTableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {proposalRequests.map((proposal) => (
                    <TableRow key={proposal.id}>
                      <StyledTableCell align="left">
                        <Chip
                          style={{
                            height: '30px',
                            fontSize: '0.9rem',
                            backgroundColor: creditApplicationStatusLookup[proposal.bank_status].colorCode,
                            color: 'white'
                          }}
                          label={creditApplicationStatusLookup[proposal.bank_status].text}
                          size="medium"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {proposal.kredi_basvuran_isim_soyisim}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {proposal.company}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row" align="left">
                        {`${proposal.credit_request_amount} ₺`}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {String(format(new Date(Date.parse(proposal.created_at)), 'dd/MM/yyyy - HH.mm'))}
                      </StyledTableCell>
                      {(requestStatus === 'OFFERED' || requestStatus === 'APPROVED')
                        && (
                          <StyledTableCell align="left">
                            {String(format(new Date(Date.parse(proposal.proposed_at)), 'dd/MM/yyyy - HH.mm'))}
                          </StyledTableCell>
                        )}
                      {requestStatus === 'APPROVED'
                        && (
                          <StyledTableCell align="left">
                            {String(format(new Date(Date.parse(proposal.approved_at)), 'dd/MM/yyyy - HH.mm'))}
                          </StyledTableCell>
                        )}
                      <StyledTableCell align="left" style={{ width: '200px' }}>
                        <Link to={`/credit-applications/${proposal.id}`} state={{ data: { projectId: proposal.musteri_id_or_subcon_id } }}>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ textTransform: 'none' }}
                          >
                            Detaylı Görüntüle
                          </Button>
                        </Link>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <PaginationButtons />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
import React /* { useState, useRef } */ from 'react';
import {
  makeStyles,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputBase,
  withStyles,
  CardHeader,
  CardContent,
  InputAdornment,
  Divider,
  Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import 'reactjs-popup/dist/index.css';
import 'date-fns';
// import panelsDb from './FakeData/dummyVars2';
import axiosInstance from 'src/utils/axiosApi';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: theme.spacing(3)
  },
  formControl: {
    margin: 0,
    width: '80%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    maxWidth: 500,
    minWidth: 400,
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '60px'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 12,
    fontFamily: 'Poppins'
  },
}))(TableCell);

const StyledTableContainer = withStyles(() => ({
  root: {
    marginTop: 30,
  },
}))(TableCell);

const StyledButton = withStyles(() => ({
  root: {
    marginLeft: 20,
  },
}))(Button);

const MaliyetTest = () => {
  const { t, } = useTranslation();
  const classes = useStyles();

  // Guc degeri
  const [guc, setGuc] = React.useState(10);

  // Maliyetler.
  const [maliyetler, setMaliyetler] = React.useState({
    karOncesi: 0,
    karSonrasi: 0,
    beklenmeyenGiderYuzde: 0,
    beklenmeyenGider: 0,
    karMarjiYuzde: 0,
    karMiktari: 0,
    kdvliFinal: 0
  });

  // Seçili olan itemler ilgili güç aralığı
  const [malzemeler, setMalzemeler] = React.useState([]);

  const fetchMaliyet = () => {
    axiosInstance.get(`maliyet_hesapla?dc_guc=${guc}&format=json`)
      .then((response) => {
        // console.log(response.data.response[gucAralıgı]);
        setMalzemeler(response.data.malzeme_listesi);
        setMaliyetler({
          karOncesi: response.data.kar_oncesi_maliyet,
          karSonrasi: response.data.kar_sonrasi_maliyet,
          beklenmeyenGiderYuzde: response.data.beklenmeyen_giderler.yuzde,
          beklenmeyenGider: response.data.beklenmeyen_giderler.gider,
          karMarjiYuzde: response.data.kar_marji.yuzde,
          karMiktari: response.data.kar_marji.kar,
          kdvliFinal: response.data.kdvli_final_maliyet,
        });
      })
      .catch((error) => console.log(error));
  };

  const generateRows = () => {
    // console.log('malzemeler:', malzemeler);
    const tempSortedMalzemeler = malzemeler.slice().sort((a, b) => {
      const textA = a.malzeme_maliyet;
      const textB = b.malzeme_maliyet;
      return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
    });
    return tempSortedMalzemeler.map((row) => {
      return (
        <TableRow key={row.malzeme_id}>
          <StyledTableCell component="th" scope="row">
            {row.kalem}
          </StyledTableCell>
          <StyledTableCell align="left">{row.marka_model}</StyledTableCell>
          <StyledTableCell align="left">{row.adet_fiyat}</StyledTableCell>
          <StyledTableCell align="left">{row.wattpeak}</StyledTableCell>
          <StyledTableCell align="right">{row.malzeme_adet}</StyledTableCell>
          <StyledTableCell align="right">$ {row.malzeme_maliyet}</StyledTableCell>
        </TableRow>
      );
    });
  };

  const handleChangeInput = (event) => {
    setGuc(event.target.value);
  };

  return (
    <>
      <CardHeader
        title={t('MaterialsPage.testTab')}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={12}
            lg={12}
            xs={12}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <FormControl>
                <BootstrapInput
                  name="guc"
                  type="number"
                  value={guc}
                  id="guc"
                  inputProps={{ min: 0, step: 1 }}
                  style={{ width: '150px' }}
                  onChange={handleChangeInput}
                  endAdornment={<InputAdornment position="end">kWp</InputAdornment>}
                />
              </FormControl>
              <StyledButton onClick={fetchMaliyet} color="primary" variant="contained">
                {t('MaterialsPage.calculateButton')}
              </StyledButton>
            </div>
          </Grid>
          {Boolean(maliyetler.karSonrasi)
          && (
          <Grid
            item
            md={12}
            lg={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{t('MaterialsPage.category')}</StyledTableCell>
                    <StyledTableCell align="left">{t('MaterialsPage.brandModel')}</StyledTableCell>
                    <StyledTableCell align="left">{t('MaterialsPage.pcsPrice')} ($)</StyledTableCell>
                    <StyledTableCell align="left">/Wp ($)</StyledTableCell>
                    <StyledTableCell align="right">{t('MaterialsPage.adet')}</StyledTableCell>
                    <StyledTableCell align="right">{t('MaterialsPage.cost')}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {generateRows()}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <StyledTableContainer component={Paper}>
                <Table className={classes.table} aria-label="checkout">
                  <TableBody>
                    <TableRow key="beklenmeyen_giderler">
                      <StyledTableCell component="th" scope="row">
                        {t('MaterialsPage.unexpectedCost')} (%{maliyetler.beklenmeyenGiderYuzde})
                      </StyledTableCell>
                      <StyledTableCell align="right">$ {maliyetler.beklenmeyenGider}</StyledTableCell>
                    </TableRow>
                    <TableRow key="malzeme_maliyet_aratop">
                      <StyledTableCell component="th" scope="row">
                        {t('MaterialsPage.subTotal')}
                      </StyledTableCell>
                      <StyledTableCell align="right">$ {maliyetler.karOncesi}</StyledTableCell>
                    </TableRow>
                    <TableRow key="kar_marji">
                      <StyledTableCell component="th" scope="row">
                        {t('MaterialsPage.profitMargin')} (%{maliyetler.karMarjiYuzde})
                      </StyledTableCell>
                      <StyledTableCell align="right">$ {maliyetler.karMiktari}</StyledTableCell>
                    </TableRow>
                    <TableRow key="toplam">
                      <StyledTableCell component="th" scope="row">
                        <span style={{ fontSize: '1.1rem' }}>{t('MaterialsPage.totalWoutTax')}</span>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <span style={{ fontSize: '1.1rem' }}>$ {maliyetler.karSonrasi}</span>
                      </StyledTableCell>
                    </TableRow>
                    <TableRow key="toplam_kdvli">
                      <StyledTableCell component="th" scope="row">
                        <span style={{ fontSize: '1.1rem' }}>{t('MaterialsPage.totalWithTax')}</span>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <span style={{ fontSize: '1.1rem' }}>$ {maliyetler.kdvliFinal}</span>
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </StyledTableContainer>
            </div>
          </Grid>
          )}
        </Grid>
      </CardContent>
    </>
  );
};

export default MaliyetTest;

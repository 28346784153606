/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axiosInstance from 'src/utils/axiosApi';
import {
  makeStyles,
  withStyles,
  Grid,
  Container,
  Snackbar,
  Divider,
  Card,
  InputBase,
  TableCell,
  TableRow,
  TextField,
  Table,
  CardContent,
  TableBody,
  Select,
  Button,
  MenuItem
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import MuiAlert from '@material-ui/lab/Alert';
import CitiesFilter from 'src/components/FilterListComp';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BootstrapSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins',
    borderBottom: 'none'
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: 0,
    paddingBottom: 0
  },
}));

const SubconUser = (props) => {
  const { t, } = useTranslation();
  const MUIclasses = useStyles();
  const { userId } = useParams();
  const { pageType } = props;

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [adminTypes, setAdminTypes] = useState([]);
  const [adminTypeObjects, setAdminTypeObjects] = useState([]);
  const [subconFirms, setSubconFirms] = useState([]);

  const [subconAdminInfo, setSubconAdminInfo] = useState({
    name: '',
    surname: '',
    phone: '',
    email: '',
    adminType: adminTypes.length > 0 ? adminTypes[0] : '',
  });

  const [subconFirmName, setSubconFirmName] = useState('');

  const titles = {
    name: t('SingleStaffPage.name'),
    surname: t('SingleStaffPage.surname'),
    phone: t('SingleStaffPage.phone'),
    email: t('SingleStaffPage.email'),
    adminType: t('SingleStaffPage.type'),
    subconName: t('SingleStaffPage.subcontractor')
  };

  const placeholders = {
    name: t('SingleStaffPage.namePlaceholder'),
    surname: t('SingleStaffPage.surnamePlaceholder'),
    phone: t('SingleStaffPage.phonePlaceholder'),
    email: t('SingleStaffPage.emailPlaceholder'),
    adminType: t('SingleStaffPage.typePlaceholder'),
    subconName: t('SingleStaffPage.subcontractorPlaceholder')
  };

  const handleChangeSubconAdminInfo = (event) => {
    // Prevents React from resetting its properties:
    event.persist();
    setSubconAdminInfo((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  // Integrate create or update subcon user API call
  const fetchSubconAdminTypes = () => {
    axiosInstance.get('subcon/fetch_admin_types')
      .then((response) => {
        const { status } = response.data;
        if (status) {
          const { data, message } = response.data;
          const adminTypeList = data.map((item) => item.name);
          setAdminTypes(adminTypeList);
          setAdminTypeObjects(data);
        }
      })
      .catch((error) => console.log(error));
  };

  const createUpdateAdminInfo = () => {
    const bodyData = {
      subcon_admin_type: subconAdminInfo.adminType,
      isim: subconAdminInfo.name,
      soy_isim: subconAdminInfo.surname,
      telefon: subconAdminInfo.phone,
      subcon_company: subconFirmName,
      username: subconAdminInfo.email,
    };

    if (pageType === 'newUser') {
      bodyData.username = subconAdminInfo.email;
      bodyData.is_company_superuser = false;
      bodyData.is_subcon_superuser = false;
    }

    axiosInstance.post('subcon/create_update_admin', bodyData)
      .then((response) => {
        const { status } = response.data;
        if (status) {
          // After successful API call, redirect to subcon user list page - /settings/subcontractor-management
          const { data, message } = response.data;
          alert(message);
          window.location.reload();
        }
      })
      .catch((error) => console.log(error));
  };

  const fetchSubconAdmin = () => {
    axiosInstance.get(`subcon/fetch_subcon_admins?is_all=0&admin_id=${userId}`)
      .then((response) => {
        const { status } = response.data;
        if (status) {
          const { data, message } = response.data;
          const subconAdminData = data.admins;
          setSubconAdminInfo({
            name: subconAdminData.isim,
            surname: subconAdminData.soy_isim,
            phone: subconAdminData.telefon,
            email: subconAdminData.email,
            adminType: subconAdminData.type,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  const fetchSubconFirms = () => {
    axiosInstance.get('subcon/fetch_subcontractors?is_all=1&id=47')
      .then((response) => {
        const { status } = response.data;
        if (status) {
          const { data, message } = response.data;
          const subconFirmList = data.subcontractors.map((item) => item.name);
          setSubconFirms(subconFirmList);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  useEffect(() => {
    fetchSubconAdminTypes();
    fetchSubconAdmin();
    fetchSubconFirms();
  }, []);

  return (
    <Page
      className={MUIclasses.root}
      title={`solarVis | ${t('SingleStaffPage.pageTitle')}`}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            {pageType === 'newUser' && <h2 style={{ fontWeight: 'normal' }}>{t('SingleStaffPage.addNewStaff')}</h2>}
            {pageType === 'updateUser' && <h2 style={{ fontWeight: 'normal' }}>{t('SingleStaffPage.updateStaff')}</h2>}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Alert severity="info">
              {pageType === 'newUser' && <span>{t('SingleStaffPage.newStaffMessage')}</span>}
              {pageType === 'updateUser' && <span>{t('SingleStaffPage.updateStaffMessage')}</span>}
            </Alert>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card>
              <div style={{ padding: 16 }}>
                <h4>{t('SingleStaffPage.pageTitle')}</h4>
              </div>
              <Divider />
              <CardContent>
                <Table>
                  <TableBody>
                    {Object.keys(subconAdminInfo).map((infoKey) => (
                      <TableRow
                        key={infoKey}
                      >
                        <StyledTableCell style={{ width: '30%' }}>
                          <strong>{titles[infoKey]}</strong>
                        </StyledTableCell>
                        <StyledTableCell style={{ width: '100%' }}>
                          {infoKey !== 'adminType'
                            && (
                              <TextField
                                // fullWidth
                                style={{ width: '100%' }}
                                name={infoKey}
                                onChange={(e) => handleChangeSubconAdminInfo(e)}
                                required
                                value={subconAdminInfo[infoKey]}
                                placeholder={placeholders[infoKey]}
                                variant="outlined"
                                type="text"
                                size="small"
                                disabled={infoKey === 'email' && pageType === 'updateUser'}
                                multiline
                              />
                            )}
                          {infoKey === 'adminType'
                            && (
                              <Select
                                labelId="admin-type-select"
                                id={infoKey}
                                displayEmpty
                                style={{ width: '100%' }}
                                name={infoKey}
                                input={<BootstrapSelect />}
                                onChange={(e) => handleChangeSubconAdminInfo(e)}
                                value={subconAdminInfo[infoKey]}
                              >
                                {adminTypes.map((adminType) => (
                                  <MenuItem value={adminType}>{adminType}</MenuItem>
                                ))}
                              </Select>
                            )}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                    {subconAdminInfo.adminType.includes('Bayi')
                      && (
                        <TableRow
                          key="subcontractor-name"
                        >
                          <StyledTableCell style={{ width: '30%' }}>
                            <strong>{titles.subconName}</strong>
                          </StyledTableCell>
                          <StyledTableCell style={{ width: '100%' }}>
                            <Select
                              labelId="admin-type-select"
                              id="subconFirmName"
                              displayEmpty
                              style={{ width: '100%' }}
                              name="subconFirmName"
                              input={<BootstrapSelect />}
                              onChange={(e) => setSubconFirmName(e.target.value)}
                              value={subconFirmName}
                            >
                              {subconFirms.map((subconFirm) => (
                                <MenuItem value={subconFirm}>{subconFirm}</MenuItem>
                              ))}
                            </Select>
                          </StyledTableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<SaveIcon />}
                onClick={() => createUpdateAdminInfo()}
              >
                {pageType === 'newUser' && <span>{t('SingleStaffPage.addNewStaff')}</span>}
                {pageType === 'updateUser' && <span>{t('SingleStaffPage.updateStaff')}</span>}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default SubconUser;

/* eslint-disable no-constant-condition */
/* eslint-disable react/no-children-prop */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  withStyles,
  makeStyles,
  Divider,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  InputAdornment,
  InputBase,
  Select,
  MenuItem,
} from '@material-ui/core';

const StyledCardContent = withStyles({
  root: {
    position: 'relative'
  }
})(CardContent);

const BootstrapSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  tabRoot: {
    width: 'fit-content'
  },
  list: {
    padding: '5px',
  },
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
    fontSize: 16,
    backgroundColor: '#f7e2e2',
    color: '#ad3737'
  },
  action: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row'
  },
  card: {
    width: '100%',
  },
}));

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins',
    borderBottom: 'none'
  },
}))(TableCell);

const SolarMaterial = (props) => {
  const { t, } = useTranslation();
  // const { packageId } = useParams();
  const MUIClasses = useStyles();
  const {
    materialType,
    materialInfo,
    changeMaterialFunction,
    selectableMaterials
  } = props;

  console.log(selectableMaterials.material_list_acc_to_manufacturer_company[materialInfo.manufacturer_company]);

  const typeOfField = {
    material_amount_value: 'number',
  };

  const units = {
    solar_panel: {
      material_amount_value: 'adet',
    },
    inverter: {
      material_amount_value: 'adet',
    },
    construction_material: {
      material_amount_value: '',
    },
    electrical_panel: {
      material_amount_value: '',
    },
    solar_cable: {
      material_amount_value: t('SolarPackagesPage.meter'),
    },
  };

  const titles = {
    mainTitles: {
      solar_panel: t('SolarPackagesPage.panel'),
      solar_cable: t('SolarPackagesPage.cable'),
      inverter: t('SolarPackagesPage.inverter'),
      construction_material: t('SolarPackagesPage.construction'),
      electrical_panel: t('SolarPackagesPage.board')
    },
    fieldTitles: {
      material_amount_value: t('SolarPackagesPage.value1'),
    }
  };

  const placeholders = {
    solar_panel: {
      material_amount_value: '12',
    },
    inverter: {
      material_amount_value: '1',
    },
    construction_material: {
      material_amount_value: '',
    },
    electrical_panel: {
      material_amount_value: '',
    },
    solar_cable: {
      material_amount_value: '50',
    },
  };

  return (
    <>
      <Card className={MUIClasses.card}>
        <div style={{ padding: 14 }}>
          <h4>{titles.mainTitles[materialType]}</h4>
        </div>
        <Divider />
        <StyledCardContent>
          <div className={MUIClasses.list}>
            <Table>
              <TableBody>
                <TableRow
                  key="manufacturer_company"
                >
                  <StyledTableCell style={{ width: '30%' }}>
                    <strong>{t('SolarPackagesPage.brandOnly')}</strong>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '70%' }}>
                    <Select
                      labelId="currency-select"
                      id="manufacturer_company"
                      name="manufacturer_company"
                      value={materialInfo.manufacturer_company}
                      displayEmpty
                      onChange={(event) => changeMaterialFunction(event, materialType)}
                      label={t('SolarPackagesPage.brandOnly')}
                      input={<BootstrapSelect />}
                      style={{ width: '100%', maxWidth: 200 }}
                    >
                      {selectableMaterials.available_manufacturer_companies.map((company) => (
                        <MenuItem value={company}>{company}</MenuItem>
                      ))}
                    </Select>
                  </StyledTableCell>
                </TableRow>
                <TableRow
                  key="product_name"
                >
                  <StyledTableCell style={{ maxWidth: '30%' }}>
                    <strong>{t('SolarPackagesPage.modelOnly')}</strong>
                  </StyledTableCell>
                  <StyledTableCell style={{ maxWidth: '70%' }}>
                    <Select
                      labelId="model-select"
                      id="material_id"
                      name="material_id"
                      value={materialInfo.material_id}
                      displayEmpty
                      onChange={(event) => changeMaterialFunction(event, materialType)}
                      label={t('SolarPackagesPage.modelOnly')}
                      input={<BootstrapSelect />}
                      style={{ width: '100%', maxWidth: 200 }}
                    >
                      {selectableMaterials.material_list_acc_to_manufacturer_company[materialInfo.manufacturer_company].map((material) => (
                        <MenuItem value={material.material_id}>{material.product_name}</MenuItem>
                      ))}
                    </Select>
                  </StyledTableCell>
                </TableRow>
                {['solar_panel', 'inverter', 'solar_cable'].includes(materialType) && (
                <TableRow
                  key="material_amount_value"
                >
                  <StyledTableCell style={{ width: '30%' }}>
                    <strong> </strong>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '70%' }}>
                    <TextField
                      style={{ width: '100%', maxWidth: 200 }}
                      fullWidth
                      name="material_amount_value"
                      onChange={(event) => changeMaterialFunction(event, materialType)}
                      required
                      value={materialInfo.material_amount_value}
                      variant="outlined"
                      type={typeOfField.material_amount_value}
                      error={(materialInfo.material_amount_value === '' || materialInfo.material_amount_value === 0)}
                      helperText={(materialInfo.material_amount_value === '' || materialInfo.material_amount_value === 0) ? 'Bu alanı doldurmak zorunludur.' : ''}
                      size="small"
                      placeholder={placeholders[materialType].material_amount_value}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {units[materialType].material_amount_value}
                          </InputAdornment>
                        )
                      }}
                    />
                  </StyledTableCell>
                </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </StyledCardContent>
      </Card>
    </>
  );
};

export default SolarMaterial;

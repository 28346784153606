/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Container,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
  IconButton
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeletePackageDialog from 'src/components/DeletePackageDialog';
import 'reactjs-popup/dist/index.css';
import Page from 'src/components/Page';
import Spinner from 'src/components/Spinner/Spinner';
import axiosInstance from 'src/utils/axiosApi';

const StyledIconButton = withStyles({
  root: {
    margin: 2,
    textTransform: 'none',
    fontSize: 16,
    padding: 8,
    backgroundColor: '#e2e5fa'
  }
})(IconButton);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins'
  },
}))(TableCell);

const StyledTableCellHead = withStyles(() => ({
  root: {
    padding: 10,
    fontFamily: 'Poppins',
    fontWeight: 'bold'
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: 0,
    paddingBottom: 0
  },
  /* cardContentRoot: {
    paddingBottom: 0
  } */
}));

const PackageList = () => {
  const { t, } = useTranslation();
  const MUIclasses = useStyles();
  const [loading, setLoading] = useState(false);
  const [packagePreviews, setPackagePreviews] = useState([]);

  // const largeYerlesim = 3;

  const fetchAllPackages = async () => {
    setLoading(true);
    await axiosInstance.get('solar_package/fetch_solar_packages?solar_package_ids=&grid_type=&language=TR')
      .then((response) => {
        setPackagePreviews(response.data.data);
      })
      .catch((error) => console.log(error));
    setLoading(false);
  };

  const deletePackage = async (packageId) => {
    setLoading(true);
    await axiosInstance.get(`home/delete_package?id=${packageId}&format=json`)
      .then(() => {
        fetchAllPackages();
      })
      .catch((error) => console.log(error));
    setLoading(false);
  };

  const willMount = useRef(true);
  if (willMount.current) {
    fetchAllPackages();
    willMount.current = false;
  }

  if (loading) {
    return (
      <>
        <Spinner text={t('SolarPackagesPage.loadingPackageList')} />
      </>
    );
  }

  return (
    <Page
      className={MUIclasses.root}
      title={t('SolarPackagesPage.title')}
    >
      <Container maxWidth={false} style={{ paddingBottom: 24 }}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <h2 style={{ fontWeight: 'normal' }}>{t('SolarPackagesPage.yourPackages')}</h2>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table aria-label="solar-packages-table">
                <TableHead>
                  <TableRow>
                    <StyledTableCellHead> </StyledTableCellHead>
                    <StyledTableCellHead align="left" style={{ fontSize: 14 }}>
                      {t('SolarPackagesPage.name')}
                    </StyledTableCellHead>
                    <StyledTableCellHead align="left" style={{ fontSize: 14 }}>
                      {t('SolarPackagesPage.gridType')}
                    </StyledTableCellHead>
                    <StyledTableCellHead align="left" style={{ fontSize: 14 }}>
                      {t('SolarPackagesPage.price')}
                    </StyledTableCellHead>
                    <StyledTableCellHead align="left" style={{ fontSize: 14 }}>
                      {t('SolarPackagesPage.dcPower')}
                    </StyledTableCellHead>
                    <StyledTableCellHead align="center"> </StyledTableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {packagePreviews.sort((a, b) => a.dc_guc_watt - b.dc_guc_watt).map((solarPackage) => (
                    <TableRow key={solarPackage.id}>
                      <StyledTableCell component="th" scope="row" align="center">
                        <img
                          style={{
                            width: '60px',
                            height: '60px',
                            borderRadius: '50%'
                          }}
                          src={solarPackage.package_image_presigned_url}
                          alt={solarPackage.name}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {solarPackage.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {solarPackage.grid_type === 'ON' ? 'ON-GRID' : 'OFF-GRID'}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {`${solarPackage.price} ${solarPackage.currency}`}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {`${solarPackage.dc_guc_kilo_watt} kWp`}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: '200px' }}>
                        <div style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-around'
                        }}
                        >
                          <Link to={`${solarPackage.id}/package-info`}>
                            <StyledIconButton aria-label="edit" color="primary">
                              <EditIcon />
                            </StyledIconButton>
                          </Link>
                          <DeletePackageDialog
                            packageId={solarPackage.id}
                            deleteFunction={deletePackage}
                          />
                        </div>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <div style={{ position: 'fixed', right: '1rem', bottom: '1rem' }}>
            <Link to="new-package">
              <Fab color="primary" aria-label="add" variant="extended">
                <AddIcon style={{ marginRight: 5 }} />
                {t('SolarPackagesPage.addPackage')}
              </Fab>
            </Link>
          </div>
        </Grid>
      </Container>
    </Page>
  );
};

export default PackageList;

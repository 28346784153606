/* eslint-disable react/jsx-boolean-value */
/* eslint-disable object-curly-newline */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { useMediaQuery } from 'react-responsive';
import classes from './NivoBar.module.css';

function LineGraph(props) {
  const { monthlySPPInvoice, monthlyNormalInvoice, monthList } = props;

  const lineGraphTicksTemp = [];
  const [lineGraphTicks, setLineGraphTicks] = React.useState(null);

  const [graphData, setGraphData] = React.useState({
    firstGraphDataNormalFatura: [],
    firstGraphDataGesFatura: [],
  });

  const firstGraphDataNormalFaturaTemp = [];
  const firstGraphDataGesFaturaTemp = [];

  const dataStored = [
    {
      id: "GES'le Fatura",
      color: 'hsl(83, 70%, 50%)',
      data: graphData.firstGraphDataGesFatura
    },
    {
      id: 'Normal Fatura',
      color: 'hsl(320, 70%, 50%)',
      data: graphData.firstGraphDataNormalFatura
    },
  ];

  const graphDataAdapter = (monthlySPPInvoiceInp, monthlyNormalInvoiceInp, monthListInp) => {
    monthListInp.slice(0, 24).forEach((date, index) => {
      const veri = monthlyNormalInvoiceInp[index];
      const updatedData = veri.replace('.', '');

      const obje = {
        x: date,
        y: Number(updatedData)
      };
      firstGraphDataNormalFaturaTemp.push(obje);

      lineGraphTicksTemp.push(date);
    });

    monthListInp.slice(0, 24).forEach((date, index) => {
      const veri = monthlySPPInvoiceInp[index];
      const updatedData = veri.replace('.', '');
      const obje = {
        x: date,
        y: Number(updatedData)
      };
      firstGraphDataGesFaturaTemp.push(obje);
    });

    setGraphData({
      firstGraphDataNormalFatura: firstGraphDataNormalFaturaTemp,
      firstGraphDataGesFatura: firstGraphDataGesFaturaTemp,
    });
    setLineGraphTicks(lineGraphTicksTemp);
  };

  const labelBottom = 'Zaman';
  const labelLeft = 'Fatura Tutarı ($)';
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  let generalGraphSettings;
  if (isTabletOrMobile) {
    generalGraphSettings = {
      widthLineGraph: '90%',
      widthBarGraph: '100%',
      tickRotation: -90,
      marginRight: 20,
      marginLeft: 20,
      marginBottom: 130,
      legendOffsetBottom: 110,
      legendOffsetLeft: -70
    };
  } else {
    generalGraphSettings = {
      widthLineGraph: '70%',
      widthBarGraph: '90%',
      tickRotation: -60,
      marginRight: 50,
      marginLeft: 110,
      marginBottom: 110,
      legendOffsetBottom: 100,
      legendOffsetLeft: -95
    };
  }

  const lineGraphSettings = {
    theme: {
      background: 'transparent',
      fontFamily: 'Poppins',
      textColor: '#7d7d7d',
      color: '#7d7d7d',
      legends: {
        text: {
          fontSize: '16px',
          fill: '#7d7d7d'
        }
      },
      axis: {
        legend: {
          text: {
            fontSize: '16px',
            color: 'white'
          }
        },
        ticks: {
          text: {
            fontSize: '12px'
          }
        }
      },
    }
  };

  const willMount = React.useRef(true);
  if (willMount.current) {
    graphDataAdapter(monthlySPPInvoice, monthlyNormalInvoice, monthList);
    willMount.current = false;
  }

  return (
    <div className={classes.ChartContainer}>
      <div className={classes.ChartShow}>
        <ResponsiveLine
          style={{
            paddingLeft: '100px'
          }}
          data={dataStored}
          margin={{ top: 50, right: generalGraphSettings.marginRight, bottom: generalGraphSettings.marginBottom, left: 110 }}
          xScale={{ type: 'point' }}
          yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
          curve="natural"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: generalGraphSettings.tickRotation,
            legend: labelBottom,
            legendOffset: generalGraphSettings.legendOffsetBottom,
            legendPosition: 'middle',
            /* tickValues: ['2020', '2021', '2022', '2023','2024', '2025', '2026', '2027',
                        '2028', '2029', '2030'] */
            tickValues: lineGraphTicks,
            format: (value) => `${String(value)}`,
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: labelLeft,
            legendOffset: -85,
            legendPosition: 'middle'
          }}
          enableGridX={false}
          colors={['#ff884b', '#085b06']}
          lineWidth={4}
          pointSize={2}
          pointColor={{ from: 'color', modifiers: [] }}
          pointBorderWidth={3}
          pointBorderColor={{ from: 'serieColor', modifiers: [] }}
          pointLabel="y"
          pointLabelYOffset={-11}
          theme={lineGraphSettings.theme}
          enableArea={false}
          areaOpacity={0.7}
          enableSlices="x"
          useMesh={true}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'top',
              direction: 'row',
              justify: false,
              translateX: 17,
              translateY: -40,
              itemsSpacing: 50,
              itemWidth: 130,
              itemHeight: 18,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          sliceTooltip={({ slice }) => {
            return (
              <div
                style={{
                  background: 'white',
                  padding: '9px 12px',
                  border: '1px solid #ccc',
                  fontSize: '18px'
                }}
              >
                {slice.points[0].data.xFormatted}
                {slice.points.map((point) => (
                  <div
                    key={point.id}
                    style={{
                      color: point.serieColor,
                      padding: '3px 0',
                      fontSize: '18px'
                    }}
                  >
                    <strong>{point.serieId}</strong>
                    :
                    {point.data.yFormatted}
                    $
                  </div>
                ))}
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}

export default LineGraph;

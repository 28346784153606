/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable */

import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import {
  makeStyles,
  withStyles,
  Grid,
  Container,
  Divider,
  Card,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Table,
  CardContent,
  TableBody,
  Button,
  IconButton
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeAdminInfo,
  changeSubconCompanyInfo,
} from 'src/services/users/userReducer';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import axiosInstance from 'src/utils/axiosApi';
import GeneralAddDialog from 'src/components/GeneralAddDialog';
import GeneralDeleteDialog from 'src/components/GeneralDeleteDialog';
import GeneralAddDialog2 from 'src/components/GeneralAddDialog2';
import { Form, Input, Select, Upload, message } from 'antd';

const StyledTableCellHead = withStyles(() => ({
  root: {
    padding: 10,
    fontFamily: 'Poppins',
    fontWeight: 'bold'
  },
}))(TableCell);

const StyledIconButton = withStyles({
  root: {
    margin: 2,
    textTransform: 'none',
    fontSize: 16,
    padding: 8,
    backgroundColor: '#e2e5fa'
  }
})(IconButton);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins',
    borderBottom: 'none'
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: 0,
    paddingBottom: 0
  },
}));

const SubcontractorManagement = (props) => {
  const MUIclasses = useStyles();
  const { t, } = useTranslation();
  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    adminInfo,
  } = useSelector((state) => state.users);

  const [newDocumentCategoryInfo, setNewDocumentCategoryInfo] = useState({
    documentCategory: '',
    documentDescription: '',
    required: '1',
    mayCompanuUsersUse: '1',
    isDemandedFromEndUser: '1',
  });

  const [uploadedDocumentFiles, setUploadedDocumentFiles] = useState(null);

  const handleChangeDocumentCategory = (event) => {
    // Prevents React from resetting its properties:
    event.persist();
    setNewDocumentCategoryInfo((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const [documentList, setDocumentList] = useState([]);

  const [shouldReloadPage, setShouldReloadPage] = useState(false);

  const handleChangeDocumentList = (event, documentId, documentVar, changeDocumentFunc) => {
    // Prevents React from resetting its properties:
    const newData = documentVar.map((document) => {
      if (document.id === Number(documentId)) {
        return {
          ...document,
          [event.target.name]: event.target.value
        };
      }
      return document;
    });
    changeDocumentFunc(newData);
  };

  const handleChangeDialogStatus = (id, dialogName, documentVar, changeDocumentFunc) => {
    // Prevents React from resetting its properties:
    console.log({ id, dialogName, documentVar, changeDocumentFunc})
    const newData = documentVar.map((document) => {
      if (document.id === Number(id)) {
        return {
          ...document,
          [dialogName]: !document[dialogName]
        };
      }
      return document;
    });
    changeDocumentFunc(newData);
  };

  const handleUploadDocuments = (values,docId) => {
    if(values.document.fileList.length === 0) {
      alert('En az 1 dosya ekleyin');
    }
    else {

      const formData = new FormData();
      formData.append("file",values.document.file)
      formData.append("json_data_str",JSON.stringify({document_category_id:docId}))

      axiosInstance.post("subcon/document/document_category_APIs/upload_document_category_example_document",formData,{
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then((res) => {
        const {status,message} = res.data;
        if (status) {
          alert(message)
          setShouldReloadPage(true);
          documentUploadFormInstance.resetFields();
        }
        else {
          alert(message)
        }
      }).catch(err => {
        alert("Dosya yüklenirken bir hata oluştu")
      })


    }
  }

  const fetchDocumentList = () => {
    // Fetch Admin Info
    axiosInstance.get('crm/get_admin_permissions?format=json')
      .then((response) => {
        const { data, status, message } = response.data;
        if (status && message === 'User has permission to access the subcontractor module.') {
          const adminInfo = data.admin_info;
          dispatch(changeAdminInfo({
            userId: adminInfo.user_id,
            isCompanySuperUser: adminInfo.is_company_superuser,
            isSubconSuperUser: adminInfo.is_subcon_superuser,
            adminType: adminInfo.admin_type,
            isSubconCompanyUser: adminInfo.is_subcon_company_user,
          }));

          const subconCompanyInfo = adminInfo.subcon_company_info;
          if (subconCompanyInfo) {
            dispatch(changeSubconCompanyInfo(subconCompanyInfo));
          }
          setShouldReloadPage(false);
        }
      })
      .catch((error) => console.log(error));

    axiosInstance.get('subcon/document/document_category_APIs/admin_get_document_categories')
      .then((response) => {
        const { data, message } = response.data;
        const updatedData = data.map((document) => {
          return {
            ...document,
            is_required: document.is_required ? '1' : '0',
            may_company_users_use: document.may_company_users_use ? '1' : '0',
            is_demanded_from_end_user: document.is_demanded_from_end_user ? '1' : '0',
            update_dialog_open: false,
            delete_dialog_open: false,
            delete_dialog_example_document_open: false,
            upload_dialog_open: false,
          };
        });
        setDocumentList(updatedData);
      })
      .catch((error) => console.log(error));
  };

  /* const fetchTemplateDocumentList = () => {
    axiosInstance.get('subcon/document/download_document/?download_document_mod=TEMPLATE&document_category_id=0&subcon_project_id=0&document_id=0')
      .then((response) => {
        const { status } = response.data;
        if (status) {
          const { data, message } = response.data;
          const updatedData = data.map((document) => ({
            ...document,
            update_dialog_open: false,
            delete_dialog_open: false
          }));
          setTemplateDocumentList(updatedData);
        }
      })
      .catch((error) => console.log(error));
  }; */

  const updateDocumentCategory = (values,docId) => {
    axiosInstance.post("subcon/document/document_category_APIs/update_document_category",{
      document_category_id: docId,
      document_category_description: values.description,
      is_required: values.required,
      may_company_users_use: values.mayCompanuUsersUse,
      is_demanded_from_end_user: values.isDemandedFromEndUser,
    }).then((res) => {
      const {status,message} = res.data;
      if (status) {
        alert(message)
        setShouldReloadPage(true);
      }
      else {
        alert(message)
      }
    }).catch(err => {
      alert("Bir problem oluştu")
    })
  }

  const addNewDocumentCategoryHandle = (values) => {
    axiosInstance.post("subcon/document/document_category_APIs/create_document_category", {
      document_category_name: values.category,
      document_category_description: values.description,
      is_required: values.required,
      may_company_users_use: values.mayCompanuUsersUse,
      is_demanded_from_end_user: values.isDemandedFromEndUser,
    })
      .then((response) => {
        const { status, message } = response.data;
        if (status) {
          // show message on snackbar
          alert(message)
          setDialogOpen(false);
          setShouldReloadPage(true);
        }
        else {
          alert(message);
        }
      })
      .catch((error) => alert("Bir problem oluştu"));
  }

  const [documentUploadFormInstance] = Form.useForm();

  useEffect(() => {
    fetchDocumentList();
    /* fetchTemplateDocumentList(); */
  }, [shouldReloadPage]);

  const beforeUpload = (file, files) => {
    setUploadedDocumentFiles(files);
    return false;
  };

  return (
    <Page
      className={MUIclasses.root}
      title={`solarVis | ${t('DocumentManagementPage.title')}`}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <h2 style={{ fontWeight: 'normal' }}>{t('DocumentManagementPage.title')}</h2>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card>
              {/* <div style={{ padding: 16 }}>
                <h4>{t('DocumentManagementPage.endUserDocuments')}</h4>
              </div>
              <Divider /> */}
              <CardContent>
                {documentList.length === 0 && (<div style={{ padding: 16, textAlign: 'center' }}>{t('DocumentManagementPage.noEndUserDocuments')}</div>)}
                {documentList.length > 0 && (
                <TableContainer>
                  <Table aria-label="solar-packages-table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCellHead align="left">
                          {t('DocumentManagementPage.documentCategory')}
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left">
                          {t('DocumentManagementPage.documentDescription')}
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left">
                          {t('DocumentManagementPage.isRequestedFromEndUser')}
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left">
                          {t('DocumentManagementPage.documentRequired')}
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left">
                          {t('DocumentManagementPage.canUseCompanyAdmins')}
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left">
                          {t('DocumentManagementPage.adminDocument')}
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left"> </StyledTableCellHead>
                        <StyledTableCellHead align="left"> </StyledTableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {documentList.map((doc) => (
                        <TableRow key={doc.id}>
                          <StyledTableCell align="left">
                            {doc.document_category_name}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {doc.document_category_description}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {doc.is_demanded_from_end_user === '1' ? t('DocumentManagementPage.yes') : t('DocumentManagementPage.no')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {doc.is_required === '1' ? t('DocumentManagementPage.yes') : t('DocumentManagementPage.no')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {doc.may_company_users_use === '1' ? t('DocumentManagementPage.yes') : t('DocumentManagementPage.no')}
                          </StyledTableCell>
                          {adminInfo.isCompanySuperUser && (
                          <StyledTableCell align="left">
                            {doc.example_document_s3_path
                              ? (
                                <div style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                                >
                                  <span
                                    style={{ textDecoration: 'underline' }}
                                    onClick={() => handleChangeDialogStatus(doc.id, 'delete_dialog_example_document_open', documentList, setDocumentList)}
                                  >
                                    {t('DocumentManagementPage.remove')}
                                  </span>
                                  <GeneralDeleteDialog
                                    dialogTitle={t('DocumentManagementPage.deleteAdminDocument')}
                                    requestURL={`subcon/document/document_category_APIs/remove_document_category_example_document?document_category_id=${doc.id}`}
                                    reloadPageState={shouldReloadPage}
                                    reloadPageFunc={setShouldReloadPage}
                                    dialogContent={t('DocumentManagementPage.deleteAdminDocumentDialogTitle')}
                                    requestMethod="DELETE"
                                    openState={doc.delete_dialog_example_document_open}
                                    setOpenState={() => handleChangeDialogStatus(doc.id, 'delete_dialog_example_document_open', documentList, setDocumentList)}
                                    changeDataFunction={(e) => handleChangeDocumentList(e, doc.id, documentList, setDocumentList)}
                                  />
                                </div>
                              )
                              : (
                                <>
                                  <span
                                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    onClick={() => handleChangeDialogStatus(doc.id, 'upload_dialog_open', documentList, setDocumentList)}
                                  >
                                    {t('DocumentManagementPage.add')}
                                  </span>
                                  {/* <GeneralAddDialog
                                    dialogTitle={t('DocumentManagementPage.addNewAdminDocument')}
                                    requestURL="subcon/document/document_category_APIs/upload_document_category_example_document"
                                    requestMethod="FILE"
                                    openState={document.upload_dialog_open}
                                    setOpenState={() => handleChangeDialogStatus(document.id, 'upload_dialog_open', documentList, setDocumentList)}
                                    reloadPageState={shouldReloadPage}
                                    reloadPageFunc={setShouldReloadPage}
                                    bodyData={{
                                      document_category_id: document.id
                                    }}
                                    inputs={[
                                      {
                                        title: t('DocumentManagementPage.uploadDocument'),
                                        type: 'file',
                                        name: 'file'
                                      }
                                    ]}
                                    changeDataFunction={(e) => handleChangeDocumentList(e, document.id, documentList, setDocumentList)}
                                  /> */}
                                  <GeneralAddDialog2
                                    title={t('DocumentManagementPage.addNewAdminDocument')}
                                    modalShow={doc.upload_dialog_open}
                                    setModalShow={() => handleChangeDialogStatus(doc.id, 'upload_dialog_open', documentList, setDocumentList)}
                                    handle={(values) => handleUploadDocuments(values,doc.id)}
                                    form={documentUploadFormInstance}
                                    initialValues={{
                                      document: null
                                    }}
                                  >
                                    <Form.Item name="document" rules={[{ required: true, message: t('ErrorMessages.requiredField') }]}>
                                      <Upload.Dragger beforeUpload={beforeUpload} maxCount={1} multiple={false} listType="picture-card">
                                        <p className="ant-upload-text">
                                          {t('DocumentManagementPage.drag')}
                                        </p>
                                      </Upload.Dragger>
                                    </Form.Item>
                                  </GeneralAddDialog2>
                                </>
                              )}
                          </StyledTableCell>
                          )}
                          {doc.example_document_s3_path
                            ? (
                              <StyledTableCell align="left">
                                <a href={doc.example_document_s3_path} style={{ textDecoration: 'underline' }}>{t('DocumentManagementPage.download')}</a>
                              </StyledTableCell>
                            )
                            : (
                              <StyledTableCell align="left">
                                -
                              </StyledTableCell>
                            )}
                          {adminInfo.isCompanySuperUser && (
                          <StyledTableCell align="left">
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-around'
                            }}
                            >
                              <StyledIconButton
                                aria-label="edit"
                                color="primary"
                                onClick={() => handleChangeDialogStatus(doc.id, 'update_dialog_open', documentList, setDocumentList)}
                              >
                                <EditIcon />
                              </StyledIconButton>
                              {/* <GeneralAddDialog
                                dialogTitle={t('DocumentManagementPage.updateDocumentCategory')}
                                requestURL="subcon/document/document_category_APIs/update_document_category"
                                reloadPageState={shouldReloadPage}
                                reloadPageFunc={setShouldReloadPage}
                                requestMethod="POST"
                                dontDisableButton
                                openState={doc.update_dialog_open}
                                setOpenState={() => handleChangeDialogStatus(doc.id, 'update_dialog_open', documentList, setDocumentList)}
                                bodyData={{
                                  ...doc,
                                  document_category_id: doc.id
                                }}
                                inputs={[
                                  {
                                    title: t('DocumentManagementPage.documentCategory'),
                                    value: doc.document_category_name,
                                    type: 'text',
                                    name: 'document_category_name',
                                  },
                                  {
                                    title: t('DocumentManagementPage.documentDescription'),
                                    value: doc.document_category_description,
                                    type: 'text',
                                    name: 'document_category_description',
                                  },
                                  {
                                    title: t('DocumentManagementPage.documentRequired'),
                                    value: doc.is_required,
                                    type: 'select',
                                    name: 'is_required',
                                    choices: [
                                      { title: t('DocumentManagementPage.required'), value: 1 },
                                      { title: t('DocumentManagementPage.notRequired'), value: 0 }
                                    ]
                                  },
                                  {
                                    title: t('DocumentManagementPage.isRequestedFromEndUser'),
                                    value: doc.is_demanded_from_end_user,
                                    type: 'select',
                                    name: 'is_demanded_from_end_user',
                                    choices: [
                                      { title: 'Evet', value: 1 },
                                      { title: 'Hayır', value: 0 }
                                    ]
                                  },
                                  {
                                    title: t('DocumentManagementPage.canUseCompanyAdmins'),
                                    value: doc.may_company_users_use,
                                    type: 'select',
                                    name: 'may_company_users_use',
                                    choices: [
                                      { title: 'Evet', value: 1 },
                                      { title: 'Hayır', value: 0 }
                                    ]
                                  }
                                ]}
                                changeDataFunction={(e) => handleChangeDocumentList(e, doc.id, documentList, setDocumentList)}
                              /> */}

                              <GeneralAddDialog2
                                title={t('DocumentManagementPage.updateDocumentCategory')}
                                modalShow={doc.update_dialog_open}
                                setModalShow={() => handleChangeDialogStatus(doc.id, 'update_dialog_open', documentList, setDocumentList)}
                                handle={(values) => updateDocumentCategory(values,doc.id)}
                                initialValues={{
                                  category: doc.document_category_name,
                                  description: doc.document_category_description,
                                  required: doc.is_required,
                                  isDemandedFromEndUser: doc.is_demanded_from_end_user,
                                  mayCompanuUsersUse: doc.may_company_users_use
                                }}
                              >
                                <Form.Item name={"category"} rules={[{required: true,message: t("ErrorMessages.requiredField")}]} label={t('DocumentManagementPage.documentCategory')}>
                                  <Input disabled placeholder={t("DocumentManagementPage.documentCategory")} size='large' />
                                </Form.Item>
                                <Form.Item name={"description"} rules={[{required: true,message: t("ErrorMessages.requiredField")}]} label={t('DocumentManagementPage.documentDescription')}>
                                  <Input placeholder={t("DocumentManagementPage.documentDescription")} size='large' />
                                </Form.Item>
                                <Form.Item name={"required"} rules={[{required: true,message: t("ErrorMessages.requiredField")}]} label={t('DocumentManagementPage.documentRequired')}>
                                  <Select
                                    allowClear
                                    placeholder={t("DocumentManagementPage.documentRequired")}
                                    options={[{label: t('DocumentManagementPage.required'),value: "1"},
                                      {label: t('DocumentManagementPage.notRequired'),value: "0"}]}
                                  />
                                </Form.Item>
                                <Form.Item name={"isDemandedFromEndUser"} rules={[{required: true,message: t("ErrorMessages.requiredField")}]} label={ t('DocumentManagementPage.isRequestedFromEndUser')}>
                                  <Select
                                    allowClear
                                    placeholder={t("DocumentManagementPage.isRequestedFromEndUser")}
                                    options={[{label: 'Evet',value: "1"},

                                      {label: 'Hayır',value: "0"}]}
                                  />
                                </Form.Item>
                                <Form.Item name={"mayCompanuUsersUse"} rules={[{required: true,message: t("ErrorMessages.requiredField")}]} label={t('DocumentManagementPage.canUseCompanyAdmins')}>
                                  <Select
                                    allowClear
                                    placeholder={t("DocumentManagementPage.canUseCompanyAdmins")}
                                    options={[{label: 'Evet',value: "1"},
                                      {label: 'Hayır',value: "0"}]}
                                  />
                                </Form.Item>
                              </GeneralAddDialog2>


                              <StyledIconButton
                                aria-label="edit"
                                color="primary"
                                onClick={() => handleChangeDialogStatus(doc.id, 'delete_dialog_open', documentList, setDocumentList)}
                              >
                                <DeleteIcon />
                              </StyledIconButton>
                              
                              <GeneralDeleteDialog
                                dialogTitle={t('DocumentManagementPage.deleteDocumentCategory')}
                                requestURL={`subcon/document/document_category_APIs/delete_document_category?document_category_id_to_delete=${doc.id}`}
                                reloadPageState={shouldReloadPage}
                                reloadPageFunc={setShouldReloadPage}
                                dialogContent={t('DocumentManagementPage.deleteDocumentCategoryDialogTitle')}
                                requestMethod="DELETE"
                                openState={doc.delete_dialog_open}
                                setOpenState={() => handleChangeDialogStatus(doc.id, 'delete_dialog_open', documentList, setDocumentList)}
                                changeDataFunction={(e) => handleChangeDocumentList(e, doc.id, documentList, setDocumentList)}
                              />
                            </div>
                          </StyledTableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                )}
                {adminInfo.isCompanySuperUser && (
                <div style={{
                  display: 'flex',
                  marginTop: '30px',
                  justifyContent: 'end'
                }}
                >
                  <Button
                    color="primary"
                    startIcon={<AddIcon />}
                    size="small"
                    variant="contained"
                    style={{ textTransform: 'none' }}
                    onClick={() => setDialogOpen(true)}
                  >
                    {t('DocumentManagementPage.addNewDocumentCategory')}
                  </Button>
                  <GeneralAddDialog2
                    title={t('DocumentManagementPage.addNewDocumentCategory')}
                    modalShow={dialogOpen}
                    setModalShow={() => setDialogOpen(false)}
                    initialValues={{
                      category:null,
                      description: null,
                      required: null,
                      isDemandedFromEndUser: null,
                      mayCompanuUsersUse: null
                    }}
                    handle={addNewDocumentCategoryHandle}
                  >
                    <Form.Item name={"category"} rules={[{required: true,message: t("ErrorMessages.requiredField")}]} label={t('DocumentManagementPage.documentCategory')}>
                      <Input placeholder={t("DocumentManagementPage.documentCategory")} size='large' />
                    </Form.Item>
                    <Form.Item name={"description"} rules={[{required: true,message: t("ErrorMessages.requiredField")}]} label={t('DocumentManagementPage.documentDescription')}>
                      <Input placeholder={t("DocumentManagementPage.documentDescription")} size='large' />
                    </Form.Item>
                    <Form.Item name={"required"} rules={[{required: true,message: t("ErrorMessages.requiredField")}]} label={t('DocumentManagementPage.documentRequired')}>
                      <Select
                        allowClear
                        placeholder={t("DocumentManagementPage.documentRequired")}
                        options={[{label: t('DocumentManagementPage.required'),value: 1},
                          {label: t('DocumentManagementPage.notRequired'),value: 0}]}
                      />
                    </Form.Item>
                    <Form.Item name={"isDemandedFromEndUser"} rules={[{required: true,message: t("ErrorMessages.requiredField")}]} label={ t('DocumentManagementPage.isRequestedFromEndUser')}>
                      <Select
                        allowClear
                        placeholder={t("DocumentManagementPage.isRequestedFromEndUser")}
                        options={[{label: 'Evet',value: 1},

                          {label: 'Hayır',value: 0}]}
                      />
                    </Form.Item>
                    <Form.Item name={"mayCompanuUsersUse"} rules={[{required: true,message: t("ErrorMessages.requiredField")}]} label={t('DocumentManagementPage.canUseCompanyAdmins')}>
                      <Select
                        allowClear
                        placeholder={t("DocumentManagementPage.canUseCompanyAdmins")}
                        options={[{label: 'Evet',value: 1},
                          {label: 'Hayır',value: 0}]}
                      />
                    </Form.Item>
                  </GeneralAddDialog2>


                </div>
                )}
              </CardContent>
              <Divider />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default SubcontractorManagement;

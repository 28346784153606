/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
    marginLeft: '6px'
  },
}));

export default function Tags(props) {
  const {
    changeFilterFunction,
    filterType,
    filterValue,
    page,
    filteredAvailableCities
  } = props;
  const { t, } = useTranslation();
  const classes = useStyles();

  const sehirlerTamListe = ['Adana', 'Adıyaman', 'Afyon', 'Ağrı', 'Amasya', 'Ankara', 'Antalya', 'Artvin', 'Aydın', 'Balıkesir', 'Bilecik', 'Bingöl', 'Bitlis', 'Bolu', 'Burdur', 'Bursa', 'Çanakkale', 'Çankırı', 'Çorum', 'Denizli', 'Diyarbakır', 'Edirne', 'Elazığ', 'Erzincan', 'Erzurum', 'Eskişehir', 'Gaziantep', 'Giresun', 'Gümüşhane', 'Hakkari', 'Hatay', 'Isparta', 'Mersin', 'İstanbul', 'İzmir', 'Kars', 'Kastamonu', 'Kayseri', 'Kırklareli', 'Kırşehir', 'Kocaeli', 'Konya', 'Kütahya', 'Malatya', 'Manisa', 'Kahramanmaraş', 'Mardin', 'Muğla', 'Muş', 'Nevşehir', 'Niğde', 'Ordu', 'Rize', 'Sakarya', 'Samsun', 'Siirt', 'Sinop', 'Sivas', 'Tekirdağ', 'Tokat', 'Trabzon', 'Tunceli', 'Şanlıurfa', 'Uşak', 'Van', 'Yozgat', 'Zonguldak', 'Aksaray', 'Bayburt', 'Karaman', 'Kırıkkale', 'Batman', 'Şırnak', 'Bartın', 'Ardahan', 'Iğdır', 'Yalova', 'Karabük', 'Kilis', 'Osmaniye', 'Düzce'];
  const tesislerTamListe = ['Mesken', 'Ticarethane', 'Sanayi', 'Tarımsal Sulama'];

  const getFilterList = (type) => {
    let sendList = [];
    if (type === 'cities') {
      if (page === 'subcontractor') {
        sendList = filteredAvailableCities;
      } else {
        sendList = sehirlerTamListe;
      }
    } else if (type === 'facilities') {
      sendList = tesislerTamListe;
      if (localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') {
        sendList = ['Residential', 'Commercial', 'Industrial', 'Agricultural Watering'];
      }
    }
    return sendList;
  };

  const labels = {
    cities: {
      label: t('Filtre.sehirler'),
      placeholder: t('Filtre.sehirSec')
    },
    facilities: {
      label: t('Filtre.tesisler'),
      placeholder: t('Filtre.tesisSec')
    }
  };

  return (
    <div className={classes.root} style={{ width: page === 'subcontractor' ? '100%' : 300, marginLeft: page === 'subcontractor' ? 0 : 6 }}>
      <Autocomplete
        value={filterValue}
        onChange={(event, newValue) => changeFilterFunction(filterType, newValue)}
        multiple
        id={filterType}
        options={getFilterList(filterType)}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            style={{ margin: 5 }}
            label={labels[filterType].label}
            placeholder={labels[filterType].placeholder}
          />
        )}
      />
    </div>
  );
}

/* eslint-disable max-len */
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Grid,
  Container,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  InputAdornment,
  Button,
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useSelector, useDispatch } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import Page from 'src/components/Page';
import Spinner from 'src/components/Spinner/Spinner';
import axiosInstance from 'src/utils/axiosApi';
/* import ConsumptionBarGraph from 'src/views/designer/graphs/ConsumptionBarGraph'; */
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { changeConsumptionProfile } from 'src/services/products/projectReducer';
import InternalRouterHorizontal from './internalRouterHorizontal';
import 'date-fns';
import ConsumptionProfileDialog from './consumptionProfileDialog';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: 0,
    paddingBottom: 0
  },
  formControl: {
    minWidth: 200,
  },
  /* cardContentRoot: {
    paddingBottom: 0
  } */
}));

/* const monthlyConsumptionData = [
  { month: 'Ocak', consumption: 10000 },
  { month: 'Şubat', consumption: 10000 },
  { month: 'Mart', consumption: 10000 },
  { month: 'Nisan', consumption: 10000 },
  { month: 'Mayıs', consumption: 10000 },
  { month: 'Haziran', consumption: 10000 },
  { month: 'Temmuz', consumption: 10000 },
  { month: 'Ağustos', consumption: 10000 },
  { month: 'Eylül', consumption: 10000 },
  { month: 'Ekim', consumption: 10000 },
  { month: 'Kasım', consumption: 10000 },
  { month: 'Aralık', consumption: 10000 },
];

const hourlyConsumptionData = [
  { hour: '00.00 - 06.00', consumption: 20 },
  { hour: '06.00 - 10.00', consumption: 20 },
  { hour: '10.00 - 14.00', consumption: 20 },
  { hour: '14.00 - 18.00', consumption: 20 },
  { hour: '18.00 - 00.00', consumption: 20 },
]; */

const ConsumptionPage = ({ className, ...rest }) => {
  const { projectId } = useParams();
  const MUIclasses = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const { consumptionProfile } = useSelector((state) => state.project);

  const [tesisBilgileri, setTesisBilgileri] = useState({
    tesisTipi: '',
    sozlesmeGucu: 0
  });

  const [tuketimBilgileri, setTuketimBilgileri] = useState({
    tuketim: 0,
    periyot: ''
  });

  const [yillikTuketim, setYillikTuketim] = useState([]);
  const [saatlikTuketim, setSaatlikTuketim] = useState([]);

  const [yillikTotalTuketim, setYillikTotalTuketim] = useState(120000);

  // const largeYerlesim = 3;

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  const handleChangeTesis = (event) => {
    setTesisBilgileri({ ...tesisBilgileri, [event.target.name]: event.target.value });
  };

  /* const fetchConsumptionProfile = (tuketim, periyot) => {
    setLoading(true);
    axiosInstance.get(`designer/fetch_consumption_profile?consumption=${tuketim}&period=${periyot}&profile=${consumptionProfile}&format=json`)
      .then((response) => {
        const projectInfoGet = response.data.data;
        setYillikTuketim(projectInfoGet.monthly_consumption);
        setYillikTotalTuketim(projectInfoGet.yearly_consumption);
        setSaatlikTuketim(projectInfoGet.daily_consumption);
        setLoading(false);
      })
      .catch((error) => console.log(error));

    setLoading(false);
  }; */

  const handleChangeTuketim = (event) => {
    setTuketimBilgileri({ ...tuketimBilgileri, [event.target.name]: event.target.value });
    /* let tempTuketim = tuketimBilgileri.tuketim;
    let tempPeriyot = tuketimBilgileri.periyot;
    if (event.target.name === 'tuketim') {
      tempTuketim = event.target.value;
    } else if (event.target.name === 'periyot') {
      tempPeriyot = event.target.value;
    }
    fetchConsumptionProfile(tempTuketim, tempPeriyot); */
  };

  const fetchConsumptionInfo = async () => {
    setLoading(true);
    await axiosInstance.get(`designer/fetch_consumption_info?project_id=${projectId}&format=json`)
      .then((response) => {
        const consumptionInfoGet = response.data.data;
        setTuketimBilgileri({
          tuketim: consumptionInfoGet.tuketim_input,
          periyot: consumptionInfoGet.periyot,
        });
        setTesisBilgileri({
          tesisTipi: consumptionInfoGet.tesis_tipi,
          sozlesmeGucu: consumptionInfoGet.sgucu
        });
        setYillikTuketim(consumptionInfoGet.aylik_tuketim);
        setYillikTotalTuketim((consumptionInfoGet.yillik_total_tuketim / 1000).toFixed(2));
        setSaatlikTuketim(consumptionInfoGet.gunluk_tuketim);
        dispatch(changeConsumptionProfile({ consumptionProfile: consumptionInfoGet.tuketim_profili }));
      })
      .catch((error) => console.log(error));
    setLoading(false);
  };

  const saveConsumptionInfo = async () => {
    setLoading(true);
    await axiosInstance.post('designer/update_basic_consumption_info?format=json', {
      project_id: projectId,
      tesis_tipi: tesisBilgileri.tesisTipi,
      sgucu: tesisBilgileri.sozlesmeGucu,
      tuketim_input: tuketimBilgileri.tuketim,
      periyot: tuketimBilgileri.periyot,
    })
      .then((response) => {
        if (response.data.status) {
          setSnackbarMessage(response.data.message);
          setSnackbarType('success');
          setSnackbarState(true);
          navigate(`/projects/${response.data.project_id}/material-cost`, { replace: true });
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
        }
      })
      .catch((error) => console.log(error));

    setLoading(false);
  };

  const willMount = React.useRef(true);
  if (willMount.current) {
    fetchConsumptionInfo();
    willMount.current = false;
  }

  if (loading) {
    return (
      <>
        <Spinner text="Tüketim bilgileri yükleniyor.." />
      </>
    );
  }

  return (
    <Page
      className={MUIclasses.root}
      title="solarVis | Proje Tüketim Bilgisi"
    >
      <InternalRouterHorizontal projectId={projectId} />
      <div style={{
        bottom: 0,
        height: 'auto',
        left: 'calc(6rem + 256px)',
        overflowX: 'auto',
        padding: 0,
        position: 'absolute',
        right: 0,
        top: '88px',
      }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={1}
          >
            {/* <InternalRouter projectId={projectId} /> */}
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Card
                className={clsx(MUIclasses.cardRoot, className)}
                {...rest}
              >
                <CardContent
                  className={clsx(MUIclasses.cardContentRoot, className)}
                >
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={12}
                      lg={12}
                      xs={12}
                    >
                      <h5 style={{ fontSize: '1.1rem' }}>Tesis Bilgileri</h5>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      lg={3}
                      xs={12}
                    >
                      <div>
                        <FormControl className={MUIclasses.formControl}>
                          <InputLabel id="demo-simple-select-label">Tesis Tipi</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={tesisBilgileri.tesisTipi}
                            onChange={handleChangeTesis}
                            name="tesisTipi"
                          >
                            <MenuItem value="Mesken">Mesken</MenuItem>
                            <MenuItem value="Ticarethane">Ticarethane</MenuItem>
                            <MenuItem value="Sanayi">Sanayi</MenuItem>
                            <MenuItem value="Tarimsal">Tarımsal Sulama</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      lg={3}
                      xs={12}
                    >
                      <div>
                        <FormControl>
                          <TextField
                            name="sozlesmeGucu"
                            label="Sözleşme Gücü"
                            type="number"
                            value={tesisBilgileri.sozlesmeGucu}
                            style={{ minWidth: 200 }}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">kW</InputAdornment>
                            }}
                            id="sozlesmeGucu"
                            onChange={handleChangeTesis}
                            size="small"
                            error={tesisBilgileri.sozlesmeGucu === ''}
                          />
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Card
                className={clsx(MUIclasses.cardRoot, className)}
                {...rest}
              >
                <CardContent
                  className={clsx(MUIclasses.cardContentRoot, className)}
                >
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={12}
                      lg={12}
                      xs={12}
                    >
                      <h5 style={{ fontSize: '1.1rem' }}>Tüketim Bilgisi</h5>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      lg={12}
                      xs={12}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <div>
                          <FormControl>
                            <TextField
                              name="tuketim"
                              label="Tüketim"
                              type="number"
                              value={tuketimBilgileri.tuketim}
                              style={{ width: 150 }}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">kWh</InputAdornment>
                              }}
                              id="tuketim"
                              onChange={handleChangeTuketim}
                              size="small"
                              error={tesisBilgileri.tuketim === ''}
                            />
                          </FormControl>
                        </div>
                        <div style={{ marginLeft: 20 }}>
                          <FormControl>
                            <InputLabel id="demo-simple-select-label">Periyot</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={tuketimBilgileri.periyot}
                              onChange={handleChangeTuketim}
                              name="periyot"
                              style={{ width: 150 }}
                            >
                              <MenuItem value="aylik">Aylık</MenuItem>
                              <MenuItem value="yillik">Yıllık</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div style={{
                          marginTop: 10, display: 'flex', alignItems: 'center', marginLeft: 20
                        }}
                        >
                          <span style={{ fontWeight: 'bold' }}>Tüketim Profili:</span>
                          <span>{`  ${consumptionProfile}`}</span>
                        </div>
                        <div style={{ marginTop: 10, marginLeft: 20 }}>
                          <ConsumptionProfileDialog
                            projectId={projectId}
                            yillikTuketim={yillikTuketim}
                            tuketimInput={tuketimBilgileri.tuketim}
                            periyot={tuketimBilgileri.periyot}
                            yillikTotalTuketim={yillikTotalTuketim}
                            saatlikTuketim={saatlikTuketim}
                          />
                        </div>
                      </div>
                    </Grid>
                    {/* <Grid
                      item
                      md={8}
                      lg={8}
                      xs={12}
                    >
                      <ConsumptionBarGraph barData={yillikTuketim} mB={-30} mT={-30} />
                    </Grid> */}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Card
                className={clsx(MUIclasses.cardRoot, className)}
                {...rest}
              >
                <CardContent
                  className={clsx(MUIclasses.cardContentRoot, className)}
                >
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={12}
                      lg={12}
                      xs={12}
                    >
                      <Button
                        color="primary"
                        style={{ textTransform: 'none' }}
                        endIcon={<ArrowRightIcon />}
                        variant="contained"
                        onClick={saveConsumptionInfo}
                      >
                        Güncelle ve İlerle
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Page>
  );
};

ConsumptionPage.propTypes = {
  className: PropTypes.string
};

export default ConsumptionPage;

/* eslint-disable */
/* eslint-disable no-alert */
/* eslint-disable prefer-template */
import React, { useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import * as Yup from 'yup';4
import PhoneInput from 'react-phone-input-2'
import { Formik } from 'formik';
import tr from "react-phone-input-2/lang/tr.json";
import 'react-phone-input-2/lib/style.css'


import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeCompany,
  changeLogState,
  changeOwner,
  changeEmail,
  changeTimeZone
} from '../../services/users/userReducer';
import axiosInstance from '../../utils/axiosApi';
import cssClasses from './LoginView.module.css';
import Background from '../../icons/bg_image_white.png';
import { Form, Modal } from 'antd';
import ReactCodeInput from 'react-code-input';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  container: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: '20px',
    paddingBottom: '20px',
    borderRadius: '20px'
  },
  poppins: {
    fontFamily: 'Poppins'
  }
}));

const StyledButton = withStyles({
  root: {
    textTransform: 'none'
  }
})(Button);

const LoginView = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginLanguage, loggedIn } = useSelector(state => state.users);
  const [second, setSecond] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [verifyForm] = Form.useForm();

  const [updatePhoneNumberModal,setUpdatePhoneNumberModal] = React.useState(false);

  const [loginFormValues, setLoginFormValues] = React.useState({});
  React.useEffect(() => {
    const timer = setInterval(() => {
      setSecond((prevCount) => prevCount - 1);
    }, 1000);

    if (second === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [second]);
  const basicTranslation = {
    tr: {
      title: 'solarVis Müşteri Yönetim Paneline Hoş Geldiniz!',
      email: 'E-posta',
      password: 'Şifre',
      button: 'Giriş Yap',
      hataliGiris: 'Doğrulama kodu hatalı',
      hataliGiris2: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz',
      passwordError: 'Şifre gereklidir',
      emailError: 'E-posta gereklidir',
      emailRequired: 'E-posta adresi gereklidir',
      pageTitle: 'Giriş Yap',
      verify: 'Doğrula',
      description: 'Telefonunuza gönderilen doğrulama kodunu giriniz.',
      leftTime: " saniye sonra tekrar deneyebilirsiniz",
      send:"Gönder",
      newNumber:"Yeni Telefon Numaranız",
      requiredField: "Bu alan zorunludur",
      sentEmail:"Doğrulama kodu hesabınıza kayıtlı e-posta adresinize gönderildi",
      verifyCode:"Doğrulama Kodu",
      invalidCode: "Doğrulama kodu 6 haneli olmalıdır."

    },
    en: {
      title: 'Welcome to solarVis Customer Management Panel!',
      email: 'Email',
      password: 'Password',
      newNumber:"New Your Phone Number",
      button: 'Login',
      hataliGiris: 'Verification code is wrong',
      hataliGiris2: 'An error occurred. Please try again later',
      passwordError: 'Password is required',
      emailError: 'Email is required',
      emailRequired: 'Email is required',
      pageTitle: 'Login',
      verify: 'Verify',
      description: 'Enter the verification code sent to your phone.',
      leftTime: " seconds left to try again",
      send:"Send",
      requiredField: "This field is required",
      sentEmail:"Verification code sent to your registered email address",
      verifyCode:"Verification Code",
      invalidCode: "Verification code must be 6 digits"





    }
  };


  const onHandleUpdatePhoneNumber = (values) => {
    const {newNumber,verificationCode} = values;
    axiosInstance.post("update_phone_number",{
      email: loginFormValues.email,
      verification_code: verificationCode,
      updated_phone: newNumber
    }).then((result) => {
      const {status,message,data} = result.data;
      if(status){
        const {access,refresh} = data;
        localStorage.setItem("access",access);
        localStorage.setItem("refresh",refresh);

        const parsedInfo = parseJwt(access);
        if (parsedInfo.firma_kodu && parsedInfo.is_admin) {
          axiosInstance.defaults.headers.Authorization = `Bearer ${access}`;

          dispatch(changeCompany({ company: parsedInfo.firma_kodu }));
          dispatch(
            changeOwner({ owner: `${parsedInfo.isim} ${parsedInfo.soy_isim}` })
          );
          dispatch(changeEmail({ email: values.email }));
          dispatch(changeTimeZone({ timeZone: parsedInfo.timezone }));
          window.location.reload();

        } else {
          alert(basicTranslation[loginLanguage].hataliGiris);
          setUpdatePhoneNumberModal(false)
        }
      }
      else {
        alert(message);
      }
    }).catch(err => {
      console.log(err)
      alert(basicTranslation[loginLanguage].hataliGiris2);
    })

  } 

  if (loggedIn) {
    return <Navigate to="/user-redirect" />;
  }

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  useEffect(() => {
    if (!openModal) {
      verifyForm.resetFields();
    }
  }, [openModal]);

  // send sms verificaiton code request
  const sendSms = values => {
    axiosInstance
      .post('/single_sign_on', {
        email: values.email
      })
      .then(result => {
        const { status, message } = result.data;
        if(status){ 
          setOpenModal(true);
        }
        else if (!status && String(message).includes("lütfen telefon numaranızı güncelleyin.")) {
          setUpdatePhoneNumberModal(true);
        } else {
          alert(message || 'Bir hata oluştu');
        }
      })
      .catch(error => {
        if (
          error.response.status === 401 &&
          error.response.data.detail ===
            'No active account found with the given credentials'
        ) {
          alert(basicTranslation[loginLanguage].hataliGiris);
        }
      });
  };

  const onHandleVerifyNumber = values => {
    axiosInstance
      .post('/verify_single_sign_on', {
        email: loginFormValues.email,
        verification_code: values.code
      })
      .then(res => {
        const { status, message } = res.data;
        if (status) {
          const { access, refresh } = res.data.data;
          axiosInstance.defaults.headers.Authorization = `Bearer ${access}`;
          localStorage.setItem('access', access);
          localStorage.setItem('refresh', refresh);
          const parsedInfo = parseJwt(access);
          dispatch(changeCompany({ company: parsedInfo.firma_kodu }));
          dispatch(
            changeOwner({ owner: `${parsedInfo.isim} ${parsedInfo.soy_isim}` })
          );
          dispatch(changeEmail({ email: loginFormValues.email }));
          dispatch(changeTimeZone({ timeZone: parsedInfo.timezone }));
          window.location.reload();
        } else {
          alert(message || basicTranslation[loginLanguage].hataliGiris);
        }
      }).catch(error => {
        if (
          error.response.status === 429
        ) {
          setSecond(60)
        }
      }
      );
  };

  return (
    <Page
      className={classes.root}
      style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover' }}
      title={basicTranslation[loginLanguage].pageTitle}
    >
      <Modal
        footer={null}
        open={openModal}
        onCancel={() => setOpenModal(false)}
        title={basicTranslation[loginLanguage].title}
      >
        <p>{basicTranslation[loginLanguage].description}</p>
        <Form
          form={verifyForm}
          initialValues={{ code: '' }}
          style={{ marginTop: 8 }}
          onFinish={second > 0 ? null : onHandleVerifyNumber}
          disabled={second > 0}
        >
          <Form.Item
            name={'code'}
            required
            rules={[{ required: true, message: 'Kod zorunludur' }]}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ReactCodeInput
              fields={6}
              autoFocus
              type='number'
            />
          </Form.Item>
          <Form.Item
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 8
            }}
          >
            <StyledButton
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={second > 0}
            >
              {basicTranslation[loginLanguage].verify}
            </StyledButton>
          </Form.Item>

          {
            second > 0 && <Form.Item style={{marginTop: "12px"}}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", color:'black'}}>
              <span style={{fontSize: "14px", fontWeight: 'bold'}}>{second}</span>
              &nbsp;
 
              <span style={{fontSize: "14px"}}>{
                basicTranslation[loginLanguage].leftTime
              }</span>
            </div>
          </Form.Item>
          }
        </Form>
      </Modal>

      {/* for update phone number */}
      <Modal footer={null} open={updatePhoneNumberModal} title={""} onCancel={() => setUpdatePhoneNumberModal(false)}>
      <p style={{ marginBottom: "16px" }}>
          {basicTranslation[loginLanguage].sentEmail}
        </p>
        <Form
          initialValues={{
            newNumber: "",
            verificationCode: "",
          }}
          layout="vertical"
          onFinish={onHandleUpdatePhoneNumber}
        >
          <Form.Item
            label={basicTranslation[loginLanguage].verifyCode}
            required
            name={"verificationCode"}
            rules={[
              { required: true, message: basicTranslation[loginLanguage].requiredField },
              {
                len: 6,
                message: basicTranslation[loginLanguage].invalidCode,
              },
            ]}
          >
            <ReactCodeInput fields={6} autoFocus type="number" />
          </Form.Item>
          <Form.Item
            name="newNumber"
            required
            rules={[{ required: true, message: basicTranslation[loginLanguage].requiredField }]}
            label={basicTranslation[loginLanguage].newNumber}
          >
            <PhoneInput
              country={"tr"}
              onlyCountries={["tr"]}
              placeholder={
                  "(5xx) xxx xx xx"
              }
              masks={{ tr: "(...) ... .. .."}}
              areaCodes={false}
              prefix=""
              autoFormat
              disableCountryCode
              // countryCodeEditable={false}
              containerStyle={{ outline: "none", border: "none" }}
              localization={tr} // for input lang
              inputProps={{ autoComplete: "tel-national" }}
              inputStyle={{
                width: "100%",
                paddingTop: "12px",
                paddingBottom: "12px",
                outline: "none",
                borderTop: "none",
                borderRight: "none",
                borderLeft: "none",
                borderRadius: "0px",
                borderColor: "darkgray",
              }}
              specialLabel=''
            />
          </Form.Item>
          <Form.Item
            style={{ margin: 12, display: "flex", justifyContent: "end" }}
          >
            <Button variant="contained" type="submit" color="primary" style={{textTransform: 'capitalize'}}>
              {basicTranslation[loginLanguage].send}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container className={classes.container} maxWidth="xs">
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email(basicTranslation[loginLanguage].emailRequired)
                .max(255)
                .required(basicTranslation[loginLanguage].emailRequired)
            })}
            onSubmit={values => {
              sendSms(values);
              setLoginFormValues(values);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h3"
                    className={classes.poppins}
                  >
                    {basicTranslation[loginLanguage].title}
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={basicTranslation[loginLanguage].email}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />

                <Box my={2}>
                  <StyledButton
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {basicTranslation[loginLanguage].button}
                  </StyledButton>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  statusIds: JSON.parse(localStorage.getItem('statusIds')) || [],
  availableModules: JSON.parse(localStorage.getItem('availableModules')) || [],
};

export const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    changeStatusIds: (state, action) => {
      state.statusIds = action.payload;
      localStorage.setItem('statusIds', JSON.stringify(action.payload));
    },
    changeAvailableModules: (state, action) => {
      state.availableModules = action.payload;
      localStorage.setItem('availableModules', JSON.stringify(action.payload));
    },
    resetState: () => initialState,
  }
});

// Action creators are generated for each case reducer function
export const {
  changeStatusIds,
  changeAvailableModules,
  resetState
} = moduleSlice.actions;

export default moduleSlice.reducer;

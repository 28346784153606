/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  makeStyles,
  withStyles,
  Button,
  Dialog,
  IconButton,
  FormControl,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Typography,
  Divider,
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import ConsumptionBarGraph from 'src/views/designer/graphs/ConsumptionBarGraph';
import axiosInstance from 'src/utils/axiosApi';
import { changeConsumptionProfile } from 'src/services/products/projectReducer';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledButton = withStyles({
  root: {
    borderRadius: 20,
    marginRight: '1rem',
  }
})(Button);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    fontFamily: 'Poppins'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children,
    classes,
    onClose,
    ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 400,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function ConsumptionProfileDialog(props) {
  const {
    projectId,
    yillikTuketim,
    saatlikTuketim,
    yillikTotalTuketim,
    tuketimInput,
    periyot
  } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const { consumptionProfile } = useSelector((state) => state.project);

  const [gunlukTuketim, setGunlukTuketim] = useState(saatlikTuketim);
  const [aylikTuketim, setAylikTuketim] = useState(yillikTuketim);
  const [yillikTotTuk, setyillikTotTuk] = useState(yillikTotalTuketim);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  const saveConsumptionDialog = async () => {
    await axiosInstance.post('designer/update_consumption_profile_info?format=json', {
      project_id: projectId,
      gunluk_tuketim: gunlukTuketim,
      aylik_tuketim: aylikTuketim,
      tuketim_profili: consumptionProfile,
    })
      .then((response) => {
        if (response.data.status) {
          setSnackbarMessage(response.data.message);
          setSnackbarType('success');
          setSnackbarState(true);
          setOpen(false);
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
        }
      })
      .catch((error) => console.log(error));
  };

  const fetchConsumptionProfile = async (profile) => {
    await axiosInstance.get(`designer/fetch_consumption_profile?consumption=${tuketimInput}&period=${periyot}&profile=${profile}&format=json`)
      .then((response) => {
        const projectInfoGet = response.data.data;
        setAylikTuketim(projectInfoGet.monthly_consumption);
        setyillikTotTuk((projectInfoGet.yearly_consumption / 1000).toFixed(2));
        setGunlukTuketim(projectInfoGet.daily_consumption);
      })
      .catch((error) => console.log(error));
  };

  const handleChangeTabNumber = (event, newValue) => {
    dispatch(changeConsumptionProfile({ consumptionProfile: newValue }));
    console.log(newValue);
    if (newValue !== 'ozel') {
      fetchConsumptionProfile(newValue);
    }
  };

  const handleChangeGunlukTuketim = (event) => {
    // console.log(event.target.name, event.target.value, event.target.id);
    /* setGunlukTuketim({ ...gunlukTuketim, [event.target.name]: event.target.value }); */
    const newData = gunlukTuketim.map((obj) => {
      if (obj.hour === String(event.target.id)) {
        /* console.log(event.target.name, event.target.value);
        console.log(event.target.type); */
        return {
          ...obj,
          consumption: event.target.value
        };
      }
      return obj;
    });
    setGunlukTuketim(newData);
  };

  const handleOnBlurGunlukTuketim = () => {
    let tempTuketim = 0;
    gunlukTuketim.map((tuketimObj) => {
      if (tuketimObj.hour === '18.00 - 00.00') {
        tempTuketim += 0;
      } else {
        tempTuketim += Number(tuketimObj.consumption);
      }
    });

    const newData = gunlukTuketim.map((obj) => {
      if (obj.hour === '18.00 - 00.00') {
        return {
          ...obj,
          consumption: (100 - tempTuketim)
        };
      }
      return obj;
    });
    setGunlukTuketim(newData);
  };

  const handleChangeAylikTuketim = (event) => {
    /* setAylikTuketim({ ...aylikTuketim, [event.target.name]: event.target.value }); */
    let tempYillikTotTuk = 0;
    const newData = aylikTuketim.map((obj) => {
      tempYillikTotTuk += Number(obj.consumption);
      if (obj.month === String(event.target.id)) {
        /* console.log(event.target.name, event.target.value);
        console.log(event.target.type); */
        return {
          ...obj,
          consumption: event.target.value
        };
      }
      return obj;
    });
    setAylikTuketim(newData);
    setyillikTotTuk((tempYillikTotTuk / 1000).toFixed(2));
  };

  return (
    <div>
      <Button style={{ textTransform: 'none' }} variant="contained" color="primary" onClick={handleClickOpen}>
        Değiştir
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Tüketim Profili Seç
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.root}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={consumptionProfile}
              onChange={handleChangeTabNumber}
              aria-label="Vertical tabs example"
              className={classes.tabs}
            >
              <Tab style={{ textTransform: 'none' }} value="sabit" label="Sabit Tüketim" {...a11yProps('sabit')} />
              <Tab style={{ textTransform: 'none' }} value="aksam" label="Akşam Yoğun Tüketim" {...a11yProps('aksam')} />
              <Tab style={{ textTransform: 'none' }} value="gunduz" label="Gün İçi Yoğun Tüketim" {...a11yProps('gunduz')} />
              <Tab style={{ textTransform: 'none' }} value="ozel" label="Özel Profil" {...a11yProps('ozel')} />
            </Tabs>
            <div style={{
              width: '100%',
              padding: 20,
              display: 'flex',
              flexDirection: 'column'
            }}
            >
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                  <h5 style={{ fontSize: '1.1rem' }}>Günlük Tüketim</h5>
                  <div style={{
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                  }}
                  >
                    {gunlukTuketim.map((obj) => {
                      return (
                        <>
                          <div style={{ padding: '0px 15px', paddingBottom: '10px' }}>
                            <FormControl>
                              <TextField
                                name={obj.hour}
                                label={obj.hour}
                                type="number"
                                disabled={consumptionProfile !== 'ozel' || obj.hour === '18.00 - 00.00'}
                                value={obj.consumption}
                                style={{ width: 120 }}
                                inputProps={{ min: 0, max: 50, step: 1 }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                                }}
                                id={obj.hour}
                                onChange={handleChangeGunlukTuketim}
                                onBlur={handleOnBlurGunlukTuketim}
                                size="small"
                                error={obj.consumption === ''}
                              />
                            </FormControl>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                <div style={{ width: '50%' }} />
              </div>
              <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <div>
                  <h5 style={{ fontSize: '1.1rem' }}>Aylık Tüketim</h5>
                </div>
                <div style={{
                  padding: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }}
                >
                  {aylikTuketim.map((obj) => {
                    return (
                      <>
                        <div style={{ padding: '0px 15px', width: '15%', paddingBottom: '10px' }}>
                          <FormControl>
                            <TextField
                              name={obj.month}
                              label={obj.month}
                              type="number"
                              value={obj.consumption}
                              style={{ width: 100 }}
                              disabled={consumptionProfile !== 'ozel'}
                              inputProps={{ min: 0, step: 1 }}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">kWh</InputAdornment>
                              }}
                              id={obj.month}
                              onChange={handleChangeAylikTuketim}
                              size="small"
                              error={obj.consumption === ''}
                            />
                          </FormControl>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div>
                  <span style={{ marginLeft: '25px' }}>
                    Yıllık Tüketim:
                    <strong>
                      {`${yillikTotTuk} MWh`}
                    </strong>
                  </span>
                </div>
                <div>
                  <ConsumptionBarGraph barData={aylikTuketim} mB={0} mT={20} />
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <StyledButton onClick={handleClose} variant="outlined" color="primary">
                İptal
              </StyledButton>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={saveConsumptionDialog}
              >
                Kaydet
              </StyledButton>
            </div>
          </div>
        </DialogActions>
        <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Dialog>
    </div>
  );
}

ConsumptionProfileDialog.propTypes = {
  projectId: PropTypes.number,
  tesisTipi: PropTypes.string,
  sgucu: PropTypes.number,
  yillikTuketim: PropTypes.array,
  saatlikTuketim: PropTypes.array,
  yillikTotalTuketim: PropTypes.number,
  tuketimInput: PropTypes.number,
  periyot: PropTypes.string
};

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
/* eslint-disable array-callback-return */
/* eslint-disable no-lonely-if */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/self-closing-comp */
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import i18n from 'src/i18n';
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  withStyles,
  Card,
  CardMedia,
  Divider,
  CardContent,
  CardActions,
  IconButton,
  Checkbox,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import axiosInstance from 'src/utils/axiosApi';
import Page from 'src/components/Page';
// import Dialog from 'src/components/Dialog';
import BootstrapSelect from 'src/components/BootstrapSelect';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeTotalPageNumbers,
  resetState,
} from 'src/services/products/filterReducer';
import DeleteDialog from 'src/components/DeleteDialog';
import StarIcon from '@material-ui/icons/Star';
import CloseIcon from '@material-ui/icons/Close';
// import BackspaceIcon from '@material-ui/icons/Backspace';
import FilterListIcon from '@material-ui/icons/FilterList';
import PaginationButtons from 'src/components/Pagination';
import Filter from 'src/components/FilterListComp';
import { useMediaQuery } from 'react-responsive';
import { IconContext } from 'react-icons/lib';
import { FaFilePdf, FaFileExcel } from 'react-icons/fa';
import Spinner from 'src/components/Spinner/Spinner';
import download from 'downloadjs';
// Date Picker Related Libraries and Fuctions
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import tr from 'date-fns/locale/tr';

registerLocale('tr', tr);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  tabRoot: {
    width: 'fit-content'
  },
  list: {
    padding: '5px',
  },
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
    fontSize: 16,
    backgroundColor: '#f7e2e2',
    color: '#ad3737'
  },
  action: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  card: {
    width: '100%',
    position: 'relative'
  },
}));

const StyledTypography = withStyles({
  root: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    '@media (max-width:900px)': {
      fontSize: 14
    },
    textAlign: 'start'
  }
})(Typography);

const StyledOutlinedInput = withStyles({
  input: {
    padding: '12px 14px'
  }
})(OutlinedInput);

const StyledIconButton = withStyles({
  root: {
    position: 'absolute',
    top: 6,
    right: 6,
    color: '#f5a81e'
  }
})(IconButton);

const StyledIconButtonGray = withStyles({
  root: {
    position: 'absolute',
    top: 6,
    right: 6,
    color: '#a4a3a3'
  }
})(IconButton);

const StyledCardContent = withStyles({
  root: {
    position: 'relative'
  }
})(CardContent);

const StyledDivider = withStyles({
  root: {
    margin: '0px 6px'
  }
})(Divider);

// http://solarvis.co/api/musteri/fetch?firma_kodu=electravis&talep=1

const Dashboard = (props) => {
  const {
    statusFetch,
    title
  } = props;
  const { t, } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    leadPerPage,
    currentPage
  } = useSelector((state) => state.filters);
  const {
    statusIds,
    availableModules
  } = useSelector((state) => state.module);
  const {
    company,
    adminInfo,
    loggedIn
  } = useSelector((state) => state.users);

  const [proposalRequests, setProposalRequests] = useState([]);

  const [filterOn, setFilterOn] = useState(false);
  const [leadNumbers, setLeadNumbers] = useState();
  const [loadingReport, setLoadingReport] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [isSubconModule, setIsSubconModule] = useState(false);

  const [filters, setFilters] = useState({
    cities: [],
    starred: false,
    facilities: []
  });

  const [orderBy, setOrderBy] = useState('descCreatedDate');
  const [searchWord, setSearchWord] = useState('');

  // Date Picker Related States and Component
  const today = new Date();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(today.setDate(today.getDate() + 1));
  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button style={{ padding: '5px', fontSize: '1rem' }} onClick={onClick} ref={ref}>
      {value ? value : t('Filtre.choose')}
    </button>
  ));

  // Hook
  function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }

  // Get the previous value (was passed into hook on last render)
  const prevSearchWord = usePrevious(searchWord);

  const changeFilter = (filterType, value) => {
    setFilters({
      ...filters,
      [filterType]: value
    });
  };

  // const [currentPage, setCurrentPage ] = useState(1);

  // ------------ DETERMINE MOBILE OR DESKTOP ---------- //

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' });

  // --------------------------------------------------- //

  const handleYildiz = (event) => {
    setFilters({
      ...filters,
      starred: event.target.checked
    });
  };

  async function getReport(id, facilityType) {
    console.log(facilityType);
    // Define report type
    let reportType = 'default_tr';
    let reportName = 'GES Ön Fizibilite Raporu.pdf';
    if (localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') {
      reportType = 'default_en';
      reportName = 'SPP Pre-Feasibility Report.pdf';
    }

    if (company === 'Arcelik Solar') {
      reportType = 'arcelik_tr';
      if (localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') {
        reportType = 'arcelik_en';
      }
    }

    if (['Mesken', 'Residential'].includes(facilityType) && availableModules.includes('home')) {
      reportType = 'home_tr';
      if (localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') {
        reportType = 'home_en';
      }
      if (localStorage.getItem('language') === 'de' || String(i18n.language).split('-')[0] === 'de') {
        reportType = 'home_de';
      }
    }

    if (company === 'solarVis Germany') { reportType = 'default_de'; }
    if (['solarVis Florida', 'Skywells Energy', 'Lumen United'].includes(company)) { reportType = 'default_us'; }

    setLoadingReport(true);
    await axiosInstance.get(`report/generate?musteri_id=${id}&format=json&is_admin_page=1&report_type=${reportType}`, {
      responseType: 'blob'
    })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data],
          { type: 'application/pdf' }
        );
        // Build a URL from the file
        // const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        download(file, reportName,);
      })
      .catch((error) => console.log(error));
    setLoadingReport(false);
  }

  const getExcelExport = async () => {
    setLoadingReport(true);
    let statusId;

    if (statusFetch === 'all') {
      statusId = statusIds;
    } else {
      statusId = [statusFetch];
    }

    await axiosInstance.post('musteri/excel_export', {
      status_id: statusId,
      yildizli: filters.starred,
      iller: filters.cities,
      tesisler: filters.facilities,
      aralik: [(currentPage - 1) * leadPerPage, currentPage * leadPerPage],
      search: searchWord,
      orderBy,
      startDate: startDate ? String(format(startDate, 'yyyy-MM-dd')) : null,
      endDate: String(format(endDate, 'yyyy-MM-dd'))
    }, {
      responseType: 'blob'
    })
      .then((response) => {
        // Create a Blob from the Excel Stream
        const file = new Blob(
          [response.data],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
        );
        // Build a URL from the file
        // const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        let fileName = 'solarvis_musteriler_excel.xlsx';
        if (localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') {
          fileName = 'solarvis_customers_excel.xlsx';
        }
        download(file, fileName);
      })
      .catch((error) => console.log(error));
    setLoadingReport(false);
  };

  function getProposals(searchBy) {
    setPageLoading(true);
    let statusId;

    if (statusFetch === 'all') {
      statusId = statusIds;
    } else {
      statusId = [statusFetch];
    }

    axiosInstance.post('musteri/fetch?format=json', {
      status_id: statusId,
      yildizli: filters.starred,
      iller: filters.cities,
      tesisler: filters.facilities,
      aralik: [(currentPage - 1) * leadPerPage, currentPage * leadPerPage],
      search: searchBy,
      orderBy,
      startDate: startDate ? String(format(startDate, 'yyyy-MM-dd')) : null,
      endDate: String(format(endDate, 'yyyy-MM-dd'))
    })
      .then((response) => {
        const { data } = response.data;
        // console.log(response);
        // console.log(response.data[response.data.length - 1].leads_sayisi);
        // console.log(localStorage.getItem('loggedIn'));
        const leadSayisi = data.leads_sayisi;
        setLeadNumbers(leadSayisi);
        dispatch(changeTotalPageNumbers({
          totalPageNumbers: Math.ceil(Number(leadSayisi) / leadPerPage)
        }));
        const tempProposals = [];
        data.leads.map((proposal) => {
          /* if (String(proposal.ilce) === 'null' || String(proposal.ilce) === 'undefined') {
            proposal.ilce = 'Merkez';
          } */

          proposal.googleMapURL = `https://www.google.com/maps/search/?api=1&query=${proposal.enlem},${proposal.boylam}&basemap=satellite`;
          // proposal.image = Promise.resolve(previewImage);
          proposal.createdDate = new Date(Date.parse(proposal.created_at));
          proposal.lastUpdateDate = new Date(Date.parse(proposal.zaman));
          proposal.lastUpdateStr = String(format(new Date(Date.parse(proposal.zaman)), 'dd/MM/yyyy'));
          proposal.lastUpdateStrWithTime = String(format(new Date(Date.parse(proposal.zaman)), 'dd/MM/yyyy - HH.mm'));
          proposal.roi = String(proposal.roi).slice(0, 4);
          proposal.imageAlt = `ID: ${proposal.id}`;
          proposal.customerId = proposal.id;
          if (proposal.is_battery) {
            proposal.is_battery = t('MusteriBilgileriDialog.var');
          } else {
            proposal.is_battery = t('MusteriBilgileriDialog.yok');
          }
          if (proposal.is_charger) {
            proposal.is_charger = t('MusteriBilgileriDialog.var');
          } else {
            proposal.is_charger = t('MusteriBilgileriDialog.yok');
          }
          if ('order' in proposal) {
            proposal.upfront_cost = proposal.order.upfront_cost;
          }

          tempProposals.push(proposal);
          // setProposalRequests((prevArray) => [...prevArray, proposal]);
          return proposal;
        });
        setProposalRequests(tempProposals);
      })
      .catch((error) => console.log(error));
    setPageLoading(false);
  }

  async function changeStarState(id, currentState) {
    await axiosInstance.get(`/musteri/yildizli?musteri_id=${id}&yildizli=${Number(!currentState)}&format=json`)
      .then(() => {
        // console.log(response);
        // Müşteri id'sine göre roposalRequests'te o müşteriyi bulup  local state'de update'le
        // console.log(proposalRequests);
        const idx = (element) => element.id === id;
        const requestIdx = proposalRequests.findIndex(idx);
        const newRequests = [...proposalRequests];
        newRequests[requestIdx].yildizli = !currentState;
        setProposalRequests(newRequests);
        return null;
        // console.log(proposalRequests);
      })
      .catch((error) => console.log(error));
  }

  async function changeViewStatus(id, currentState) {
    if (currentState) {
      await axiosInstance.get(`musteri/oku?musteri_id=${id}&format=json`)
        .then(() => {
          // console.log(response);
          // Müşteri id'sine göre roposalRequests'te o müşteriyi bulup  local state'de update'le
          // console.log(proposalRequests);
          const idx = (element) => element.id === id;
          const requestIdx = proposalRequests.findIndex(idx);
          const newRequests = [...proposalRequests];
          newRequests[requestIdx].is_changed = false;
          setProposalRequests(newRequests);
          return null;
          // console.log(proposalRequests);
        })
        .catch((error) => console.log(error));
    }
  }

  const orderLeadsFunc = (leadsArr, orderByX) => {
    let sendLeads = [];

    if (orderByX === 'descLastUpdateDate') {
      sendLeads = leadsArr.slice().sort((a, b) => b.lastUpdateDate - a.lastUpdateDate);
    } else if (orderByX === 'ascLastUpdateDate') {
      sendLeads = leadsArr.slice().sort((a, b) => a.lastUpdateDate - b.lastUpdateDate);
    } else if (orderByX === 'descCreatedDate') {
      sendLeads = leadsArr.slice().sort((a, b) => b.createdDate - a.createdDate);
    } else if (orderByX === 'ascCreatedDate') {
      sendLeads = leadsArr.slice().sort((a, b) => a.createdDate - b.createdDate);
    } else if (orderByX === 'descId') {
      sendLeads = leadsArr.slice().sort((a, b) => b.id - a.id);
    } else if (orderByX === 'ascId') {
      sendLeads = leadsArr.slice().sort((a, b) => a.id - b.id);
    }

    return sendLeads;
  };

  if (!loggedIn) {
    navigate('/login', { replace: true });
  }

  const willMount = useRef(true);

  useEffect(() => {
    if (willMount.current === true) {
      dispatch(resetState());
      getProposals(searchWord);
      willMount.current = false;
    } else {
      getProposals(searchWord);
    }
  }, [filters, currentPage, statusIds, orderBy, startDate, endDate]);

  useEffect(() => {
    if (searchWord.length > 2 || (prevSearchWord?.length > 0 && searchWord.length === 0)) {
      getProposals(searchWord);
    }
  }, [searchWord]);

  useEffect(() => {
    if (availableModules.length > 0 && availableModules.includes('subcon_detailed')) {
      setIsSubconModule(true);
    }
  }, [availableModules]);

  if (loadingReport) {
    return (
      <>
        <Spinner text={t('ProjeTalepleriSayfası.raporOlusturuluyor')} />
      </>
    );
  }

  if (pageLoading) {
    return (
      <>
        <Spinner text="" />
      </>
    );
  }

  if (!leadNumbers) {
    return (
      <Page
        className={classes.root}
        title={title}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            {location.state
              && (
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <h4 style={{ fontWeight: 'normal' }}>{`${location.state.data.title} - ${location.state.data.detailed_explanation}`}</h4>
                </Grid>
              )}
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              {filterOn && (
                <div style={{
                  alignItems: 'center',
                  display: 'flex',
                  backgroundColor: 'white',
                  borderRadius: '6px',
                  justifyContent: 'center',
                  flexDirection: isDesktopOrLaptop ? 'row' : 'column'
                }}
                >
                  <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    margin: '0px 10px'
                  }}
                  >
                    <Checkbox
                      checked={filters.starred}
                      onChange={(event) => handleYildiz(event)}
                      color="default"
                      inputProps={{ 'aria-label': 'checkbox with default color' }}
                    />
                    <span>{t('Filtre.yildiz')}</span>
                  </div>
                  <StyledDivider orientation="vertical" flexItem />
                  {!(company === 'GreenEffect' || company === 'KSL')
                    && (
                      <Filter
                        changeFilterFunction={changeFilter}
                        filterType="cities"
                        filterValue={filters.cities}
                      />
                    )}
                  <Filter
                    changeFilterFunction={changeFilter}
                    filterType="facilities"
                    filterValue={filters.facilities}
                  />
                  <StyledDivider orientation="vertical" flexItem />
                  <span style={{ marginRight: 20, marginLeft: 20 }}>
                    {t('Filtre.toplam')}
                    :
                    <span>  </span>
                    <strong>{leadNumbers}</strong>
                  </span>
                  <div style={{
                    justifyContent: 'flex-end',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  >
                    <IconButton color="default" aria-label="Filtrelemeyi kapat" onClick={() => setFilterOn(false)}>
                      <CloseIcon />
                    </IconButton>
                    <span>{t('Filtre.kapat')}</span>
                  </div>
                </div>
              )}
              {!filterOn && (
                <div style={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: isDesktopOrLaptop ? 'row' : 'column'
                }}
                >
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                  >
                    <FormControl style={{ minWidth: '300px', backgroundColor: 'white' }} variant="outlined">
                      <StyledOutlinedInput
                        id="outlined-adornment-search"
                        type="text"
                        value={searchWord}
                        onChange={(e) => setSearchWord(e.target.value)}
                        placeholder={t('Filtre.searchPlaceholder')}
                        autoFocus={searchWord.length > 2}
                        endAdornment={(
                          <InputAdornment position="end">
                            <div>
                              {searchWord && (
                                <IconButton
                                  aria-label="search"
                                  edge="end"
                                  onClick={() => {
                                    setSearchWord('');
                                    setProposalRequests([]);
                                    getProposals('');
                                  }}
                                >
                                  <ClearIcon />
                                </IconButton>
                              )}
                              {!searchWord && (
                                <IconButton
                                  aria-label="search"
                                  edge="end"
                                  color="primary"
                                >
                                  <SearchIcon />
                                </IconButton>
                              )}
                            </div>
                          </InputAdornment>
                        )}
                      />
                    </FormControl>
                    {searchWord.length < 3 && searchWord.length > 0 && (
                      <span>{t('Filtre.minCharacterWarn')}</span>
                    )}
                  </div>
                  <div>
                    <span style={{ marginRight: 20 }}>
                      {t('Filtre.toplam')}
                      :
                      <span>  </span>
                      <strong>{leadNumbers}</strong>
                    </span>
                    <IconButton color="default" aria-label="Filtrele" onClick={() => setFilterOn(true)}>
                      <FilterListIcon />
                    </IconButton>
                    <span>{t('Filtre.filtre')}</span>
                  </div>
                </div>
              )}
            </Grid>
            <div style={{
              height: '40vh',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center'
            }}
            >
              <span style={{ fontSize: '1.2rem' }}>{t('ProjeTalepleriSayfası.noCustomers')}</span>
            </div>
          </Grid>
        </Container>
      </Page>
    );
  }

  return (
    <Page
      className={classes.root}
      title={title}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          {location.state
            && (
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <h4 style={{ fontWeight: 'normal' }}>{`${location.state.data.title} - ${location.state.data.detailed_explanation}`}</h4>
              </Grid>
            )}
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            {filterOn && (
              <div style={{
                alignItems: 'center',
                display: 'flex',
                backgroundColor: 'white',
                borderRadius: '6px',
                justifyContent: 'center',
                flexDirection: isDesktopOrLaptop ? 'row' : 'column'
              }}
              >
                <div style={{
                  alignItems: 'center',
                  display: 'flex',
                  margin: '0px 10px'
                }}
                >
                  <Checkbox
                    checked={filters.starred}
                    onChange={(event) => handleYildiz(event)}
                    color="default"
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                  />
                  <span>{t('Filtre.yildiz')}</span>
                </div>
                <StyledDivider orientation="vertical" flexItem />
                {!(company === 'GreenEffect' || company === 'KSL')
                  && (
                    <Filter
                      changeFilterFunction={changeFilter}
                      filterType="cities"
                      filterValue={filters.cities}
                    />
                  )}
                <Filter
                  changeFilterFunction={changeFilter}
                  filterType="facilities"
                  filterValue={filters.facilities}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <div style={{ padding: '0px 10px', width: 'min-content' }}>
                    <span style={{ fontSize: '0.8rem' }}>{t('Filtre.beginning')}</span>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      locale={(localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') ? 'en' : 'tr'}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={<ExampleCustomInput />}
                    />
                  </div>
                  <div>
                    <span style={{ fontSize: '0.8rem' }}>{t('Filtre.ending')}</span>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      locale={(localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') ? 'en' : 'tr'}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={<ExampleCustomInput />}
                    />
                  </div>
                </div>
                <StyledDivider orientation="vertical" flexItem />
                <span style={{ marginRight: 20, marginLeft: 20 }}>
                  {t('Filtre.toplam')}
                  :
                  <span>  </span>
                  <strong>{leadNumbers}</strong>
                </span>
                <div style={{
                  justifyContent: 'flex-end',
                  display: 'flex',
                  alignItems: 'center',
                }}
                >
                  <IconButton color="default" aria-label="Filtrelemeyi kapat" onClick={() => setFilterOn(false)}>
                    <CloseIcon />
                  </IconButton>
                  <span>{t('Filtre.kapat')}</span>
                </div>
              </div>
            )}
            {!filterOn && (
              <div style={{
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'center',
                flexDirection: isDesktopOrLaptop ? 'row' : 'column'
              }}
              >
                <div style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
                >
                  <FormControl style={{ minWidth: '300px', backgroundColor: 'white' }} variant="outlined">
                    <StyledOutlinedInput
                      id="outlined-adornment-search"
                      type="text"
                      value={searchWord}
                      onChange={(e) => setSearchWord(e.target.value)}
                      placeholder={t('Filtre.searchPlaceholder')}
                      autoFocus={searchWord.length > 2}
                      endAdornment={(
                        <InputAdornment position="end">
                          <div>
                            {searchWord && (
                              <IconButton
                                aria-label="search"
                                edge="end"
                                onClick={() => {
                                  setSearchWord('');
                                  setProposalRequests([]);
                                  getProposals('');
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            )}
                            {!searchWord && (
                              <IconButton
                                aria-label="search"
                                edge="end"
                                color="primary"
                              >
                                <SearchIcon />
                              </IconButton>
                            )}
                          </div>
                        </InputAdornment>
                      )}
                    />
                  </FormControl>
                  {searchWord.length < 3 && searchWord.length > 0 && (
                    <span>{t('Filtre.minCharacterWarn')}</span>
                  )}
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: isDesktopOrLaptop ? 'row' : 'column',
                  alignItems: 'center'
                }}
                >
                  <div style={{ marginRight: isDesktopOrLaptop ? '40px' : '0px', padding: isDesktopOrLaptop ? '0px' : '15px 0px' }}>
                    <span>{`${t('OrderBy.orderBy')}:`}</span>
                    <BootstrapSelect
                      label="order"
                      name="leadsOrderBy"
                      id="leadsOrderBy"
                      value={orderBy}
                      changeFuction={setOrderBy}
                      selectItemValues={
                        ['descCreatedDate', 'ascCreatedDate', 'descLastUpdateDate', 'ascLastUpdateDate', 'descId', 'ascId']
                      }
                      selectItemLabels={
                        [t('OrderBy.lastCreated'), t('OrderBy.oldCreated'), t('OrderBy.lastUpdate'), t('OrderBy.oldUpdate'), t('OrderBy.idDesc'), t('OrderBy.idAsc')]
                      }
                      width={isDesktopOrLaptop ? 300 : 220}
                    />
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  >
                    <span style={{ marginRight: 20 }}>
                      {t('Filtre.toplam')}
                      :
                      <span>  </span>
                      <strong>{leadNumbers}</strong>
                    </span>
                    <div style={{
                      borderRadius: 4,
                      backgroundColor: '#007700',
                      padding: 5,
                      cursor: 'pointer',
                      marginRight: 20
                    }}
                    >
                      <div style={{ textDecoration: 'none' }} onClick={() => getExcelExport()}>
                        <IconContext.Provider value={{ color: '#ffffff', size: 20 }}>
                          <FaFileExcel />
                        </IconContext.Provider>
                      </div>
                    </div>
                    <IconButton color="default" aria-label="Filtrele" onClick={() => setFilterOn(true)}>
                      <FilterListIcon />
                    </IconButton>
                    <span>{t('Filtre.filtre')}</span>
                  </div>
                </div>
              </div>
            )}
          </Grid>
          {orderLeadsFunc(proposalRequests, orderBy).map((request) => (
            <Grid
              item
              lg={3}
              md={3}
              xl={3}
              xs={12}
            >
              <Card className={classes.card}>
                {/* <CardHeader title={request.tesis} className={classes.header} /> */}
                {request.gmaps_image_link && (
                  <CardMedia
                    component="img"
                    alt={request.imageAlt}
                    height="170"
                    image={request.gmaps_image_link}
                    title={request.imageAlt}
                  />
                )}
                {!request.gmaps_image_link && (
                  <CardMedia
                    component="div"
                    style={{ height: 170 }}
                    children={(
                      <>
                        <div style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          alignContent: 'center',
                          justifyContent: 'center'
                        }}
                        >
                          <span>{t('MusteriBilgileriDialog.imageLoading')}</span>
                        </div>
                      </>
                    )}
                  />
                )}
                <span
                  style={{
                    position: 'absolute',
                    top: 6,
                    left: 6,
                    color: '#a4a3a3',
                    height: '20px',
                    width: '20px',
                    backgroundColor: request.is_changed ? '#FFC966' : '#2e9272',
                    borderRadius: '50%',
                    display: 'inline-block'
                  }}
                >
                </span>
                <Divider variant="middle" />
                <StyledCardContent>
                  {request.yildizli && (availableModules.includes('subcon_detailed') && adminInfo.isCompanySuperUser) && (
                    <StyledIconButton color="primary" aria-label="Yıldızla" onClick={() => changeStarState(request.id, request.yildizli)}>
                      <StarIcon />
                    </StyledIconButton>
                  )}
                  {request.yildizli && !(availableModules.includes('subcon_detailed')) && (
                    <StyledIconButton color="primary" aria-label="Yıldızla" onClick={() => changeStarState(request.id, request.yildizli)}>
                      <StarIcon />
                    </StyledIconButton>
                  )}
                  {!request.yildizli && (availableModules.includes('subcon_detailed') && adminInfo.isCompanySuperUser) && (
                    <StyledIconButtonGray color="primary" aria-label="Yıldızla" onClick={() => changeStarState(request.id, request.yildizli)}>
                      <StarIcon />
                    </StyledIconButtonGray>
                  )}
                  {!request.yildizli && !(availableModules.includes('subcon_detailed')) && (
                    <StyledIconButtonGray color="primary" aria-label="Yıldızla" onClick={() => changeStarState(request.id, request.yildizli)}>
                      <StarIcon />
                    </StyledIconButtonGray>
                  )}
                  <div className={classes.list}>
                    <div
                      style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '6px'
                      }}
                    >
                      <CalendarTodayIcon fontSize="small" color="action" />
                      <Typography
                        color="textSecondary"
                        display="inline"
                        sx={{ pl: 1 }}
                        variant="body1"
                        style={{ marginLeft: '5px', fontFamily: 'Poppins', fontSize: '14px' }}
                      >
                        {request.lastUpdateStr}
                      </Typography>
                    </div>
                    <StyledTypography align="center">
                      <strong>
                        {t('ProjeTalepleriSayfası.tesis')}
                        :
                        <span>  </span>
                      </strong>
                      {request.tesis_tipi}
                    </StyledTypography>
                    <StyledTypography align="center">
                      <strong>
                        {t('MusteriBilgileriDialog.konum')}
                        :
                        <span>  </span>
                      </strong>
                      {request.il}
                      {(request.ilce !== '-' && request.ilce !== null && request.ilce !== undefined) && <span>- {request.ilce}</span>}
                    </StyledTypography>
                    {request.isim
                      && (
                        <StyledTypography align="center">
                          <strong>
                            {t('ProjeTalepleriSayfası.isim')}
                            :
                            <span>  </span>
                          </strong>
                          {request.isim}
                          <span> </span>
                          {request.soy_isim}
                        </StyledTypography>
                      )}
                  </div>
                </StyledCardContent>
                <Divider variant="middle" />
                <CardActions
                  className={classes.action}
                  onClick={() => changeViewStatus(request.id, request.is_changed)}
                >
                  {/* TODO: availableModules is not ready when i render conditionally. So, always enters in !('subcon' in availableModules). */}
                  <Link to={`/projects/${request.id}`} state={{ data: request }}>
                    <Button style={{ textTransform: 'none' }} variant="contained" color="primary">
                      {t('MusteriBilgileriDialog.butonText')}
                    </Button>
                  </Link>
                  {company !== 'IBT Solar Home' && !(['Mesken', 'Residential'].includes(request.tesis_tipi) && availableModules.includes('home'))
                    && (
                      <div style={{
                        borderRadius: 4,
                        backgroundColor: '#ad3737',
                        padding: 5,
                        cursor: 'pointer'
                      }}
                      >
                        <div style={{ textDecoration: 'none' }} onClick={() => getReport(request.id, request.tesis_tipi)}>
                          <IconContext.Provider value={{ color: '#ffffff', size: 20 }}>
                            <FaFilePdf />
                          </IconContext.Provider>
                        </div>
                      </div>
                    )}
                  {(availableModules.includes('subcon_detailed') && adminInfo.isCompanySuperUser) && <DeleteDialog id={request.id} />}
                  {!(availableModules.includes('subcon_detailed')) && <DeleteDialog id={request.id} />}
                </CardActions>
              </Card>
            </Grid>
          ))}
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <PaginationButtons />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;

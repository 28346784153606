/* eslint-disable */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { /* useRoutes */ Navigate, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import './App.css';

import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import SalesFunnelPage from 'src/views/customerList';
import FirmaBilgileri from 'src/views/conf/firmaBilgileri';
import UserInfoPage from 'src/views/conf/userInfoPage';
import MaliyetWrapper from 'src/views/conf/maliyetWrapper';
import FormInputsManagement from 'src/views/conf/formInputsManagement';
import SubcontractorManagement from 'src/views/conf/subcontractor/subcontractorManagement';
import SingleSubcontractor from 'src/views/conf/subcontractor/singleSubcontractor';
import SingleSubconUserConfiguration from 'src/views/conf/subcontractor/singleSubconUser';
import DocumentManagement from 'src/views/conf/subcontractor/documentManagement';
import PackageList from 'src/views/solarPackages/PackageList';
import PackageInfo from 'src/views/solarPackages/PackageInfo';
import PromotionCodeList from 'src/views/solarPackages/PromotionCodeList';
import PromotionCodeInfo from 'src/views/solarPackages/PromotionCodeInfo';
import UserRedirect from 'src/views/redirect/userRedirect';

import ProjectList from 'src/views/designer/projectList';
import ProjectInfo from 'src/views/designer/projectInfo';
import CreateNewProject from 'src/views/designer/newProject';
import DrawingPage from 'src/views/designer/drawingPage';
import ConsumptionPage from 'src/views/designer/consumptionPage';
import CostPage from 'src/views/designer/costPage';
import ReportPage from 'src/views/designer/reportPage';

import CreditListPage from 'src/views/creditApplication/creditApplicationList';
import CreditInfo from 'src/views/creditApplication/creditApplicationInfo';
import BankConfigurationPage from 'src/views/conf/creditApplication/bankConfiguration';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import CustomerInfo from './views/customerPage';
import { AuthContext } from './context/AuthContext';

// Define Working Environment Variables -> development or production
const NODE_ENV = process.env.NODE_ENV;

// ------- SENTRY - ERROR TRACING APPLICATION --------- //
// Run sentry only in production mode
if (NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://43d862d756074ab9a32301a98016fd9e@o1341554.ingest.sentry.io/6661861',
    integrations: [new BrowserTracing()],

    environment: 'production',
    ignoreErrors: ['ResizeObserver loop limit exceeded'],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

// ------------------------------ //

const App = () => {
  // const [loading, setLoading] = useState(false);
  /* const commonRoutes = [
    {
      path: 'projects',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <ProjectList /> },
        { path: 'new-project', element: <CreateNewProject /> },
        {
          path: ':projectId/project-info',
          element: <ProjectInfo />,
        },
        {
          path: ':projectId/roof-modelling',
          element: <DrawingPage />,
        },
        {
          path: ':projectId/facility-consumption',
          element: <ConsumptionPage />,
        },
        {
          path: ':projectId/material-cost',
          element: <CostPage />,
        },
        {
          path: ':projectId/summary-report',
          element: <ReportPage />,
        },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: 'settings',
      element: <DashboardLayout />,
      children: [
        { path: 'company-info', element: <FirmaBilgileri /> },
        { path: 'user-info', element: <UserInfoPage /> },
        { path: 'cost-configuration', element: <MaliyetWrapper /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/giris', element: <Navigate to="/login" />
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: 'login', element: <LoginView /> },
        { path: 'register', element: <RegisterView /> },
        { path: '404', element: <NotFoundView /> },
        { path: '/', element: <Navigate to="/login" /> },
        { path: '*', element: <Navigate to="/login" /> }
      ]
    },
    {
      path: 'solar-packages',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <PackageList /> },
        { path: 'new-package', element: <PackageInfo pageType="newPackage" /> },
        {
          path: ':redirectedPackageId/package-info',
          element: <PackageInfo pageType="updatePackage" />,
        },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: 'leadgen-crm',
      element: <DashboardLayout />,
      children: [
        { path: 'status-1', element: <SalesFunnelPage statusFetch={0} pageType="leadgen" key="leadgen-1" title="solarVis | Leadgen Customers Status 1" /> },
        { path: 'status-2', element: <SalesFunnelPage statusFetch={1} pageType="leadgen" key="leadgen-2" title="solarVis | Leadgen Customers Status 2" /> },
        { path: 'status-3', element: <SalesFunnelPage statusFetch={2} pageType="leadgen" key="leadgen-3" title="solarVis | Leadgen Customers Status 3" /> },
        { path: 'status-4', element: <SalesFunnelPage statusFetch={3} pageType="leadgen" key="leadgen-4" title="solarVis | Leadgen Customers Status 4" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: 'package-crm',
      element: <DashboardLayout />,
      children: [
        { path: 'status-1', element: <SalesFunnelPage statusFetch={0} pageType="package" key="package-1" title="solarVis | Solar Package Customers Status 1" /> },
        { path: 'status-2', element: <SalesFunnelPage statusFetch={1} pageType="package" key="package-2" title="solarVis | Solar Package Customers Status 2" /> },
        { path: 'status-3', element: <SalesFunnelPage statusFetch={2} pageType="package" key="package-3" title="solarVis | Solar Package Customers Status 3" /> },
        { path: 'status-4', element: <SalesFunnelPage statusFetch={3} pageType="package" key="package-4" title="solarVis | Solar Package Customers Status 4" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: 'user-redirect',
      element: <UserRedirect />,
      children: []
    }
  ]; */

  // const routing = useRoutes(commonRoutes);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {/* {loading ? <Spinner text="" /> : routing} */}
      {/* {routing} */}
      <AuthContext>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Navigate to="/login" />} />
            <Route path="login" element={<LoginView />} />
            <Route path="404" element={<NotFoundView />} />
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Route>
          <Route path="/giris" element={<Navigate to="/login" />} />
          <Route path="user-redirect" element={<UserRedirect />} />
          <Route path="customers" element={<DashboardLayout />}>
            <Route index element={<Navigate to="/" />} />
            <Route path=":customerId" element={<CustomerInfo />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
          <Route path="projects" element={<DashboardLayout />}>
            <Route index element={<Navigate to="/" />} />
            <Route path=":customerId" element={<CustomerInfo />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
          <Route path="designer" element={<DashboardLayout />}>
            <Route index element={<ProjectList />} />
            <Route path="new-project" element={<CreateNewProject />} />
            <Route path=":projectId/project-info" element={<ProjectInfo />} />
            <Route path=":projectId/roof-modelling" element={<DrawingPage />} />
            <Route
              path=":projectId/facility-consumption"
              element={<ConsumptionPage />}
            />
            <Route path=":projectId/material-cost" element={<CostPage />} />
            <Route path=":projectId/summary-report" element={<ReportPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
          <Route path="settings" element={<DashboardLayout />}>
            <Route path="company-info" element={<FirmaBilgileri />} />
            <Route
              path="bank-configuration"
              element={<BankConfigurationPage />}
            />
            <Route path="user-info" element={<UserInfoPage />} />
            <Route
              path="form-input-management"
              element={<FormInputsManagement />}
            />
            <Route path="cost-configuration" element={<MaliyetWrapper />} />
            <Route
              path="subcontractor-management"
              element={<SubcontractorManagement />}
            />
            <Route
              path="subcontractor-documents"
              element={<DocumentManagement />}
            />
            <Route
              path="subcontractor-management/new-subcontractor"
              element={<SingleSubcontractor pageType="newSubcontractor" />}
            />
            <Route
              path="subcontractor-management/sub/:subcontractorId"
              element={<SingleSubcontractor pageType="updateSubcontractor" />}
            />
            <Route
              path="subcontractor-management/new-subcon-user"
              element={<SingleSubconUserConfiguration pageType="newUser" />}
            />
            <Route
              path="subcontractor-management/user/:userId"
              element={<SingleSubconUserConfiguration pageType="updateUser" />}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
          <Route path="solar-package-management" element={<DashboardLayout />}>
            <Route path="packages" element={<PackageList />} />
            <Route
              path="packages/new-package"
              element={<PackageInfo pageType="newPackage" />}
            />
            <Route
              path="packages/:redirectedPackageId/package-info"
              element={<PackageInfo pageType="updatePackage" />}
            />
            <Route path="promotions" element={<PromotionCodeList />} />
            <Route
              path="new-promotion"
              element={<PromotionCodeInfo pageType="newPromotion" />}
            />
            <Route
              path="promotions/:redirectedPromotionId/promotion-info"
              element={<PromotionCodeInfo pageType="updatePromotion" />}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
          <Route path="crm" element={<DashboardLayout />}>
            <Route
              path="status-canceled"
              element={
                <SalesFunnelPage
                  statusFetch={-1}
                  key="status-canceled"
                  title="solarVis | Canceled Projects"
                />
              }
            />
            <Route
              path="status-1"
              element={
                <SalesFunnelPage
                  statusFetch={1}
                  key="status-1"
                  title="solarVis | Customers Status 1"
                />
              }
            />
            <Route
              path="status-2"
              element={
                <SalesFunnelPage
                  statusFetch={2}
                  key="status-2"
                  title="solarVis | Customers Status 2"
                />
              }
            />
            <Route
              path="status-3"
              element={
                <SalesFunnelPage
                  statusFetch={3}
                  key="status-3"
                  title="solarVis | Customers Status 3"
                />
              }
            />
            <Route
              path="status-4"
              element={
                <SalesFunnelPage
                  statusFetch={4}
                  key="status-4"
                  title="solarVis | Customers Status 4"
                />
              }
            />
            <Route
              path="status-5"
              element={
                <SalesFunnelPage
                  statusFetch={5}
                  key="status-5"
                  title="solarVis | Customers Status 5"
                />
              }
            />
            <Route
              path="status-6"
              element={
                <SalesFunnelPage
                  statusFetch={6}
                  key="status-6"
                  title="solarVis | Customers Status 6"
                />
              }
            />
            <Route
              path="status-7"
              element={
                <SalesFunnelPage
                  statusFetch={7}
                  key="status-7"
                  title="solarVis | Customers Status 7"
                />
              }
            />
            <Route
              path="status-8"
              element={
                <SalesFunnelPage
                  statusFetch={8}
                  key="status-8"
                  title="solarVis | Customers Status 8"
                />
              }
            />
            <Route
              path="status-9"
              element={
                <SalesFunnelPage
                  statusFetch={9}
                  key="status-9"
                  title="solarVis | Customers Status 9"
                />
              }
            />
            <Route
              path="status-all"
              element={
                <SalesFunnelPage
                  statusFetch="all"
                  key="status-all"
                  title="solarVis | All Customers"
                />
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
          <Route path="credit-applications-crm" element={<DashboardLayout />}>
            <Route
              path="status-1"
              element={
                <CreditListPage
                  apiRequestURL="bankloan/banks/get-credit-applications"
                  key="status-1"
                  requestStatus="APPLIED"
                  title="solarVis | Credit Application Status 1"
                />
              }
            />
            <Route
              path="status-2"
              element={
                <CreditListPage
                  apiRequestURL="bankloan/banks/get-proposed-credit-applications"
                  key="status-2"
                  requestStatus="OFFERED"
                  title="solarVis | Credit Application Status 2"
                />
              }
            />
            <Route
              path="status-3"
              element={
                <CreditListPage
                  apiRequestURL="bankloan/banks/get-approved-credit-applications"
                  key="status-3"
                  requestStatus="APPROVED"
                  title="solarVis | Credit Application Status 3"
                />
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
          <Route path="credit-applications" element={<DashboardLayout />}>
            <Route index element={<Navigate to="/" />} />
            <Route path=":creditApplicationId" element={<CreditInfo />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </AuthContext>
    </ThemeProvider>
  );
};

export default App;

/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Container,
  Card,
  CardContent,
  Divider,
  TextField,
  Table,
  TableCell,
  TableRow,
  withStyles,
  TableBody,
  InputBase,
  Select,
  MenuItem,
  Button,
  Snackbar,
} from '@material-ui/core';
import 'reactjs-popup/dist/index.css';
import SaveIcon from '@material-ui/icons/Save';
import Page from 'src/components/Page';
import axiosInstance from 'src/utils/axiosApi';
import Spinner from 'src/components/Spinner/Spinner';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BootstrapSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: 0,
    paddingBottom: 0
  },
  formControl: {
    minWidth: 110,
  },
  /* cardContentRoot: {
    paddingBottom: 0
  } */
}));

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins',
    borderBottom: 'none'
  },
}))(TableCell);

const PromotionInfo = (props) => {
  const { t, } = useTranslation();
  const MUIclasses = useStyles();
  const [loading, setLoading] = useState(false);
  const { redirectedPromotionId } = useParams();
  const { pageType } = props;

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // const largeYerlesim = 3;

  const [promotionInfo, setPromotionInfo] = useState({
    id: 0,
    code: '',
    is_public: false,
    name: '',
    amount: '',
    currency: 'USD',
  });

  const createUpdatePromotion = async () => {
    setLoading(true);
    let sendPromotionId;
    if (pageType === 'newPromotion') {
      sendPromotionId = '00';
    } else {
      sendPromotionId = promotionInfo.id;
    }
    await axiosInstance.post('/home/promotion/create_update?format=json', {
      promotion_code_id: sendPromotionId,
      name: promotionInfo.name,
      currency: promotionInfo.currency,
      amount: promotionInfo.amount,
      is_public: promotionInfo.is_public,
    })
      .then((response) => {
        if (response.data.status) {
          setSnackbarMessage(response.data.message);
          setSnackbarType('success');
          setSnackbarState(true);

          // get package and material ids
          const promotionIdGet = response.data.data.promotion_id;
          setPromotionInfo((prevState) => ({
            ...prevState,
            id: promotionIdGet
          }));
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
        }
      })
      .catch((error) => {
        setSnackbarMessage(t('AlertMessage.generalError'));
        setSnackbarType('warning');
        setSnackbarState(true);
        console.log(error);
      });
    setLoading(false);
  };

  const fetchPromotionInfo = async () => {
    setLoading(true);
    await axiosInstance.get(`/home/promotion/fetch?is_all=0&only_public=0&promotion_id=${redirectedPromotionId}&format=json`)
      .then((response) => {
        const promotionInfoGet = response.data.data.promotions[0];
        console.log(promotionInfoGet);
        setPromotionInfo({
          id: promotionInfoGet.id,
          code: promotionInfoGet.code,
          is_public: promotionInfoGet.is_public,
          name: promotionInfoGet.name,
          amount: promotionInfoGet.amount,
          currency: promotionInfoGet.currency,
        });
      })
      .catch((error) => console.log(error));

    setLoading(false);
  };

  const handleChangePromotionInfo = (event) => {
    // Prevents React from resetting its properties:
    event.persist();
    setPromotionInfo((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  const willMount = useRef(true);
  if (willMount.current) {
    if (pageType === 'updatePromotion') {
      fetchPromotionInfo();
    }
    willMount.current = false;
  }

  if (loading) {
    return (
      <>
        {pageType === 'newPromotion' && <Spinner text="" />}
        {pageType === 'updatePromotion' && <Spinner text={t('SolarPackagesPage.loadingPackage')} />}
      </>
    );
  }

  return (
    <Page
      className={MUIclasses.root}
      title={t('SolarPackagePromotionsPage.title')}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            {pageType === 'newPromotion' && <h2 style={{ fontWeight: 'normal' }}>{t('SolarPackagePromotionsPage.createNewPromotion')}</h2>}
            {pageType === 'updatePromotion' && <h2 style={{ fontWeight: 'normal' }}>{t('SolarPackagePromotionsPage.promotionInfo')}</h2>}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card>
              <CardContent>
                <Table>
                  <TableBody>
                    <TableRow
                      key="name"
                    >
                      <StyledTableCell>
                        <strong>{t('SolarPackagePromotionsPage.promotionName')}</strong>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          fullWidth
                          name="name"
                          error={promotionInfo.name === ''}
                          helperText={promotionInfo.name === '' ? 'Bu alanı doldurmak zorunludur.' : ''}
                          onChange={handleChangePromotionInfo}
                          required
                          value={promotionInfo.name}
                          placeholder={t('SolarPackagePromotionsPage.promotionPlaceholder')}
                          variant="outlined"
                          type="text"
                          size="small"
                          style={{ maxWidth: 300 }}
                        />
                      </StyledTableCell>
                    </TableRow>
                    <TableRow
                      key="code"
                    >
                      <StyledTableCell>
                        <strong>{t('SolarPackagePromotionsPage.code')}</strong>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          fullWidth
                          name="code"
                          value={promotionInfo.code}
                          disabled
                          variant="outlined"
                          type="text"
                          size="small"
                          style={{ maxWidth: 300 }}
                        />
                      </StyledTableCell>
                    </TableRow>
                    <TableRow
                      key="amount"
                    >
                      <StyledTableCell>
                        <strong>{t('SolarPackagePromotionsPage.promotionAmount')}</strong>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          fullWidth
                          name="amount"
                          error={promotionInfo.amount === '' || promotionInfo.amount === 0}
                          helperText={(promotionInfo.amount === '' || promotionInfo.amount === 0) ? 'Bu alanı doldurmak zorunludur.' : ''}
                          onChange={handleChangePromotionInfo}
                          required
                          value={promotionInfo.amount}
                          placeholder="1000"
                          variant="outlined"
                          type="number"
                          size="small"
                          style={{ maxWidth: 150 }}
                        />
                        <Select
                          labelId="currency-select"
                          id="currency"
                          name="currency"
                          value={promotionInfo.currency}
                          displayEmpty
                          onChange={handleChangePromotionInfo}
                          label="Para Birimi"
                          input={<BootstrapSelect />}
                          style={{ width: 100, marginLeft: 20 }}
                        >
                          <MenuItem value="TL">TL</MenuItem>
                          <MenuItem value="USD">USD</MenuItem>
                        </Select>
                      </StyledTableCell>
                    </TableRow>
                    <TableRow
                      key="is_public"
                    >
                      <StyledTableCell>
                        <strong>{t('SolarPackagePromotionsPage.isPublic')}</strong>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Select
                          labelId="is_public-select"
                          id="is_public"
                          name="is_public"
                          value={promotionInfo.is_public}
                          displayEmpty
                          onChange={handleChangePromotionInfo}
                          label={t('SolarPackagePromotionsPage.isPublic')}
                          input={<BootstrapSelect />}
                        >
                          <MenuItem value={true}>{t('SolarPackagePromotionsPage.everyoneSee')}</MenuItem>
                          <MenuItem value={false}>{t('SolarPackagePromotionsPage.private')}</MenuItem>
                        </Select>
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<SaveIcon />}
                onClick={() => createUpdatePromotion()}
                disabled={(
                  !promotionInfo.name
                  || !promotionInfo.amount
                )}
              >
                {pageType === 'newPromotion' && <span>{t('SolarPackagePromotionsPage.createNewPromotion')}</span>}
                {pageType === 'updatePromotion' && <span>{t('SolarPackagePromotionsPage.updatePromotion')}</span>}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default PromotionInfo;

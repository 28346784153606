const creditApplicationDocuments = [
  {
    id: 1,
    name: 'Simülasyon Raporu',
    type: 'pdf',
    date: '2020-01-01',
    is_required: true,
    download_link: 'https://solarvis.co'
  },
  {
    id: 2,
    name: 'Proforma Fatura',
    type: 'pdf',
    date: '2020-01-01',
    is_required: true,
    download_link: 'https://solarvis.co'
  },
  {
    id: 3,
    name: 'Çağrı Mektubu',
    type: 'pdf',
    date: '2020-01-01',
    is_required: false,
    download_link: ''
  },
  {
    id: 4,
    name: 'Tedaş Onayı',
    type: 'pdf',
    date: '2020-01-01',
    is_required: false,
    download_link: ''
  },
  {
    id: 5,
    name: 'Bağlantı Anlaşması',
    type: 'pdf',
    date: '2020-01-01',
    is_required: false,
    download_link: ''
  },
  {
    id: 6,
    name: 'Proje Zaman Çizelgesi',
    type: 'pdf',
    date: '2020-01-01',
    is_required: true,
    download_link: 'https://solarvis.co'
  },
  {
    id: 7,
    name: 'ALL RİSK SİGORTASI',
    type: 'pdf',
    date: '2020-01-01',
    is_required: true,
    download_link: 'https://solarvis.co'
  },
];

const creditProposals = [
  {
    id: 1,
    bankName: 'İŞ BANKASI',
    creditAmount: 100000,
    creditTerm: 12,
    interestRate: 1.5,
    monthlyPayment: 10000,
    totalPayment: 120000,
    status: 'approved',
  },
  {
    id: 2,
    bankName: 'AKBANK',
    creditAmount: 100000,
    creditTerm: 12,
    interestRate: 1.5,
    monthlyPayment: 10000,
    totalPayment: 120000,
    status: 'pending'
  },
  {
    id: 3,
    bankName: 'HAYAT FİNANS',
    creditAmount: 100000,
    creditTerm: 12,
    interestRate: 1.5,
    monthlyPayment: 10000,
    totalPayment: 120000,
    status: 'rejected'
  },
];

const dummyData = {
  creditApplicationDocuments,
  creditProposals,
};

export default dummyData;

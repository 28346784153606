/* eslint-disable no-self-assign */
/* eslint-disable quotes */
/* eslint-disable quote-props */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Scatter,
} from 'recharts';

const CustomizedShape = (props) => {
  const {
    cx, cy, stroke, payload, value
  } = props;

  return (
    <svg x={cx - 22} y={cy} width={300} height={300} fill="rgb(92, 157, 245)" viewBox="0 0 1024 1024">
      <path d="M0,0 150,0 150,20 0,20" />
    </svg>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '10px' }}>
        <p className="label" style={{ fontWeight: 'bold' }}>{`${label}`}</p>
        <p className="label">{`${payload[0].name} : ${payload[0].value} kWh`}</p>
        <p className="label">{`${payload[1].name} : ${payload[1].value} kWh`}</p>
        <p className="label">{`${payload[2].name} : ${payload[2].value} kWh`}</p>
      </div>
    );
  }

  return null;
};

const StyledGraphWrapper = styled(ResponsiveContainer)`
  width: 100%;
  height: 100%;

  @media print {
    width: 50%;
    height: 50%;
  }
`;
StyledGraphWrapper.displayName = ResponsiveContainer.displayName;

function BarGraph(props) {
  const { barData } = props;

  return (
    <StyledGraphWrapper>
      <ComposedChart
        width={400}
        height={400}
        data={barData}
        margin={{
          top: 20,
          right: 40,
          bottom: 20,
          left: 50,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="month" scale="band" />
        <YAxis label={{
          value: 'kWh', angle: -90, position: 'left', offset: 30
        }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar name="Üretim" dataKey="solar_production" legendType="circle" barSize={20} fill="rgb(33, 204, 114)" />
        <Bar name="Tüketim" dataKey="consumption" legendType="circle" barSize={20} fill="rgb(244, 116, 115)" />
        <Scatter name="Öz Tüketim" dataKey="self_consumption" fill="rgb(92, 157, 245)" shape={<CustomizedShape />} />
      </ComposedChart>
    </StyledGraphWrapper>
  );
}

BarGraph.propTypes = {
  barData: PropTypes.array,
};

export default BarGraph;

/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-template */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import i18n from 'src/i18n';
import _, { set } from 'lodash';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';
import {
  withStyles,
  makeStyles,
  Grid,
  Container,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  TableContainer,
  Card,
  CardContent,
  Divider,
  Button,
  IconButton,
  InputBase,
  InputAdornment,
  Snackbar
  /* TextField */
} from '@material-ui/core';
import axiosInstance from 'src/utils/axiosApi';
import GeneralAddDialog from 'src/components/GeneralAddDialog2';
import { useMediaQuery } from 'react-responsive';
import Spinner from 'src/components/Spinner/Spinner';
import MuiAlert from '@material-ui/lab/Alert';
import ActivityStepper from '../customerPage/activityStepper';
import { Form, Upload } from 'antd';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function isFormDataEmpty(formData) {
  for (const value of formData.values()) {
    if (value) {
      return false;
    }
  }
  return true;
}

function showFilePreview(formData) {
  const fileInput = formData.get('file');

  if (fileInput) {
    const file = fileInput instanceof File ? fileInput : fileInput[0];
    return URL.createObjectURL(file);
  }
  return null;
}

const StyledTableCell = withStyles(() => ({
  root: {
    width: '25%',
    padding: 8,
    fontFamily: 'Poppins',
    borderBottom: 'none'
  },
}))(TableCell);

const StyledTableCellWithBorder = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins',
  },
}))(TableCell);

const StyledIconButton = withStyles({
  root: {
    margin: 2,
    textTransform: 'none',
    fontSize: 16,
    padding: 8,
    backgroundColor: '#e2e5fa'
  }
})(IconButton);

const StyledTableCellHead = withStyles(() => ({
  root: {
    width: '25%',
    padding: 8,
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    borderBottom: 'none'
  },
}))(TableCell);

const StyledTableCellHeadWithBorder = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins',
    fontWeight: 'bold',
  },
}))(TableCell);

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '60px'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: 0,
    paddingBottom: 0
  },
  /* cardContentRoot: {
    paddingBottom: 0
  } */
}));

const CustomerInfo = () => {
  const { t, } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const MUIclasses = useStyles();
  const { creditApplicationId } = useParams();
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' });
  const [bankloadUploadedFiles, setBankloadUploadedFiles] = useState(null);
  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [pageLoading, setPageLoading] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);

  // States from the request response
  const [documentList, setDocumentList] = useState([]);
  const [activities, setActivities] = useState([]);
  const [creditApplicationInfo, setCreditApplicationInfo] = useState({});

  const [shouldReloadPage, setShouldReloadPage] = useState(true);

  const handlePaymentPlanDocument = (values) => {

    const bodyData = {
      credit_application_id: creditApplicationId,
      category_id: 11
    }

    const formData = new FormData();
    formData.append('file', values.file);
    formData.append('body', JSON.stringify(bodyData));

    axiosInstance.post('bankloan/banks/upload-file/', formData)
    .then(res => {
      const { status,message } = res.data;
      if (status) {
        setSnackbarState(true);
        setSnackbarType('success');
        setSnackbarMessage(message);
        setShouldReloadPage(true);
        setBankloadUploadedFiles(null)
      }
      else {
        setSnackbarState(true);
        setSnackbarType('error');
        setSnackbarMessage(message);
      }
    }).catch(err => {
      alert("Doküman yüklenirken bir hata oluştu.")
    })

  }

  // Credit Application Related States
  const [creditApplicationDocumentCategories, setCreditApplicationDocumentCategories] = useState([]);
  const [creditProposalInfo, setCreditProposalInfo] = useState({
    isOffered: false,
    offeredCreditAmount: '',
    interestRate: '',
    creditTerm: '',
    offeredDate: '',
    bankStatus: '',
    paymentPlanURL: ''
  });
  const [isCreditProposalDialogOpen, setIsCreditProposalDialogOpen] = useState(false);
  const [paymentPlanDocument, setPaymentPlanDocument] = useState(new FormData());

  const {
    availableModules,
  } = useSelector((state) => state.module);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  const orderedKeysBasicInfo = [
    'isim',
    'soy_isim',
    'id',
    'telefon',
    'username',
    'tesis_tipi',
    'il',
    'ilce',
    'googleMapURL',
    'lastUpdateStrWithTime',
    'user_vkn',
    'user_vd',
  ];

  const orderedKeysSolarvisCalculation = [
    'dolar',
    'fatura_tutari',
    'sgucu',
    'aylik_tuketim',
    'guc',
    'ac_guc',
    'panel_sayisi',
    'kdvli_usd_maliyet',
    'kdvli_tl_maliyet',
    'yillik_ort_usd_tasrrf',
    'yillik_ort_tl_tasrrf',
    'roi',
    'yillik_uretim',
    'is_battery',
    'is_charger',
    'co2',
    'agac',
    'consumption_profile',
    'trafoGucu',
    'emptyCell'
  ];

  const orderedKeysBasicInfoDesktop = orderedKeysBasicInfo.reduce((rows, key, index) => {
    return (index % 2 === 0 ? rows.push([key])
      : rows[rows.length - 1].push(key)) && rows;
  }, []);

  const orderedKeysSCalcDesktop = orderedKeysSolarvisCalculation.reduce((rows, key, index) => {
    return (index % 2 === 0 ? rows.push([key])
      : rows[rows.length - 1].push(key)) && rows;
  }, []);

  const titleInfo = {
    id: {
      title: 'ID',
      extension: ''
    },
    dolar: {
      title: t('MusteriBilgileriDialog.calculatedCurrencyUSD'),
      extension: ' USD/TRY'
    },
    tesis_tipi: {
      title: t('MusteriBilgileriDialog.tesisTipi'),
      extension: ''
    },
    il: {
      title: t('MusteriBilgileriDialog.il'),
      extension: ''
    },
    ilce: {
      title: t('MusteriBilgileriDialog.ilce'),
      extension: ''
    },
    guc: {
      title: t('MusteriBilgileriDialog.dcGuc'),
      extension: ' kWp'
    },
    ac_guc: {
      title: t('MusteriBilgileriDialog.acGuc'),
      extension: ' kWe'
    },
    alan: {
      title: t('MusteriBilgileriDialog.alan'),
      extension: ' m2'
    },
    isim: {
      title: t('MusteriBilgileriDialog.isim'),
      extension: ''
    },
    soy_isim: {
      title: t('MusteriBilgileriDialog.soyisim'),
      extension: ''
    },
    telefon: {
      title: t('MusteriBilgileriDialog.telefon'),
      extension: ''
    },
    username: {
      title: t('MusteriBilgileriDialog.eposta'),
      extension: ''
    },
    fatura_tutari: {
      title: t('MusteriBilgileriDialog.fatura'),
      extension: ' ₺'
    },
    aylik_tuketim: {
      title: t('MusteriBilgileriDialog.tuketim'),
      extension: ' kWh'
    },
    sgucu: {
      title: t('MusteriBilgileriDialog.sgucu'),
      extension: ' kWe'
    },
    trafoGucu: {
      title: t('MusteriBilgileriDialog.trafoGucu'),
      extension: ' kVA'
    },
    googleMapURL: {
      title: t('MusteriBilgileriDialog.konum'),
      extension: ''
    },
    aci: {
      title: t('MusteriBilgileriDialog.egim'),
      extension: '°'
    },
    panel_sayisi: {
      title: t('MusteriBilgileriDialog.panel'),
      extension: ''
    },
    yillik_uretim: {
      title: t('MusteriBilgileriDialog.uretim'),
      extension: ' MWh'
    },
    kdvli_usd_maliyet: {
      title: t('MusteriBilgileriDialog.maliyetDolar'),
      extension: ' $'
    },
    kdvli_tl_maliyet: {
      title: t('MusteriBilgileriDialog.maliyetTL'),
      extension: ' ₺'
    },
    yillik_ort_usd_tasrrf: {
      title: t('MusteriBilgileriDialog.yillikTasarrufUSD'),
      extension: ' $'
    },
    yillik_ort_tl_tasrrf: {
      title: t('MusteriBilgileriDialog.yillikTasarrufTL'),
      extension: ' ₺'
    },
    roi: {
      title: t('MusteriBilgileriDialog.amortisman'),
      extension: ` ${t('MusteriBilgileriDialog.yil')}`
    },
    lastUpdateStrWithTime: {
      title: t('MusteriBilgileriDialog.zaman'),
      extension: ''
    },
    is_battery: {
      title: t('MusteriBilgileriDialog.batarya'),
      extension: ''
    },
    is_charger: {
      title: t('MusteriBilgileriDialog.sarj'),
      extension: ''
    },
    co2: {
      title: `${t('MusteriBilgileriDialog.onlenen')} CO2`,
      extension: ' ton'
    },
    agac: {
      title: t('MusteriBilgileriDialog.agac'),
      extension: ''
    },
    selected_package_name: {
      title: t('MusteriBilgileriDialog.paketismi'),
      extension: ''
    },
    upfront_cost: {
      title: t('MusteriBilgileriDialog.upfrontCost'),
      extension: ' ₺'
    },
    consumption_profile: {
      title: t('MusteriBilgileriDialog.consumptionProfile'),
      extension: ''
    },
    vkn: {
      title: t('MusteriBilgileriDialog.vkn'),
      extension: ''
    },
    tckn: {
      title: t('MusteriBilgileriDialog.tckn'),
      extension: ''
    },
    customer_firm: {
      title: t('MusteriBilgileriDialog.customerFirm'),
      extension: ''
    },
    branchSelection: {
      title: t('MusteriBilgileriDialog.branchSelection'),
      extension: ''
    },
    emptyCell: {
      title: '',
      extension: ''
    },
    user_vkn: {
      title: t('MusteriBilgileriDialog.vkn'),
      extension: ''
    },
    user_vd: {
      title: 'Vergi Dairesi',
      extension: ''
    }
  };

  const fetchProjectFeasibilityInfo = async () => {
    let customerId = null;
    await axiosInstance.get(`bankloan/banks/get-detailed-credit-application?credit_id=${creditApplicationId}&lang=${(localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') ? 'EN' : 'TR'}`)
      .then((response) => {
        const { data } = response.data;
        customerId = data.musteri.id;
        const tempCreditProposalInfo = creditProposalInfo;

        if (data.hasOwnProperty('credit_application')) {
          const applicationDetails = data.credit_application;
          tempCreditProposalInfo.bankStatus = applicationDetails.bank_status;
          tempCreditProposalInfo.paymentPlanURL = applicationDetails.payment_plan_url;
        }

        if (data.hasOwnProperty('bank_proposal')) {
          const proposalDetails = data.bank_proposal;
          tempCreditProposalInfo.offeredCreditAmount = proposalDetails.creditable_amount;
          tempCreditProposalInfo.interestRate = proposalDetails.interest_rate;
          tempCreditProposalInfo.creditTerm = proposalDetails.credit_term;
          tempCreditProposalInfo.offeredDate = String(format(new Date(Date.parse(proposalDetails.proposed_at)), 'dd/MM/yyyy - HH.mm'));
          tempCreditProposalInfo.isOffered = true;
        }

        setCreditProposalInfo(tempCreditProposalInfo);

        const proposal = data.musteri;
        if (String(proposal.ilce) === 'null' || String(proposal.ilce) === 'undefined') {
          proposal.ilce = 'Merkez';
        }

        proposal.googleMapURL = `https://www.google.com/maps/search/?api=1&query=${proposal.enlem},${proposal.boylam}&basemap=satellite`;
        // proposal.image = Promise.resolve(previewImage);
        proposal.createdDate = new Date(Date.parse(proposal.created_at));
        proposal.lastUpdateDate = new Date(Date.parse(proposal.zaman));
        proposal.lastUpdateStr = String(format(new Date(Date.parse(proposal.zaman)), 'dd/MM/yyyy'));
        proposal.lastUpdateStrWithTime = String(format(new Date(Date.parse(proposal.zaman)), 'dd/MM/yyyy - HH.mm'));
        proposal.roi = String(proposal.roi).slice(0, 4);
        proposal.imageAlt = `Müşteri id: ${proposal.id}`;
        proposal.customerId = proposal.id;
        if (proposal.is_battery) {
          proposal.is_battery = t('MusteriBilgileriDialog.var');
        } else {
          proposal.is_battery = t('MusteriBilgileriDialog.yok');
        }
        if (proposal.is_charger) {
          proposal.is_charger = t('MusteriBilgileriDialog.var');
        } else {
          proposal.is_charger = t('MusteriBilgileriDialog.yok');
        }
        proposal.emptyCell = '';
        proposal.user_vkn = data.credit_application.user_vkn;
        proposal.user_vd = data.credit_application.user_vd;
        setCreditApplicationInfo({
          ...data,
          musteri: proposal
        });
      })
      .catch((error) => console.log(error));

    await axiosInstance.get('activity/render_credit_application_feed_API', {
      params: {
        language: (localStorage.getItem('language') === 'tr' || String(i18n.language).split('-')[0] === 'tr') ? 'TR' : 'EN',
        musteri_id: customerId,
        ignored_descriptive_activity_tags: ''
      }
    })
      .then((response) => {
        const { status_code } = response.data;
        if (status_code) {
          const { final_list } = response.data;
          setActivities(final_list);
        }
      })
      .catch((error) => console.log(error));
  };

  const fetchProjectDetails = async () => {
    setPageLoading(true);

    // Fetch Project Details
    // If project is redirected from project list page, fetch project details from location state, otherwise fetch from API
    await fetchProjectFeasibilityInfo();

    setPageLoading(false);
  };

  const handleChangeCreditProposalInfo = (event, info) => {
    event.persist();
    setCreditProposalInfo({
      ...creditProposalInfo,
      [info]: event.target.value
    });
  };

  const fetchCreditApplicationRelatedData = async () => {
    // Fetch Available Document Categories
    await axiosInstance.get(`bankloan/banks/get-document-categories?musteri_id=${creditApplicationInfo.musteri.id}&lang=${(localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') ? 'EN' : 'TR'}`)
      .then((response) => {
        const { data } = response.data;
        const updatedData = data.document_categories.map((document) => {
          return {
            ...document,
            title: document.document_category_name,
            value: document.id,
            open_dialog_state: false
          };
        });
        setCreditApplicationDocumentCategories(updatedData);
      })
      .catch((error) => console.log(error));
  };

  const sendCreditProposal = (event) => {
    event.preventDefault();

    let requestURL = 'bankloan/banks/make-credit-proposal/';
    if (creditProposalInfo.isOffered) {
      requestURL = 'bankloan/banks/update-credit-proposal/';
    }

    axiosInstance.post(requestURL, {
      credit_application_id: creditApplicationId,
      creditable_amount: creditProposalInfo.offeredCreditAmount,
      interest_rate: creditProposalInfo.interestRate,
      credit_term: creditProposalInfo.creditTerm,
      lang: (localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') ? 'EN' : 'TR'
    })
      .then((response) => {
        if (response.data.status) {
          setSnackbarMessage(response.data.message);
          setSnackbarType('success');
          setSnackbarState(true);
          setShouldReloadPage(!shouldReloadPage);
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
          // alert('Böyle bir kayıt bulunamadı.');
        }
      })
      .catch((error) => {
        setSnackbarMessage(t('AlertMessage.generalError'));
        setSnackbarType('warning');
        setSnackbarState(true);
        console.log(error);
      });
  };

  // Send fetch project data request when the states are changed
  // Which states -> newAdminUser, newSubcontractor, newDocument
  useEffect(() => {
    fetchProjectDetails();
  }, [shouldReloadPage]);

  useEffect(() => {
    if (!_.isEmpty(creditApplicationInfo.musteri)) {
      fetchCreditApplicationRelatedData();
    }
  }, [creditApplicationInfo.musteri, shouldReloadPage]);




  const bankloadBeforeUpload = (file,files) => {
    setBankloadUploadedFiles(files)
    return false;
  }


  if (pageLoading) {
    return (
      <>
        <Spinner text={t('SingleCustomerPage.projectInfoLoading')} />
      </>
    );
  }

  if (loadingReport) {
    return (
      <>
        <Spinner text={t('Loader.proposalLoading')} />
      </>
    );
  }

  return (
    <Page
      className={MUIclasses.root}
      title={`solarVis | ${t('SingleCustomerPage.pageTitle')} - ${creditApplicationId}`}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          {/* <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
            >
              <h2 style={{ fontWeight: 'normal' }}>{t('SingleCustomerPage.pageTitle')}</h2>
              <Chip label={creditApplicationInfo.musteri.status_name} color="primary" />
            </div>
          </Grid> */}
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card>
              <div style={{ padding: 16 }}>
                <h3>{t('SingleCustomerPage.essentialInfo')}</h3>
              </div>
              <Divider />
              <CardContent>
                <TableContainer>
                  <Table aria-label="fundamental-project-info">
                    <TableBody>
                      {isDesktopOrLaptop && !(_.isEmpty(creditApplicationInfo.musteri)) && orderedKeysBasicInfoDesktop.map((keys) => (
                        <>
                          {(creditApplicationInfo.musteri[keys[0]] || creditApplicationInfo.musteri[keys[0]] === 0)
                          && (
                            <TableRow
                              key={creditApplicationInfo.musteri[keys[0]]}
                            >
                              {(creditApplicationInfo.musteri[keys[0]] || creditApplicationInfo.musteri[keys[0]] === 0)
                              && (
                              <StyledTableCell>
                                {titleInfo[keys[0]].title}
                              </StyledTableCell>
                              )}
                              <StyledTableCell>
                                {keys[0] !== 'googleMapURL' && <strong>{creditApplicationInfo.musteri[keys[0]]}</strong>}
                                {keys[0] === 'googleMapURL' && <a href={creditApplicationInfo.musteri[keys[0]]} target="_blank" rel="noreferrer"><strong>{t('MusteriBilgileriDialog.mapsLinki')}</strong></a>}
                                <span><strong>{titleInfo[keys[0]].extension}</strong></span>
                              </StyledTableCell>
                              {(creditApplicationInfo.musteri[keys[1]] || creditApplicationInfo.musteri[keys[1]] === 0)
                              && (
                              <StyledTableCell>
                                {titleInfo[keys[1]].title}
                              </StyledTableCell>
                              )}
                              <StyledTableCell>
                                {keys[1] !== 'googleMapURL' && <strong>{creditApplicationInfo.musteri[keys[1]]}</strong>}
                                {keys[1] === 'googleMapURL' && <a href={creditApplicationInfo.musteri[keys[1]]} target="_blank" rel="noreferrer"><strong>{t('MusteriBilgileriDialog.mapsLinki')}</strong></a>}
                                <span><strong>{titleInfo[keys[1]].extension}</strong></span>
                              </StyledTableCell>
                            </TableRow>
                          )}
                        </>
                      ))}
                      {!isDesktopOrLaptop && !(_.isEmpty(creditApplicationInfo.musteri)) && orderedKeysBasicInfo.map((keys) => (
                        <>
                          {(creditApplicationInfo.musteri[keys] || creditApplicationInfo.musteri[keys] === 0)
                          && (
                            <TableRow
                              key={creditApplicationInfo.musteri[keys]}
                            >
                              {(creditApplicationInfo.musteri[keys] || creditApplicationInfo.musteri[keys] === 0)
                              && (
                              <StyledTableCell>
                                {titleInfo[keys].title}
                              </StyledTableCell>
                              )}
                              <StyledTableCell>
                                {keys !== 'googleMapURL' && <strong>{creditApplicationInfo.musteri[keys]}</strong>}
                                {keys === 'googleMapURL' && <a href={creditApplicationInfo.musteri[keys]} target="_blank" rel="noreferrer"><strong>{t('MusteriBilgileriDialog.mapsLinki')}</strong></a>}
                                <span><strong>{titleInfo[keys].extension}</strong></span>
                              </StyledTableCell>
                            </TableRow>
                          )}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card>
              <div style={{ padding: 16 }}>
                <h3>{t('SingleCustomerPage.solarVisCalculations')}</h3>
              </div>
              <Divider />
              <CardContent>
                <TableContainer>
                  <Table aria-label="solarvis-calculations">
                    <TableBody>
                      {isDesktopOrLaptop && !(_.isEmpty(creditApplicationInfo.musteri)) && orderedKeysSCalcDesktop.map((keys) => (
                        <>
                          {(creditApplicationInfo.musteri[keys[0]] || creditApplicationInfo.musteri[keys[0]] === 0)
                          && (
                            <TableRow
                              key={creditApplicationInfo.musteri[keys[0]]}
                            >
                              {(creditApplicationInfo.musteri[keys[0]] || creditApplicationInfo.musteri[keys[0]] === 0)
                              && (
                              <StyledTableCell>
                                {titleInfo[keys[0]].title}
                              </StyledTableCell>
                              )}
                              <StyledTableCell>
                                {keys[0] !== 'googleMapURL' && <strong>{creditApplicationInfo.musteri[keys[0]]}</strong>}
                                {keys[0] === 'googleMapURL' && <a href={creditApplicationInfo.musteri[keys[0]]} target="_blank" rel="noreferrer"><strong>{t('MusteriBilgileriDialog.mapsLinki')}</strong></a>}
                                <span><strong>{titleInfo[keys[0]].extension}</strong></span>
                              </StyledTableCell>
                              {(creditApplicationInfo.musteri[keys[1]] || creditApplicationInfo.musteri[keys[1]] === 0)
                              && (
                              <StyledTableCell>
                                {titleInfo[keys[1]].title}
                              </StyledTableCell>
                              )}
                              <StyledTableCell>
                                {keys[1] !== 'googleMapURL' && <strong>{creditApplicationInfo.musteri[keys[1]]}</strong>}
                                {keys[1] === 'googleMapURL' && <a href={creditApplicationInfo.musteri[keys[1]]} target="_blank" rel="noreferrer"><strong>{t('MusteriBilgileriDialog.mapsLinki')}</strong></a>}
                                <span><strong>{titleInfo[keys[1]].extension}</strong></span>
                              </StyledTableCell>
                            </TableRow>
                          )}
                        </>
                      ))}
                      {!isDesktopOrLaptop && !(_.isEmpty(creditApplicationInfo.musteri)) && orderedKeysSolarvisCalculation.map((keys) => (
                        <>
                          {(creditApplicationInfo.musteri[keys] || creditApplicationInfo.musteri[keys] === 0)
                          && (
                            <TableRow
                              key={creditApplicationInfo.musteri[keys]}
                            >
                              {(creditApplicationInfo.musteri[keys] || creditApplicationInfo.musteri[keys] === 0)
                              && (
                              <StyledTableCell>
                                {titleInfo[keys].title}
                              </StyledTableCell>
                              )}
                              <StyledTableCell>
                                {keys !== 'googleMapURL' && <strong>{creditApplicationInfo.musteri[keys]}</strong>}
                                {keys === 'googleMapURL' && <a href={creditApplicationInfo.musteri[keys]} target="_blank" rel="noreferrer"><strong>{t('MusteriBilgileriDialog.mapsLinki')}</strong></a>}
                                <span><strong>{titleInfo[keys].extension}</strong></span>
                              </StyledTableCell>
                            </TableRow>
                          )}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* roofAreas */}
                <h4 style={{ marginTop: 20 }}>Çatı Bölgeleri</h4>
                <TableContainer>
                  <Table aria-label="solarvis-calculations">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell> </StyledTableCell>
                        <StyledTableCell><strong>Alan</strong></StyledTableCell>
                        <StyledTableCell><strong>Eğim</strong></StyledTableCell>
                        <StyledTableCell><strong>Yön</strong></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!(_.isEmpty(creditApplicationInfo.musteri)) && creditApplicationInfo.musteri.hasOwnProperty('drawing_points') && creditApplicationInfo.musteri.drawing_points.map((roofArea, roofAreaIdx) => (
                        <TableRow key={roofAreaIdx}>
                          <StyledTableCell>
                            {roofAreaIdx + 1}
                          </StyledTableCell>
                          <StyledTableCell>
                            {`${roofArea.area} m2`}
                          </StyledTableCell>
                          <StyledTableCell>
                            {`${roofArea.slope} °`}
                          </StyledTableCell>
                          <StyledTableCell>
                            {roofArea.human_readable_aspect}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card>
              <div style={{ padding: 16 }}>
                <h3>{t('SingleCustomerPage.activity')}</h3>
              </div>
              <Divider />
              <CardContent>
                {activities.length === 0 && (<div style={{ padding: 16, textAlign: 'center' }}>{t('SingleCustomerPage.noActivity')}</div>)}
                {activities.length > 0
                && (
                <div>
                  <ActivityStepper activities={activities} projectId={creditApplicationId} />
                </div>
                )}
              </CardContent>
            </Card>
          </Grid>
          {availableModules.includes('credit_application') && (
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card>
              <div style={{ padding: 16 }}>
                <h3>Kredi Başvurusu Bilgileri</h3>
              </div>
              <Divider />
              <CardContent>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                >
                  <h4 style={{ marginBottom: 15 }}>Dokümanlar</h4>
                  {creditApplicationDocumentCategories.length > 0
                  && (
                  <TableContainer>
                    <Table aria-label="credit-application-documents">
                      <TableHead>
                        <TableRow>
                          <StyledTableCellHeadWithBorder align="left">
                            Doküman İsmi
                          </StyledTableCellHeadWithBorder>
                          <StyledTableCellHeadWithBorder align="left">
                            Zorunlu Mu?
                          </StyledTableCellHeadWithBorder>
                          <StyledTableCellHeadWithBorder align="left"> </StyledTableCellHeadWithBorder>
                          {/* <StyledTableCellHeadWithBorder align="left"> </StyledTableCellHeadWithBorder> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {creditApplicationDocumentCategories.map((document) => (
                          <TableRow key={document.id}>
                            <StyledTableCellWithBorder align="left">
                              {document.name}
                            </StyledTableCellWithBorder>
                            <StyledTableCellWithBorder align="left">
                              {document.is_required ? 'Evet' : 'Hayır'}
                            </StyledTableCellWithBorder>
                            <StyledTableCellWithBorder align="left">
                              {document.exists
                                ? <a href={document.exists}>İndir</a>
                                : 'Henüz Yüklenmemiş'}
                            </StyledTableCellWithBorder>
                            {/* <StyledTableCellWithBorder align="left">
                              {document.file_url ? document.date : ''}
                            </StyledTableCellWithBorder> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  )}
                  {creditApplicationDocumentCategories.length === 0
                  && (
                  <div style={{ padding: 16, textAlign: 'center' }}>Bu proje için henüz kredi başvurusuna dair dokümanı yüklenmemiş.</div>
                  )}
                </div>
              </CardContent>
              <Divider style={{ margin: '20px 0px' }} />
              <CardContent>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                >
                  <h4 style={{ marginBottom: 15 }}>Proje Bilgileri</h4>
                  <TableContainer>
                    <Table aria-label="credit-application-project-info">
                      <TableBody>
                        <TableRow key="credit_request_amount">
                          <StyledTableCellWithBorder align="left">
                            Başvurulan Kredi Miktarı
                          </StyledTableCellWithBorder>
                          <StyledTableCellWithBorder align="left">
                            {`${creditApplicationInfo.hasOwnProperty('credit_application') ? creditApplicationInfo.credit_application.credit_request_amount : ''} ₺`}
                          </StyledTableCellWithBorder>
                        </TableRow>
                        <TableRow key="monthly-consumption">
                          <StyledTableCellWithBorder align="left">
                            12 Aylık Tüketim
                          </StyledTableCellWithBorder>
                          <StyledTableCellWithBorder
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                              alignItems: 'center'
                            }}
                            align="left"
                          >
                            <Table aria-label="monthly-consumptions">
                              {[['Ocak', 'Şubat', 'Mart'], ['Nisan', 'Mayıs', 'Haziran'], ['Temmuz', 'Ağustos', 'Eylül'], ['Ekim', 'Kasım', 'Aralık']].map((monthChunk, index) => (
                                <TableRow key={monthChunk[0]}>
                                  {monthChunk.map((month, monthChunkIdx) => (
                                    <>
                                      <StyledTableCellWithBorder>{month}</StyledTableCellWithBorder>
                                      <StyledTableCellWithBorder>{`${creditApplicationInfo.hasOwnProperty('musteri') ? creditApplicationInfo.musteri.monthly_manual_consumptions[index * 3 + monthChunkIdx] : ''} kWh`}</StyledTableCellWithBorder>
                                    </>
                                  ))}
                                </TableRow>
                              ))}
                            </Table>
                          </StyledTableCellWithBorder>
                        </TableRow>
                        <TableRow key="maintenance_agreement_cost">
                          <StyledTableCellWithBorder align="left">
                            Bakım/Onarım Anlaşması Maliyeti (₺)
                          </StyledTableCellWithBorder>
                          <StyledTableCellWithBorder align="left">
                            {`${creditApplicationInfo.hasOwnProperty('credit_application') && creditApplicationInfo.credit_application.extra_data.hasOwnProperty('maintenance_agreement_cost') ? creditApplicationInfo.credit_application.extra_data.maintenance_agreement_cost : ''} ₺`}
                          </StyledTableCellWithBorder>
                        </TableRow>
                        <TableRow key="performance_warranty">
                          <StyledTableCellWithBorder align="left">
                            Performans Garantisi - Yıllık MWh Garanti Üretim Miktarı
                          </StyledTableCellWithBorder>
                          <StyledTableCellWithBorder align="left">
                            {`${creditApplicationInfo.hasOwnProperty('credit_application') && creditApplicationInfo.credit_application.extra_data.hasOwnProperty('performance_warranty') ? creditApplicationInfo.credit_application.extra_data.performance_warranty : ''} MWh`}
                          </StyledTableCellWithBorder>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </CardContent>
            </Card>
          </Grid>
          )}
          {availableModules.includes('credit_application') && creditProposalInfo.bankStatus !== 'MISSED' && (
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card>
              <div style={{ padding: 16 }}>
                <h3>Kredi Teklifi</h3>
              </div>
              <Divider />
              <CardContent>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                >
                  <h4 style={{ marginBottom: 15 }}>Teklif Detayları</h4>
                  <TableContainer>
                    <Table aria-label="credit-application-project-info">
                      <TableBody>
                        {creditProposalInfo.isOffered
                        && (
                        <TableRow key="1">
                          <StyledTableCell align="left">
                            Teklif Verilen Tarih
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {creditProposalInfo.offeredDate}
                          </StyledTableCell>
                        </TableRow>
                        )}
                        <TableRow key="2">
                          <StyledTableCell align="left">
                            Kredi Tutarı
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {!['APPROVED', 'REJECTED'].includes(creditProposalInfo.bankStatus)
                            && (
                            <BootstrapInput
                              name="offeredCreditAmount"
                              type="number"
                              value={creditProposalInfo.offeredCreditAmount}
                              id="offeredCreditAmount"
                              inputProps={{ min: 0, step: 1 }}
                              style={{ width: '150px' }}
                              onChange={(e) => {
                                handleChangeCreditProposalInfo(e, 'offeredCreditAmount');
                              }}
                              endAdornment={<InputAdornment position="end">₺</InputAdornment>}
                            />
                            )}
                            {['APPROVED', 'REJECTED'].includes(creditProposalInfo.bankStatus)
                            && (
                            <span>
                              {creditProposalInfo.offeredCreditAmount}
                              ₺
                            </span>
                            )}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow key="3">
                          <StyledTableCell align="left">
                            Faiz Oranı
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {!['APPROVED', 'REJECTED'].includes(creditProposalInfo.bankStatus)
                            && (
                            <BootstrapInput
                              name="interestRate"
                              type="number"
                              value={creditProposalInfo.interestRate}
                              id="interestRate"
                              inputProps={{ min: 0, step: 1 }}
                              style={{ width: '150px' }}
                              onChange={(e) => {
                                handleChangeCreditProposalInfo(e, 'interestRate');
                              }}
                              endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            />
                            )}
                            {['APPROVED', 'REJECTED'].includes(creditProposalInfo.bankStatus)
                            && (
                            <span>
                              {creditProposalInfo.interestRate}
                              %
                            </span>
                            )}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow key="4">
                          <StyledTableCell align="left">
                            Vade Süresi
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {!['APPROVED', 'REJECTED'].includes(creditProposalInfo.bankStatus)
                            && (
                            <BootstrapInput
                              name="creditTerm"
                              type="number"
                              value={creditProposalInfo.creditTerm}
                              id="creditTerm"
                              inputProps={{ min: 0, step: 1 }}
                              style={{ width: '150px' }}
                              onChange={(e) => {
                                handleChangeCreditProposalInfo(e, 'creditTerm');
                              }}
                              endAdornment={<InputAdornment position="end">ay</InputAdornment>}
                            />
                            )}
                            {['APPROVED', 'REJECTED'].includes(creditProposalInfo.bankStatus)
                            && (
                            <span>
                              {creditProposalInfo.creditTerm}
                              ay
                            </span>
                            )}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow key="5">
                          <StyledTableCell align="left">
                            Ödeme Planı Dokümanı
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {!['APPROVED', 'REJECTED'].includes(creditProposalInfo.bankStatus)
                            && (
                            <>
                              {isFormDataEmpty(paymentPlanDocument) && !creditProposalInfo.paymentPlanURL
                              && (
                              <span
                                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => setIsCreditProposalDialogOpen(true)}
                              >
                                {creditProposalInfo.paymentPlanURL}
                                Yükle
                              </span>
                              )}
                              {!isFormDataEmpty(paymentPlanDocument) && !creditProposalInfo.paymentPlanURL
                              && (
                                <>
                                  <a
                                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    target="_blank"
                                    rel="noreferrer"
                                    href={showFilePreview(paymentPlanDocument)}
                                  >
                                    Görüntüle
                                  </a>
                                  <span
                                    style={{ textDecoration: 'underline', cursor: 'pointer', marginLeft: 20 }}
                                    onClick={() => setPaymentPlanDocument(new FormData())}
                                  >
                                    Kaldır
                                  </span>
                                </>
                              )}
                              {creditProposalInfo.paymentPlanURL
                              && (
                                <a
                                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                  target="_blank"
                                  rel="noreferrer"
                                  href={creditProposalInfo.paymentPlanURL}
                                >
                                  Görüntüle
                                </a>
                              )}
                              <GeneralAddDialog
                                title={t('SingleCustomerPage.uploadDocument')}
                                requestInfo={{
                                  url: 'bankloan/banks/upload-file/',
                                  method: 'BANK-PAYMENT-PLAN-UPLOAD',
                                
                                }}
                                initialValues={{
                                  bankloadFile: null
                                }}
                                handle={handlePaymentPlanDocument}
                                modalShow={isCreditProposalDialogOpen}
                                setModalShow={setIsCreditProposalDialogOpen}
                              >
                                <Form.Item name={"bankloadFile"} rules={[{required:true,message:t("ErrorMessages.requiredField")}]}>
                                  <Upload.Dragger beforeUpload={bankloadBeforeUpload} multiple={false} maxCount={1} listType='picture-card'>
                                    <p className="ant-upload-text">
                                      {t('DocumentManagementPage.drag')}
                                    </p>
                                  </Upload.Dragger> 
                                </Form.Item>

                              </GeneralAddDialog>
                              {/* <GeneralAddDialog
                                dialogTitle={t('SingleCustomerPage.uploadDocument')}
                                requestURL="bankloan/banks/upload-file/"
                                requestMethod="BANK-PAYMENT-PLAN-UPLOAD"
                                openState={isCreditProposalDialogOpen}
                                setOpenState={setIsCreditProposalDialogOpen}
                                reloadPageState={shouldReloadPage}
                                reloadPageFunc={setShouldReloadPage}
                                bodyData={{
                                  credit_application_id: creditApplicationId,
                                  category_id: 11
                                }}
                                inputs={[
                                  {
                                    title: t('SingleCustomerPage.documentUpload'),
                                    type: 'file',
                                    name: 'file'
                                  }
                                ]}
                                changeDataFunction={() => console.log('xxx')}
                                externalFormData={paymentPlanDocument}
                                setExternalFormData={setPaymentPlanDocument}
                              /> */}
                            </>
                            )}
                            {['APPROVED', 'REJECTED'].includes(creditProposalInfo.bankStatus)
                            && (
                              <a href={creditProposalInfo.paymentPlanURL} target="_blank" rel="noreferrer"><span style={{ textDecoration: 'underline', cursor: 'pointer' }}>İndir</span></a>
                            )}
                          </StyledTableCell>
                        </TableRow>
                        {!['APPROVED', 'REJECTED'].includes(creditProposalInfo.bankStatus)
                        && (
                        <TableRow key="6">
                          <StyledTableCell align="left">
                            <Button
                              color="primary"
                              size="small"
                              variant="contained"
                              style={{ textTransform: 'none' }}
                              onClick={(e) => sendCreditProposal(e)}
                            >
                              {creditProposalInfo.isOffered ? 'Düzenle' : 'Teklif Oluştur'}
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell align="left"> </StyledTableCell>
                        </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </CardContent>
              <Divider style={{ margin: '20px 0px' }} />
              {creditProposalInfo.bankStatus === 'APPLIED'
              && (
              <CardContent>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                >
                  <h4 style={{ marginBottom: 15 }}>Aksiyonlar</h4>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      style={{ textTransform: 'none', width: '200px' }}
                    >
                      Kredi Başvurusunu Reddet
                    </Button>
                  </div>
                </div>
              </CardContent>
              )}
            </Card>
          </Grid>
          )}
        </Grid>
      </Container>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default CustomerInfo;

/* eslint-disable */


import { useDispatch } from 'react-redux';
import { changeAdminInfo, resetStateAuth } from '../services/users/userReducer';
import { useEffect, useState } from 'react';
import axiosInstance from 'src/utils/axiosApi';
import Spinner from '../components/Spinner/Spinner';


export const AuthContext = ({children}) => {

  const [loading,setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true)
    axiosInstance.get("token/verify").then((res) => {
      const {is_demo,status} = res.data;
      if(status === true) {
        if(is_demo === false) {
          dispatch(resetStateAuth({loggedIn: true}))
        }
      }
    }).finally(() => {
      const adminInfo = localStorage.getItem("adminInfo");
      if(adminInfo) {
        dispatch(changeAdminInfo(JSON.parse(localStorage.getItem('adminInfo'))));
      }
      setLoading(false)
    })
  },[])

  if(loading) {
    return <Spinner text="" />
  }
  else {
    return children;
  }
};

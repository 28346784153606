/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  Container,
  FormControl,
  InputBase,
  withStyles,
  CardHeader,
  CardContent,
  InputAdornment,
  Divider,
  Button,
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
// import { useTranslation } from 'react-i18next';
import 'reactjs-popup/dist/index.css';
import 'date-fns';
import axiosInstance from 'src/utils/axiosApi';
import Page from 'src/components/Page';
import Spinner from 'src/components/Spinner/Spinner';
import InternalRouterHorizontal from './internalRouterHorizontal';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: theme.spacing(3)
  },
  formControl: {
    margin: 0,
    width: '80%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    maxWidth: 500,
    minWidth: 400,
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '60px'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 12,
    fontFamily: 'Poppins'
  },
}))(TableCell);

const StyledTableContainer = withStyles(() => ({
  root: {
    marginTop: 30,
  },
}))(TableCell);

const MaliyetKonf = ({ className, ...rest }) => {
  // const { t, } = useTranslation();
  const { projectId } = useParams();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [showContent, setShowContent] = React.useState(true);

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Guc degeri
  const [guc, setGuc] = React.useState(10);
  const [customPowerValues, setCustomPowerValues] = React.useState({
    DCPower: 0,
    ACPower: 0,
  });

  // Final Cost
  const [finalCost, setFinalCost] = React.useState(0);

  // Maliyetler.
  const [maliyetler, setMaliyetler] = React.useState({
    karOncesi: 0,
    karSonrasi: 0,
    beklenmeyenGiderYuzde: 0,
    beklenmeyenGider: 0,
    karMarjiYuzde: 0,
    karMiktari: 0,
    kdvliFinal: 0
  });

  // Seçili olan itemler ilgili güç aralığı
  const [malzemeler, setMalzemeler] = React.useState([]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  const changeProjectFinalCost = async () => {
    setLoading(true);
    await axiosInstance.post('designer/update_project_final_cost?format=json', {
      project_id: projectId,
      final_cost: finalCost
    })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => console.log(error));
    setLoading(false);
  };

  const makeFeasibility = async () => {
    setLoading(true);
    let dcPower = 0;
    await axiosInstance.get(`designer/make_feasibility?project_id=${projectId}&format=json`)
      .then((response) => {
        // console.log(response.data.response[gucAralıgı]);
        if (response.data.status) {
          setGuc(response.data.data.calculated_dc_guc);
          dcPower = response.data.data.guc;
          dcPower = Number(dcPower.replace(',', '.'));
          setShowContent(true);
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
          setShowContent(false);
          setLoading(false);
        }
        /* console.log(response.data.data.guc); */
      })
      .catch((error) => console.log(error));

    await axiosInstance.get(`maliyet_hesapla?dc_guc=${dcPower}&format=json`)
      .then((response) => {
        // console.log(response.data.response[gucAralıgı]);
        setMalzemeler(response.data.malzeme_listesi);
        setMaliyetler({
          karOncesi: response.data.kar_oncesi_maliyet,
          karSonrasi: response.data.kar_sonrasi_maliyet,
          beklenmeyenGiderYuzde: response.data.beklenmeyen_giderler.yuzde,
          beklenmeyenGider: response.data.beklenmeyen_giderler.gider,
          karMarjiYuzde: response.data.kar_marji.yuzde,
          karMiktari: response.data.kar_marji.kar,
          kdvliFinal: response.data.kdvli_final_maliyet,
        });
      })
      .catch((error) => console.log(error));

    await axiosInstance.get(`designer/fetch_project_final_cost?project_id=${projectId}&format=json`)
      .then((response) => {
        setFinalCost(response.data.data.final_cost);
      })
      .catch((error) => console.log(error));

    await axiosInstance.get(`designer/fetch_project_dc_power?project_id=${projectId}&format=json`)
      .then((response) => {
        setCustomPowerValues({
          DCPower: response.data.data.dc_power,
          ACPower: 0,
        });
      })
      .catch((error) => console.log(error));

    setLoading(false);
  };

  const changeProjectDCPower = async () => {
    setLoading(true);
    await axiosInstance.post('designer/update_project_dc_power?format=json', {
      project_id: projectId,
      dc_power: customPowerValues.DCPower
    })
      .then((response) => {
        console.log(response.data);
        makeFeasibility();
      })
      .catch((error) => console.log(error));
    setLoading(false);
  };

  const generateRows = () => {
    // console.log('malzemeler:', malzemeler);
    const tempSortedMalzemeler = malzemeler.slice().sort((a, b) => {
      const textA = a.malzeme_maliyet;
      const textB = b.malzeme_maliyet;
      return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
    });
    return tempSortedMalzemeler.map((row) => {
      return (
        <TableRow key={row.malzeme_id}>
          <StyledTableCell component="th" scope="row">
            {row.kalem.replace('_', ' ')[0].toUpperCase() + row.kalem.replace('_', ' ').substring(1)}
          </StyledTableCell>
          <StyledTableCell align="left">{row.marka_model}</StyledTableCell>
          <StyledTableCell align="left">{row.deger}</StyledTableCell>
          <StyledTableCell align="left">{row.birim}</StyledTableCell>
          <StyledTableCell align="left">{row.adet_fiyat}</StyledTableCell>
          <StyledTableCell align="left">{row.wattpeak}</StyledTableCell>
          <StyledTableCell align="right">{row.malzeme_adet}</StyledTableCell>
          <StyledTableCell align="right">$ {row.malzeme_maliyet}</StyledTableCell>
        </TableRow>
      );
    });
  };

  /*   const handleChangeInput = (event) => {
    setGuc(event.target.value);
  }; */

  const handleChangeCustomPowers = (event) => {
    setCustomPowerValues({
      ...customPowerValues,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeFinalCost = (event) => {
    setFinalCost(event.target.value);
  };

  const willMount = React.useRef(true);
  if (willMount.current) {
    makeFeasibility();
    willMount.current = false;
  }

  if (loading) {
    return (
      <>
        <Spinner text="Malzemeler ve proje maliyeti hesaplanıyor.." />
      </>
    );
  }

  if (!showContent) {
    return (
      <>
        <div />
      </>
    );
  }

  return (
    <>
      <Page
        className={classes.root}
        title="solarVis | Proje Finansan ve Malzeme Bilgisi"
      >
        <InternalRouterHorizontal projectId={projectId} />
        <div style={{
          bottom: 0,
          height: 'auto',
          left: 'calc(6rem + 256px)',
          overflowX: 'auto',
          padding: 0,
          position: 'absolute',
          right: 0,
          top: '88px',
        }}
        >
          <Container maxWidth={false}>
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <Card
                  className={clsx(classes.cardRoot, className)}
                  {...rest}
                >
                  <CardHeader
                    title="Proje Gücüne Göre Malzemeler ve Maliyetler"
                  />
                  <Divider />
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        md={4}
                        lg={4}
                        xs={4}
                        style={{ display: 'flex', alignContent: 'center' }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <span>Hesaplanan Optimum DC Güç:</span>
                          <h4>{` ${guc} kWp`}</h4>
                        </div>
                      </Grid>
                      <Grid
                        item
                        md={8}
                        lg={8}
                        xs={8}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <span style={{ marginRight: '10px' }}>Güncellenecek DC Güç:</span>
                          <FormControl>
                            <BootstrapInput
                              name="DCPower"
                              type="number"
                              value={customPowerValues.DCPower}
                              id="DCPower"
                              inputProps={{ min: 0, step: 1 }}
                              style={{ width: '150px' }}
                              onChange={handleChangeCustomPowers}
                              endAdornment={<InputAdornment position="end">kWp</InputAdornment>}
                            />
                          </FormControl>
                          <Button
                            style={{ textTransform: 'none', marginLeft: '10px' }}
                            variant="contained"
                            color="primary"
                            onClick={changeProjectDCPower}
                          >
                            Proje Gücünü Değiştir
                          </Button>
                        </div>
                      </Grid>
                      {Boolean(maliyetler.karSonrasi)
                      && (
                      <Grid
                        item
                        md={12}
                        lg={12}
                        xs={12}
                      >
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Kalem</StyledTableCell>
                                <StyledTableCell align="left">Marka-Model</StyledTableCell>
                                <StyledTableCell align="left">Değer</StyledTableCell>
                                <StyledTableCell align="left">Birim</StyledTableCell>
                                <StyledTableCell align="left">Adet Fiyat ($)</StyledTableCell>
                                <StyledTableCell align="left">/Wp ($)</StyledTableCell>
                                <StyledTableCell align="right">Malzeme Adet</StyledTableCell>
                                <StyledTableCell align="right">Malzeme Maliyet</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {generateRows()}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <StyledTableContainer component={Paper}>
                            <Table className={classes.table} aria-label="checkout">
                              <TableBody>
                                <TableRow key="beklenmeyen_giderler">
                                  <StyledTableCell component="th" scope="row">
                                    Beklenmeyen Giderler (%{maliyetler.beklenmeyenGiderYuzde})
                                  </StyledTableCell>
                                  <StyledTableCell align="right">$ {maliyetler.beklenmeyenGider}</StyledTableCell>
                                </TableRow>
                                <TableRow key="malzeme_maliyet_aratop">
                                  <StyledTableCell component="th" scope="row">
                                    Ara Toplam
                                  </StyledTableCell>
                                  <StyledTableCell align="right">$ {maliyetler.karOncesi}</StyledTableCell>
                                </TableRow>
                                <TableRow key="kar_marji">
                                  <StyledTableCell component="th" scope="row">
                                    Kar Marjı (%{maliyetler.karMarjiYuzde})
                                  </StyledTableCell>
                                  <StyledTableCell align="right">$ {maliyetler.karMiktari}</StyledTableCell>
                                </TableRow>
                                <TableRow key="toplam">
                                  <StyledTableCell component="th" scope="row">
                                    <span style={{ fontSize: '1.1rem' }}>KDV Hariç Toplam</span>
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    <span style={{ fontSize: '1.1rem' }}>$ {maliyetler.karSonrasi}</span>
                                  </StyledTableCell>
                                </TableRow>
                                <TableRow key="toplam_kdvli">
                                  <StyledTableCell component="th" scope="row">
                                    <span style={{ fontSize: '1.1rem' }}>KDV Dahil Toplam</span>
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    <span style={{ fontSize: '1.1rem' }}>$ {maliyetler.kdvliFinal}</span>
                                  </StyledTableCell>
                                </TableRow>
                                <TableRow key="toplam_duzenle">
                                  <StyledTableCell component="th" scope="row">
                                    <Button
                                      style={{ textTransform: 'none' }}
                                      variant="contained"
                                      color="primary"
                                      onClick={changeProjectFinalCost}
                                    >
                                      Proje Maliyetini Güncelle
                                    </Button>
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    <FormControl>
                                      <BootstrapInput
                                        name="finalCost"
                                        type="number"
                                        value={finalCost}
                                        id="finalCost"
                                        inputProps={{ min: 0, step: 1 }}
                                        style={{ width: '150px' }}
                                        onChange={handleChangeFinalCost}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                      />
                                    </FormControl>
                                  </StyledTableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </StyledTableContainer>
                        </div>
                      </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </div>
        <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Page>
    </>
  );
};

MaliyetKonf.propTypes = {
  className: PropTypes.string
};

export default MaliyetKonf;

/* eslint-disable */
/* eslint-disable object-shorthand */
/* eslint-disable no-else-return */
import axios from 'axios';
import i18n from 'src/i18n';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const sessionExpired = () => {
  if (localStorage.getItem('language') === 'tr') {
    alert('Oturumunuz sona erdi. Lütfen tekrar giriş yapın.');
  } else {
    alert('Your session has expired. Please login again.');
  }
  localStorage.clear();
  window.location.href = '/login';
};

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  // console.log(base64Url);
  // console.log(localStorage.getItem('access'));
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

function updateInfo(companyName, ownerName, ownerSurname) {
  localStorage.setItem('company', companyName);
  localStorage.setItem('owner', `${ownerName} ${ownerSurname}`);
}

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 40000,
  headers: {
    /* Authorization: localStorage.getItem('access') ?
    `Bearer ${localStorage.getItem('access')}` : null, */
    'Content-Type': 'application/json',
    accept: 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('access');

    axiosInstance.defaults.headers.common.Authorization = accessToken ? `Bearer ${accessToken}` : '';
    config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';

    if (config.url.includes('get_cust_file') || config.url.includes('musteri/fetch/image') || config.url.includes('generate')) {
      config.responseType = 'blob';
    }

    return config;
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem('refresh');
    if (originalRequest.url !== '/token/refresh/') {
      localStorage.setItem('tempRequest', originalRequest.url);
    }

    // User is inactive
    if (error.response.status === 401 && error.response.data.detail === 'User is inactive') {
      sessionExpired();
    }

    if (error.response.status == 429) {
      if (localStorage.getItem('dil') == "tr" || String(i18n.language) == "tr") {
        alert("Çok fazla istek gönderdiniz. Lütfen 1 dakika sonra tekrar deneyin.");
      } else {
        alert("You have sent too many requests. Please try again in 1 minute.");
      }
    }

    // Prevent infinite loops
    if (error.response.status === 401 && originalRequest.url === '/token/refresh/') {
      sessionExpired();
    }

    if (error.response.status === 401 && originalRequest.url !== '/token/refresh/') {
      
      if (refreshToken) {
        const tokenParts = parseJwt(refreshToken);

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return axiosInstance
            .post('/token/refresh/', { refresh: refreshToken })
            .then((response) => {
              localStorage.setItem('access', response.data.access);
              const parsedInfo = parseJwt(response.data.access);
              updateInfo(parsedInfo.firma_kodu, parsedInfo.isim, parsedInfo.soy_isim);
              axiosInstance.defaults.headers.Authorization = `Bearer ${response.data.access}`;
              originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          sessionExpired();
          return
        }
      } else {
        sessionExpired();
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  maliyetTabNo: 0,
};

export const maliyetSlice = createSlice({
  name: 'maliyet',
  initialState,
  reducers: {
    changeMaliyetTabNo: (state, action) => {
      state.maliyetTabNo = action.payload.maliyetTabNo;
    },
    resetState: () => initialState,
  }
});

// Action creators are generated for each case reducer function
export const {
  changeMaliyetTabNo,
  resetState
} = maliyetSlice.actions;

export default maliyetSlice.reducer;

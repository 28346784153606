/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-template */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* global google */
import React, { Component } from 'react';
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  Polygon
} from 'react-google-maps';
import { DrawingManager } from 'react-google-maps/lib/components/drawing/DrawingManager';
import MuiAlert from '@material-ui/lab/Alert';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import axiosInstance from 'src/utils/axiosApi';
import { connect } from 'react-redux';
import {
  FormControl,
  Button,
  TextField,
  Snackbar,
  InputBase,
  withStyles,
  Select,
  MenuItem,
  InputAdornment,
  Tooltip
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import {
  changeLocation,
  changeTempLocation,
  changeMarker,
  changePolygonPoints,
  changeZoom,
} from 'src/services/products/projectReducer';
import { BiArea } from 'react-icons/bi';
import { FaDraftingCompass, FaCompass, FaArrowsAlt } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import classes from './ReactGoogleMapExample.module.css';

const StyledButton = withStyles({
  root: {
    borderRadius: 20,
    marginRight: '1rem',
  }
})(Button);

const BootstrapSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Map extends Component {
  constructor(props) {
    super(props);
    this.shapes = [];
    /* this.colorList = ['#EDD500', '#ffffff', '#000000']; */
    this.state = {
      currentMode: 'draw',
      showError: false,
      markerLat: null,
      catiBolgeleri: [],
    };
    this.handleOverlayComplete = this.handleOverlayComplete.bind(this);
    this.deleteShapes = this.deleteShapes.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.mapType === 'drawing') {
      this.fetchDrawingInfo();
    }
  }

  handleOverlayComplete(e) {
    // this.deleteShapes();

    const shape = e.overlay;
    shape.type = e.type;
    google.maps.event.addListener(shape, 'click', () => {
      this.toggleSelection(shape);
    });
    const areaGoogle = google.maps.geometry.spherical.computeArea(shape.getPath());

    if (areaGoogle < 15) {
      this.setState({
        showError: true
      });
      this.shapes.push(shape);
      this.shapes.forEach((shape) => shape.setMap(null));
    } else {
      this.toggleSelection(shape);
      this.shapes.push(shape);

      // eslint-disable-next-line no-unused-vars
      let drawPointsString = '';
      const tempPolygonPoints = [];
      const areaCenterLatList = [];
      const areaCenterLngList = [];
      this.props.dispatch(changeLocation({ latitude: this.map.getCenter().lat() + 0.00001, longitude: this.map.getCenter().lng() + 0.00001 }));
      shape.getPath().getArray().map((point, index) => {
        // console.log(point.lat());
        // drawPoints.push([point.lat(), point.lng()]);
        if (shape.getPath().getArray().length - 1 === index) {
          drawPointsString += String(point.lat()) + ',' + String(point.lng());
        } else {
          drawPointsString += String(point.lat()) + ',' + String(point.lng()) + '|';
        }

        // Alanın tam ortasına imleç koymak için listeye kaydet sonrasında ortalamasını al
        areaCenterLatList.push(Number(point.lat()));
        areaCenterLngList.push(Number(point.lng()));

        // Define the LatLng coordinates for the polygon's path. Geri geldiğinde tekrar çizili olması için.
        tempPolygonPoints.push({ lat: Number(point.lat()), lng: Number(point.lng()) });
        return point;
      });
      this.setState((prevState) => ({
        currentMode: null,
        catiBolgeleri: [...prevState.catiBolgeleri, {
          noktalar: tempPolygonPoints,
          alan: Math.round(String(Math.round(areaGoogle))),
          egim: 0,
          yon: 'guney',
          alan_yuzde: 80
        }],
      }));
    }

    // google.maps.drawing.DrawingManager.setDrawingMode(null);
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      showError: false
    });
  };

  handleClick(event) {
    this.setState({
      markerLat: event.latLng.lat(),
    });
    this.props.dispatch(changeTempLocation({ tempLatitude: event.latLng.lat(), tempLongitude: event.latLng.lng() }));
    this.props.dispatch(changeMarker({ markerOn: true }));
    this.props.dispatch(changeZoom({ zoomLevel: this.map.getZoom() }));
  }

  handleOnMapDrag() {
    this.props.dispatch(changeLocation({ latitude: this.map.getCenter().lat(), longitude: this.map.getCenter().lng() }));
  }

  handleZoomChange() {
    this.props.dispatch(changeZoom({ zoomLevel: this.map.getZoom() }));
  }

  handleChangeInput(event) {
    const newBolgeler = this.state.catiBolgeleri.slice();
    newBolgeler[event.target.id][event.target.name] = event.target.value;
    this.setState({ catiBolgeleri: newBolgeler });
  }

  handleChangeCepheSelect(event) {
    const newBolgeler = this.state.catiBolgeleri.slice();
    newBolgeler[event.target.name].yon = event.target.value;
    this.setState({ catiBolgeleri: newBolgeler });
  }

  getStaticGmapsURL(roofRegions) {
    let staticMapURLString = '';
    let pathsString = '';
    roofRegions.map((roof) => {
      let starterString = 'path=color:0x00000000|weight:5|fillcolor:0xFFFF0033|';
      roof.noktalar.map((point, index) => {
        if (roof.noktalar.length - 1 === index) {
          starterString += String(point.lat) + ',' + String(point.lng) + '~';
        } else {
          starterString += String(point.lat) + ',' + String(point.lng) + '|';
        }
      });
      pathsString += starterString;
    });
    staticMapURLString = `https://maps.googleapis.com/maps/api/staticmap?size=400x400~maptype=satellite~${pathsString}`;
    return staticMapURLString;
  }

  mapMounted = ((ref) => {
    this.map = ref;
  })

  fetchDrawingInfo() {
    axiosInstance.get(`designer/fetch_project_roof_info?project_id=${this.props.projectId}&format=json`)
      .then((response) => {
        this.props.dispatch(changeLocation({ latitude: response.data.data.enlem, longitude: response.data.data.boylam }));
        this.props.dispatch(changeTempLocation({ tempLatitude: response.data.data.enlem, tempLongitude: response.data.data.boylam }));
        this.setState({ catiBolgeleri: response.data.data.cizimler });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async saveDrawingInfo() {
    const staticMapURLString = this.getStaticGmapsURL(this.state.catiBolgeleri);
    await axiosInstance.post('designer/update_project_roof_info?format=json', {
      project_id: this.props.projectId,
      cizimler: this.state.catiBolgeleri,
      gmaps_link: staticMapURLString
    })
      .then((response) => {
        if (response.data.status) {
          console.log(response.data);
          this.props.navigate(`/projects/${this.props.projectId}/facility-consumption`, { replace: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  generateShapeCards2() {
    if (this.state.catiBolgeleri.length !== 0) {
      return this.state.catiBolgeleri.map((bolge, idxx) => {
        console.log(bolge);
        return (
          <div className={classes.shapeCard}>
            <span style={{ width: '100%' }}>
              {`Çatı Bölgesi ${idxx + 1}`}
            </span>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className={classes.insideWrapper}>
                <div style={{ marginRight: 8 }}>
                  <IconContext.Provider value={{ color: '#000000', size: 20 }}>
                    <BiArea />
                  </IconContext.Provider>
                </div>
                {bolge.alan && <span>{`${bolge.alan} m2`}</span>}
              </div>
              <div className={classes.insideWrapper}>
                <div style={{ marginRight: 8 }}>
                  <IconContext.Provider value={{ color: '#000000', size: 20 }}>
                    <FaDraftingCompass />
                  </IconContext.Provider>
                </div>
                <FormControl>
                  <TextField
                    style={{ width: '100px' }}
                    name="egim"
                    type="number"
                    value={bolge.egim}
                    /* style={{ width: '150px' }} */
                    id={idxx}
                    onChange={(e) => this.handleChangeInput(e)}
                    size="small"
                    variant="outlined"
                    error={Number(bolge.egim) < 0 || Number(bolge.egim) > 90}
                    helperText={bolge.egim === '' ? 'Boş bırakılamaz!' : ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          °
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
                <div style={{ marginLeft: '8px' }}>
                  <Tooltip placement="top" title="Çatı eğimini ifade eder. 0-90 arasında bir değer giriniz.">
                    <InfoIcon style={{ color: '#b1b1b1' }} />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className={classes.insideWrapper}>
                <div style={{ marginRight: 8 }}>
                  <IconContext.Provider value={{ color: '#000000', size: 20 }}>
                    <FaCompass />
                  </IconContext.Provider>
                </div>
                <FormControl variant="outlined">
                  <Select
                    labelId="yon"
                    id={String(idxx)}
                    name={idxx}
                    value={bolge.yon}
                    onChange={(e) => this.handleChangeCepheSelect(e)}
                    label="Cephe Seçimi"
                    input={<BootstrapSelect />}
                  >
                    {[
                      { name: 'Kuzey', value: 'kuzey' },
                      { name: 'Güney', value: 'guney' },
                      { name: 'Doğu', value: 'dogu' },
                      { name: 'Batı', value: 'bati' },
                      { name: 'Kuzey Doğu', value: 'kuzey-dogu' },
                      { name: 'Kuzey Batı', value: 'kuzey-bati' },
                      { name: 'Güney Doğu', value: 'guney-dogu' },
                      { name: 'Güney Batı', value: 'guney-bati' }].map((yon) => (
                        <MenuItem value={yon.value}>
                          {yon.name}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className={classes.insideWrapper}>
                <div style={{ marginRight: 8 }}>
                  <IconContext.Provider value={{ color: '#000000', size: 20 }}>
                    <FaArrowsAlt />
                  </IconContext.Provider>
                </div>
                <FormControl>
                  <TextField
                    style={{ width: '100px' }}
                    name="alan_yuzde"
                    type="number"
                    value={bolge.alan_yuzde}
                    /* style={{ width: '150px' }} */
                    id={idxx}
                    onChange={(e) => this.handleChangeInput(e)}
                    size="small"
                    variant="outlined"
                    error={Number(bolge.alan_yuzde) < 0 || Number(bolge.alan_yuzde) > 100}
                    helperText={bolge.alan_yuzde === '' ? 'Boş bırakılamaz!' : ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          %
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
                <div style={{ marginLeft: '8px' }}>
                  <Tooltip placement="top" title="Kullanılabilir çatı alanını ifade eder. Yüzde olarak 0-100 arasında bir değer giriniz.">
                    <InfoIcon style={{ color: '#b1b1b1' }} />
                  </Tooltip>
                </div>
              </div>
            </div>
            <Button
              style={{ marginTop: '10px', textTransform: 'none', width: '80px' }}
              variant="contained"
              color="primary"
              onClick={() => this.deleteShape(idxx)}
              fullWidth={false}
            >
              Sil
            </Button>
          </div>
        );
      });
    }
  }

  generateShapeCards() {
    if (this.state.catiBolgeleri.length !== 0 && this.state.catiBolgeleri.length === this.shapes.length) {
      return this.shapes.map((shape, idxx) => {
        console.log(this.state.catiBolgeleri[idxx]);
        return (
          <div className={classes.shapeCard}>
            <span style={{ width: '100%' }}>
              {`Çatı Bölgesi ${idxx + 1}`}
            </span>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className={classes.insideWrapper}>
                <div style={{ marginRight: 8 }}>
                  <IconContext.Provider value={{ color: '#000000', size: 20 }}>
                    <BiArea />
                  </IconContext.Provider>
                </div>
                {this.state.catiBolgeleri[idxx].alan && <span>{`${this.state.catiBolgeleri[idxx].alan} m2`}</span>}
              </div>
              <div className={classes.insideWrapper}>
                <div style={{ marginRight: 8 }}>
                  <IconContext.Provider value={{ color: '#000000', size: 20 }}>
                    <FaDraftingCompass />
                  </IconContext.Provider>
                </div>
                <FormControl>
                  <TextField
                    name="egim"
                    type="number"
                    value={this.state.catiBolgeleri[idxx].egim}
                    /* style={{ width: '150px' }} */
                    id={idxx}
                    onChange={(e) => this.handleChangeInput(e)}
                    size="small"
                    variant="outlined"
                    error={this.state.catiBolgeleri[idxx].egim === ''}
                    helperText={this.state.catiBolgeleri[idxx].egim === '' ? 'Boş bırakılamaz!' : ''}
                  />
                </FormControl>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className={classes.insideWrapper}>
                <div style={{ marginRight: 8 }}>
                  <IconContext.Provider value={{ color: '#000000', size: 20 }}>
                    <FaCompass />
                  </IconContext.Provider>
                </div>
                <FormControl variant="outlined">
                  <Select
                    labelId="yon"
                    id={String(idxx)}
                    name={idxx}
                    value={this.state.catiBolgeleri[idxx].yon}
                    onChange={(e) => this.handleChangeCepheSelect(e)}
                    label="Cephe Seçimi"
                    input={<BootstrapSelect />}
                  >
                    {['kuzey', 'guney', 'dogu', 'bati', 'kuzey-dogu', 'kuzey-bati', 'guney-dogu', 'guney-bati'].map((yon) => (
                      <MenuItem value={yon}>
                        {yon}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className={classes.insideWrapper}>
                <div style={{ marginRight: 8 }}>
                  <IconContext.Provider value={{ color: '#000000', size: 20 }}>
                    <FaArrowsAlt />
                  </IconContext.Provider>
                </div>
                <FormControl>
                  <TextField
                    name="alan_yuzde"
                    type="number"
                    value={this.state.catiBolgeleri[idxx].alan_yuzde}
                    /* style={{ width: '150px' }} */
                    id={idxx}
                    onChange={(e) => this.handleChangeInput(e)}
                    size="small"
                    variant="outlined"
                    error={this.state.catiBolgeleri[idxx].alan_yuzde === ''}
                    helperText={this.state.catiBolgeleri[idxx].alan_yuzde === '' ? 'Boş bırakılamaz!' : ''}
                  />
                </FormControl>
              </div>
            </div>
            <Button
              style={{ marginTop: '10px', textTransform: 'none' }}
              variant="contained"
              color="primary"
              onClick={() => this.deleteShape(idxx)}
            >
              Sil
            </Button>
            {/* <button onClick={() => this.deleteShape(idxx)} type="button">
              Sil
            </button> */}
          </div>
        );
      });
    }
  }

  deleteShape(idxx) {
    /* this.shapes[index].setMap(null); */
    this.shapes.forEach((shape) => {
      if (Number(idxx) === Number(this.shapes.indexOf(shape))) {
        shape.setMap(null);
      } else {
        console.log('x');
      }
    });
    this.shapes.splice(idxx, 1);
    this.props.dispatch(changeLocation({ latitude: this.map.getCenter().lat() + 0.00001, longitude: this.map.getCenter().lng() + 0.00001 }));
    this.setState((prevState) => ({
      catiBolgeleri: prevState.catiBolgeleri.filter((bolge) => prevState.catiBolgeleri.indexOf(bolge) !== idxx),
    }));
  }

  deleteShapes() {
    this.shapes.forEach((shape) => shape.setMap(null));
    this.setState({
      currentMode: 'draw',
    });
    this.props.dispatch(changePolygonPoints({ polygonPoints: null }));
    this.props.dispatch(changeMarker({ markerOn: false }));
  }

  startDrawButton() {
    this.setState({
      currentMode: 'draw',
    });
  }

  toggleSelection(shape) {
    shape.setEditable(false);
  }

  render() {
    return (
      <>
        <GoogleMap
          onClick={(e) => this.handleClick(e)}
          onDragEnd={(e) => this.handleOnMapDrag(e)}
          onZoomChanged={(e) => this.handleZoomChange(e)}
          zoom={this.props.zoomLevel}
          defaultMapTypeId="hybrid"
          defaultOptions={{
            /* scrollwheel: false,  mouse scroll'unu kapatıyor zoom için */
            disableDefaultUI: true,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            styles: [
              {
                featureType: 'all',
                elementType: 'labels',
                stylers: [
                  { visibility: 'off' }
                ]
              },
              {
                featureType: 'road',
                elementType: 'labels',
                stylers: [
                  { visibility: 'on' }
                ]
              },
              {
                featureType: 'administrative',
                elementType: 'labels',
                stylers: [
                  { visibility: 'on' }
                ]
              },
              {
                featureType: 'water',
                elementType: 'labels',
                stylers: [
                  { visibility: 'on' }
                ]
              }
            ]
          }}
          center={{ lat: this.props.latitude, lng: this.props.longitude }}
          ref={this.mapMounted}
        >
          {this.props.markerOn && this.props.mapType === 'address'
          && (
          <Marker
            key={Math.round(this.state.markerLat)}
            // position={{ lat: this.state.markerLat, lng: this.state.markerLng }}
            position={{ lat: this.props.tempLatitude, lng: this.props.tempLongitude }}
          />
          )}

          {this.state.catiBolgeleri.length !== 0
          && this.state.catiBolgeleri.map((bolge) => (
            <Polygon
              key={bolge.alan}
              paths={bolge.noktalar}
              options={{
                strokeWeight: 5,
                fillOpacity: 0.45,
                fillColor: '#EDD500', // '#EDD500',
                strokeColor: '#EDD500'
              }}
            />
          ))}

          {this.props.desktop && this.props.mapType === 'drawing'
          && (
          <DrawingManager
            // defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
            key={this.state.currentMode}
            defaultOptions={{
              polygonOptions: {
                strokeWeight: 5,
                fillOpacity: 0.45,
                fillColor: '#EDD500',
                strokeColor: '#EDD500'
              },
              drawingControl: true,
              drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: ['polygon']
              }
            }}
            drawingMode={this.state.currentMode === 'draw'
              ? google.maps.drawing.OverlayType.POLYGON
              : null}
            onOverlayComplete={this.handleOverlayComplete}
          />
          )}
        </GoogleMap>
        {this.props.mapType === 'drawing'
        && (
        <div className={classes.shapeCardWrapper}>
          {this.generateShapeCards2()}
          <Button
            style={{ marginBottom: '10px', textTransform: 'none' }}
            variant="contained"
            color="primary"
            /* className={classes.button} */
            onClick={() => this.startDrawButton()}
            startIcon={<BorderColorIcon />}
          >
            {(this.state.catiBolgeleri.length !== 0) ? 'Çizim Ekle' : 'Çizim Başlat' }
          </Button>
          {(this.state.catiBolgeleri.length !== 0)
          && (
            <div className={classes.sidePanelButtons}>
              <div className={classes.buttonWrapper}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={() => this.saveDrawingInfo()}
                >
                  Kaydet
                </StyledButton>
              </div>
            </div>
          )}
        </div>
        )}
        <Snackbar open={this.state.showError} autoHideDuration={6000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="info">
            {this.props.smallAreaAlertText}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  latitude: state.project.latitude,
  longitude: state.project.longitude,
  tempLatitude: state.project.tempLatitude,
  tempLongitude: state.project.tempLongitude,
  zoomLevel: state.project.zoomLevel,
  markerOn: state.project.markerOn,
  mapFeature: state.project.mapFeature,
});

export default connect(mapStateToProps)(withGoogleMap(Map));

/* eslint-disable */
import React from 'react';
import './PureCssSpinner.module.css';

function PureCssSpinner() {
  return (
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default PureCssSpinner;

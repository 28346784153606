/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  PieChart, Pie, Sector, ResponsiveContainer, Cell
} from 'recharts';

/* const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
]; */

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} fontWeight="bold" fontSize="20px" textAnchor="middle" fill="#333">
        {`${((Number(value) * 100) / (percent * 100)).toFixed(2)}`}
        MWh
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${payload.name}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text> */}
    </g>
  );
};

function PieChartX(props) {
  const { data, total, purpose } = props;
  const [activeIndex, setActiveIndex] = React.useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const productionColor = '#21cc7263'; // soft: #21cc7263 / strong: rgb(33, 204, 114)
  const consumptionColor = '#f4747363'; // soft: #f4747363 / strong: rgb(244, 116, 115)
  const selfConsColor = '#5c9df563'; // soft: #5c9df563 / strong: rgb(92, 157, 245)

  const totalProdColor = 'rgb(33, 204, 114)';
  const totalConsColor = 'rgb(244, 116, 115)';

  let COLORS = [];
  let totalColor;

  if (purpose === 'production') {
    COLORS = [selfConsColor, productionColor];
    totalColor = totalProdColor;
  } else if (purpose === 'consumption') {
    COLORS = [selfConsColor, consumptionColor];
    totalColor = totalConsColor;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={400} height={400}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={90}
          outerRadius={120}
          fill="rgb(92, 157, 245)"
          dataKey="value"
          onMouseEnter={onPieEnter}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Pie
          data={total}
          cx="50%"
          cy="50%"
          innerRadius={70}
          outerRadius={85}
          fill={totalColor}
          dataKey="value"
        />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default PieChartX;

import React from 'react';
import axiosInstance from 'src/utils/axiosApi';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const StyledIconButton = withStyles({
  root: {
    margin: 2,
    textTransform: 'none',
    fontSize: 16,
    backgroundColor: '#f7e2e2',
    color: '#ad3737',
    padding: 8
  }
})(IconButton);

const StyledDialogTitle = withStyles({
  root: {
    fontSize: 18,
    fontWeight: 'bold'
  }
})(DialogTitle);

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    axiosInstance.get(`musteri/delete?musteri_id=${props.id}&format=json`)
      .then((response) => {
        if (response.data.status) {
          window.location.reload();
          alert('İşleminiz başarılı bir şekilde gerçekleştirildi.');
        } else {
          alert('Böyle bir kayıt bulunamadı.');
        }
      })
      .catch((error) => {
        alert('Şu an bu işlemi gerçekleştiremiyoruz. Lütfen tekrar deneyin.');
        console.log(error);
      }).finally(() => {
        setOpen(false);
      });
  };

  return (
    <div>
      <StyledIconButton aria-label="delete" onClick={handleClickOpen}>
        <DeleteIcon />
      </StyledIconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title" disableTypography>Bu kaydı silmek istediğinize emin misiniz?</StyledDialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bu geri dönülemez bir işlemdir. Bu müşteri kaydı veri tabanımızdan silinecektir.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Geri Dön
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Sil
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertDialog.propTypes = {
  id: PropTypes.string,
};

/* eslint-disable react/jsx-boolean-value */
/* eslint-disable max-len */
import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useMediaQuery } from 'react-responsive';
import classes from './NivoBar.module.css';

function BarGraph() {
  const labelBottom = 'Zaman';
  const labelLeft = 'Tasarruf Miktarı ($)';
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  let generalGraphSettings;
  if (isTabletOrMobile) {
    generalGraphSettings = {
      widthLineGraph: '90%',
      widthBarGraph: '100%',
      tickRotation: -90,
      marginRight: 20,
      marginLeft: 20,
      marginBottom: 20,
      legendOffsetBottom: 60,
      legendOffsetLeft: -70
    };
  } else {
    generalGraphSettings = {
      widthLineGraph: '70%',
      widthBarGraph: '90%',
      tickRotation: -60,
      marginRight: 50,
      marginLeft: 110,
      marginBottom: 20,
      legendOffsetBottom: 50,
      legendOffsetLeft: -95
    };
  }

  const lineGraphSettings = {
    theme: {
      background: 'transparent',
      fontFamily: 'Poppins',
      textColor: '#7d7d7d',
      color: '#7d7d7d',
      /* legends: {
        text: {
          fontSize: '16px',
          fill: '#7d7d7d'
        }
      }, */
      axis: {
        legend: {
          text: {
            fontSize: '16px',
            color: 'white'
          }
        },
        ticks: {
          text: {
            fontSize: '12px'
          }
        }
      },
    }
  };

  return (
    <div className={classes.ChartContainer}>
      <div className={classes.ChartShow} style={{ width: generalGraphSettings.widthBarGraph }}>
        <ResponsiveBar
          /* data={props.barData} */
          keys={['Yatırımınız cebinize dönüyor', 'Muhteşem! Artık kara geçiyorsunuz']}
          indexBy="year"
          enableLabel={false}
          margin={{
            top: 0, right: generalGraphSettings.marginRight, bottom: generalGraphSettings.marginBottom, left: 110
          }}
          padding={0.45}
          tooltip={({ id, color, value }) => (
            <strong style={{ color, fontSize: '14px' }}>
              {id}
              :
              {value}
              $
            </strong>
          )}
          colors={['#ff884b', '#085b06']}
          // borderWidth={3}
          borderColor={{ from: 'color', modifiers: [['darker', '1.7']] }}
          axisTop={null}
          borderRadius={2}
          axisRight={null}
          axisBottom={{
            tickSize: 3,
            tickPadding: 9,
            tickRotation: generalGraphSettings.tickRotation,
            legend: labelBottom,
            legendPosition: 'middle',
            legendOffset: generalGraphSettings.legendOffsetBottom
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: labelLeft,
            legendPosition: 'middle',
            legendOffset: generalGraphSettings.legendOffsetLeft
          }}
          labelSkipWidth={15}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', '1.6']] }}
          animate={true}
          theme={lineGraphSettings.theme}
          motionStiffness={90}
          motionDamping={15}
          /* legends={[
            {
              dataFrom: 'keys',
              anchor: 'top',
              direction: 'row',
              justify: false,
              translateX: 17,
              translateY: -9,
              itemsSpacing: 6,
              itemWidth: 93,
              itemHeight: 18,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]} */
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
        />
      </div>
    </div>
  );
}

export default BarGraph;

/* eslint-disable */

import { InboxOutlined } from '@material-ui/icons';
import { Button, Form, Input, Modal, Select, Upload } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import React from 'react';
import { useTranslation } from 'react-i18next';

// this file for upload files to server
function GeneralAddDialog({
  modalShow,
  setModalShow,
  title,
  children,
  initialValues,
  handle,
  form
}) {
  const handleChange = value => {
    console.log(`selected ${value}`);
  };4

  const {t} = useTranslation();

  const uploadComponentProps = {
    name: 'file',
    multiple: true,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    }
  };

  return (
    <Modal
      footer={null}
      zIndex={50}
      open={modalShow}
      title={title}
      onCancel={setModalShow}
    >
      <Form form={form} layout="vertical" onFinish={handle} initialValues={initialValues}>
        {Array([children]).map((input,index) => <React.Fragment key={index}>
            {input}
        </React.Fragment>)}
        <div style={{display:'flex',justifyContent:'end',gap:'4px'}}>
            <Button htmlType='reset' type='dashed' onClick={setModalShow}>
              { t('Button.back')}
            </Button>
            <Button htmlType="submit" type="primary">{ t('Button.add') }</Button>
        </div>
      </Form>
    </Modal>
  );
}

export default GeneralAddDialog;

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable no-multi-assign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import i18n from 'src/i18n';
import {
  Box,
  Card,
  makeStyles,
  CardContent,
  Grid,
  Container,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  Switch,
  Snackbar
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Page from 'src/components/Page';
import 'date-fns';
import CompanyInfoSaveDialog from 'src/components/CompanyInfoSaveDialog';
import axiosInstance from 'src/utils/axiosApi';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    width: '100%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
}));

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins'
  },
}))(TableCell);

const StyledTableCellHead = withStyles(() => ({
  root: {
    padding: 10,
    fontFamily: 'Poppins',
    fontWeight: 'bold'
  },
}))(TableCell);

const AccordionComponent = ({
  accordionContent,
  accordionOpenState,
  accordionStateChangeFunc,
  accordionTitle,
  expandSectionName
}) => {
  return (
    <Accordion
      expanded={accordionOpenState[expandSectionName]}
      onChange={() => accordionStateChangeFunc({
        ...accordionOpenState,
        [expandSectionName]: !accordionOpenState[expandSectionName]
      })}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h2 style={{ fontSize: '1.1rem', fontWeight: 'normal' }}>{accordionTitle}</h2>
      </AccordionSummary>
      <AccordionDetails
        style={{ padding: 0 }}
      >
        {accordionContent}
      </AccordionDetails>
    </Accordion>
  );
};

const FirmaBilgileri = ({ className, ...rest }) => {
  const { t, } = useTranslation();
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' });

  const classes = useStyles();

  const {
    company,
  } = useSelector((state) => state.users);

  const [expandSections, setExpandSections] = useState({
    companyInfo: true,
  });

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [solarvisCompanies, setSolarvisCompanies] = useState([]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  const largeYerlesim = 3;

  const getSolarvisCompanies = () => {
    axiosInstance.get(`bankloan/banks/retrieve-epc-companies?lang=${(localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') ? 'EN' : 'TR'}`)
      .then((response) => {
        const data = response.data.data;
        setSolarvisCompanies(data);
      })
      .catch((error) => console.log(error));
  };

  const saveCompanyStatus = () => {
    axiosInstance.post('bankloan/banks/select-epc-companies/', {
      accepted_company_ids: solarvisCompanies.filter((solarvisCompany) => solarvisCompany.is_accepted).map((solarvisCompany) => solarvisCompany.id),
      rejected_company_ids: solarvisCompanies.filter((solarvisCompany) => !solarvisCompany.is_accepted).map((solarvisCompany) => solarvisCompany.id),
      lang: (localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') ? 'EN' : 'TR'
    })
      .then((response) => {
        if (response.data.status) {
          setSnackbarMessage(response.data.message);
          setSnackbarType('success');
          setSnackbarState(true);
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
          // alert('Böyle bir kayıt bulunamadı.');
        }
      })
      .catch((error) => {
        setSnackbarMessage(t('AlertMessage.generalError'));
        setSnackbarType('warning');
        setSnackbarState(true);
        console.log(error);
      });
  };

  const handleChangeCompanyActiveStatus = (event, companyId) => {
    // Prevents React from resetting its properties:
    event.persist();
    const newData = solarvisCompanies.map((solarvisCompany) => {
      if (solarvisCompany.id === Number(companyId)) {
        return {
          ...solarvisCompany,
          is_accepted: event.target.checked
        };
      }
      return solarvisCompany;
    });
    setSolarvisCompanies(newData);
  };

  useEffect(() => {
    getSolarvisCompanies();
  }, []);

  return (
    <Page
      className={classes.root}
      title={t('ConfigurationPage.pageTitle')}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <AccordionComponent
              accordionTitle="solarVis'e Kayıtlı Firmalar"
              accordionContent={(
                <Card
                  className={clsx(classes.cardRoot, className)}
                  {...rest}
                >
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >
                      <TableContainer>
                        <Table aria-label="epc-table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCellHead> </StyledTableCellHead>
                              <StyledTableCellHead align="left">
                                Firma İsmi
                              </StyledTableCellHead>
                              <StyledTableCellHead align="left">
                                Yetkili İsim Soy isim
                              </StyledTableCellHead>
                              <StyledTableCellHead align="left">
                                Yetkili Telefon
                              </StyledTableCellHead>
                              <StyledTableCellHead align="left">
                                Yetkili E-posta
                              </StyledTableCellHead>
                              <StyledTableCellHead align="left">
                                Vergi No
                              </StyledTableCellHead>
                              <StyledTableCellHead align="left">
                                Vergi Dairesi
                              </StyledTableCellHead>
                              <StyledTableCellHead align="center"> </StyledTableCellHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {solarvisCompanies.map((solarvisCompany) => (
                              <TableRow key={solarvisCompany.company_name}>
                                <StyledTableCell component="th" scope="row" align="center">
                                  <img
                                    style={{
                                      width: '60px',
                                      height: '60px',
                                      borderRadius: '50%',
                                      objectFit: 'contain'
                                    }}
                                    src={solarvisCompany.logo_url}
                                    alt={solarvisCompany.company_name}
                                  />
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align="left">
                                  {solarvisCompany.company_name}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {solarvisCompany.contact_person_name_surname}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {solarvisCompany.contact_person_phone}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {solarvisCompany.contact_person_email}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {solarvisCompany.vergi_no}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {solarvisCompany.vergi_dairesi}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <Switch
                                    checked={solarvisCompany.is_accepted}
                                    onChange={(e) => handleChangeCompanyActiveStatus(e, solarvisCompany.id)}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                  />
                                </StyledTableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </CardContent>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    p={2}
                  >
                    <Button
                      color="primary"
                      endIcon={<ArrowRightIcon />}
                      size="small"
                      variant="contained"
                      onClick={saveCompanyStatus}
                    >
                      {t('ConfigurationPage.kaydetButon')}
                    </Button>
                  </Box>
                </Card>
              )}
              accordionOpenState={expandSections}
              accordionStateChangeFunc={setExpandSections}
              expandSectionName="companyInfo"
            />
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Page>
  );
};

FirmaBilgileri.propTypes = {
  className: PropTypes.string
};

export default FirmaBilgileri;

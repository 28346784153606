/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable no-multi-assign */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardHeader,
  makeStyles,
  CardContent,
  Grid,
  Container,
  TextField,
  Divider,
  CardActions,
  Button,
  InputAdornment,
  Tooltip,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import InfoIcon from '@material-ui/icons/Info';
import Page from 'src/components/Page';
import UndoIcon from '@material-ui/icons/Undo';
import 'date-fns';
import CompanyInfoSaveDialog from 'src/components/CompanyInfoSaveDialog';
import axiosInstance from 'src/utils/axiosApi';
import { useSelector } from 'react-redux';
import { ChromePicker } from 'react-color';
import ColorUpdateDialog from 'src/components/ColorUpdateDialog';
import ConfSaveDialog from 'src/components/ConfSaveDialog';
import { useMediaQuery } from 'react-responsive';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    width: '100%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
}));

const AccordionComponent = ({
  accordionContent,
  accordionOpenState,
  accordionStateChangeFunc,
  accordionTitle,
  expandSectionName
}) => {
  console.log('accordionState: ', accordionOpenState);
  console.log('expandSectionName: ', expandSectionName);
  return (
    <Accordion
      expanded={accordionOpenState[expandSectionName]}
      onChange={() => accordionStateChangeFunc({
        ...accordionOpenState,
        [expandSectionName]: !accordionOpenState[expandSectionName]
      })}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h2 style={{ fontSize: '1.1rem', fontWeight: 'normal' }}>{accordionTitle}</h2>
      </AccordionSummary>
      <AccordionDetails
        style={{ padding: 0 }}
      >
        {accordionContent}
      </AccordionDetails>
    </Accordion>
  );
};

const FirmaBilgileri = ({ className, ...rest }) => {
  const { t, } = useTranslation();
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' });

  const sehirlerTamListe = ['Adana', 'Adıyaman', 'Afyonkarahisar', 'Ağrı', 'Amasya', 'Ankara', 'Antalya', 'Artvin', 'Aydın', 'Balıkesir', 'Bilecik', 'Bingöl', 'Bitlis', 'Bolu', 'Burdur', 'Bursa', 'Çanakkale', 'Çankırı', 'Çorum', 'Denizli', 'Diyarbakır', 'Edirne', 'Elazığ', 'Erzincan', 'Erzurum', 'Eskişehir', 'Gaziantep', 'Giresun', 'Gümüşhane', 'Hakkari', 'Hatay', 'Isparta', 'Mersin', 'İstanbul', 'İzmir', 'Kars', 'Kastamonu', 'Kayseri', 'Kırklareli', 'Kırşehir', 'Kocaeli', 'Konya', 'Kütahya', 'Malatya', 'Manisa', 'Kahramanmaraş', 'Mardin', 'Muğla', 'Muş', 'Nevşehir', 'Niğde', 'Ordu', 'Rize', 'Sakarya', 'Samsun', 'Siirt', 'Sinop', 'Sivas', 'Tekirdağ', 'Tokat', 'Trabzon', 'Tunceli', 'Şanlıurfa', 'Uşak', 'Van', 'Yozgat', 'Zonguldak', 'Aksaray', 'Bayburt', 'Karaman', 'Kırıkkale', 'Batman', 'Şırnak', 'Bartın', 'Ardahan', 'Iğdır', 'Yalova', 'Karabük', 'Kilis', 'Osmaniye', 'Düzce'];

  const classes = useStyles();

  const [logoImage, setLogoImage] = useState({
    preview: '',
    name: ''
  });

  const {
    company,
  } = useSelector((state) => state.users);

  const [expandSections, setExpandSections] = useState({
    companyInfo: true,
    companyColors: true,
    companyCities: false,
    otherSettings: false,
  });

  const [selectedCities, setSelectedCities] = useState();
  const [allCitiesSelected, setAllCitiesSelected] = useState(false);

  const [companyInfo, setCompanyInfo] = useState({
    companyName: '',
    mainWeb: '',
    companyPhone: '',
    companyMail: '',
    companyAddress: '',
    mwAlan: '',
    contactPerson: '',
    vergiDairesi: '',
    vergiNo: '',
    mersisNo: ''
  });

  const [companyColors, setCompanyColors] = useState({
    primaryColor: '#373242',
    secondaryColor: '#653a94',
    displayPrimaryColorPicker: false,
    displaySecondaryColorPicker: false
  });

  const largeYerlesim = 3;

  const placeholders = {
    companyName: t('ConfigurationPage.companyName'),
    mainWeb: t('ConfigurationPage.mainWeb'),
    companyPhone: t('ConfigurationPage.companyPhone'),
    companyMail: t('ConfigurationPage.companyMail'),
    companyAddress: t('ConfigurationPage.companyAddress'),
    mwAlan: t('ConfigurationPage.mwAlan'),
    contactPerson: t('ConfigurationPage.contactPerson'),
    vergiDairesi: t('ConfigurationPage.vergiDairesi'),
    vergiNo: t('ConfigurationPage.vergiNo'),
    mersisNo: t('ConfigurationPage.mersisNo'),
  };

  async function getCompanyInfo() {
    await axiosInstance.get('settings/company/fetch?format=json')
      .then((response) => {
        if (response.data.status) {
          const data = response.data.data;
          setCompanyInfo({
            ...companyInfo,
            companyName: data.company,
            mainWeb: data.main_web,
            companyPhone: data.phone,
            companyMail: data.mail,
            companyAddress: data.adress,
            mwAlan: data.mw_alan,
            contactPerson: data.contact_person,
            vergiDairesi: data.vergi_dairesi,
            vergiNo: data.vergi_no,
            mersisNo: data.mersis_no
          });
          setCompanyColors({
            ...companyColors,
            primaryColor: data.primary_color,
            secondaryColor: data.secondary_color,
          });
        }
      })
      .catch((error) => console.log(error));
  }

  async function getServiceRegions() {
    await axiosInstance.get('region/fetch_company_service_region_api')
      .then((response) => {
        if (response.data.status || response.data.status === 'OK') {
          const regionsGet = response.data.regions;
          const tempSelectedCities = {};
          sehirlerTamListe.map((city) => {
            if (regionsGet.includes(city)) {
              tempSelectedCities[city] = true;
            } else {
              tempSelectedCities[city] = false;
            }
          });
          setSelectedCities(tempSelectedCities);
          if (regionsGet.length === sehirlerTamListe.length) {
            setAllCitiesSelected(true);
          }
        }
      })
      .catch((error) => console.log(error));
  }

  function saveSelectedCities() {
    const selectedCitiesArray = [];
    Object.keys(selectedCities).map((key) => {
      if (selectedCities[key]) {
        selectedCitiesArray.push(key);
      }
    });
    axiosInstance.post('region/update_company_service_region_api', {
      regions: selectedCitiesArray
    })
      .then((response) => {
        if (response.data.solarvis_response_status === 'OK') {
          alert('Şehirler başarıyla güncellendi.');
        }
      })
      .catch((error) => console.log(error));
  }

  function fetchLogo() {
    axiosInstance.get('logo/fetch?format=json', {
      responseType: 'blob'
    })
      .then((response) => {
        const file = new Blob(
          [response.data],
          { type: 'image/*' }
        );
        // console.log(response);
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        setLogoImage({ preview: fileURL, name: 'Firma Logosu' });
        return fileURL;
      })
      .catch((error) => console.log(error));
  }

  const handleChangeCityCheckbox = (event) => {
    setSelectedCities((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked
    }));
  };

  const toggleAllCities = () => {
    if (allCitiesSelected) {
      const tempSelectedCities = {};
      sehirlerTamListe.map((city) => {
        tempSelectedCities[city] = false;
      });
      setSelectedCities(tempSelectedCities);
      setAllCitiesSelected(false);
    } else {
      const tempSelectedCities = {};
      sehirlerTamListe.map((city) => {
        tempSelectedCities[city] = true;
      });
      setSelectedCities(tempSelectedCities);
      setAllCitiesSelected(true);
    }
  };

  const handleClickColorPicker = (pickerName) => {
    setCompanyColors({
      ...companyColors,
      [pickerName]: !companyColors[pickerName]
    });
  };

  const handleCloseColorPicker = (pickerName) => {
    setCompanyColors({
      ...companyColors,
      [pickerName]: false
    });
  };

  const handleChangeColorPicker = (pickerName, color) => {
    setCompanyColors({
      ...companyColors,
      [pickerName]: color.hex
    });
  };

  const thumb = (
    <>
      <img
        style={{
          width: '100%',
          marginTop: '10px',
          padding: '5px',
          border: '#dfdfdf 0.5px solid',
          borderRadius: '10px'
        }}
        src={logoImage.preview}
        alt={logoImage.name}
      />
    </>
  );

  const companyColorsComponent = (
    <>
      <Grid
        item
        md={6}
        lg={6}
        xs={6}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{t('ConfigurationPage.renk1')}</span>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
            <div
              style={{
                width: '35px',
                height: '20px',
                backgroundColor: companyColors.primaryColor,
                /* border: '1px solid black', */
                cursor: 'pointer',
                borderRadius: '3px'
              }}
              onClick={() => handleClickColorPicker('displayPrimaryColorPicker')}
            >
              <span> </span>
            </div>
            {companyColors.displayPrimaryColorPicker
              ? (
                <div style={{ position: 'absolute', zIndex: 2 }}>
                  <div
                    style={{
                      position: 'fixed',
                      top: '0px',
                      right: '0px',
                      bottom: '0px',
                      left: '0px'
                    }}
                    onClick={() => handleCloseColorPicker('displayPrimaryColorPicker')}
                  />
                  <ChromePicker color={companyColors.primaryColor} onChange={(color) => handleChangeColorPicker('primaryColor', color)} />
                </div>
              )
              : null}
            <span style={{ marginLeft: '10px' }}>{companyColors.primaryColor}</span>
          </div>
          <Button
            style={{
              textTransform: 'none',
              width: 'fit-content',
              marginTop: '10px',
              backgroundColor: '#003769',
              color: '#ffffff'
            }}
            endIcon={<UndoIcon />}
            size="small"
            variant="contained"
            onClick={() => handleChangeColorPicker('primaryColor', { hex: '#373242' })}
          >
            {t('ConfigurationPage.varsayılan')}
          </Button>
        </div>
      </Grid>
      <Grid
        item
        md={6}
        lg={6}
        xs={6}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{t('ConfigurationPage.renk2')}</span>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
            <div
              style={{
                width: '35px',
                height: '20px',
                backgroundColor: companyColors.secondaryColor,
                /* border: '1px solid black', */
                cursor: 'pointer',
                borderRadius: '3px'
              }}
              onClick={() => handleClickColorPicker('displaySecondaryColorPicker')}
            >
              <span> </span>
            </div>
            {companyColors.displaySecondaryColorPicker
              ? (
                <div style={{ position: 'absolute', zIndex: 2 }}>
                  <div
                    style={{
                      position: 'fixed',
                      top: '30px',
                      right: '0px',
                      bottom: '0px',
                      left: '0px'
                    }}
                    onClick={() => handleCloseColorPicker('displaySecondaryColorPicker')}
                  />
                  <ChromePicker color={companyColors.secondaryColor} onChange={(color) => handleChangeColorPicker('secondaryColor', color)} />
                </div>
              )
              : null}
            <span style={{ marginLeft: '10px' }}>{companyColors.secondaryColor}</span>
          </div>
          <Button
            style={{
              textTransform: 'none',
              width: 'fit-content',
              marginTop: '10px',
              backgroundColor: '#003769',
              color: '#ffffff'
            }}
            endIcon={<UndoIcon />}
            size="small"
            variant="contained"
            onClick={() => handleChangeColorPicker('secondaryColor', { hex: '#653a94' })}
          >
            {t('ConfigurationPage.varsayılan')}
          </Button>
        </div>
      </Grid>
    </>
  );

  const willMount = useRef(true);
  if (willMount.current) {
    fetchLogo();
    getCompanyInfo();
    getServiceRegions();
    willMount.current = false;
  }

  const handleChangeCompanyInfo = (event) => {
    setCompanyInfo({
      ...companyInfo,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Page
      className={classes.root}
      title={t('ConfigurationPage.pageTitle')}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={6}
            md={12}
            xl={6}
            xs={12}
          >
            <AccordionComponent
              accordionTitle={t('ConfigurationPage.firmaRenkleri')}
              accordionContent={(
                <Card
                  className={clsx(classes.cardRoot, className)}
                  {...rest}
                >
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >
                      {companyColorsComponent}
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <ColorUpdateDialog
                      primaryColor={companyColors.primaryColor}
                      secondaryColor={companyColors.secondaryColor}
                    />
                  </CardActions>
                </Card>
              )}
              accordionOpenState={expandSections}
              accordionStateChangeFunc={setExpandSections}
              expandSectionName="companyColors"
            />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <AccordionComponent
              accordionTitle={t('ConfigurationPage.firmaBilgileri')}
              accordionContent={(
                <Card
                  className={clsx(classes.cardRoot, className)}
                  {...rest}
                >
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >
                      {Object.keys(companyInfo).filter((infoType) => infoType !== 'mwAlan').map((infoType) => (
                        <Grid
                          item
                          md={6}
                          lg={6}
                          xs={12}
                          key={infoType}
                        >
                          <TextField
                            key={infoType}
                            fullWidth
                            label={placeholders[infoType]}
                            name={infoType}
                            onChange={handleChangeCompanyInfo}
                            required
                            multiline
                            value={companyInfo[infoType]}
                            variant="outlined"
                            type="text"
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    p={2}
                  >
                    <CompanyInfoSaveDialog
                      companyName={companyInfo.companyName}
                      companyPhone={companyInfo.companyPhone}
                      companyMail={companyInfo.companyMail}
                      companyAddress={companyInfo.companyAddress}
                      mainWeb={companyInfo.mainWeb}
                      contactPerson={companyInfo.contactPerson}
                      vergiDairesi={companyInfo.vergiDairesi}
                      vergiNo={companyInfo.vergiNo}
                      mersisNo={companyInfo.mersisNo}
                    />
                  </Box>
                </Card>
              )}
              accordionOpenState={expandSections}
              accordionStateChangeFunc={setExpandSections}
              expandSectionName="companyInfo"
            />
          </Grid>
          {!(company === 'GreenEffect' || company === 'KSL')
          && (
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <AccordionComponent
              accordionTitle="Hizmet Verilen İller"
              accordionContent={(
                <Card
                  className={clsx(classes.cardRoot, className)}
                  {...rest}
                >
                  <div style={{
                    padding: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'
                  }}
                  >
                    <div>
                      <Checkbox
                        checked={allCitiesSelected}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        onChange={toggleAllCities}
                      />
                      <span>Bütün İlleri Seç</span>
                    </div>
                  </div>
                  <Divider />
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >
                      {selectedCities && Object.keys(selectedCities).map((cityName) => (
                        <div style={{ width: '20%' }}>
                          <Checkbox
                            checked={selectedCities[cityName]}
                            name={cityName}
                            color="primary"
                            onChange={handleChangeCityCheckbox}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          <span>{cityName}</span>
                        </div>
                      ))}
                    </Grid>
                  </CardContent>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    p={2}
                  >
                    <Button
                      color="primary"
                      endIcon={<ArrowRightIcon />}
                      size="small"
                      variant="contained"
                      onClick={saveSelectedCities}
                    >
                      {t('ConfigurationPage.kaydetButon')}
                    </Button>
                  </Box>
                </Card>
              )}
              accordionOpenState={expandSections}
              accordionStateChangeFunc={setExpandSections}
              expandSectionName="companyCities"
            />
          </Grid>
          )}
          {!(company === 'GreenEffect' || company === 'KSL')
          && (
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <AccordionComponent
              accordionTitle={t('ConfigurationPage.digerSecenekler')}
              accordionContent={(
                <Card
                  className={clsx(classes.cardRoot, className)}
                  {...rest}
                >
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                      mt={3}
                    >
                      <Grid
                        item
                        md={3}
                        lg={largeYerlesim}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label={t('ConfigurationPage.alanOranLabel')}
                          name="mwAlan"
                          onChange={handleChangeCompanyInfo}
                          required
                          value={companyInfo.mwAlan}
                          variant="outlined"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">m2</InputAdornment>,
                            endAdornment: (isDesktopOrLaptop ? (
                              <Tooltip title={t('ConfigurationPage.alanOranInfo')}>
                                <InfoIcon />
                              </Tooltip>
                            ) : (
                              <Popup
                                trigger={(
                                  <div>
                                    <InfoIcon />
                                  </div>
                                  )}
                                position="bottom right"
                                closeOnDocumentClick
                              >
                                <span style={{ fontSize: 12 }}>{t('ConfigurationPage.alanOranInfo')}</span>
                              </Popup>
                            )
                            )
                          }}
                          type="number"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    p={2}
                  >
                    <ConfSaveDialog
                      panelOran={companyInfo.mwAlan}
                      forMailGonder={0}
                      forPanelOran={1}
                    />
                  </Box>
                </Card>
              )}
              accordionOpenState={expandSections}
              accordionStateChangeFunc={setExpandSections}
              expandSectionName="otherSettings"
            />
          </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

FirmaBilgileri.propTypes = {
  className: PropTypes.string
};

export default FirmaBilgileri;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loggedIn: false,
  company: localStorage.getItem('company') || null,
  owner: localStorage.getItem('owner') || null,
  email: localStorage.getItem('email') || null,
  timeZone: localStorage.getItem('timeZone') || null,
  language: localStorage.getItem('language') || 'tr',
  loginLanguage: localStorage.getItem('loginLanguage') || 'tr',
  adminInfo: {
    userId: null,
    isCompanySuperUser: false,
    isSubconSuperUser: false,
    adminType: '',
    isSubconCompanyUser: false,
  } || JSON.parse(localStorage.getItem('adminInfo')),
  subconCompanyInfo: {
    name: '',
    id: '',
    code: '',
  } || JSON.parse(localStorage.getItem('subconCompanyInfo')),
};

export const loginSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    changeLogState: (state, action) => {
      state.loggedIn = action.payload.loggedIn;
      localStorage.setItem('loggedIn', action.payload.loggedIn);
    },
    changeCompany: (state, action) => {
      state.company = action.payload.company;
      localStorage.setItem('company', action.payload.company);
    },
    changeOwner: (state, action) => {
      state.owner = action.payload.owner;
      localStorage.setItem('owner', action.payload.owner);
    },
    changeEmail: (state, action) => {
      state.email = action.payload.email;
      localStorage.setItem('email', action.payload.email);
    },
    changeTimeZone: (state, action) => {
      state.timeZone = action.payload.timeZone;
      localStorage.setItem('timeZone', action.payload.timeZone);
    },
    changeLanguage: (state, action) => {
      state.language = action.payload.language;
      localStorage.setItem('language', action.payload.language);
    },
    changeLoginLanguage: (state, action) => {
      state.loginLanguage = action.payload.loginLanguage;
      localStorage.setItem('loginLanguage', action.payload.loginLanguage);
    },
    changeAdminInfo: (state, action) => {
      state.adminInfo = action.payload;
      localStorage.setItem('adminInfo', JSON.stringify(action.payload));
    },
    changeSubconCompanyInfo: (state, action) => {
      state.subconCompanyInfo = action.payload;
      localStorage.setItem('subconCompanyInfo', JSON.stringify(action.payload));
    },
    resetStateAuth: (state, action) => {
      state.loggedIn = action.payload.loggedIn;
      localStorage.removeItem('loggedIn');
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  changeLogState,
  changeCompany,
  changeOwner,
  changeEmail,
  changeTimeZone,
  changeLanguage,
  changeLoginLanguage,
  changeAdminInfo,
  changeSubconCompanyInfo,
  resetStateAuth
} = loginSlice.actions;

export default loginSlice.reducer;

/* eslint-disable react/prop-types */
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Map from './ReactGoogleMapExampleTest';

export default function GoogleMapExample(props) {
  const { mapType, projectId } = props;
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const navigate = useNavigate();

  const { t, } = useTranslation();

  return (
    <>
      <Map
        projectId={projectId}
        mapType={mapType}
        desktop={isDesktopOrLaptop}
        mobile={isTabletOrMobile}
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: mapType === 'address' ? '100%' : '85vh' }} />}
        mapElement={<div style={{ height: '100%' }} />}
        drawAgainButtonText={t('DrawingPage.tekrarCiz')}
        panelText={t('DrawingPage.panel')}
        sifirlaText={t('DrawingPage.sifirla')}
        smallAreaAlertText={t('DrawingPage.smallAreaAlert')}
        navigate={navigate}
      />
    </>
  );
}

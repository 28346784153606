/* eslint-disable max-len */
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  makeStyles,
  withStyles,
  Button,
  TextField,
  Snackbar
} from '@material-ui/core';
import {
  changeCity,
  changeDistrict
} from 'src/services/products/projectReducer';
import Page from 'src/components/Page';
import { useSelector, useDispatch } from 'react-redux';
import axiosInstance from 'src/utils/axiosApi';
// import MaterialUIAutoComplete from 'src/views/designer/GoogleMapViewer/MaterialUIAutoComplete';
import UsePlacesAutoCompleteTest from 'src/views/designer/GoogleMapViewer/UsePlacesAutoCompleteTest';
import GoogleMap from 'src/views/designer/GoogleMapViewer/Map';
import Spinner from 'src/components/Spinner/Spinner';
import MuiAlert from '@material-ui/lab/Alert';
import classes from './newProject.module.css';
import 'reactjs-popup/dist/index.css';
import 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: 0,
    paddingBottom: 0
  },
  /* cardContentRoot: {
    paddingBottom: 0
  } */
}));

const StyledButton = withStyles({
  root: {
    borderRadius: 20,
    marginRight: '1rem',
  }
})(Button);

const StyledTextField = withStyles(() => ({
  root: {
    fontFamily: 'Poppins',
    marginTop: '10px'
  },
}))(TextField);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CreateNewProject = () => {
  const MUIclasses = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    addressSelected,
    city,
    district,
    tempLatitude,
    tempLongitude,
    detailedAddress
  } = useSelector((state) => state.project);

  const [loading, setLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [projectInfo, setProjectInfo] = useState({
    projectName: '',
    address: '',
    customerName: '',
    customerSurname: '',
    customerCompany: ''
  });

  const handleChangeInput = (event) => {
    setProjectInfo({
      ...projectInfo,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeCity = (event) => {
    dispatch(changeCity({ city: event.target.value }));
  };

  const handleChangeDistrict = (event) => {
    dispatch(changeDistrict({ district: event.target.value }));
  };

  const createProject = () => {
    setLoading(true);
    axiosInstance.post('/designer/create_project?format=json', {
      proje_ismi: projectInfo.projectName,
      acik_adres: detailedAddress,
      il: city,
      ilce: district,
      enlem: tempLatitude,
      boylam: tempLongitude,
      musteri_isim: projectInfo.customerName,
      musteri_soy_isim: projectInfo.customerSurname,
      musteri_sirket: projectInfo.customerCompany,
    })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setSnackbarMessage(response.data.message);
          setSnackbarType('success');
          setSnackbarState(true);

          // project id ile yönlendirme yapmak lazım.
          navigate(`/projects/${response.data.project_id}/roof-modelling`, { replace: true });
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setSnackbarMessage('Şu anda bu işlemi gerçekleştiremiyoruz. Lütfen daha sonra tekrar deneyiniz.');
        setSnackbarType('warning');
        setSnackbarState(true);
        /* navigate('/projeler', { replace: true }); */
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  // const largeYerlesim = 3;

  if (loading) {
    return (
      <>
        <Spinner text="Proje oluşturuluyor.." />
      </>
    );
  }

  return (
    <Page
      className={MUIclasses.root}
      title="solarVis | Yeni Proje Oluştur"
    >
      <div className={classes.outerContainer}>
        <div className={classes.appContainer}>
          <div className={classes.loaderContainer}>
            <div className={classes.loaderContainer2}>
              <div className={classes.leftContainer} tabIndex={-1}>
                <div className={classes.leftContainerLayout}>
                  <div className={classes.leftContainerSidebar}>
                    <aside className={classes.sidePanelAside}>
                      <header className={classes.sidePanelHeader}>
                        <div className={classes.headerText}>Proje Bilgileri</div>
                      </header>
                      <div className={classes.sidePanelContent}>
                        <div style={{ marginBottom: '30px' }}>
                          <span style={{ fontSize: '1.1rem' }}>Genel Bilgiler</span>
                          <StyledTextField
                            name="projectName"
                            type="text"
                            value={projectInfo.projectName}
                            /* style={{ width: '150px' }} */
                            autoFocus
                            fullWidth
                            id="projectName"
                            required
                            onChange={handleChangeInput}
                            label="Proje İsmi"
                            /* helperText={projectInfo.projectName === '' ? 'Boş bırakılamaz!' : ''} */
                          />
                          {/* <MaterialUIAutoComplete /> */}
                          <UsePlacesAutoCompleteTest />
                          {city && (
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ paddingRight: '15px' }}>
                              <StyledTextField
                                name="city"
                                type="text"
                                value={city}
                                id="city"
                                required
                                onChange={handleChangeCity}
                                label="İl"
                                /* helperText={projectInfo.projectName === '' ? 'Boş bırakılamaz!' : ''} */
                              />
                            </div>
                            <div style={{ paddingRight: '15px' }}>
                              <StyledTextField
                                name="district"
                                type="text"
                                value={district}
                                id="district"
                                required
                                onChange={handleChangeDistrict}
                                label="İlçe"
                                /* helperText={projectInfo.projectName === '' ? 'Boş bırakılamaz!' : ''} */
                              />
                            </div>
                          </div>
                          )}
                        </div>
                        <div>
                          <span style={{ fontSize: '1.1rem' }}>Müşteri Bilgileri</span>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ paddingRight: '15px' }}>
                              <StyledTextField
                                name="customerName"
                                type="text"
                                value={projectInfo.customerName}
                                /* style={{ width: '100px' }} */
                                id="customerName"
                                required
                                onChange={handleChangeInput}
                                label="İsim"
                              />
                            </div>
                            <div style={{ paddingRight: '15px' }}>
                              <StyledTextField
                                name="customerSurname"
                                type="text"
                                value={projectInfo.customerSurname}
                                /* style={{ paddingLeft: '15px' }} */
                                id="customerSurname"
                                required
                                onChange={handleChangeInput}
                                label="Soy İsim"
                              />
                            </div>
                          </div>
                          <StyledTextField
                            name="customerCompany"
                            type="text"
                            value={projectInfo.customerCompany}
                            /* style={{ width: '150px' }} */
                            fullWidth
                            id="customerCompany"
                            required
                            onChange={handleChangeInput}
                            label="Firma"
                            /* helperText={projectInfo.projectName === '' ? 'Boş bırakılamaz!' : ''} */
                          />
                        </div>
                      </div>
                      <div className={classes.sidePanelButtons}>
                        <div className={classes.buttonWrapper}>
                          <Link to="/projects">
                            <StyledButton variant="outlined" color="primary">
                              İptal
                            </StyledButton>
                          </Link>
                          <StyledButton
                            onClick={createProject}
                            /* disabled={!(
                              projectInfo.projectName
                              && projectInfo.address
                              && projectInfo.customerName
                              && projectInfo.customerSurname
                              && projectInfo.customerCompany
                              && city
                              && district
                              && tempLatitude
                              && tempLongitude
                            )} */
                            variant="contained"
                            color="primary"
                          >
                            Oluştur
                          </StyledButton>
                        </div>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
              <div className={classes.rightContainer}>
                {!addressSelected
                && (
                <div className={classes.rightContainer2}>
                  <div style={{ width: '150px', height: '150px', alignSelf: 'center' }}>
                    <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src="/static/images/map_location.png" alt="xx" />
                  </div>
                  <div className={classes.cardTitle}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <h5 className={classes.cardTitleh5}>Yeni Proje Oluştur</h5>
                      <p className={classes.cardTitlep}>Adresi girdikten sonra proje konumunu harita üzerinden görebileceksiniz.</p>
                    </div>
                  </div>
                </div>
                )}
                {addressSelected
                && (
                  <GoogleMap mapType="address" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default CreateNewProject;

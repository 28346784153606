/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  withStyles,
  DialogContentText,
  Snackbar
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import axiosInstance from 'src/utils/axiosApi';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledDialog = withStyles({
  paper: {
    margin: 16,
    maxWidth: 700,
    minWidth: 300,
  }
})(Dialog);

const StyledDialogTitle = withStyles({
  root: {
    fontSize: 18,
    fontWeight: 'bold'
  }
})(DialogTitle);

export default function AlertDialog(props) {
  const {
    dialogTitle,
    dialogContent,
    openState,
    setOpenState,
    requestURL,
    bodyData,
    requestMethod,
    reloadPageState,
    reloadPageFunc,
  } = props;

  const { t, } = useTranslation();

  const handleClose = () => {
    setOpenState(false);
  };

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  const handleSend = async () => {
    if (requestMethod === 'GET') {
      await axiosInstance.get(requestURL)
        .then((response) => {
          setOpenState(false);
          if (response.data.status) {
            setSnackbarMessage(response.data.message);
            setSnackbarType('success');
            setSnackbarState(true);
            if (reloadPageState) reloadPageFunc(!reloadPageState);
            // alert('İşleminiz başarılı bir şekilde gerçekleştirildi.');
          } else {
            setSnackbarMessage(response.data.message);
            setSnackbarType('warning');
            setSnackbarState(true);
            // alert('Böyle bir kayıt bulunamadı.');
          }
        })
        .catch((error) => {
          setOpenState(false);
          // alert('Şu an bu işlemi gerçekleştiremiyoruz. Lütfen tekrar deneyin.');
          setSnackbarMessage(t('AlertMessage.generalError'));
          setSnackbarType('warning');
          setSnackbarState(true);
          console.log(error);
        });
    }

    if (requestMethod === 'POST') {
      await axiosInstance.post(requestURL, bodyData)
        .then((response) => {
          setOpenState(false);
          if (response.data.status) {
            setSnackbarMessage(response.data.message);
            setSnackbarType('success');
            setSnackbarState(true);
            if (reloadPageState) reloadPageFunc(!reloadPageState);
            // alert('İşleminiz başarılı bir şekilde gerçekleştirildi.');
          } else {
            setSnackbarMessage(response.data.message);
            setSnackbarType('warning');
            setSnackbarState(true);
            // alert('Böyle bir kayıt bulunamadı.');
          }
        })
        .catch((error) => {
          setOpenState(false);
          // alert('Şu an bu işlemi gerçekleştiremiyoruz. Lütfen tekrar deneyin.');
          setSnackbarMessage(t('AlertMessage.generalError'));
          setSnackbarType('warning');
          setSnackbarState(true);
          console.log(error);
        });
    }
  };

  return (
    <div>
      <StyledDialog
        open={openState}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title" disableTypography>{dialogTitle}</StyledDialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" style={{ textTransform: 'none' }}>
            {t('Button.back')}
          </Button>
          <Button onClick={() => handleSend()} color="primary" variant="contained" style={{ textTransform: 'none' }}>
            {t('Button.next')}
          </Button>
        </DialogActions>
      </StyledDialog>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

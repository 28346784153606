import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  MenuItem,
  withStyles,
  Select,
  InputBase
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import { changeLoginLanguage } from 'src/services/users/userReducer';

const StyledSelect = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
})(Select);

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    marginRight: 30,
    backgroundColor: 'whitesmoke',
    borderRadius: 4
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'whitesmoke',
    // border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      // borderColor: '#80bdff',
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles(({
  root: {},
  toolbar: {
    height: 64,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { loginLanguage } = useSelector((state) => state.users);

  const handleChangeLanguage = (event) => {
    dispatch(changeLoginLanguage({ loginLanguage: event.target.value }));
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <StyledSelect
          labelId="Change-language"
          id="change-language"
          value={loginLanguage}
          onChange={handleChangeLanguage}
          label="Language"
          variant="outlined"
          input={<BootstrapInput />}
        >
          <MenuItem value="tr">
            <img
              style={{ width: 20, marginRight: 10 }}
              alt="Turkey"
              src="/static/images/turkey.svg"
            />
            Türkçe
          </MenuItem>
          <MenuItem value="en">
            <img
              style={{ width: 20, marginRight: 10 }}
              alt="US"
              src="/static/images/united-states.svg"
            />
            English
          </MenuItem>
        </StyledSelect>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;

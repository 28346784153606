/* eslint-disable max-len */
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Container,
} from '@material-ui/core';
/* import MuiAlert from '@material-ui/lab/Alert'; */
import GoogleMap from 'src/views/designer/GoogleMapViewer/Map';
import 'reactjs-popup/dist/index.css';
import Page from 'src/components/Page';
import InternalRouterHorizontal from './internalRouterHorizontal';
import 'date-fns';

/* function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
} */

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: 0,
    paddingBottom: 0
  },
  /* cardContentRoot: {
    paddingBottom: 0
  } */
}));

const DrawingPage = () => {
  const { projectId } = useParams();
  const MUIclasses = useStyles();

  // const largeYerlesim = 3;

  return (
    <Page
      className={MUIclasses.root}
      title="solarVis | Proje Çatı Çizimi"
    >
      <InternalRouterHorizontal projectId={projectId} />
      <div style={{
        bottom: 0,
        height: 'auto',
        left: 'calc(6rem + 256px)',
        overflowX: 'auto',
        padding: 0,
        position: 'absolute',
        right: 0,
        top: '88px',
      }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <GoogleMap projectId={projectId} mapType="drawing" />
            </Grid>
          </Grid>
        </Container>
      </div>
    </Page>
  );
};

export default DrawingPage;

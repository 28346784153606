/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Page from 'src/components/Page';
import {
  makeStyles,
  withStyles,
  Grid,
  Container,
  Snackbar,
  Divider,
  Card,
  CardContent,
  Button,
  Switch
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import axiosInstance from 'src/utils/axiosApi';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: 0,
    paddingBottom: 0
  },
}));

const FormInputManagement = (props) => {
  const MUIclasses = useStyles();

  const {
    company
  } = useSelector((state) => state.users);

  const [formInputList, setFormInputList] = useState([]);

  const [shouldReloadPage, setShouldReloadPage] = useState(false);

  const handleChangeFormInputs = (event, formInputId) => {
    // Prevents React from resetting its properties:
    event.persist();
    const newData = formInputList.map((formInput) => {
      if (formInput.id === Number(formInputId)) {
        return {
          ...formInput,
          select_state: event.target.checked
        };
      }
      return formInput;
    });
    setFormInputList(newData);
  };

  const fetchFormInputsOfCompany = () => {
    axiosInstance.get(`input/get_company/?company_code=${company}`)
      .then((response) => {
        const { status } = response.data;
        if (status) {
          const { active_inputs, passive_inputs } = response.data;
          const updatedDataActiveInputs = active_inputs.map((activeInput) => {
            return {
              ...activeInput,
              select_state: true
            };
          });
          const updatedDataPassiveInputs = passive_inputs.map((passiveInput) => {
            return {
              ...passiveInput,
              select_state: false
            };
          });
          setFormInputList([...updatedDataActiveInputs, ...updatedDataPassiveInputs]);
        }
      })
      .catch((error) => console.log(error));
  };

  const updateFormInputSelection = () => {
    const activeFormInputIdList = formInputList.filter((formInput) => formInput.select_state && !formInput.is_must).map((formInput) => formInput.id);
    const passiveFormInputIdList = formInputList.filter((formInput) => !formInput.select_state).map((formInput) => formInput.id);
    axiosInstance.post('input/update_company/', {
      active_inputs: activeFormInputIdList,
      passive_inputs: passiveFormInputIdList,
    })
      .then((response) => {
        const { status } = response.data;
        if (status) {
          setShouldReloadPage(true);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchFormInputsOfCompany();
  }, [shouldReloadPage]);

  return (
    <Page
      className={MUIclasses.root}
      title="solarVis | Doküman Yönetimi"
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <h2 style={{ fontWeight: 'normal' }}>Form Input Yönetimi</h2>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card>
              <div style={{ padding: 16 }}>
                <h4>Kullanıcılardan Talep Ettiğiniz Bilgiler</h4>
              </div>
              <Divider />
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  {formInputList.map((formInput) => (
                    <Grid
                      item
                      lg={6}
                      md={6}
                      xl={6}
                      xs={12}
                    >
                      <Switch
                        checked={formInput.select_state}
                        onChange={(e) => handleChangeFormInputs(e, formInput.id)}
                        disabled={formInput.is_must}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      <span style={{ marginLeft: '10px' }}>{formInput.name_tr}</span>
                    </Grid>
                  ))}
                </Grid>
                <div style={{
                  display: 'flex',
                  marginTop: '30px',
                  justifyContent: 'start'
                }}
                >
                  <Button
                    color="primary"
                    startIcon={<SaveIcon />}
                    size="small"
                    variant="contained"
                    style={{ textTransform: 'none' }}
                    onClick={updateFormInputSelection}
                  >
                    Güncelle
                  </Button>
                </div>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default FormInputManagement;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  detailedAddress: '',
  latitude: 39.9088914,
  longitude: 32.7503243,
  tempLatitude: 39.9088914,
  tempLongitude: 32.7503243,
  city: '',
  district: '',
  markerOn: false,
  zoomLevel: 19,
  addressSelected: false,
  mapFeature: 'manuel',
  polygonPoints: null,
  consumptionProfile: 'sabit'
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    changeLocation: (state, action) => {
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },
    changeTempLocation: (state, action) => {
      state.tempLatitude = action.payload.tempLatitude;
      state.tempLongitude = action.payload.tempLongitude;
    },
    changeDetailedAddress: (state, action) => {
      state.detailedAddress = action.payload.detailedAddress;
    },
    changeCity: (state, action) => {
      state.city = action.payload.city;
    },
    changeDistrict: (state, action) => {
      state.district = action.payload.district;
    },
    changeZoom: (state, action) => {
      state.zoomLevel = action.payload.zoomLevel;
    },
    changeMarker: (state, action) => {
      state.markerOn = action.payload.markerOn;
    },
    changeAddressSelected: (state, action) => {
      state.addressSelected = action.payload.addressSelected;
    },
    changeMapFeature: (state, action) => {
      state.mapFeature = action.payload.mapFeature;
    },
    changePolygonPoints: (state, action) => {
      state.polygonPoints = action.payload.polygonPoints;
    },
    changeConsumptionProfile: (state, action) => {
      state.consumptionProfile = action.payload.consumptionProfile;
    },
    resetState: () => initialState,
  }
});

// Action creators are generated for each case reducer function
export const {
  changeLocation,
  changeZoom,
  changeCity,
  changeDistrict,
  changeMarker,
  changeAddressSelected,
  changeMapFeature,
  changePolygonPoints,
  changeTempLocation,
  changeDetailedAddress,
  changeConsumptionProfile,
  resetState
} = projectSlice.actions;

export default projectSlice.reducer;

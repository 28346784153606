/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import axiosInstance from 'src/utils/axiosApi';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  withStyles,
  makeStyles,
  Snackbar
} from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledDialog = withStyles({
  paper: {
    margin: 16,
    maxWidth: 900,
    minWidth: 300,
  }
})(Dialog);

const StyledDialogTitle = withStyles({
  root: {
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingBottom: 0
  }
})(DialogTitle);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: theme.spacing(3)
  },
  formControl: {
    margin: 0,
    minWidth: 100,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function GeneralDialog(props) {
  const {
    dialogTitle,
    dialogContent,
    requestURL,
    requestURL2,
    requestMethod,
    reloadPageState,
    reloadPageFunc,
    openState,
    setOpenState,
  } = props;

  const { t, } = useTranslation();

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });

  const classes = useStyles();

  const handleClose = () => {
    setOpenState(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  const handleDelete = async () => {
    if (requestMethod === 'DELETE') {
      await axiosInstance.delete(requestURL)
        .then((response) => {
          setOpenState(false);
          if (response.data.status) {
            setSnackbarMessage(response.data.message);
            setSnackbarType('success');
            setSnackbarState(true);
            reloadPageFunc(!reloadPageState);
            // alert('İşleminiz başarılı bir şekilde gerçekleştirildi.');
          } else {
            setSnackbarMessage(response.data.message);
            setSnackbarType('warning');
            setSnackbarState(true);
            // alert('Böyle bir kayıt bulunamadı.');
          }
        })
        .catch((error) => {
          setOpenState(false);
          setSnackbarMessage(t('AlertMessage.generalError'));
          setSnackbarType('warning');
          setSnackbarState(true);
          console.log(error);
        });
    }
    if (requestURL2) {
      await axiosInstance.post(requestURL2)
        .then((response) => {
          setOpenState(false);
          if (response.data.status) {
            reloadPageFunc(!reloadPageState);
            // alert('İşleminiz başarılı bir şekilde gerçekleştirildi.');
          }
        })
        .catch((error) => {
          setOpenState(false);
          console.log(error);
        });
    }
  };

  return (
    <div>
      <StyledDialog
        open={openState}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title" disableTypography>{dialogTitle}</StyledDialogTitle>
        <DialogContent>
          <p style={{ padding: '8px 0' }}>{dialogContent}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" style={{ textTransform: 'none' }}>
            {t('Button.back')}
          </Button>
          <Button
            onClick={() => handleDelete()}
            color="primary"
            variant="contained"
            style={{ textTransform: 'none' }}
          >
            {t('Button.delete')}
          </Button>
        </DialogActions>
      </StyledDialog>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Container,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
  IconButton
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import GeneralAlertDialog from 'src/components/GeneralAlertDialog';
import 'reactjs-popup/dist/index.css';
import Page from 'src/components/Page';
import Spinner from 'src/components/Spinner/Spinner';
import axiosInstance from 'src/utils/axiosApi';
import { format } from 'date-fns';

const StyledIconButton = withStyles({
  root: {
    margin: 2,
    textTransform: 'none',
    fontSize: 16,
    padding: 8,
    backgroundColor: '#e2e5fa'
  }
})(IconButton);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins'
  },
}))(TableCell);

const StyledTableCellHead = withStyles(() => ({
  root: {
    padding: 10,
    fontFamily: 'Poppins',
    fontWeight: 'bold'
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: 0,
    paddingBottom: 0
  },
  /* cardContentRoot: {
    paddingBottom: 0
  } */
}));

const PromotionList = () => {
  const { t, } = useTranslation();
  const MUIclasses = useStyles();
  const [loading, setLoading] = useState(false);
  const [promotionPreviews, setPromotionPreviews] = useState([]);

  const [promotionCodeDeleteState, setPromotionCodeDeleteState] = useState(false);
  const [shouldReloadPage, setShouldReloadPage] = useState(false);

  // const largeYerlesim = 3;

  const fetchAllPromotions = async () => {
    setLoading(true);
    await axiosInstance.get('/home/promotion/fetch?is_all=1&only_public=0&format=json')
      .then((response) => {
        setPromotionPreviews(response.data.data.promotions);
      })
      .catch((error) => console.log(error));
    setLoading(false);
  };

  useEffect(() => {
    fetchAllPromotions();
  }, []);

  if (loading) {
    return (
      <>
        <Spinner text={t('SolarPackagePromotionsPage.loadingPromotion')} />
      </>
    );
  }

  return (
    <Page
      className={MUIclasses.root}
      title={t('SolarPackagePromotionsPage.title')}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <h2 style={{ fontWeight: 'normal' }}>{t('SolarPackagePromotionsPage.yourPromotions')}</h2>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table aria-label="solar-packages-table">
                <TableHead>
                  <TableRow>
                    <StyledTableCellHead align="left">
                      {t('SolarPackagePromotionsPage.name')}
                    </StyledTableCellHead>
                    <StyledTableCellHead align="left">
                      {t('SolarPackagePromotionsPage.code')}
                    </StyledTableCellHead>
                    <StyledTableCellHead align="left">
                      {t('SolarPackagePromotionsPage.amount')}
                    </StyledTableCellHead>
                    <StyledTableCellHead align="left">
                      {t('SolarPackagePromotionsPage.isPublic')}
                    </StyledTableCellHead>
                    <StyledTableCellHead align="left">
                      {t('SolarPackagePromotionsPage.createdDate')}
                    </StyledTableCellHead>
                    <StyledTableCellHead align="center"> </StyledTableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {promotionPreviews.map((promotion) => (
                    <TableRow key={promotion.id}>
                      <StyledTableCell align="left">
                        {promotion.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {promotion.code}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {`${promotion.amount} ${promotion.currency}`}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {promotion.is_public ? t('SolarPackagePromotionsPage.publicUse') : t('SolarPackagePromotionsPage.privateUse')}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {format(new Date(promotion.created_at), 'dd/MM/yyyy HH:mm')}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: '200px' }}>
                        <div style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-around'
                        }}
                        >
                          <Link to={`${promotion.id}/promotion-info`}>
                            <StyledIconButton aria-label="edit" color="primary">
                              <EditIcon />
                            </StyledIconButton>
                          </Link>
                          <GeneralAlertDialog
                            dialogTitle="Promosyonu silmed istediğinize emin misiniz?"
                            dialogContent="Bu geri dönülemez bir işlemdir. Oluşturduğunuz bu promosyon veri tabanımızdan silinecektir."
                            requestURL={`home/promotion/delete?id=${promotion.id}`}
                            requestMethod="GET"
                            bodyData={{}}
                            openState={promotionCodeDeleteState}
                            setOpenState={setPromotionCodeDeleteState}
                            reloadPageState={shouldReloadPage}
                            reloadPageFunc={setShouldReloadPage}
                          />
                        </div>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <div style={{ position: 'fixed', right: '1rem', bottom: '1rem' }}>
            <Link to="new-promotion">
              <Fab color="primary" aria-label="add" variant="extended">
                <AddIcon style={{ marginRight: 5 }} />
                {t('SolarPackagePromotionsPage.createPromotion')}
              </Fab>
            </Link>
          </div>
        </Grid>
      </Container>
    </Page>
  );
};

export default PromotionList;

import React from 'react';
import axiosInstance from 'src/utils/axiosApi';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const StyledDialogTitle = withStyles({
  root: {
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingBottom: 0
  }
})(DialogTitle);

export default function ColorUpdateDialog(props) {
  const { t, } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = () => {
    // http://api.localhost/settings/save?domain=localhost&variables=[mail_gonder|maliyet_x|maliyet_y|maliyet_z|mw_alan|panel_gucu|kayip_yuzdesi|co2_katsayi|agac_katsayi|komisyon_orani]
    axiosInstance.post('/settings/company/save?format=json', {
      primary_color: props.primaryColor,
      secondary_color: props.secondaryColor
    })
      .then((response) => {
        setOpen(false);
        if (response.data.status) {
          window.location.reload();
          alert('İşleminiz başarılı bir şekilde gerçekleştirildi.');
        } else {
          alert('Şu an bu işlemi gerçekleştiremiyoruz. Lütfen tekrar deneyin.');
        }
      })
      .catch((error) => {
        setOpen(false);
        alert('Şu an bu işlemi gerçekleştiremiyoruz. Lütfen tekrar deneyin.');
        console.log(error);
      });
  };

  return (
    <div>
      <Button
        color="primary"
        endIcon={<ArrowRightIcon />}
        size="small"
        variant="contained"
        onClick={handleClickOpen}
      >
        {t('ConfigurationPage.guncelle')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title" disableTypography>{t('ConfigurationPage.renkDegistirmeTitle')}</StyledDialogTitle>
        <DialogContent>
          <p style={{ padding: '8px 0' }}>
            {t('ConfigurationPage.renkDegistirmeMetin')}
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('ConfigurationPage.geriDonButonu')}
          </Button>
          <Button onClick={handleUpdate} color="primary" variant="contained">
            {t('ConfigurationPage.kaydetButon')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ColorUpdateDialog.propTypes = {
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
};

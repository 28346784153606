/* eslint-disable max-len */
import React, { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  withStyles,
  makeStyles,
  Button,
  TextField,
  Grid,
  Container,
  Snackbar
} from '@material-ui/core';
import 'reactjs-popup/dist/index.css';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeCity,
  changeDistrict,
  changeLocation,
  changeTempLocation,
  changeDetailedAddress,
  changeAddressSelected,
  changeMarker
} from 'src/services/products/projectReducer';
import UsePlacesAutoCompleteTest from 'src/views/designer/GoogleMapViewer/UsePlacesAutoCompleteTest';
import GoogleMap from 'src/views/designer/GoogleMapViewer/Map';
import Page from 'src/components/Page';
import Spinner from 'src/components/Spinner/Spinner';
import axiosInstance from 'src/utils/axiosApi';
import MuiAlert from '@material-ui/lab/Alert';
import InternalRouterHorizontal from './internalRouterHorizontal';
import classes from './newProject.module.css';
import 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: 0,
    paddingBottom: 0
  },
  /* cardContentRoot: {
    paddingBottom: 0
  } */
}));

const StyledButton = withStyles({
  root: {
    borderRadius: 20,
    marginRight: '1rem',
  }
})(Button);

const StyledTextField = withStyles(() => ({
  root: {
    fontFamily: 'Poppins',
    marginTop: '10px'
  },
}))(TextField);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ProjeBilgileri = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    addressSelected,
    city,
    district,
    detailedAddress,
    tempLatitude,
    tempLongitude
  } = useSelector((state) => state.project);
  const MUIclasses = useStyles();

  const [loading, setLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [projectInfo, setProjectInfo] = useState({
    projectName: '',
    address: '',
    customerName: '',
    customerSurname: '',
    customerCompany: ''
  });

  const handleChangeCity = (event) => {
    dispatch(changeCity({ city: event.target.value }));
  };

  const handleChangeDistrict = (event) => {
    dispatch(changeDistrict({ district: event.target.value }));
  };

  const handleChangeInput = (event) => {
    setProjectInfo({
      ...projectInfo,
      [event.target.name]: event.target.value
    });
  };

  // const largeYerlesim = 3;

  const fetchProjectInfo = () => {
    setLoading(true);
    axiosInstance.get(`designer/fetch_project_info?project_id=${projectId}&format=json`)
      .then((response) => {
        const projectInfoGet = response.data.data;
        setProjectInfo({
          projectName: projectInfoGet.proje_ismi,
          address: projectInfoGet.acik_adres,
          customerName: projectInfoGet.musteri_isim,
          customerSurname: projectInfoGet.musteri_soy_isim,
          customerCompany: projectInfoGet.musteri_sirket
        });
        dispatch(changeDetailedAddress({ detailedAddress: projectInfoGet.acik_adres }));
        dispatch(changeCity({ city: projectInfoGet.il }));
        dispatch(changeDistrict({ district: projectInfoGet.ilce }));
        dispatch(changeLocation({ latitude: projectInfoGet.enlem, longitude: projectInfoGet.boylam }));
        dispatch(changeTempLocation({ tempLatitude: projectInfoGet.enlem, tempLongitude: projectInfoGet.boylam }));
        dispatch(changeAddressSelected({ addressSelected: true }));
        dispatch(changeMarker({ markerOn: true }));

        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const updateProjectInfo = () => {
    setLoading(true);
    axiosInstance.post('/designer/update_project_info?format=json', {
      project_id: projectId,
      proje_ismi: projectInfo.projectName,
      acik_adres: detailedAddress,
      il: city,
      ilce: district,
      enlem: tempLatitude,
      boylam: tempLongitude,
      musteri_isim: projectInfo.customerName,
      musteri_soy_isim: projectInfo.customerSurname,
      musteri_sirket: projectInfo.customerCompany,
    })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setSnackbarMessage(response.data.message);
          setSnackbarType('success');
          setSnackbarState(true);

          // project id ile yönlendirme yapmak lazım.
          navigate(`/projects/${response.data.project_id}/roof-modelling`, { replace: true });
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setSnackbarMessage('Şu anda bu işlemi gerçekleştiremiyoruz. Lütfen daha sonra tekrar deneyiniz.');
        setSnackbarType('warning');
        setSnackbarState(true);
        /* navigate('/projeler', { replace: true }); */
      });
  };

  const willMount = React.useRef(true);
  if (willMount.current) {
    fetchProjectInfo();
    willMount.current = false;
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  if (loading) {
    return (
      <>
        <Spinner text="Proje bilgileri yükleniyor.." />
      </>
    );
  }

  return (
    <Page
      className={MUIclasses.root}
      title={projectId}
    >
      <InternalRouterHorizontal projectId={projectId} />
      <div style={{
        bottom: 0,
        height: 'auto',
        left: '7rem',
        overflowX: 'auto',
        padding: 0,
        position: 'absolute',
        right: 0,
        top: 0,
      }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            {/* <InternalRouter projectId={projectId} /> */}
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <div className={classes.outerContainer}>
                <div className={classes.appContainer}>
                  <div className={classes.loaderContainer}>
                    <div className={classes.loaderContainer2}>
                      <div className={classes.leftContainer} tabIndex={-1}>
                        <div className={classes.leftContainerLayout}>
                          <div className={classes.leftContainerSidebar}>
                            <aside className={classes.sidePanelAside}>
                              <header className={classes.sidePanelHeader}>
                                <div className={classes.headerText}>Proje Bilgileri</div>
                              </header>
                              <div className={classes.sidePanelContent}>
                                <div style={{ marginBottom: '30px' }}>
                                  <span style={{ fontSize: '1.1rem' }}>Genel Bilgiler</span>
                                  <StyledTextField
                                    name="projectName"
                                    type="text"
                                    value={projectInfo.projectName}
                                    /* style={{ width: '150px' }} */
                                    autoFocus
                                    fullWidth
                                    id="projectName"
                                    required
                                    onChange={handleChangeInput}
                                    label="Proje İsmi"
                                    /* helperText={projectInfo.projectName === '' ? 'Boş bırakılamaz!' : ''} */
                                  />
                                  {/* <MaterialUIAutoComplete /> */}
                                  <UsePlacesAutoCompleteTest />
                                  {city && (
                                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ paddingRight: '15px' }}>
                                      <StyledTextField
                                        name="city"
                                        type="text"
                                        value={city}
                                        id="city"
                                        required
                                        onChange={handleChangeCity}
                                        label="İl"
                                        /* helperText={projectInfo.projectName === '' ? 'Boş bırakılamaz!' : ''} */
                                      />
                                    </div>
                                    <div style={{ paddingRight: '15px' }}>
                                      <StyledTextField
                                        name="district"
                                        type="text"
                                        value={district}
                                        id="district"
                                        required
                                        onChange={handleChangeDistrict}
                                        label="İlçe"
                                        /* helperText={projectInfo.projectName === '' ? 'Boş bırakılamaz!' : ''} */
                                      />
                                    </div>
                                  </div>
                                  )}
                                </div>
                                <div>
                                  <span style={{ fontSize: '1.1rem' }}>Müşteri Bilgileri</span>
                                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ paddingRight: '15px' }}>
                                      <StyledTextField
                                        name="customerName"
                                        type="text"
                                        value={projectInfo.customerName}
                                        /* style={{ width: '100px' }} */
                                        id="customerName"
                                        required
                                        onChange={handleChangeInput}
                                        label="İsim"
                                      />
                                    </div>
                                    <div style={{ paddingRight: '15px' }}>
                                      <StyledTextField
                                        name="customerSurname"
                                        type="text"
                                        value={projectInfo.customerSurname}
                                        /* style={{ paddingLeft: '15px' }} */
                                        id="customerSurname"
                                        required
                                        onChange={handleChangeInput}
                                        label="Soy İsim"
                                      />
                                    </div>
                                  </div>
                                  <StyledTextField
                                    name="customerCompany"
                                    type="text"
                                    value={projectInfo.customerCompany}
                                    /* style={{ width: '150px' }} */
                                    fullWidth
                                    id="customerCompany"
                                    required
                                    onChange={handleChangeInput}
                                    label="Firma"
                                    /* helperText={projectInfo.projectName === '' ? 'Boş bırakılamaz!' : ''} */
                                  />
                                </div>
                              </div>
                              <div className={classes.sidePanelButtons}>
                                <div className={classes.buttonWrapper}>
                                  <Link to="/projects">
                                    <StyledButton variant="outlined" color="primary">
                                      İptal
                                    </StyledButton>
                                  </Link>
                                  <StyledButton
                                    onClick={updateProjectInfo}
                                    /* disabled={!(
                                      projectInfo.projectName
                                      && projectInfo.address
                                      && projectInfo.customerName
                                      && projectInfo.customerSurname
                                      && projectInfo.customerCompany
                                      && city
                                      && district
                                      && tempLatitude
                                      && tempLongitude
                                    )} */
                                    variant="contained"
                                    color="primary"
                                  >
                                    İlerle
                                  </StyledButton>
                                </div>
                              </div>
                            </aside>
                          </div>
                        </div>
                      </div>
                      <div className={classes.rightContainer}>
                        {!addressSelected
                        && (
                        <div className={classes.rightContainer2}>
                          <div style={{ width: '150px', height: '150px', alignSelf: 'center' }}>
                            <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src="/static/images/map_location.png" alt="xx" />
                          </div>
                          <div className={classes.cardTitle}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <h5 className={classes.cardTitleh5}>Yeni Proje Oluştur</h5>
                              <p className={classes.cardTitlep}>Adresi girdikten sonra proje konumunu harita üzerinden görebileceksiniz.</p>
                            </div>
                          </div>
                        </div>
                        )}
                        {addressSelected
                        && (
                          <GoogleMap mapType="address" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default ProjeBilgileri;

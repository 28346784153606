import React, { useState } from 'react';
import axiosInstance from 'src/utils/axiosApi';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledIconButton = withStyles({
  root: {
    margin: 0,
    textTransform: 'none',
    fontSize: 16,
    backgroundColor: '#f7e2e2',
    color: '#ad3737',
    padding: 6
  }
})(IconButton);

const StyledDialogTitle = withStyles({
  root: {
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingBottom: 0
  }
})(DialogTitle);

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);

  const { t, } = useTranslation();

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  const handleDelete = () => {
    axiosInstance.post('malzeme_veritabani/delete?format=json', { malzeme_id: props.malzeme_id })
      .then((response) => {
        setOpen(false);
        if (response.data.status) {
          window.location.reload();
          setSnackbarMessage(response.data.message);
          setSnackbarType('success');
          setSnackbarState(true);
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
        }
      })
      .catch((error) => {
        setOpen(false);
        setSnackbarMessage(t('AlertMessage.generalError'));
        setSnackbarType('warning');
        setSnackbarState(true);
        console.log(error);
      });
  };

  return (
    <div>
      <StyledIconButton aria-label="delete" onClick={handleClickOpen}>
        <DeleteIcon />
      </StyledIconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title" disableTypography>{t('MaterialsPage.deleteMaterailTitle')}</StyledDialogTitle>
        <DialogContent>
          <p style={{ padding: '8px 0' }}>
            {t('MaterialsPage.deleteMaterailDescription')}
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('Button.back')}
          </Button>
          <Button onClick={handleDelete} color="primary" variant="contained" autoFocus>
            {t('Button.delete')}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

AlertDialog.propTypes = {
  malzeme_id: PropTypes.number,
};

/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  withStyles,
  makeStyles,
  Grid,
  Container,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  TableContainer,
  Card,
  CardContent,
  Divider,
  IconButton,
  OutlinedInput,
  FormControl,
  InputAdornment,
  Button
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Spinner from 'src/components/Spinner/Spinner';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import axiosInstance from 'src/utils/axiosApi';

const StyledOutlinedInput = withStyles({
  input: {
    padding: '12px 14px'
  }
})(OutlinedInput);

const StyledIconButton = withStyles({
  root: {
    margin: 2,
    textTransform: 'none',
    fontSize: 16,
    padding: 8,
    backgroundColor: '#e2e5fa'
  }
})(IconButton);

const StyledTableCell = withStyles(() => ({
  root: {
    width: '20%',
    padding: 8,
    fontFamily: 'Poppins'
  },
}))(TableCell);

const StyledTableCellHead = withStyles(() => ({
  root: {
    padding: 10,
    fontFamily: 'Poppins',
    fontWeight: 'bold'
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: 0,
    paddingBottom: 0
  },
  /* cardContentRoot: {
    paddingBottom: 0
  } */
}));

const SubcontractorManagement = () => {
  const { t, } = useTranslation();
  const MUIclasses = useStyles();
  const [loading, setLoading] = useState(false);

  const [subconPreviews, setSubconPreviews] = useState([]);
  const [subconAdminPreviews, setSubconAdminPreviews] = useState([]);

  const {
    adminInfo,
  } = useSelector((state) => state.users);

  // Integrate fetch subcontractor data API and update state
  const fetchSubconData = () => {
    setLoading(true);
    axiosInstance.get('subcon/fetch_subcontractors?is_all=1&id=')
      .then((response) => {
        const { status } = response.data;
        if (status) {
          const { data, message } = response.data;
          setSubconPreviews(data.subcontractors);
        }
      })
      .catch((error) => console.log(error));
    setLoading(false);
  };

  // Integrate fetch subcon admin data API and update state
  const fetchAdminData = () => {
    setLoading(true);
    axiosInstance.get('subcon/fetch_subcon_admins?is_all=1&admin_type=')
      .then((response) => {
        const { status } = response.data;
        if (status) {
          const { data, message } = response.data;
          setSubconAdminPreviews(data.admins);
        }
      })
      .catch((error) => console.log(error));
    setLoading(false);
  };

  useEffect(() => {
    fetchSubconData();
    fetchAdminData();
  }, []);

  if (loading) {
    return (
      <>
        <Spinner text={t('SubcontractorManagementPage.loadingInfo')} />
      </>
    );
  }

  return (
    <Page
      className={MUIclasses.root}
      title={`solarVis | ${t('SubcontractorManagementPage.pageTitle')}`}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <h2 style={{ fontWeight: 'normal' }}>{t('SubcontractorManagementPage.headquarterConfigurationScreen')}</h2>
          </Grid>
          {/* {adminInfo.isCompanySuperUser */}
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card>
              <div style={{
                padding: 16,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
              >
                <h3 style={{ fontWeight: 'normal' }}>{t('SubcontractorManagementPage.registeredSubcontractor')}</h3>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  {/*
                  <FormControl
                  style={{ minWidth: '300px', backgroundColor: 'white' }} variant="outlined">
                    <StyledOutlinedInput
                      id="outlined-adornment-search"
                      type="text"
                      // value={searchWord}
                      // onChange={(e) => setSearchWord(e.target.value)}
                      placeholder={t('SubcontractorManagementPage.searchPlaceholder')}
                      // autoFocus={searchWord.length > 2}
                      endAdornment={(
                        <InputAdornment position="end">
                          <div>
                            <IconButton
                              aria-label="search"
                              edge="end"
                              color="primary"
                            >
                              <SearchIcon />
                            </IconButton>
                          </div>
                        </InputAdornment>
                      )}
                    />
                  </FormControl> */}
                </div>
              </div>
              <Divider />
              <CardContent>
                <TableContainer>
                  <Table aria-label="solar-packages-table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCellHead align="left">
                          {t('SubcontractorManagementPage.subcontractorName')}
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left">
                          {t('SubcontractorManagementPage.subcontractorContactPerson')}
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left">
                          {t('SubcontractorManagementPage.subcontractorCode')}
                        </StyledTableCellHead>
                        <StyledTableCellHead align="center">
                          {t('SubcontractorManagementPage.subcontractorActiveProjects')}
                        </StyledTableCellHead>
                        <StyledTableCellHead align="center">
                          {t('SubcontractorManagementPage.subcontractorCities')}
                        </StyledTableCellHead>
                        <StyledTableCellHead align="center"> </StyledTableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subconPreviews.map((subcontractor) => (
                        <TableRow key={subcontractor.id}>
                          <StyledTableCell align="left">
                            {subcontractor.name}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {subcontractor.authorized_person.isim + ' ' + subcontractor.authorized_person.soy_isim}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {subcontractor.code}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {subcontractor.projectCount}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {subcontractor.cityCount}
                          </StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '200px' }}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-around'
                            }}
                            >
                              <Link to={`sub/${subcontractor.id}`}>
                                <StyledIconButton aria-label="edit" color="primary">
                                  <EditIcon />
                                </StyledIconButton>
                              </Link>
                            </div>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div style={{
                  display: 'flex',
                  marginTop: '30px',
                  justifyContent: 'end'
                }}
                >
                  <Link to="new-subcontractor">
                    <Button
                      color="primary"
                      startIcon={<AddIcon />}
                      size="small"
                      variant="contained"
                    >
                      {t('SubcontractorManagementPage.addNewSubcontractor')}
                    </Button>
                  </Link>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card>
              <div style={{
                padding: 16,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
              >
                <h3 style={{ fontWeight: 'normal' }}>{t('SubcontractorManagementPage.staff')}</h3>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  {/*
                  <FormControl
                  style={{ minWidth: '300px', backgroundColor: 'white' }} variant="outlined">
                    <StyledOutlinedInput
                      id="outlined-adornment-search"
                      type="text"
                      // value={searchWord}
                      // onChange={(e) => setSearchWord(e.target.value)}
                      placeholder={t('SubcontractorManagementPage.searchPlaceholder')}
                      // autoFocus={searchWord.length > 2}
                      endAdornment={(
                        <InputAdornment position="end">
                          <div>
                            <IconButton
                              aria-label="search"
                              edge="end"
                              color="primary"
                            >
                              <SearchIcon />
                            </IconButton>
                          </div>
                        </InputAdornment>
                      )}
                    />
                  </FormControl>
                  */}
                </div>
              </div>
              <Divider />
              <CardContent>
                <TableContainer>
                  <Table aria-label="solar-packages-table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCellHead align="left">
                          {t('SubcontractorManagementPage.staffName')}
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left">
                          {t('SubcontractorManagementPage.staffEmail')}
                        </StyledTableCellHead>
                        <StyledTableCellHead align="left">
                          {t('SubcontractorManagementPage.staffType')}
                        </StyledTableCellHead>
                        <StyledTableCellHead align="center">
                          {t('SubcontractorManagementPage.staffrojects')}
                        </StyledTableCellHead>
                        <StyledTableCellHead align="center"> </StyledTableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subconAdminPreviews.map((adminUser) => (
                        <TableRow key={adminUser.admin_id}>
                          <StyledTableCell align="left">
                            {`${adminUser.isim} ${adminUser.soy_isim}`}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {adminUser.email}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {adminUser.type}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {adminUser.project_count}
                          </StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '200px' }}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-around'
                            }}
                            >
                              <Link to={`user/${adminUser.id}`}>
                                <StyledIconButton aria-label="edit" color="primary">
                                  <EditIcon />
                                </StyledIconButton>
                              </Link>
                            </div>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div style={{
                  display: 'flex',
                  marginTop: '30px',
                  justifyContent: 'end'
                }}
                >
                  <Link to="new-subcon-user">
                    <Button
                      color="primary"
                      startIcon={<AddIcon />}
                      size="small"
                      variant="contained"
                    >
                      {t('SubcontractorManagementPage.addNewStaff')}
                    </Button>
                  </Link>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default SubcontractorManagement;

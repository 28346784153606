/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import axiosInstance from 'src/utils/axiosApi';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  IconButton
} from '@material-ui/core';
import {
  changeStatusIds,
  changeAvailableModules
} from 'src/services/products/moduleReducer';
import {
  resetStateAuth
} from 'src/services/users/userReducer';
import { useMediaQuery } from 'react-responsive';
import InputIcon from '@material-ui/icons/Input';
import Spinner from 'src/components/Spinner/Spinner';
import { useSelector, useDispatch } from 'react-redux';
import NavItem from './NavItem';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  jobTitle: {
    textAlign: 'center'
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' });
  const { t, } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    company,
    owner
  } = useSelector((state) => state.users);

  const [logoImage, setLogoImage] = useState({
    preview: '',
    name: ''
  });

  const user = {
    jobTitle: company,
    name: owner
  };

  const [routesState, setRoutesState] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCompanyRoutes = async () => {
    setLoading(true);
    await axiosInstance.get('crm/funnel/fetch?format=json')
      .then((response) => {
        const {
          modules,
          avail_module_codes,
          status_funnel_code
        } = response.data.data;

        let availableModulesList = [];

        setRoutesState(modules);

        if (avail_module_codes) {
          availableModulesList = avail_module_codes; // ['home', 'leadgen', 'subcon', 'bank'];
          dispatch(changeAvailableModules(availableModulesList));
        }

        dispatch(changeStatusIds(status_funnel_code));
      })
      .catch((error) => console.log(error));
    setLoading(false);
  };

  const fetchLogo = () => {
    axiosInstance.get('logo/fetch?format=json')
      .then((response) => {
        const fileURL = response.data.data.logo_url;
        setLogoImage({ preview: fileURL, name: 'Firma Logosu' });
        return fileURL;
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    fetchLogo();
    fetchCompanyRoutes();
  }, []);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <RouterLink to="/settings/company-info">
          <img
            src={logoImage.preview}
            alt={company}
            style={{
              width: 144,
              height: 80,
              objectFit: 'contain',
              marginBottom: 10,
            }}
          />
        </RouterLink>
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
          className={classes.jobTitle}
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {routesState.map((item) => (
            <NavItem
              href={item.href}
              /* key={item.title} */
              key={item.href}
              title={item.title}
              icon={item.icon}
              collapsable={item.collapsable}
              nestedItems={item.nestedItems}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Divider />
      {!isDesktopOrLaptop && (
        <div
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: 5
          }}
          >
            <span style={{ padding: 10, paddingRight: 0, fontSize: 14 }}>{t('Navbar.cikis')}</span>
            <RouterLink
              onClick={() => {
                dispatch(resetStateAuth({ loggedIn: false }));
                axiosInstance.defaults.headers.Authorization = null;
                localStorage.removeItem('access');
                localStorage.removeItem('refresh');
              }}
              to="/login"
            >
              <IconButton className={classes.iconButton}>
                <InputIcon />
              </IconButton>
            </RouterLink>
          </div>
        </div>
      )}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {loading ? <Spinner text="" /> : content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {loading ? <Spinner text="" /> : content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;

/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-template */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-debugger */
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import i18n from 'src/i18n';
import _, { assign, set } from 'lodash';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';
import {
  withStyles,
  makeStyles,
  Grid,
  Container,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  TableContainer,
  Card,
  CardContent,
  Divider,
  Button,
  IconButton,
  Chip,
  InputBase,
  InputAdornment,
  Snackbar,
  Radio,
  Switch,
  MenuItem,
  Select as MaterialSelect
  /* TextField */
} from '@material-ui/core';
import {
  changeAdminInfo,
  changeSubconCompanyInfo,
} from 'src/services/users/userReducer';
import CancelIcon from '@material-ui/icons/Cancel';
import axiosInstance from 'src/utils/axiosApi';
import GeneralAddDialog from 'src/components/GeneralAddDialog';
import {
  Form,
  Input,
  Upload,
  Select
} from 'antd';
import GeneralAddDialog2 from 'src/components/GeneralAddDialog2';

import GeneralAlertDialog from 'src/components/GeneralAlertDialog';
import GeneralDeleteDialog from 'src/components/GeneralDeleteDialog';
import { useMediaQuery } from 'react-responsive';
import AddIcon from '@material-ui/icons/Add';
import Spinner from 'src/components/Spinner/Spinner';
import download from 'downloadjs';
import ActivityStepper from './activityStepper';
import dummyData from './dummyData';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
      width: '100%'
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: "100%",
    padding: '8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const BootstrapSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    maxWidth: 200,
    width: '100%',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins',
    borderBottom: 'none'
  },
}))(TableCell);

const StyledTableCellWithBorder = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins',
  },
}))(TableCell);

const StyledIconButton = withStyles({
  root: {
    margin: 2,
    textTransform: 'none',
    fontSize: 16,
    padding: 8,
    backgroundColor: '#e2e5fa'
  }
})(IconButton);

const StyledTableCellHead = withStyles(() => ({
  root: {
    width: '25%',
    padding: 8,
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    borderBottom: 'none'
  },
}))(TableCell);

const StyledTableCellHeadWithBorder = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins',
    fontWeight: 'bold',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: 0,
    paddingBottom: 0
  },
  /* cardContentRoot: {
    paddingBottom: 0
  } */
}));

const ManualWidthTableCell = ({
  widthPercentage
}) => {
  return (
    withStyles(() => ({
      root: {
        width: widthPercentage,
        padding: 8,
        fontFamily: 'Poppins',
      },
    }))(TableCell)
  );
};

const CustomerInfo = () => {
  const { Dragger } = Upload;
  const { Option } = Select;
  const { t, } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const MUIclasses = useStyles();
  const { customerId } = useParams();
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' });

  const [pageLoading, setPageLoading] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [dialogOpen1, setDialogOpen1] = useState(false);
  const [newSubcontracorDialogOpen, setNewSubcontracorDialogOpen] = useState(false);
  const [removeSubcontracorDialogOpen, setRemoveSubcontracorDialogOpen] = useState(false);
  const [newDocumentDialogOpen, setNewDocumentDialogOpen] = useState(false);

  const [newAdminUserEmail, setNewAdminUserEmail] = useState('');
  const [newSubcontractor, setNewSubcontractor] = useState('');
  const [newDocumentInfo, setNewDocumentInfo] = useState({
    categoryId: '',
    documentName: '',
    categoryName: '',
    userComment: ''
  });

  const [adminSingleUploadForm] = Form.useForm();

  const [uploadeFile, setUploadeFile] = useState(null);

  // States from the request response
  const [relatedCompanyUsers, setRelatedCompanyUsers] = useState([]);
  const [relatedSubcontractors, setRelatedSubcontractors] = useState({});
  const [availableSubcontractors, setAvailableSubcontractors] = useState([]);
  // const [relatedSubcontractorUsers, setRelatedSubcontractorUsers] = useState([]);
  const [documentCategories, setDocumentCategories] = useState([]);
  React.useEffect(() => {
    console.log(documentCategories);
  }, [documentCategories]);
  const [documentList, setDocumentList] = useState([]);
  const [activities, setActivities] = useState([]);
  const [projectFeasibilityInfo, setProjectFeasibilityInfo] = useState({});

  const [shouldReloadPage, setShouldReloadPage] = useState(true);

  // const [revisionNote, setRevisionNote] = useState('');

  // Home related states
  const [homeAcceptDialogOpen, setHomeAcceptDialogOpen] = useState(false);
  const [homeRejectDialogOpen, setHomeRejectDialogOpen] = useState(false);

  // Solar Package Related States
  const [allPackages, setAllPackages] = useState([]);
  const [sortFeatures, setSortFeatures] = useState({});

  const [packageNotFound, setPackageNotFound] = useState(false);

  const [bestRoiPackageId, setBestRoiPackageId] = useState();
  const [cheapestPackageId, setCheapestPackageId] = useState();

  const [gridType, setGridType] = useState('ON');
  const [filterOrder, setFilterOrder] = useState('roi');

  // State for IBT Solar Proposal
  const [proposalTypeForIBTSolar, setProposalTypeForIBTSolar] = useState('on_grid');
  const [panelNumberForIBTSolar, setPanelNumberForIBTSolar] = useState(0);
  const [projectDCPowerForIBTSolar, setProjectDCPowerForIBTSolar] = useState(0);

  // Credit Application Related States
  const [creditApplicationDocuments, setCreditApplicationDocuments] = useState(dummyData.creditApplicationDocuments);
  const [creditProposals, setCreditProposals] = useState(dummyData.creditProposals);
  const [requestedCreditAmount, setRequestedCreditAmount] = useState(0);
  const [creditApplicationDocumentCategories, setCreditApplicationDocumentCategories] = useState([]);
  const [creditApplicationInfo, setCreditApplicationInfo] = useState({});
  const [creditApplicationInputsByEPC, setCreditApplicationInputsByEPC] = useState({
    maintenanceCost: 0,
    guaranteedProduction: 0
  });

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  const [discountType, setDiscountType] = useState('amount');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discountOn, setDiscountOn] = useState(false);

  const [priceIncreaseType, setPriceIncreaseType] = useState('amount');
  const [priceIncreaseAmount, setPriceIncreaseAmount] = useState(0);
  const [priceIncreasePercentage, setPriceIncreasePercentage] = useState(0);
  const [priceIncreaseOn, setPriceIncreaseOn] = useState(false);

  const [projectCostIncluded, setProjectCostIncluded] = useState(false);
  const [mountingCostIncluded, setMountingCostIncluded] = useState(false);

  const [bankloadFileList, setBankloadFileList] = useState([]);
  const [temporaryContactInfo, setTemporaryContactInfo] = useState({
    isim: '',
    soy_isim: '',
    telefon: '',
  });

  const sortPackages = (allPackages, sortList) => {
    const tempSortedPackages = [];
    sortList.map((packageId) => {
      allPackages.map((solarPackage) => {
        if (Number(solarPackage.id) === Number(packageId)) {
          tempSortedPackages.push(solarPackage);
        }
      });
    });
    return tempSortedPackages;
  };

  const handleChangeNewAdminUserEmail = (event) => {
    setNewAdminUserEmail(event.target.value);
  };

  const handleChangeNewSubcontractor = (event) => {
    setNewSubcontractor(event.target.value);
  };

  const handleChangeProposalTypeForIBTSolar = (event) => {
    setProposalTypeForIBTSolar(event.target.value);
  };

  const bankloadBeforeUpload = (file, fileList) => {
    setBankloadFileList(fileList);
    return false;
  }

  const bankloadFileUploadHandle = (values, docId) => {
    const { file, fileList } = values.bankloadFile;
    if (fileList.length === 0) {
      alert("En az 1 adet dosya yüklemelisiniz.");
    }
    else {

      const body = new FormData();
      const jsonData = {
        musteri_id: projectFeasibilityInfo.id,
        category_id: docId,
        lang:
          localStorage.getItem('language') === 'en' ||
            String(i18n.language).split('-')[0] === 'en'
            ? 'EN'
            : 'TR'
      };
      body.append('json_data_str', JSON.stringify(jsonData));
      body.append('file', file);

      axiosInstance.post("bankloan/companies/upload-file/", body)
        .then((response) => {
          const { message, status } = response.data;
          if (status) {
            // snackkbar message
            setSnackbarMessage(message);
            setSnackbarType('success');
            setSnackbarState(true);

            setBankloadFileList([]);
            handleChangeDialogStatusForCreditAppDocs(docId, 'open_dialog_state', creditApplicationDocumentCategories, setCreditApplicationDocumentCategories)
            window.location.reload();
          }
          else {
            setSnackbarMessage(message);
            setSnackbarType('error');
            setSnackbarState(true);
          }
        }
        ).catch((error) => {
          alert("Dosya yüklenirken bir hata oluştu.");
        }
        );
    }
  }

  const handleChangeDocumentInfo = (event) => {
    // Prevents React from resetting its properties:
    event.persist();
    if (event.target.name === 'categoryName') {
      setNewDocumentInfo((prevState) => ({
        ...prevState,
        categoryId: event.target.value,
        categoryName: event.target.value
      }));
    } else {
      setNewDocumentInfo((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value
      }));
    }
  };

  const assignSubcontractorHandle = (values) => {

    const bodyData = {
      subcon_project_id: projectFeasibilityInfo.subconProjectId,
      subcontractor_code: values.newSubcontractor
    }

    axiosInstance.post('subcon/document/project_APIs/assign_subcontractor_to_project', {
      ...bodyData
    }).then((response) => {
      const { status, message } = response.data;
      if (status) {
        // set show message
        setSnackbarState(true);
        setSnackbarType('success');
        setSnackbarMessage(message);
        setNewSubcontracorDialogOpen(false)
        window.location.reload();

      }
      else {
        // set show message
        setSnackbarState(true);
        setSnackbarType('error');
        setSnackbarMessage(message);
      }
    }).catch((error) => {
      alert("İşlem sırasında bir hata oluştu!")
    }
    );

  }

  const handleChangeTemporaryContactInfo = (event) => {
    // Prevents React from resetting its properties:
    event.persist();
    setTemporaryContactInfo((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const {
    adminInfo,
    company
  } = useSelector((state) => state.users);

  const {
    availableModules,
  } = useSelector((state) => state.module);

  const orderedKeysBasicInfo = [
    'isim',
    'soy_isim',
    'id',
    'telefon',
    'username',
    'tesis_tipi',
    'il',
    'ilce',
    'googleMapURL',
    'lastUpdateStrWithTime',
    'vkn',
    'tckn',
    'customer_firm',
    'branchSelection'
  ];

  const orderedKeysSolarvisCalculation = [
    'dolar',
    'fatura_tutari',
    'sgucu',
    'aylik_tuketim',
    'guc',
    'ac_guc',
    'panel_sayisi',
    'kdvli_usd_maliyet',
    'kdvli_tl_maliyet',
    'yillik_ort_usd_tasrrf',
    'yillik_ort_tl_tasrrf',
    'roi',
    'yillik_uretim',
    'is_battery',
    'is_charger',
    'co2',
    'agac',
    'consumption_profile',
  ];

  const orderedKeysSolarvisCalculationGerman = [
    'guc',
    'ac_guc',
    'aylik_tuketim',
    'panel_sayisi',
    'kdvli_usd_maliyet',
    'yillik_ort_usd_tasrrf',
    'roi',
    'yillik_uretim',
    'is_battery',
    'is_charger',
    'co2',
    'agac',
    'consumption_profile',
    'emptyCell',
  ];

  const orderedKeysForAKSA = [
    "trafoGucu",
    "feasibility_model",
    "esco_months",
    "base_type",
    "calculated_esco_unit_price",
    "tarife",
    "gerilim",
    "terim",
  ];

  // Static Variables
  const creditApplicationStatusLookup = {
    APPROVED: {
      colorCode: '#4caf50',
      text: 'Onaylandı'
    },
    REJECTED: {
      colorCode: '#f44336',
      text: 'Reddedildi'
    },
    APPLIED: {
      colorCode: '#ff9800',
      text: 'Teklif Bekleniyor'
    },
    OFFERED: {
      colorCode: '#2196f3',
      text: 'Teklif Verildi'
    },
    MISSED: {
      colorCode: '#f44336',
      text: 'Müşteri Kaçırıldı'
    },
    NOT_APPLIED: {
      colorCode: '#706f6f',
      text: 'Başvuru Yapılmadı'
    }
  };

  const orderedKeysBasicInfoDesktop = orderedKeysBasicInfo.reduce((rows, key, index) => {
    return (index % 2 === 0 ? rows.push([key])
      : rows[rows.length - 1].push(key)) && rows;
  }, []);

  const orderedKeysSCalcDesktop = orderedKeysSolarvisCalculation.reduce((rows, key, index) => {
    return (index % 2 === 0 ? rows.push([key])
      : rows[rows.length - 1].push(key)) && rows;
  }, []);

  const orderedKeysSCalcDesktopGerman = orderedKeysSolarvisCalculationGerman.reduce((rows, key, index) => {
    return (index % 2 === 0 ? rows.push([key])
      : rows[rows.length - 1].push(key)) && rows;
  }, []);

  const orderedKeysForAKSADesktop = orderedKeysForAKSA.reduce((rows, key, index) => {
    return (index % 2 === 0 ? rows.push([key])
      : rows[rows.length - 1].push(key)) && rows;
  }, []);

  const titleInfo = {
    id: {
      title: 'ID',
      extension: ''
    },
    dolar: {
      title: t('MusteriBilgileriDialog.calculatedCurrencyUSD'),
      extension: ' USD/TRY'
    },
    tesis_tipi: {
      title: t('MusteriBilgileriDialog.tesisTipi'),
      extension: ''
    },
    il: {
      title: t('MusteriBilgileriDialog.il'),
      extension: ''
    },
    ilce: {
      title: t('MusteriBilgileriDialog.ilce'),
      extension: ''
    },
    guc: {
      title: t('MusteriBilgileriDialog.dcGuc'),
      extension: ' kWp'
    },
    ac_guc: {
      title: t('MusteriBilgileriDialog.acGuc'),
      extension: ' kWe'
    },
    alan: {
      title: t('MusteriBilgileriDialog.alan'),
      extension: ' m2'
    },
    isim: {
      title: t('MusteriBilgileriDialog.isim'),
      extension: ''
    },
    soy_isim: {
      title: t('MusteriBilgileriDialog.soyisim'),
      extension: ''
    },
    telefon: {
      title: t('MusteriBilgileriDialog.telefon'),
      extension: ''
    },
    username: {
      title: t('MusteriBilgileriDialog.eposta'),
      extension: ''
    },
    fatura_tutari: {
      title: t('MusteriBilgileriDialog.fatura'),
      extension: ' ₺'
    },
    aylik_tuketim: {
      title: t('MusteriBilgileriDialog.tuketim'),
      extension: ' kWh'
    },
    sgucu: {
      title: t('MusteriBilgileriDialog.sgucu'),
      extension: ' kWe'
    },
    trafoGucu: {
      title: t('MusteriBilgileriDialog.trafoGucu'),
      extension: ' kVA'
    },
    googleMapURL: {
      title: t('MusteriBilgileriDialog.konum'),
      extension: ''
    },
    aci: {
      title: t('MusteriBilgileriDialog.egim'),
      extension: '°'
    },
    panel_sayisi: {
      title: t('MusteriBilgileriDialog.panel'),
      extension: ''
    },
    yillik_uretim: {
      title: t('MusteriBilgileriDialog.uretim'),
      extension: ' MWh'
    },
    kdvli_usd_maliyet: {
      title: t('MusteriBilgileriDialog.maliyetDolar'),
      extension: ' $'
    },
    kdvli_tl_maliyet: {
      title: t('MusteriBilgileriDialog.maliyetTL'),
      extension: ' ₺'
    },
    yillik_ort_usd_tasrrf: {
      title: t('MusteriBilgileriDialog.yillikTasarrufUSD'),
      extension: ' $'
    },
    yillik_ort_tl_tasrrf: {
      title: t('MusteriBilgileriDialog.yillikTasarrufTL'),
      extension: ' ₺'
    },
    roi: {
      title: t('MusteriBilgileriDialog.amortisman'),
      extension: ` ${t('MusteriBilgileriDialog.yil')}`
    },
    lastUpdateStrWithTime: {
      title: t('MusteriBilgileriDialog.zaman'),
      extension: ''
    },
    is_battery: {
      title: t('MusteriBilgileriDialog.batarya'),
      extension: ''
    },
    is_charger: {
      title: t('MusteriBilgileriDialog.sarj'),
      extension: ''
    },
    co2: {
      title: `${t('MusteriBilgileriDialog.onlenen')} CO2`,
      extension: ' ton'
    },
    agac: {
      title: t('MusteriBilgileriDialog.agac'),
      extension: ''
    },
    selected_package_name: {
      title: t('MusteriBilgileriDialog.paketismi'),
      extension: ''
    },
    upfront_cost: {
      title: t('MusteriBilgileriDialog.upfrontCost'),
      extension: ' ₺'
    },
    consumption_profile: {
      title: t('MusteriBilgileriDialog.consumptionProfile'),
      extension: ''
    },
    vkn: {
      title: t('MusteriBilgileriDialog.vkn'),
      extension: ''
    },
    tckn: {
      title: t('MusteriBilgileriDialog.tckn'),
      extension: ''
    },
    customer_firm: {
      title: t('MusteriBilgileriDialog.customerFirm'),
      extension: ''
    },
    branchSelection: {
      title: t('MusteriBilgileriDialog.branchSelection'),
      extension: ''
    },
    emptyCell: {
      title: '',
      extension: ''
    },
    feasibility_model: {
      title: 'Fizibilite Modeli',
      extension: ''
    },
    esco_months: {
      title: 'ESCO Periyodu',
      extension: ' yıl'
    },
    base_type: {
      title: 'GES Tipi',
      extension: ''
    },
    calculated_esco_unit_price: {
      title: 'ESCO Birim Fiyat',
      extension: ' kWh/₺'
    },
    tarife: {
      title: 'Tarife',
      extension: ''
    },
    gerilim: {
      title: 'Gerilim',
      extension: ''
    },
    terim: {
      title: 'Terim',
      extension: ''
    }
  };

  const handleChangeDialogStatus = (id, dialogName, variableList, changeFunc) => {
    // Prevents React from resetting its properties:
    const newData = variableList.map((document) => {
      if (document.user_id === Number(id)) {
        return {
          ...document,
          [dialogName]: !document[dialogName]
        };
      }
      return document;
    });
    changeFunc(newData);
  };

  const assignStaffHandle = (values) => {

    const bodyData = {
      subcon_project_id: projectFeasibilityInfo.subconProjectId,
      new_admin_email: values.newAdminUserEmail
    }

    axiosInstance.post("subcon/document/project_APIs/add_admin_to_project", {
      ...bodyData
    }).then(res => {

      const { status, message } = res.data;
      if (status) {
        setDialogOpen1(false);
        // show message in snackbar
        setSnackbarMessage(message);
        setSnackbarState(true);
        setSnackbarType("success");
        window.location.reload();


      }
      else {
        // show message in snackbar
        setSnackbarMessage(message);
        setSnackbarState(true);
        setSnackbarType("error");
      }
    }).catch(err => {
      alert("Bir hata oluştu")
    })



  }

  const handleChangeConsumption = (newValue, idx) => {
    setProjectFeasibilityInfo({
      ...projectFeasibilityInfo,
      monthly_manual_consumptions: projectFeasibilityInfo.monthly_manual_consumptions.map((item, i) => {
        if (i === idx) {
          return newValue;
        }
        return item;
      })
    });
  };

  const handleChangeDialogStatusForCreditAppDocs = (id, dialogName, variableList, changeFunc) => {
    // Prevents React from resetting its properties:
    const newData = variableList.map((document) => {
      if (document.id === Number(id)) {
        return {
          ...document,
          [dialogName]: !document[dialogName]
        };
      }
      return document;
    });
    console.log(newData);
    changeFunc(newData);
  };

  const fetchProjectFeasibilityInfo = async () => {
    let proposalX = {};
    await axiosInstance.get(`musteri/fetch_feasibility_single_user?musteri_id=${customerId}`)
      .then((response) => {
        const { data, status, message } = response.data;
        if (status) {
          const proposal = data;
          if (String(proposal.ilce) === 'null' || String(proposal.ilce) === 'undefined') {
            proposal.ilce = 'Merkez';
          }

          proposal.googleMapURL = `https://www.google.com/maps/search/?api=1&query=${proposal.enlem},${proposal.boylam}&basemap=satellite`;
          // proposal.image = Promise.resolve(previewImage);
          proposal.createdDate = new Date(Date.parse(proposal.created_at));
          proposal.lastUpdateDate = new Date(Date.parse(proposal.zaman));
          proposal.lastUpdateStr = String(format(new Date(Date.parse(proposal.zaman)), 'dd/MM/yyyy'));
          proposal.lastUpdateStrWithTime = String(format(new Date(Date.parse(proposal.zaman)), 'dd/MM/yyyy - HH.mm'));
          proposal.roi = String(proposal.roi).slice(0, 4);
          proposal.imageAlt = `Müşteri id: ${proposal.id}`;
          if (proposal.hasOwnProperty('subconproject_id')) {
            proposal.subconProjectId = proposal.subconproject_id;
          } else {
            proposal.subconProjectId = null;
          }
          const tempContactInfo = {
            isim: '',
            soy_isim: '',
            telefon: '',
          };
          if (proposal.hasOwnProperty('isim')) {
            tempContactInfo.isim = proposal.isim;
          }
          if (proposal.hasOwnProperty('soy_isim')) {
            tempContactInfo.soy_isim = proposal.soy_isim;
          }
          if (proposal.hasOwnProperty('telefon')) {
            tempContactInfo.telefon = proposal.telefon;
          }
          setTemporaryContactInfo(tempContactInfo);

          proposal.customerId = proposal.id;
          if (proposal.is_battery) {
            proposal.is_battery = t('MusteriBilgileriDialog.var');
          } else {
            proposal.is_battery = t('MusteriBilgileriDialog.yok');
          }
          if (proposal.is_charger) {
            proposal.is_charger = t('MusteriBilgileriDialog.var');
          } else {
            proposal.is_charger = t('MusteriBilgileriDialog.yok');
          }
          proposal.emptyCell = '';
          proposalX = proposal;
          setProjectFeasibilityInfo(proposal);
        } else {
          alert(message);
          navigate('/crm/status-all', { replace: true });
        }
      })
      .catch((error) => console.log(error));

    return proposalX;
  };

  const updateProjectStatusSubcon = (status) => {
    axiosInstance.post('subcon/project/update_status', {
      project_id: projectFeasibilityInfo.subconProjectId,
      status
    })
      .then((response) => {
        const { status } = response.data;
        if (status) {
          const { message } = response.data;
          alert(message);
        }
      })
      .catch((error) => console.log(error));
  };

  const initializeCreditApplication = () => {
    axiosInstance.post('bankloan/companies/initializeProcess/', {
      musteri_id: projectFeasibilityInfo.id,
      credit_request_amount: requestedCreditAmount,
      lang: (localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') ? 'EN' : 'TR'
    })
      .then((response) => {
        if (response.data.status) {
          setSnackbarMessage(response.data.message);
          setSnackbarType('success');
          setSnackbarState(true);
          setShouldReloadPage(!shouldReloadPage);
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
          // alert('Böyle bir kayıt bulunamadı.');
        }
      })
      .catch((error) => {
        setSnackbarMessage(t('AlertMessage.generalError'));
        setSnackbarType('warning');
        setSnackbarState(true);
        console.log(error);
      });
  };

  const updateCreditApplicationInfo = () => {
    const sendBodyData = {};
    const convertKeyLookup = {
      guaranteedProduction: 'performance_warranty',
      maintenanceCost: 'maintenance_cost'
    };

    Object.keys(creditApplicationInputsByEPC).forEach((key) => {
      if (creditApplicationInputsByEPC[key] !== 0) {
        sendBodyData[convertKeyLookup[key]] = Number(creditApplicationInputsByEPC[key]);
      }
    });

    axiosInstance.post('bankloan/companies/handle-project-info/', {
      credit_application_id: creditApplicationInfo.id,
      monthly_manual_consumption: projectFeasibilityInfo.monthly_manual_consumptions.map((item) => Number(item)),
      lang: (localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') ? 'EN' : 'TR',
      ...sendBodyData
    })
      .then((response) => {
        if (response.data.status) {
          setSnackbarMessage(response.data.message);
          setSnackbarType('success');
          setSnackbarState(true);
          setShouldReloadPage(!shouldReloadPage);
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
          // alert('Böyle bir kayıt bulunamadı.');
        }
      })
      .catch((error) => {
        setSnackbarMessage(t('AlertMessage.generalError'));
        setSnackbarType('warning');
        setSnackbarState(true);
        console.log(error);
      });
  };
  const saveTemporaryContactInfo = () => {
    axiosInstance.post('record_musteri_extra_info', {
      musteri_id: projectFeasibilityInfo.customerId,
      isim: temporaryContactInfo.isim,
      soy_isim: temporaryContactInfo.soy_isim,
      telefon: temporaryContactInfo.telefon,
    })
      .then((response) => {
        const { status } = response.data;
        if (status) {
          window.location.reload();
        }
      })
      .catch((error) => console.log(error));
  };

  const fetchProjectDetails = async () => {
    setPageLoading(true);
    let tempActivities = [];

    // Fetch Project Details
    // If project is redirected from project list page, fetch project details from location state, otherwise fetch from API
    const proposalInfo = await fetchProjectFeasibilityInfo();
    // Fetch Admin Info
    await axiosInstance.get('crm/get_admin_permissions?format=json')
      .then((response) => {
        const { data, status, message } = response.data;
        if (status && message === 'User has permission to access the subcontractor module.') {
          const adminInfo = data.admin_info;
          dispatch(changeAdminInfo({
            userId: adminInfo.user_id,
            isCompanySuperUser: adminInfo.is_company_superuser,
            isSubconSuperUser: adminInfo.is_subcon_superuser,
            adminType: adminInfo.admin_type,
            isSubconCompanyUser: adminInfo.is_subcon_company_user,
          }));

          const subconCompanyInfo = adminInfo.subcon_company_info;
          if (subconCompanyInfo) {
            dispatch(changeSubconCompanyInfo(subconCompanyInfo));
          }
        }
      })
      .catch((error) => console.log(error));

    // If this customer has related SubconProject in the database, fetch its details
    if (proposalInfo.subconProjectId) {
      // Fetch Activities
      await axiosInstance.get('activity/render_project_feed_API', {
        params: {
          language: (localStorage.getItem('language') === 'tr' || String(i18n.language).split('-')[0] === 'tr') ? 'TR' : 'EN',
          project_id: proposalInfo.subconProjectId,
          ignored_descriptive_activity_tags: ''
        }
      })
        .then((response) => {
          const { status_code } = response.data;
          if (status_code) {
            const { final_list } = response.data;
            tempActivities = tempActivities.concat(final_list);
          }
        })
        .catch((error) => console.log(error));

      // Fetch Available Document Categories
      await axiosInstance.get('subcon/document/document_category_APIs/admin_get_document_categories')
        .then((response) => {
          const { status } = response.data;
          if (status) {
            const { data } = response.data;
            const updatedData = data.map((document) => {
              return {
                ...document,
                title: document.document_category_name,
                value: document.id
              };
            });
            setDocumentCategories(updatedData);
          }
        })
        .catch((error) => console.log(error));

      // Fetch Related Admin Users
      await axiosInstance.get(`subcon/document/project_APIs/get_subcon_project_info?musteri_id=${customerId}`)
        .then((response) => {
          const { status } = response.data;
          if (status) {
            const { data } = response.data;
            console.log('subcontractor data:', data);
            const companyAdmins = data.company_project_users;
            const updatedData = companyAdmins.map((admin) => {
              return {
                ...admin,
                remove_dialog_open: false,
              };
            });
            setRelatedCompanyUsers(updatedData);
            if (data.project_subcontractor) {
              const relatedSubcontractor = {
                subcontractorName: data.project_subcontractor,
                subcontractorPerson: data.subcontractor_project_users[0].isim + ' ' + data.subcontractor_project_users[0].soy_isim,
                remove_dialog_open: false
              };
              setRelatedSubcontractors(relatedSubcontractor);
            } else {
              setRelatedSubcontractors({});
            }
          }
        })
        .catch((error) => console.log(error));

      // Fetch available subcontractors
      await axiosInstance.get('subcon/fetch_subcontractors?is_all=1&id=47')
        .then((response) => {
          const { status } = response.data;
          if (status) {
            const { data } = response.data;
            const updatedData = data.subcontractors.map((subcontractor) => {
              return {
                ...subcontractor,
                title: subcontractor.name,
                value: subcontractor.code
              };
            });
            setAvailableSubcontractors(updatedData);
          }
        })
        .catch((error) => console.log(error));

      // Fetch Documents
      await axiosInstance.get(`subcon/document/project_APIs/admin_get_project_documents?musteri_id=${customerId}`)
        .then((response) => {
          const { status } = response.data;
          if (status) {
            const { data } = response.data;
            setDocumentList(data);
          }
        })
        .catch((error) => console.log(error));

      // Get Related Activities About Credit Application
      await axiosInstance.get('activity/render_credit_application_feed_API', {
        params: {
          language: (localStorage.getItem('language') === 'tr' || String(i18n.language).split('-')[0] === 'tr') ? 'TR' : 'EN',
          musteri_id: customerId,
          ignored_descriptive_activity_tags: ''
        }
      })
        .then((response) => {
          const { status_code } = response.data;
          if (status_code) {
            const { final_list } = response.data;
            tempActivities = tempActivities.concat(final_list);
          }
        })
        .catch((error) => console.log(error));

      setActivities(tempActivities);
    }

    // Fetch Available Solar Packages
    if (availableModules.includes('home') && (proposalInfo.tesis_tipi === 'Mesken' || proposalInfo.tesis_tipi === 'Residential')) {
      console.log('fetching solar packages...');
      let tempPackagePreviews;
      await axiosInstance.get(`solar_package/fetch_solar_packages?solar_package_ids=&grid_type=&language=${(localStorage.getItem('language') || String(i18n.language).split('-')[0]).toUpperCase()}`)
        .then((response) => {
          tempPackagePreviews = response.data.data;
          if (tempPackagePreviews.length === 0) {
            setPackageNotFound(true);
          }
        })
        .catch((error) => console.log(error));

      let tempSortFeatures;
      await axiosInstance.get(`home/fetch_suitable_packages?grid_type=${gridType}&is_admin_page=1&musteri_id=${customerId}&format=json`)
        .then((response) => {
          const { data } = response.data;
          setSortFeatures(data.sorted_list);
          tempSortFeatures = data.sorted_list;
          setBestRoiPackageId(data.sorted_list.roi[0]);
          setCheapestPackageId(data.sorted_list.kdvli_usd_maliyet[0]);
        })
        .catch((error) => {
          console.log(error);
        });
      const sortedPackageList = sortPackages(tempPackagePreviews, tempSortFeatures[filterOrder]);
      setAllPackages(sortedPackageList);
    }

    setPageLoading(false);
  };

  const fetchCreditApplicationRelatedData = async () => {
    // Check if credit application is initialized
    let isApplicationExists = false;
    await axiosInstance.get(`bankloan/companies/get-users-application?musteri_id=${projectFeasibilityInfo.id}&lang=${(localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') ? 'EN' : 'TR'}`)
      .then((response) => {
        const { data } = response.data;
        if (data) {
          setRequestedCreditAmount(data.credit_request_amount);
          setCreditApplicationInfo(data);
          setCreditApplicationInputsByEPC({
            maintenanceCost: data.maintenance_agreement_cost ? data.maintenance_agreement_cost : 0,
            guaranteedProduction: data.performance_warranty ? data.performance_warranty : 0
          });
          isApplicationExists = true;
        }
      })
      .catch((error) => console.log(error));

    // Fetch Available Document Categories
    if (isApplicationExists) {
      await axiosInstance.get(`bankloan/applications/document-categories?musteri_id=${projectFeasibilityInfo.id}&lang=${(localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') ? 'EN' : 'TR'}`)
        .then((response) => {
          const { data } = response.data;
          const updatedData = data.map((document) => {
            return {
              ...document,
              title: document.document_category_name,
              value: document.id,
              open_dialog_state: false
            };
          });
          setCreditApplicationDocumentCategories(updatedData);
        })
        .catch((error) => console.log(error));
    }
  };

  async function getReportForSolarPackage(solarPackageInformation) {
    // Define report type
    let reportType = 'home_tr';
    let reportName = `Çatı GES Ön Fizibilite Raporu ve Teklif - ${solarPackageInformation.name} - ${solarPackageInformation.dc_guc_kilo_watt} kWp.pdf`;

    if (localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') {
      reportType = 'home_en';
      reportName = `Rooftop SPP Pre-Feasibility Report and Proposal - ${solarPackageInformation.name} - ${solarPackageInformation.dc_guc_kilo_watt} kWp.pdf`;
    }

    if (company === 'solarVis Germany') {
      reportType = 'home_de';
      reportName = `Vorläufiger Machbarkeitsbericht und Vorschlag für SPP auf dem Dach - ${solarPackageInformation.name} - ${solarPackageInformation.dc_guc_kilo_watt} kWp.pdf`;
    }

    if (['solarVis Florida', 'Skywells Energy', 'Lumen United'].includes(company)) {
      reportType = 'home_us';
      reportName = `Rooftop SPP Pre-Feasibility Report and Proposal - ${solarPackageInformation.name} - ${solarPackageInformation.dc_guc_kilo_watt} kWp.pdf`;
    }

    setLoadingReport(true);
    await axiosInstance.get(`report/generate?musteri_id=${customerId}&format=json&is_admin_page=1&report_type=${reportType}&solar_package_id=${solarPackageInformation.id}`, {
      responseType: 'blob'
    })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data],
          { type: 'application/pdf' }
        );
        // Build a URL from the file
        // const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        download(file, reportName,);
      })
      .catch((error) => console.log(error));
    setLoadingReport(false);
  }

  async function getReportForIBTSolar(generateFor) {
    // Define report type
    const reportName = 'Çatı GES Ön Fizibilite Raporu ve Teklif.pdf';

    setLoadingReport(true);
    let requestURL = `special/generate_proposal_ibt_solar?musteri_id=${customerId}&is_admin_page=1&report_type=ibt_tr&package_type=${proposalTypeForIBTSolar}`;
    if (generateFor === 'panelNumber') {
      requestURL += `&panel_number=${panelNumberForIBTSolar}`;
    }
    if (generateFor === 'projectPower') {
      requestURL += `&dc_kwp_power=${projectDCPowerForIBTSolar}`;
    }

    if (discountOn) {
      if (discountType === 'amount') {
        requestURL += `&discount_amount=${discountAmount}`;
      }
      if (discountType === 'percentage') {
        requestURL += `&discount_percentage=${discountPercentage}`;
      }
    }

    if (priceIncreaseOn) {
      if (priceIncreaseType === 'amount') {
        requestURL += `&price_increase_amount=${priceIncreaseAmount}`;
      }
      if (priceIncreaseType === 'percentage') {
        requestURL += `&price_increase_percentage=${priceIncreasePercentage}`;
      }
    }

    if (projectCostIncluded) {
      requestURL += `&project_cost_included=yes`;
    } else {
      requestURL += `&project_cost_included=no`;
    }

    if (mountingCostIncluded) {
      requestURL += `&mounting_cost_included=yes`;
    } else {
      requestURL += `&mounting_cost_included=no`;
    }

    await axiosInstance.get(requestURL, {
      responseType: 'blob'
    })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data],
          { type: 'application/pdf' }
        );
        // Build a URL from the file
        // const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        download(file, reportName,);
      })
      .catch((error) => console.log(error));
    setLoadingReport(false);
  }

  // Send fetch project data request when the states are changed
  // Which states -> newAdminUser, newSubcontractor, newDocument
  useEffect(() => {
    fetchProjectDetails();
  }, [shouldReloadPage, customerId]);

  useEffect(() => {
    if (availableModules.includes('credit_application') && !_.isEmpty(projectFeasibilityInfo)) {
      fetchCreditApplicationRelatedData();
    }
  }, [projectFeasibilityInfo]);

  if (pageLoading) {
    return (
      <>
        <Spinner text={t('SingleCustomerPage.projectInfoLoading')} />
      </>
    );
  }

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const beforeUpload = (file, fileList) => {
    setUploadeFile(file);
    return false;
  };

  const getValueFromEvent = (e) => {
    console.log(e);
    debugger;
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const adminUpdateSingleDocumentProject = (val) => {
    const formData = new FormData();
    const jsonData = {
      file_document_category_id: adminSingleUploadForm.getFieldValue('category'),
      subcon_project_id: projectFeasibilityInfo.subconProjectId,
      user_comment: adminSingleUploadForm.getFieldValue('comment')
    };
    formData.append('json_data_str', JSON.stringify(jsonData));
    formData.append('file', adminSingleUploadForm.getFieldValue('document').file);
    axiosInstance.post('subcon/document/project_APIs/admin_upload_single_project_document', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      const { status, message } = res.data;
      if (status) {
        setNewDocumentDialogOpen(false);
        setSnackbarType('success');
        setSnackbarMessage(message);
        setSnackbarState(true);

        setTimeout(() => {
          setShouldReloadPage(!shouldReloadPage);
        }, 1500);
      } else {
        setSnackbarType('error');
        setSnackbarMessage(message);
        setSnackbarState(true);
      }
    }).catch((err) => {
      alert('Bir problem oluştu');
    });
  };

  if (loadingReport) {
    return (
      <>
        <Spinner text={t('Loader.proposalLoading')} />
      </>
    );
  }

  return (
    <Page
      className={MUIclasses.root}
      title={`solarVis | ${t('SingleCustomerPage.pageTitle')} - ${customerId}`}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
            >
              <h2>{t('SingleCustomerPage.pageTitle')}</h2>
              <Chip label={projectFeasibilityInfo.status_name} color="primary" />
            </div>
          </Grid>
          {/* Essential Info */}
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card>
              <div style={{ padding: 16 }}>
                <h3>{t('SingleCustomerPage.essentialInfo')}</h3>
              </div>
              <Divider />
              <CardContent>
                <TableContainer>
                  <Table aria-label="fundamental-project-info">
                    <TableBody>
                      {isDesktopOrLaptop && !(_.isEmpty(projectFeasibilityInfo)) && orderedKeysBasicInfoDesktop.map((keys) => (
                        <>
                          {(projectFeasibilityInfo[keys[0]] || projectFeasibilityInfo[keys[0]] === 0)
                            && (
                              <TableRow
                                key={projectFeasibilityInfo[keys[0]]}
                              >
                                {(projectFeasibilityInfo[keys[0]] || projectFeasibilityInfo[keys[0]] === 0)
                                  && (
                                    <StyledTableCell>
                                      {titleInfo[keys[0]].title}
                                    </StyledTableCell>
                                  )}
                                <StyledTableCell>
                                  {keys[0] !== 'googleMapURL' && <strong>{projectFeasibilityInfo[keys[0]]}</strong>}
                                  {keys[0] === 'googleMapURL' && <a href={projectFeasibilityInfo[keys[0]]} target="_blank" rel="noreferrer"><strong>{t('MusteriBilgileriDialog.mapsLinki')}</strong></a>}
                                  <span><strong>{titleInfo[keys[0]].extension}</strong></span>
                                </StyledTableCell>
                                {(projectFeasibilityInfo[keys[1]] || projectFeasibilityInfo[keys[1]] === 0)
                                  && (
                                    <StyledTableCell>
                                      {titleInfo[keys[1]].title}
                                    </StyledTableCell>
                                  )}
                                <StyledTableCell>
                                  {keys[1] !== 'googleMapURL' && <strong>{projectFeasibilityInfo[keys[1]]}</strong>}
                                  {keys[1] === 'googleMapURL' && <a href={projectFeasibilityInfo[keys[1]]} target="_blank" rel="noreferrer"><strong>{t('MusteriBilgileriDialog.mapsLinki')}</strong></a>}
                                  <span><strong>{titleInfo[keys[1]].extension}</strong></span>
                                </StyledTableCell>
                              </TableRow>
                            )}
                        </>
                      ))}
                      {!isDesktopOrLaptop && !(_.isEmpty(projectFeasibilityInfo)) && orderedKeysBasicInfo.map((keys) => (
                        <>
                          {(projectFeasibilityInfo[keys] || projectFeasibilityInfo[keys] === 0)
                            && (
                              <TableRow
                                key={projectFeasibilityInfo[keys]}
                              >
                                {(projectFeasibilityInfo[keys] || projectFeasibilityInfo[keys] === 0)
                                  && (
                                    <StyledTableCell>
                                      {titleInfo[keys].title}
                                    </StyledTableCell>
                                  )}
                                <StyledTableCell>
                                  {keys !== 'googleMapURL' && <strong>{projectFeasibilityInfo[keys]}</strong>}
                                  {keys === 'googleMapURL' && <a href={projectFeasibilityInfo[keys]} target="_blank" rel="noreferrer"><strong>{t('MusteriBilgileriDialog.mapsLinki')}</strong></a>}
                                  <span><strong>{titleInfo[keys].extension}</strong></span>
                                </StyledTableCell>
                              </TableRow>
                            )}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          {/* IBT Solar Proposal Management Section */}
          {company === 'IBT Solar Home' && !projectFeasibilityInfo.hasOwnProperty('isim')
            && (
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <Card>
                  <div style={{ padding: 16 }}>
                    <h3>Müşteri İletişim Bilgilerini Güncelle</h3>
                  </div>
                  <Divider />
                  <CardContent style={{ width: "100%" }}>
                    <div style={{ display: 'flex', gap: 5, width: "100%" }} className='updateCustomerInfosComponent'>
                      <StyledTableCell style={{ width: '100%' }}>
                        <p>İsim</p>
                        <BootstrapInput
                          style={{ width: '100%' }}
                          name="isim"
                          type="text"
                          placeholder="İsim"
                          value={temporaryContactInfo.isim}
                          id="isim"
                          inputProps={{ min: 0, step: 1 }}
                          onChange={(e) => handleChangeTemporaryContactInfo(e)}
                        />
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '100%' }}>
                        <p>Soy İsim</p>
                        <BootstrapInput
                          style={{ width: '100%' }}
                          name="soy_isim"
                          type="text"
                          placeholder="Soy İsim"
                          value={temporaryContactInfo.soy_isim}
                          id="soy_isim"
                          inputProps={{ min: 0, step: 1 }}
                          onChange={(e) => handleChangeTemporaryContactInfo(e)}
                        />
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '100%' }}>
                        <p>Telefon</p>
                        <BootstrapInput
                          name="telefon"
                          type="text"
                          style={{ width: '100%' }}
                          placeholder="Telefon"
                          value={temporaryContactInfo.telefon}
                          id="telefon"
                          inputProps={{ min: 0, step: 1 }}
                          onChange={(e) => handleChangeTemporaryContactInfo(e)}
                        />
                      </StyledTableCell>
                    </div>
                    <StyledTableCell>
                      <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        style={{ textTransform: 'none' }}
                        onClick={() => saveTemporaryContactInfo()}
                        disabled={temporaryContactInfo.isim === ''}
                      >
                        Güncelle
                      </Button>
                    </StyledTableCell>
                  </CardContent>
                </Card>
              </Grid>
            )}
          {/* solarVis Calculations */}
          {!(['solarVis Germany', 'solarVis Florida', 'Skywells Energy', 'Lumen United'].includes(company))
            && (
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <Card>
                  <div style={{ padding: 16 }}>
                    <h3>{t('SingleCustomerPage.solarVisCalculations')}</h3>
                  </div>
                  <Divider />
                  <CardContent>
                    <TableContainer>
                      <Table aria-label="solarvis-calculations">
                        <TableBody>
                          {isDesktopOrLaptop && !(_.isEmpty(projectFeasibilityInfo)) && orderedKeysSCalcDesktop.map((keys) => (
                            <>
                              {(projectFeasibilityInfo[keys[0]] || projectFeasibilityInfo[keys[0]] === 0)
                                && (
                                  <TableRow
                                    key={projectFeasibilityInfo[keys[0]]}
                                  >
                                    {(projectFeasibilityInfo[keys[0]] || projectFeasibilityInfo[keys[0]] === 0)
                                      && (
                                        <StyledTableCell>
                                          {titleInfo[keys[0]].title}
                                        </StyledTableCell>
                                      )}
                                    <StyledTableCell>
                                      {keys[0] !== 'googleMapURL' && <strong>{projectFeasibilityInfo[keys[0]]}</strong>}
                                      {keys[0] === 'googleMapURL' && <a href={projectFeasibilityInfo[keys[0]]} target="_blank" rel="noreferrer"><strong>{t('MusteriBilgileriDialog.mapsLinki')}</strong></a>}
                                      <span><strong>{titleInfo[keys[0]].extension}</strong></span>
                                    </StyledTableCell>
                                    {(projectFeasibilityInfo[keys[1]] || projectFeasibilityInfo[keys[1]] === 0)
                                      && (
                                        <StyledTableCell>
                                          {titleInfo[keys[1]].title}
                                        </StyledTableCell>
                                      )}
                                    <StyledTableCell>
                                      {keys[1] !== 'googleMapURL' && <strong>{projectFeasibilityInfo[keys[1]]}</strong>}
                                      {keys[1] === 'googleMapURL' && <a href={projectFeasibilityInfo[keys[1]]} target="_blank" rel="noreferrer"><strong>{t('MusteriBilgileriDialog.mapsLinki')}</strong></a>}
                                      <span><strong>{titleInfo[keys[1]].extension}</strong></span>
                                    </StyledTableCell>
                                  </TableRow>
                                )}
                            </>
                          ))}
                          {!isDesktopOrLaptop && !(_.isEmpty(projectFeasibilityInfo)) && orderedKeysSolarvisCalculation.map((keys) => (
                            <>
                              {(projectFeasibilityInfo[keys] || projectFeasibilityInfo[keys] === 0)
                                && (
                                  <TableRow
                                    key={projectFeasibilityInfo[keys]}
                                  >
                                    {(projectFeasibilityInfo[keys] || projectFeasibilityInfo[keys] === 0)
                                      && (
                                        <StyledTableCell>
                                          {titleInfo[keys].title}
                                        </StyledTableCell>
                                      )}
                                    <StyledTableCell>
                                      {keys !== 'googleMapURL' && <strong>{projectFeasibilityInfo[keys]}</strong>}
                                      {keys === 'googleMapURL' && <a href={projectFeasibilityInfo[keys]} target="_blank" rel="noreferrer"><strong>{t('MusteriBilgileriDialog.mapsLinki')}</strong></a>}
                                      <span><strong>{titleInfo[keys].extension}</strong></span>
                                    </StyledTableCell>
                                  </TableRow>
                                )}
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* roofAreas */}
                    <h4 style={{ marginTop: 20 }}>{t('SingleCustomerPage.roofAreas')}</h4>
                    <TableContainer>
                      <Table aria-label="solarvis-calculations">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell> </StyledTableCell>
                            <StyledTableCell><strong>{t('SingleCustomerPage.area')}</strong></StyledTableCell>
                            <StyledTableCell><strong>{t('SingleCustomerPage.slope')}</strong></StyledTableCell>
                            <StyledTableCell><strong>{t('SingleCustomerPage.aspect')}</strong></StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!(_.isEmpty(projectFeasibilityInfo)) && projectFeasibilityInfo.hasOwnProperty('drawing_points') && projectFeasibilityInfo.drawing_points.map((roofArea, roofAreaIdx) => (
                            <TableRow key={roofAreaIdx}>
                              <StyledTableCell>
                                {roofAreaIdx + 1}
                              </StyledTableCell>
                              <StyledTableCell>
                                {`${roofArea.area} m2`}
                              </StyledTableCell>
                              <StyledTableCell>
                                {`${roofArea.slope} °`}
                              </StyledTableCell>
                              <StyledTableCell>
                                {roofArea.human_readable_aspect}
                              </StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
            )}
          {/* AKSA Solar Special Inputs and Outputs */}
          {company === 'AKSA Solar'
            && (
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <Card>
                  <div style={{ padding: 16 }}>
                    <h3>Diğer Girdiler ve Çıktılar</h3>
                  </div>
                  <Divider />
                  <CardContent>
                    <TableContainer>
                      <Table aria-label="aksa-solar-calculations">
                        <TableBody>
                          {isDesktopOrLaptop && !(_.isEmpty(projectFeasibilityInfo)) && orderedKeysForAKSADesktop.map((keys) => (
                            <>
                              {(projectFeasibilityInfo[keys[0]] || projectFeasibilityInfo[keys[0]] === 0)
                                && (
                                  <TableRow
                                    key={projectFeasibilityInfo[keys[0]]}
                                  >
                                    {(projectFeasibilityInfo[keys[0]] || projectFeasibilityInfo[keys[0]] === 0)
                                      && (
                                        <StyledTableCell>
                                          {titleInfo[keys[0]].title}
                                        </StyledTableCell>
                                      )}
                                    <StyledTableCell>
                                      {keys[0] !== 'googleMapURL' && <strong>{projectFeasibilityInfo[keys[0]]}</strong>}
                                      {keys[0] === 'googleMapURL' && <a href={projectFeasibilityInfo[keys[0]]} target="_blank" rel="noreferrer"><strong>{t('MusteriBilgileriDialog.mapsLinki')}</strong></a>}
                                      <span><strong>{titleInfo[keys[0]].extension}</strong></span>
                                    </StyledTableCell>
                                    {(projectFeasibilityInfo[keys[1]] || projectFeasibilityInfo[keys[1]] === 0)
                                      && (
                                        <StyledTableCell>
                                          {titleInfo[keys[1]].title}
                                        </StyledTableCell>
                                      )}
                                    <StyledTableCell>
                                      {keys[1] !== 'googleMapURL' && <strong>{projectFeasibilityInfo[keys[1]]}</strong>}
                                      {keys[1] === 'googleMapURL' && <a href={projectFeasibilityInfo[keys[1]]} target="_blank" rel="noreferrer"><strong>{t('MusteriBilgileriDialog.mapsLinki')}</strong></a>}
                                      <span><strong>{titleInfo[keys[1]].extension}</strong></span>
                                    </StyledTableCell>
                                  </TableRow>
                                )}
                            </>
                          ))}
                          {!isDesktopOrLaptop && !(_.isEmpty(projectFeasibilityInfo)) && orderedKeysForAKSA.map((keys) => (
                            <>
                              {(projectFeasibilityInfo[keys] || projectFeasibilityInfo[keys] === 0)
                                && (
                                  <TableRow
                                    key={projectFeasibilityInfo[keys]}
                                  >
                                    {(projectFeasibilityInfo[keys] || projectFeasibilityInfo[keys] === 0)
                                      && (
                                        <StyledTableCell>
                                          {titleInfo[keys].title}
                                        </StyledTableCell>
                                      )}
                                    <StyledTableCell>
                                      {keys !== 'googleMapURL' && <strong>{projectFeasibilityInfo[keys]}</strong>}
                                      {keys === 'googleMapURL' && <a href={projectFeasibilityInfo[keys]} target="_blank" rel="noreferrer"><strong>{t('MusteriBilgileriDialog.mapsLinki')}</strong></a>}
                                      <span><strong>{titleInfo[keys].extension}</strong></span>
                                    </StyledTableCell>
                                  </TableRow>
                                )}
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
            )}
          {/* solarVis Calculations - German Only */}
          {['solarVis Germany', 'solarVis Florida', 'Skywells Energy', 'Lumen United'].includes(company)
            && (
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <Card>
                  <div style={{ padding: 16 }}>
                    <h3>{t('SingleCustomerPage.solarVisCalculations')}</h3>
                  </div>
                  <Divider />
                  <CardContent>
                    <TableContainer>
                      <Table aria-label="solarvis-calculations">
                        <TableBody>
                          {isDesktopOrLaptop && !(_.isEmpty(projectFeasibilityInfo)) && orderedKeysSCalcDesktopGerman.map((keys) => (
                            <>
                              {(projectFeasibilityInfo[keys[0]] || projectFeasibilityInfo[keys[0]] === 0)
                                && (
                                  <TableRow
                                    key={projectFeasibilityInfo[keys[0]]}
                                  >
                                    {(projectFeasibilityInfo[keys[0]] || projectFeasibilityInfo[keys[0]] === 0)
                                      && (
                                        <StyledTableCell>
                                          {titleInfo[keys[0]].title}
                                        </StyledTableCell>
                                      )}
                                    <StyledTableCell>
                                      {keys[0] !== 'googleMapURL' && <strong>{projectFeasibilityInfo[keys[0]]}</strong>}
                                      {keys[0] === 'googleMapURL' && <a href={projectFeasibilityInfo[keys[0]]} target="_blank" rel="noreferrer"><strong>{t('MusteriBilgileriDialog.mapsLinki')}</strong></a>}
                                      <span><strong>{titleInfo[keys[0]].extension}</strong></span>
                                    </StyledTableCell>
                                    {(projectFeasibilityInfo[keys[1]] || projectFeasibilityInfo[keys[1]] === 0)
                                      && (
                                        <StyledTableCell>
                                          {titleInfo[keys[1]].title}
                                        </StyledTableCell>
                                      )}
                                    <StyledTableCell>
                                      {keys[1] !== 'googleMapURL' && <strong>{projectFeasibilityInfo[keys[1]]}</strong>}
                                      {keys[1] === 'googleMapURL' && <a href={projectFeasibilityInfo[keys[1]]} target="_blank" rel="noreferrer"><strong>{t('MusteriBilgileriDialog.mapsLinki')}</strong></a>}
                                      <span><strong>{titleInfo[keys[1]].extension}</strong></span>
                                    </StyledTableCell>
                                  </TableRow>
                                )}
                            </>
                          ))}
                          {!isDesktopOrLaptop && !(_.isEmpty(projectFeasibilityInfo)) && orderedKeysSolarvisCalculationGerman.map((keys) => (
                            <>
                              {(projectFeasibilityInfo[keys] || projectFeasibilityInfo[keys] === 0)
                                && (
                                  <TableRow
                                    key={projectFeasibilityInfo[keys]}
                                  >
                                    {(projectFeasibilityInfo[keys] || projectFeasibilityInfo[keys] === 0)
                                      && (
                                        <StyledTableCell>
                                          {titleInfo[keys].title}
                                        </StyledTableCell>
                                      )}
                                    <StyledTableCell>
                                      {keys !== 'googleMapURL' && <strong>{projectFeasibilityInfo[keys]}</strong>}
                                      {keys === 'googleMapURL' && <a href={projectFeasibilityInfo[keys]} target="_blank" rel="noreferrer"><strong>{t('MusteriBilgileriDialog.mapsLinki')}</strong></a>}
                                      <span><strong>{titleInfo[keys].extension}</strong></span>
                                    </StyledTableCell>
                                  </TableRow>
                                )}
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* US Only Data */}
                    {(['solarVis Florida', 'Skywells Energy', 'Lumen United'].includes(company)
                      && projectFeasibilityInfo.hasOwnProperty('utility_provider')
                      && projectFeasibilityInfo.hasOwnProperty('PPA_request')) &&
                      <>
                        <h4 style={{ marginTop: 20 }}>Utility Provider & PPA Request</h4>
                        <TableContainer>
                          <Table aria-label="solarvis-calculations">
                            <TableBody>
                              <TableRow>
                                <StyledTableCell>
                                  Utility Provider
                                </StyledTableCell>
                                <StyledTableCell>
                                  <strong>{projectFeasibilityInfo.utility_provider}</strong>
                                </StyledTableCell>
                                <StyledTableCell>
                                  PPA Request
                                </StyledTableCell>
                                <StyledTableCell>
                                  <strong>{projectFeasibilityInfo.PPA_request ? 'Yes' : 'No'}</strong>
                                </StyledTableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>}
                    {/* roofAreas */}
                    <h4 style={{ marginTop: 20 }}>{t('SingleCustomerPage.roofAreas')}</h4>
                    <TableContainer>
                      <Table aria-label="solarvis-calculations">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell> </StyledTableCell>
                            <StyledTableCell><strong>{t('SingleCustomerPage.area')}</strong></StyledTableCell>
                            <StyledTableCell><strong>{t('SingleCustomerPage.slope')}</strong></StyledTableCell>
                            <StyledTableCell><strong>{t('SingleCustomerPage.aspect')}</strong></StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!(_.isEmpty(projectFeasibilityInfo)) && projectFeasibilityInfo.hasOwnProperty('drawing_points') && projectFeasibilityInfo.drawing_points.map((roofArea, roofAreaIdx) => (
                            <TableRow key={roofAreaIdx}>
                              <StyledTableCell>
                                {roofAreaIdx + 1}
                              </StyledTableCell>
                              <StyledTableCell>
                                {`${roofArea.area} m2`}
                              </StyledTableCell>
                              <StyledTableCell>
                                {`${roofArea.slope} °`}
                              </StyledTableCell>
                              <StyledTableCell>
                                {roofArea.human_readable_aspect}
                              </StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
            )}
          {/* IBT Solar Proposal Management Section */}
          {company === 'IBT Solar Home'
            && (
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <Card>
                  <div style={{ padding: 16 }}>
                    <h3>Teklif Üret</h3>
                  </div>
                  <Divider />
                  <CardContent>
                    <div className='generateOffer'>
                      <div className="teklifTypeRadioButtons">
                        <StyledTableCell style={{ width: "100%" }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Radio
                              checked={proposalTypeForIBTSolar === 'on_grid'}
                              onChange={handleChangeProposalTypeForIBTSolar}
                              value="on_grid"
                              name="on_grid"
                              inputProps={{ 'aria-label': 'A' }}
                              color="primary"
                            />
                            <span>On-Grid</span>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "100%" }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Radio
                              checked={proposalTypeForIBTSolar === 'off_grid'}
                              onChange={handleChangeProposalTypeForIBTSolar}
                              value="off_grid"
                              name="off_grid"
                              inputProps={{ 'aria-label': 'A' }}
                              color="primary"
                            />
                            <span>Off-Grid</span>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "100%" }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Radio
                              checked={proposalTypeForIBTSolar === 'hybrid_grid_with_battery'}
                              onChange={handleChangeProposalTypeForIBTSolar}
                              value="hybrid_grid_with_battery"
                              name="hybrid_grid_with_battery"
                              inputProps={{ 'aria-label': 'A' }}
                              color="primary"
                            />
                            <span>Hibrit Akülü</span>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "100%" }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Radio
                              checked={proposalTypeForIBTSolar === 'hybrid_grid_without_battery'}
                              onChange={handleChangeProposalTypeForIBTSolar}
                              value="hybrid_grid_without_battery"
                              name="hybrid_grid_without_battery"
                              inputProps={{ 'aria-label': 'A' }}
                              color="primary"
                            />
                            <span>Hibrit Aküsüz</span>
                          </div>
                        </StyledTableCell>
                      </div>
                      {/* <Divider orientation="vertical" /> */}
                      <div className='kwhOrQuantity'>
                        <StyledTableCell>
                          <BootstrapInput
                            name="panelNumberForIBTSolar"
                            type="number"
                            value={panelNumberForIBTSolar}
                            id="panelNumberForIBTSolar"
                            inputProps={{ min: 0, step: 1 }}
                            style={{ width: '150px' }}
                            onChange={(e) => setPanelNumberForIBTSolar(e.target.value)}
                            endAdornment={<InputAdornment position="end">adet</InputAdornment>}
                          />
                        </StyledTableCell>


                        {/* <Divider orientation="vertical" /> */}
                        <StyledTableCell>
                          <BootstrapInput
                            name="projectDCPowerForIBTSolar"
                            type="number"
                            value={projectDCPowerForIBTSolar}
                            id="projectDCPowerForIBTSolar"
                            inputProps={{ min: 0, step: 1 }}
                            style={{ width: '150px' }}
                            onChange={(e) => setProjectDCPowerForIBTSolar(e.target.value)}
                            endAdornment={<InputAdornment position="end">kWp</InputAdornment>}
                          />
                        </StyledTableCell>
                      </div>
                      <div className='generateOfferButtons'>
                        <StyledTableCell style={{ width: "100%" }}>
                          <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            style={{ textTransform: 'none' }}
                            onClick={() => getReportForIBTSolar('panelNumber')}
                            disabled={panelNumberForIBTSolar === 0 || panelNumberForIBTSolar === ''}
                          >
                            Panel Sayısına Göre Teklif Üret
                          </Button>
                        </StyledTableCell>

                        <StyledTableCell style={{ width: "100%" }}>
                          <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            style={{ textTransform: 'none' }}
                            onClick={() => getReportForIBTSolar('projectPower')}
                            disabled={projectDCPowerForIBTSolar === 0 || projectDCPowerForIBTSolar === ''}
                          >
                            Proje Gücüne Göre Teklif Üret
                          </Button>
                        </StyledTableCell>
                      </div>
                    </div>
                    <Divider />
                    <div style={{ display: 'flex', flexDirection: isDesktopOrLaptop ? 'row' : 'column', margin: '10px 0px' }}>
                      <div style={isDesktopOrLaptop ? { width: '50%' } : {}}>
                        <Switch
                          checked={projectCostIncluded}
                          onChange={(e) => setProjectCostIncluded(e.target.checked)}
                          color="primary"
                          name="projectCostIncluded"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <span style={{ fontSize: '0.875rem' }}>Projelendirme ve Onay Sürecini Fiyata Dahil Et</span>
                      </div>
                      <div>
                        <Switch
                          checked={mountingCostIncluded}
                          onChange={(e) => setMountingCostIncluded(e.target.checked)}
                          color="primary"
                          name="mountingCostIncluded"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <span style={{ fontSize: '0.875rem' }}>Montajı Fiyata Dahil Et</span>
                      </div>
                    </div>
                    <Divider />
                    <div style={{ display: 'flex', flexDirection: isDesktopOrLaptop ? 'row' : 'column', margin: '10px 0px' }}>
                      {!adminInfo.isSubconCompanyUser &&
                        <div style={{ display: 'flex', flexDirection: 'column', width: isDesktopOrLaptop ? '50%' : '100%' }}>
                          <StyledTableCell>
                            <div>
                              <Switch
                                checked={discountOn}
                                onChange={(e) => setDiscountOn(e.target.checked)}
                                color="primary"
                                name="discountOn"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                              />
                              <span>İskonto Uygula</span>
                            </div>
                          </StyledTableCell>
                          <div className='iskontoSelect'>
                            {discountOn && (
                              <StyledTableCell>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <span style={{ fontWeight: 'bold', margin: '4px 0px' }}>İskonto Tipi</span>
                                  <MaterialSelect
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={discountType}
                                    name="discountType"
                                    displayEmpty
                                    onChange={(e) => setDiscountType(e.target.value)}
                                    input={<BootstrapSelect />}
                                  >
                                    {[{ title: 'Miktar', value: 'amount' }, { title: 'Yüzdelik', value: 'percentage' }].map((choice) => (
                                      <MenuItem value={choice.value}>{choice.title}</MenuItem>
                                    ))}
                                  </MaterialSelect>
                                </div>
                              </StyledTableCell>
                            )}
                            {discountType === 'amount' && discountOn && (
                              <StyledTableCell>
                                <BootstrapInput
                                  name="discountAmount"
                                  type="number"
                                  value={discountAmount}
                                  id="discountAmount"
                                  inputProps={{ min: 0, step: 1 }}
                                  onChange={(e) => setDiscountAmount(e.target.value)}
                                  endAdornment={<InputAdornment position="end">$</InputAdornment>}
                                />
                              </StyledTableCell>
                            )}
                            {discountType === 'percentage' && discountOn && (
                              <StyledTableCell>
                                <BootstrapInput
                                  name="discountPercentage"
                                  type="number"
                                  value={discountPercentage}
                                  id="discountPercentage"
                                  inputProps={{ min: 0, step: 1, max: 100 }}
                                  onChange={(e) => setDiscountPercentage(e.target.value)}
                                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                />
                              </StyledTableCell>
                            )}
                          </div>
                        </div>}
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <StyledTableCell>
                          <div>
                            <Switch
                              checked={priceIncreaseOn}
                              onChange={(e) => setPriceIncreaseOn(e.target.checked)}
                              color="primary"
                              name="priceIncreaseOn"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <span>Fiyat Artırımı Uygula</span>
                          </div>
                        </StyledTableCell>
                        <div className='iskontoSelect'>
                          {priceIncreaseOn && (
                            <StyledTableCell>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span style={{ fontWeight: 'bold', margin: '4px 0px' }}>Fiyat Artırımı Tipi</span>
                                <MaterialSelect
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={priceIncreaseType}
                                  name="priceIncreaseType"
                                  displayEmpty
                                  onChange={(e) => setPriceIncreaseType(e.target.value)}
                                  input={<BootstrapSelect />}
                                >
                                  {[{ title: 'Miktar', value: 'amount' }, { title: 'Yüzdelik', value: 'percentage' }].map((choice) => (
                                    <MenuItem value={choice.value}>{choice.title}</MenuItem>
                                  ))}
                                </MaterialSelect>
                              </div>
                            </StyledTableCell>
                          )}
                          {priceIncreaseType === 'amount' && priceIncreaseOn && (
                            <StyledTableCell>
                              <BootstrapInput
                                name="priceIncreaseAmount"
                                type="number"
                                value={priceIncreaseAmount}
                                id="priceIncreaseAmount"
                                inputProps={{ min: 0, step: 1 }}
                                onChange={(e) => setPriceIncreaseAmount(e.target.value)}
                                endAdornment={<InputAdornment position="end">$</InputAdornment>}
                              />
                            </StyledTableCell>
                          )}
                          {priceIncreaseType === 'percentage' && priceIncreaseOn && (
                            <StyledTableCell>
                              <BootstrapInput
                                name="priceIncreasePercentage"
                                type="number"
                                value={priceIncreasePercentage}
                                id="priceIncreasePercentage"
                                inputProps={{ min: 0, step: 1, max: 100 }}
                                onChange={(e) => setPriceIncreasePercentage(e.target.value)}
                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                              />
                            </StyledTableCell>
                          )}
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            )}
          {/* Selected Package Info */}
          {availableModules.includes('home') && (projectFeasibilityInfo.tesis_tipi === 'Mesken' || projectFeasibilityInfo.tesis_tipi === 'Residential') && projectFeasibilityInfo.status === 6
            && (
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <Card>
                  <div style={{ padding: 16 }}>
                    <h3>{t('SingleCustomerPage.solarpackageInfo')}</h3>
                  </div>
                  <Divider />
                  <CardContent>
                    {!projectFeasibilityInfo.hasOwnProperty('selected_package_name') && (<div style={{ padding: 16, textAlign: 'center' }}>{t('SingleCustomerPage.noPackageSelected')}</div>)}
                    {projectFeasibilityInfo.hasOwnProperty('selected_package_name') && (
                      <TableContainer>
                        <Table aria-label="solar-package-info">
                          <TableBody>
                            <TableRow key="package-name">
                              <StyledTableCell align="left">
                                {t('MusteriBilgileriDialog.paketismi')}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <strong>{projectFeasibilityInfo.selected_package_name}</strong>
                              </StyledTableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            )}
          {/* Available Packages, Package Feasibilities & Generate Proposal for Different Packages  */}
          {availableModules.includes('home') && (projectFeasibilityInfo.tesis_tipi === 'Mesken' || projectFeasibilityInfo.tesis_tipi === 'Residential')
            && (
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <Card>
                  <div style={{ padding: 16 }}>
                    <h3>{t('SingleCustomerPage.availableSolarPackages')}</h3>
                  </div>
                  <Divider />
                  <CardContent>
                    {packageNotFound && (<div style={{ padding: 16, textAlign: 'center' }}>Uygun paket bulunamamıştır. Lütfen bizimle iletişime geçin.</div>)}
                    {!packageNotFound && (
                      <TableContainer>
                        <Table aria-label="solar-package-info">
                          <TableHead>
                            <TableRow>
                              <StyledTableCellHead align="left">
                                {t('SolarPackagesPage.packageName')}
                              </StyledTableCellHead>
                              <StyledTableCellHead align="left">
                                {t('SolarPackagesPage.dcPower')}
                              </StyledTableCellHead>
                              <StyledTableCellHead align="left">
                                {t('SolarPackagesPage.packagePrice')}
                              </StyledTableCellHead>
                              <StyledTableCellHead align="left"> </StyledTableCellHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {allPackages.map((solarPackage) => (
                              <TableRow key={solarPackage.id}>
                                <StyledTableCell align="left">
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      flexDirection: 'row'
                                    }}
                                  >
                                    <span>{solarPackage.name}</span>
                                    {bestRoiPackageId === solarPackage.id && (
                                      <Chip
                                        style={{
                                          boxShadow: '1px 1px 5px 1px #3f51b5',
                                          height: '30px',
                                          marginLeft: '20px'
                                        }}
                                        label={t('SolarPackagesPage.bestROI')}
                                        color="primary"
                                        size="medium"
                                      />
                                    )}
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {`${solarPackage.dc_guc_kilo_watt} kWp`}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {`${solarPackage.price} ${solarPackage.currency}`}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <div
                                    onClick={() => (
                                      getReportForSolarPackage(solarPackage)
                                    )}
                                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                  >
                                    {t('SingleCustomerPage.generateProposal')}
                                  </div>
                                </StyledTableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            )}
          {/* Payment Info */}
          {availableModules.includes('home') && (projectFeasibilityInfo.tesis_tipi === 'Mesken' || projectFeasibilityInfo.tesis_tipi === 'Residential') && projectFeasibilityInfo.status === 7
            && (
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <Card>
                  <div style={{ padding: 16 }}>
                    <h3>{t('SingleCustomerPage.paymentInfo')}</h3>
                  </div>
                  <Divider />
                  <CardContent>
                    {!projectFeasibilityInfo.hasOwnProperty('order') && (<div style={{ padding: 16, textAlign: 'center' }}>{t('SingleCustomerPage.noPaymentInfo')}</div>)}
                    {projectFeasibilityInfo.hasOwnProperty('order') && (
                      <TableContainer>
                        <Table aria-label="spayment-info">
                          <TableBody>
                            <TableRow key="purchase-time">
                              <StyledTableCell align="left">
                                {t('SingleCustomerPage.paymentDate')}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <strong>{String(format(new Date(Date.parse(projectFeasibilityInfo.order.purchase_time)), 'dd/MM/yyyy - HH.mm'))}</strong>
                              </StyledTableCell>
                            </TableRow>
                            <TableRow key="order-type">
                              <StyledTableCell align="left">
                                {t('SingleCustomerPage.paymentType')}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {projectFeasibilityInfo.order.payment_type === 'P' && <strong>{t('SingleCustomerPage.shoppingCredit')}</strong>}
                                {projectFeasibilityInfo.order.payment_type === 'C' && <strong>{t('SingleCustomerPage.creditCard')}</strong>}
                              </StyledTableCell>
                            </TableRow>
                            <TableRow key="order-amount">
                              <StyledTableCell align="left">
                                {`${t('SingleCustomerPage.paymentAmount')} ₺`}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <strong>{projectFeasibilityInfo.order.price_TL_kdv}</strong>
                              </StyledTableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            )}
          {/* Subcon Related Company Staff */}
          {availableModules.includes('subcon_detailed') && (
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Card>
                <div style={{ padding: 16 }}>
                  <h3>{t('SingleCustomerPage.relatedCompanyStaff')}</h3>
                </div>
                <Divider />
                <CardContent>
                  {relatedCompanyUsers.length === 0 && (<div style={{ padding: 16, textAlign: 'center' }}>{t('SingleCustomerPage.noStaffAssigned')}</div>)}
                  {relatedCompanyUsers.length > 0 && (
                    <TableContainer>
                      <Table aria-label="solar-packages-table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCellHead align="left">
                              {t('SingleCustomerPage.name')}
                            </StyledTableCellHead>
                            <StyledTableCellHead align="left">
                              {t('SingleCustomerPage.team')}
                            </StyledTableCellHead>
                            <StyledTableCellHead align="left">
                              {t('SingleCustomerPage.dateofAssignment')}
                            </StyledTableCellHead>
                            <StyledTableCellHead align="left"> </StyledTableCellHead>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {relatedCompanyUsers.map((user) => (
                            <TableRow key={user.user_id}>
                              <StyledTableCell align="left">
                                {`${user.isim} ${user.soy_isim}`}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {user.admin_type}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {String(format(new Date(Date.parse(user.added_date)), 'dd/MM/yyyy - HH.mm'))}
                              </StyledTableCell>
                              {adminInfo.isCompanySuperUser
                                && (
                                  <StyledTableCell align="left" style={{ width: '200px' }}>
                                    <div style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'space-around'
                                    }}
                                    >
                                      <StyledIconButton
                                        aria-label="edit"
                                        color="primary"
                                        onClick={() => handleChangeDialogStatus(user.user_id, 'remove_dialog_open', relatedCompanyUsers, setRelatedCompanyUsers)}
                                      >
                                        <CancelIcon />
                                      </StyledIconButton>
                                      <GeneralDeleteDialog
                                        dialogTitle="Admin Kaldır"
                                        requestURL={`subcon/document/project_APIs/remove_admin_from_project?subcon_project_id=${projectFeasibilityInfo.subconProjectId}&removed_admin_email=${user.user_email}`}
                                        reloadPageState={shouldReloadPage}
                                        reloadPageFunc={setShouldReloadPage}
                                        dialogContent="Admin kaldırılacak"
                                        requestMethod="DELETE"
                                        openState={user.remove_dialog_open}
                                        setOpenState={() => handleChangeDialogStatus(user.user_id, 'remove_dialog_open', relatedCompanyUsers, setRelatedCompanyUsers)}
                                      />
                                    </div>
                                  </StyledTableCell>
                                )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  {adminInfo.isCompanySuperUser
                    && (
                      <div style={{
                        display: 'flex',
                        marginTop: '10px',
                        justifyContent: 'end'
                      }}
                      >
                        <Button
                          color="primary"
                          startIcon={<AddIcon />}
                          size="small"
                          variant="contained"
                          style={{ textTransform: 'none' }}
                          onClick={() => setDialogOpen1(true)}
                        >
                          {t('SingleCustomerPage.assignStaff')}
                        </Button>
                        {/* <GeneralAddDialog
                    dialogTitle={t('SingleCustomerPage.assignStaff')}
                    requestURL="subcon/document/project_APIs/add_admin_to_project"
                    requestMethod="POST"
                    reloadPageState={shouldReloadPage}
                    reloadPageFunc={setShouldReloadPage}
                    openState={dialogOpen1}
                    setOpenState={setDialogOpen1}
                    dontDisableButton
                    bodyData={{
                      subcon_project_id: projectFeasibilityInfo.subconProjectId,
                      new_admin_email: newAdminUserEmail,
                    }}
                    inputs={[
                      {
                        title: t('SingleCustomerPage.staffEmail'),
                        value: newAdminUserEmail,
                        type: 'text',
                        name: 'userEmail',
                      }
                    ]}
                    changeDataFunction={handleChangeNewAdminUserEmail}
                  /> */}

                        <GeneralAddDialog2
                          title={t('SingleCustomerPage.assignStaff')}
                          modalShow={dialogOpen1}
                          setModalShow={() => setDialogOpen1(false)}
                          initialValues={{
                            newAdminUserEmail: null
                          }}
                          handle={assignStaffHandle}
                        >
                          <Form.Item name={'newAdminUserEmail'} rules={[{ required: true, message: t("ErrorMessages.requiredField") }, {
                            type: 'email',
                            message: t("ErrorMessages.emailError"),
                          }]}>
                            <Input placeholder={t('SingleCustomerPage.staffEmail')} />
                          </Form.Item>
                        </GeneralAddDialog2>

                      </div>
                    )}
                </CardContent>
              </Card>
            </Grid>
          )}
          {/* Subcon Related Subcontractors */}
          {availableModules.includes('subcon_detailed') && (
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Card>
                <div style={{ padding: 16 }}>
                  <h3>{t('SingleCustomerPage.relatedSubcontractor')}</h3>
                </div>
                <Divider />
                <CardContent>
                  {Object.keys(relatedSubcontractors).length === 0 && (<div style={{ padding: 16, textAlign: 'center' }}>{t('SingleCustomerPage.noSubcontractorAssigned')}</div>)}
                  {Object.keys(relatedSubcontractors).length > 0 && (
                    <TableContainer>
                      <Table aria-label="solar-packages-table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCellHead align="left">
                              {t('SingleCustomerPage.subcontractorContactPerson')}
                            </StyledTableCellHead>
                            <StyledTableCellHead align="left">
                              {t('SingleCustomerPage.subcontractorCompanyName')}
                            </StyledTableCellHead>
                            <StyledTableCellHead align="left"> </StyledTableCellHead>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key={relatedSubcontractors.subcontractorName}>
                            <StyledTableCell align="left">
                              {relatedSubcontractors.subcontractorPerson}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {relatedSubcontractors.subcontractorName}
                            </StyledTableCell>
                            {adminInfo.isCompanySuperUser
                              && (
                                <StyledTableCell align="left" style={{ width: '200px' }}>
                                  <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-around'
                                  }}
                                  >
                                    <StyledIconButton
                                      aria-label="edit"
                                      color="primary"
                                      onClick={() => setRemoveSubcontracorDialogOpen(true)}
                                    >
                                      <CancelIcon />
                                    </StyledIconButton>
                                    <GeneralDeleteDialog
                                      dialogTitle={t('SingleCustomerPage.removeSubcontractor')}
                                      requestURL={`subcon/document/project_APIs/remove_subcontractor_from_project?subcon_project_id=${projectFeasibilityInfo.subconProjectId}`}
                                      reloadPageState={shouldReloadPage}
                                      reloadPageFunc={setShouldReloadPage}
                                      dialogContent={t('SingleCustomerPage.removeSubcontractorDialogContent')}
                                      requestMethod="DELETE"
                                      openState={removeSubcontracorDialogOpen}
                                      setOpenState={setRemoveSubcontracorDialogOpen}
                                    />
                                  </div>
                                </StyledTableCell>
                              )}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  {adminInfo.isCompanySuperUser && Object.keys(relatedSubcontractors).length === 0
                    && (
                      <div style={{
                        display: 'flex',
                        marginTop: '10px',
                        justifyContent: 'end'
                      }}
                      >
                        <Button
                          color="primary"
                          startIcon={<AddIcon />}
                          size="small"
                          variant="contained"
                          style={{ textTransform: 'none' }}
                          onClick={() => setNewSubcontracorDialogOpen(true)}
                        >
                          {t('SingleCustomerPage.assignSubcontractor')}
                        </Button>
                        {console.log(availableSubcontractors)}
                        {/* <GeneralAddDialog
                    dialogTitle={t('SingleCustomerPage.assignSubcontractor')}
                    requestURL="subcon/document/project_APIs/assign_subcontractor_to_project"
                    requestMethod="POST"
                    reloadPageState={shouldReloadPage}
                    reloadPageFunc={setShouldReloadPage}
                    openState={newSubcontracorDialogOpen}
                    setOpenState={setNewSubcontracorDialogOpen}
                    dontDisableButton
                    bodyData={{
                      subcon_project_id: projectFeasibilityInfo.subconProjectId,
                      subcontractor_code: newSubcontractor,
                    }}
                    inputs={[
                      {
                        title: t('SingleCustomerPage.subcontractorCompanyName'),
                        value: newSubcontractor,
                        type: 'select',
                        name: 'newSubcontractor',
                        choices: availableSubcontractors
                      }
                    ]}
                    changeDataFunction={handleChangeNewSubcontractor}
                  /> */}
                        <GeneralAddDialog2
                          // form={adminSingleUploadForm}
                          handle={assignSubcontractorHandle}
                          title={t('SingleCustomerPage.assignSubcontractor')}
                          initialValues={{
                            newSubcontractor: null
                          }}
                          modalShow={newSubcontracorDialogOpen}
                          setModalShow={() => setNewSubcontracorDialogOpen(false)}
                        >
                          <Form.Item name="newSubcontractor" rules={[{ required: true, message: t('ErrorMessages.requiredField') }]}>
                            <Select
                              placeholder={t('SingleCustomerPage.assignSubcontractor')}
                              allowClear
                              size="large"
                              onChange={handleChange}
                              options={availableSubcontractors.map((category) => ({ value: category.code, label: category.name }))}
                            />
                          </Form.Item>
                        </GeneralAddDialog2>
                      </div>
                    )}
                </CardContent>
              </Card>
            </Grid>
          )}
          {/* Activity Section */}
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card>
              <div style={{ padding: 16 }}>
                <h3>{t('SingleCustomerPage.activity')}</h3>
              </div>
              <Divider />
              <CardContent>
                {activities.length === 0 && (<div style={{ padding: 16, textAlign: 'center' }}>{t('SingleCustomerPage.noActivity')}</div>)}
                {activities.length > 0
                  && (
                    <div>
                      <ActivityStepper activities={activities} />
                    </div>
                  )}
              </CardContent>
            </Card>
          </Grid>
          {/* Project Document Upload Section */}
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card>
              <div style={{ padding: 16 }}>
                <h3>{t('SingleCustomerPage.documents')}</h3>
              </div>
              <Divider />
              <CardContent>
                {documentList.length === 0 && (<div style={{ padding: 16, textAlign: 'center' }}>{t('SingleCustomerPage.noDocuments')}</div>)}
                {documentList.length > 0
                  && (
                    <TableContainer>
                      <Table aria-label="documents">
                        <TableHead>
                          <TableRow>
                            <StyledTableCellHead>{t('SingleCustomerPage.documentCategory')}</StyledTableCellHead>
                            <StyledTableCellHead>{t('SingleCustomerPage.uploader')}</StyledTableCellHead>
                            <StyledTableCellHead>{t('SingleCustomerPage.uploadDate')}</StyledTableCellHead>
                            <StyledTableCellHead>{t('SingleCustomerPage.actions')}</StyledTableCellHead>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {documentList.map((document) => (
                            <TableRow key={document.id}>
                              <StyledTableCell align="left">
                                {document.document_category_name}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {document.who_uploaded_user_instance}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {document.created_at}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <a href={document.document_download_url} target="_blank" rel="noreferrer">
                                  {t('SingleCustomerPage.download')}
                                </a>
                              </StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                <div style={{
                  display: 'flex',
                  marginTop: '10px',
                  justifyContent: 'end'
                }}
                >
                  <Button
                    color="primary"
                    startIcon={<AddIcon />}
                    size="small"
                    variant="contained"
                    style={{ textTransform: 'none' }}
                    onClick={() => setNewDocumentDialogOpen(true)}
                  >
                    {t('SingleCustomerPage.uploadDocument')}
                  </Button>
                  {/* <GeneralAddDialog
                    dialogTitle={t('SingleCustomerPage.uploadDocument')}
                    requestURL="subcon/document/project_APIs/admin_upload_single_project_document"
                    requestMethod="POST+FILE"
                    openState={newDocumentDialogOpen}
                    setOpenState={setNewDocumentDialogOpen}
                    reloadPageState={shouldReloadPage}
                    reloadPageFunc={setShouldReloadPage}
                    bodyData={{
                      subcon_project_id: projectFeasibilityInfo.subconProjectId,
                      file_document_category_id: newDocumentInfo.categoryId,
                      user_comment: newDocumentInfo.userComment
                    }}
                    inputs={[
                      {
                        title: t('SingleCustomerPage.documentCategory'),
                        value: newDocumentInfo.categoryName,
                        type: 'select',
                        name: 'categoryName',
                        choices: documentCategories
                      },
                      {
                        title: t('SingleCustomerPage.comment'),
                        value: newDocumentInfo.userComment,
                        type: 'text',
                        name: 'userComment',
                      },
                      {
                        title: t('SingleCustomerPage.documentUpload'),
                        type: 'file',
                        name: 'file'
                      }
                    ]}
                    changeDataFunction={handleChangeDocumentInfo}
                  /> */}
                  <GeneralAddDialog2
                    form={adminSingleUploadForm}
                    handle={adminUpdateSingleDocumentProject}
                    title={t('SingleCustomerPage.uploadDocument')}
                    requestInfo={{
                      url: 'subcon/document/project_APIs/admin_upload_single_project_document',
                      method: 'POST+FILE',
                      body: {
                        subcon_project_id: projectFeasibilityInfo.subconProjectId,
                        file_document_category_id: newDocumentInfo.categoryId,
                        user_comment: newDocumentInfo.userComment
                      },
                    }}
                    initialValues={{
                      category: null,
                      comment: null,
                      document: null
                    }}
                    modalShow={newDocumentDialogOpen}
                    setModalShow={() => setNewDocumentDialogOpen(false)}
                  >
                    <Form.Item name="category" label={t('SingleCustomerPage.documentCategory')} rules={[{ required: true, message: t('ErrorMessages.requiredField') }]}>
                      <Select
                        placeholder={t('SingleCustomerPage.documentCategory')}
                        allowClear
                        size="large"
                        onChange={handleChange}
                        options={documentCategories.map((category) => ({ value: category.id, label: category.document_category_name }))}
                      />
                    </Form.Item>
                    <Form.Item name="comment" label={t('SingleCustomerPage.comment')}>
                      <Input size="large" placeholder={t('SingleCustomerPage.comment')} />
                    </Form.Item>
                    <Form.Item valuePropName="file" rules={[{ required: true, message: t('ErrorMessages.requiredField') }]} name="document" label={t('SingleCustomerPage.documentUpload')}>
                      <Dragger getValueFromEvent={getValueFromEvent} listType="picture-card" beforeUpload={beforeUpload} name="file" maxCount={1} multiple={false}>
                        <p className="ant-upload-text">
                          {t('DocumentManagementPage.drag')}
                        </p>
                      </Dragger>
                    </Form.Item>
                  </GeneralAddDialog2>
                </div>
              </CardContent>
            </Card>
          </Grid>
          {/* Subcon Admin Activities */}
          {/* availableModules.includes('subcon_detailed') && adminInfo.isCompanySuperUser */}
          {/* <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card>
              <div style={{ padding: 16 }}>
                <h3>{t('SingleCustomerPage.subconAdminActivities')}</h3>
              </div>
              <Divider />
              <CardContent>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                >
                  <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
                    {projectFeasibilityInfo.status !== 6 && (
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      style={{ textTransform: 'none', width: '200px' }}
                      onClick={() => updateProjectStatusSubcon('completed')}
                    >
                      {t('SingleCustomerPage.completeProject')}
                    </Button>
                    )}
                    {projectFeasibilityInfo.status !== -1 && (
                    <Button
                      color="secondary"
                      size="small"
                      variant="contained"
                      style={{ textTransform: 'none', width: '200px', marginLeft: '20px' }}
                      onClick={() => updateProjectStatusSubcon('canceled')}
                    >
                      {t('SingleCustomerPage.cancelProject')}
                    </Button>
                    )}
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid> */}
          {/* Home Admin Activities */}
          {availableModules.includes('home') && (projectFeasibilityInfo.tesis_tipi === 'Mesken' || projectFeasibilityInfo.tesis_tipi === 'Residential') && projectFeasibilityInfo.status === 4 && (
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Card>
                <div style={{ padding: 16 }}>
                  <h3>{t('SingleCustomerPage.homeAdminActivities')}</h3>
                </div>
                <Divider />
                <CardContent>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                  >
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      style={{ textTransform: 'none', width: '200px' }}
                      onClick={() => setHomeAcceptDialogOpen(true)}
                    >
                      {t('SingleCustomerPage.approveProject')}
                    </Button>
                    <GeneralAlertDialog
                      dialogTitle={t('SingleCustomerPage.approveProjectDialogTitle')}
                      dialogContent={t('SingleCustomerPage.approveProjectDialogDescription')}
                      requestURL="home/status_update"
                      requestMethod="POST"
                      reloadPageState={shouldReloadPage}
                      reloadPageFunc={setShouldReloadPage}
                      bodyData={{
                        musteri_id: projectFeasibilityInfo.id,
                        status: 'approved',
                        language: (localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') ? 'EN' : 'TR'
                      }}
                      openState={homeAcceptDialogOpen}
                      setOpenState={setHomeAcceptDialogOpen}
                    />
                    <Button
                      color="secondary"
                      size="small"
                      variant="contained"
                      style={{ textTransform: 'none', width: '200px', marginLeft: '20px' }}
                      onClick={() => setHomeRejectDialogOpen(true)}
                    >
                      {t('SingleCustomerPage.rejectProject')}
                    </Button>
                    <GeneralAlertDialog
                      dialogTitle={t('SingleCustomerPage.rejectProjectDialogTitle')}
                      dialogContent={t('SingleCustomerPage.rejectProjectDialogDescription')}
                      requestURL="home/status_update"
                      requestMethod="POST"
                      reloadPageState={shouldReloadPage}
                      reloadPageFunc={setShouldReloadPage}
                      bodyData={{
                        musteri_id: projectFeasibilityInfo.id,
                        status: 'rejected',
                        language: (localStorage.getItem('language') === 'en' || String(i18n.language).split('-')[0] === 'en') ? 'EN' : 'TR'
                      }}
                      openState={homeRejectDialogOpen}
                      setOpenState={setHomeRejectDialogOpen}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          )}
          {/* Credit Application Info */}
          {availableModules.includes('credit_application') && !availableModules.includes('bank') && !(projectFeasibilityInfo.tesis_tipi === 'Mesken' || projectFeasibilityInfo.tesis_tipi === 'Residential') && projectFeasibilityInfo.hasOwnProperty('username') && (
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Card>
                <div style={{ padding: 16 }}>
                  <h3>Kredi Başvurusu Bilgileri</h3>
                </div>
                {!_.isEmpty(creditApplicationInfo) && (
                  <>
                    <Divider />
                    <CardContent>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      >
                        <h3 style={{ marginBottom: 15 }}>Dokümanlar</h3>
                        {creditApplicationDocumentCategories.length > 0
                          && (
                            <TableContainer>
                              <Table aria-label="credit-application-documents">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCellHeadWithBorder align="left">
                                      Doküman İsmi
                                    </StyledTableCellHeadWithBorder>
                                    <StyledTableCellHeadWithBorder align="left">
                                      Zorunlu Mu?
                                    </StyledTableCellHeadWithBorder>
                                    <StyledTableCellHeadWithBorder align="left"> </StyledTableCellHeadWithBorder>
                                    <StyledTableCellHeadWithBorder align="left"> </StyledTableCellHeadWithBorder>
                                    {/* <StyledTableCellHeadWithBorder align="left"> </StyledTableCellHeadWithBorder> */}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {creditApplicationDocumentCategories.map((document) => (
                                    <TableRow key={document.id}>
                                      <StyledTableCellWithBorder align="left">
                                        {document.name}
                                      </StyledTableCellWithBorder>
                                      <StyledTableCellWithBorder align="left">
                                        {document.is_required ? 'Evet' : 'Hayır'}
                                      </StyledTableCellWithBorder>
                                      <StyledTableCellWithBorder align="left">
                                        {document.exists
                                          ? <span>Kaldır</span>
                                          : (
                                            <>
                                              <span
                                                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                onClick={() => handleChangeDialogStatusForCreditAppDocs(document.id, 'open_dialog_state', creditApplicationDocumentCategories, setCreditApplicationDocumentCategories)}
                                              >
                                                Yükle
                                              </span>
                                              {/* <GeneralAddDialog
                                        dialogTitle={t('SingleCustomerPage.uploadDocument')}
                                        requestURL="bankloan/companies/upload-file/"
                                        requestMethod="FILE-CREDIT-APPLICATION"
                                        openState={document.open_dialog_state}
                                        setOpenState={() => handleChangeDialogStatusForCreditAppDocs(document.id, 'open_dialog_state', creditApplicationDocumentCategories, setCreditApplicationDocumentCategories)}
                                        reloadPageState={shouldReloadPage}
                                        reloadPageFunc={setShouldReloadPage}
                                        bodyData={{
                                          musteri_id: projectFeasibilityInfo.id,
                                          category_id: document.id,
                                        }}
                                        inputs={[
                                          {
                                            title: t('SingleCustomerPage.documentUpload'),
                                            type: 'file',
                                            name: 'file'
                                          }
                                        ]}
                                        changeDataFunction={handleChangeDocumentInfo}
                                      /> */}
                                              <GeneralAddDialog2
                                                // form={adminSingleUploadForm}
                                                handle={(values) => bankloadFileUploadHandle(values, document.id)}
                                                title={t('SingleCustomerPage.uploadDocument')}
                                                initialValues={{
                                                  bankloadFile: null
                                                }}
                                                modalShow={document.open_dialog_state}
                                                setModalShow={() => handleChangeDialogStatusForCreditAppDocs(document.id, 'open_dialog_state', creditApplicationDocumentCategories, setCreditApplicationDocumentCategories)}
                                              >
                                                <Form.Item label={t('SingleCustomerPage.documentUpload')} name="bankloadFile" rules={[{ required: true, message: t('ErrorMessages.requiredField') }]}>
                                                  <Dragger beforeUpload={bankloadBeforeUpload} multiple={false} maxCount={1} listType='picture-card'>
                                                    <p className="ant-upload-text">
                                                      {t('DocumentManagementPage.drag')}
                                                    </p>
                                                  </Dragger>
                                                </Form.Item>
                                              </GeneralAddDialog2>
                                            </>
                                          )}
                                      </StyledTableCellWithBorder>
                                      <StyledTableCellWithBorder align="left">
                                        {document.exists
                                          ? <a href={document.exists}>İndir</a>
                                          : ''}
                                      </StyledTableCellWithBorder>
                                      {/* <StyledTableCellWithBorder align="left">
                                {document.file_url ? document.date : ''}
                              </StyledTableCellWithBorder> */}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        {creditApplicationDocumentCategories.length === 0
                          && (
                            <div style={{ padding: 16, textAlign: 'center' }}>Bu proje için henüz kredi başvurusuna dair dokümanı yüklenmemiş.</div>
                          )}
                      </div>
                    </CardContent>
                  </>
                )}
                <Divider />
                <CardContent>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  >
                    <h3 style={{ marginBottom: 15 }}>Proje Bilgileri</h3>
                    <TableContainer>
                      <Table aria-label="credit-application-project-info">
                        <TableBody>
                          <TableRow key="1">
                            <StyledTableCell align="left">
                              Başvurulan Kredi Miktarı
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <BootstrapInput
                                name="requestedCreditAmount"
                                type="number"
                                value={requestedCreditAmount}
                                id="requestedCreditAmount"
                                inputProps={{ min: 0, step: 1 }}
                                style={{ width: '150px' }}
                                onChange={(e) => setRequestedCreditAmount(e.target.value)}
                                endAdornment={<InputAdornment position="end">₺</InputAdornment>}
                              />
                            </StyledTableCell>
                          </TableRow>
                          {!_.isEmpty(creditApplicationInfo) && (
                            <>
                              <TableRow key="2">
                                <StyledTableCellWithBorder align="left">
                                  12 Aylık Tüketim
                                </StyledTableCellWithBorder>
                                {/* <ManualWidthTableCell widthPercentage="30%">
                              12 Aylık Tüketim
                            </ManualWidthTableCell> */}
                                <StyledTableCellWithBorder
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    alignItems: 'center'
                                  }}
                                  align="left"
                                >
                                  <Table aria-label="monthly-consumptions">
                                    {[['Ocak', 'Şubat', 'Mart'], ['Nisan', 'Mayıs', 'Haziran'], ['Temmuz', 'Ağustos', 'Eylül'], ['Ekim', 'Kasım', 'Aralık']].map((monthChunk, index) => (
                                      <TableRow key={monthChunk[0]}>
                                        {monthChunk.map((month, monthChunkIdx) => (
                                          <>
                                            <StyledTableCell>{month}</StyledTableCell>
                                            <StyledTableCell>
                                              <BootstrapInput
                                                name={index * 3 + monthChunkIdx}
                                                type="number"
                                                value={projectFeasibilityInfo.hasOwnProperty('monthly_manual_consumptions') ? projectFeasibilityInfo.monthly_manual_consumptions[index * 3 + monthChunkIdx] : ''}
                                                id={index * 3 + monthChunkIdx}
                                                inputProps={{ min: 0, step: 1 }}
                                                style={{ width: '150px' }}
                                                onChange={(e) => handleChangeConsumption(e.target.value, index * 3 + monthChunkIdx)}
                                                endAdornment={<InputAdornment position="end">kWh</InputAdornment>}
                                              />
                                            </StyledTableCell>
                                          </>
                                        ))}
                                      </TableRow>
                                    ))}
                                  </Table>
                                </StyledTableCellWithBorder>
                              </TableRow>
                              <TableRow key="3">
                                <StyledTableCellWithBorder align="left">
                                  Bakım/Onarım Anlaşması Maliyeti (₺)
                                </StyledTableCellWithBorder>
                                <StyledTableCellWithBorder align="left">
                                  <BootstrapInput
                                    name="maintenanceCost"
                                    type="number"
                                    value={creditApplicationInputsByEPC.maintenanceCost}
                                    id="maintenanceCost"
                                    inputProps={{ min: 0, step: 1 }}
                                    style={{ width: '150px' }}
                                    onChange={(e) => setCreditApplicationInputsByEPC({ ...creditApplicationInputsByEPC, maintenanceCost: e.target.value })}
                                    endAdornment={<InputAdornment position="end">₺</InputAdornment>}
                                  />
                                </StyledTableCellWithBorder>
                              </TableRow>
                              <TableRow key="4">
                                <StyledTableCellWithBorder align="left">
                                  Performans Garantisi - Yıllık MWh Garanti Üretim Miktarı
                                </StyledTableCellWithBorder>
                                <StyledTableCellWithBorder align="left">
                                  <BootstrapInput
                                    name="guaranteedProduction"
                                    type="number"
                                    value={creditApplicationInputsByEPC.guaranteedProduction}
                                    id="guaranteedProduction"
                                    inputProps={{ min: 0, step: 1 }}
                                    style={{ width: '150px' }}
                                    onChange={(e) => setCreditApplicationInputsByEPC({ ...creditApplicationInputsByEPC, guaranteedProduction: e.target.value })}
                                    endAdornment={<InputAdornment position="end">MWh</InputAdornment>}
                                  />
                                </StyledTableCellWithBorder>
                              </TableRow>
                            </>
                          )}
                          <TableRow key="5" style={{ marginTop: 30 }}>
                            <StyledTableCell align="left">
                              {!_.isEmpty(creditApplicationInfo)
                                ? (
                                  <Button
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                    style={{ textTransform: 'none', width: '200px' }}
                                    onClick={() => updateCreditApplicationInfo()}
                                  >
                                    Proje Bilgilerini Güncelle
                                  </Button>
                                ) : (
                                  <Button
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                    style={{ textTransform: 'none', width: '200px' }}
                                    onClick={() => initializeCreditApplication()}
                                  >
                                    Kredi Başvurusunu Başlat
                                  </Button>
                                )}
                            </StyledTableCell>
                            <StyledTableCell align="left"> </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </CardContent>
                {!_.isEmpty(creditApplicationInfo) && (
                  <>
                    <Divider />
                    <CardContent>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      >
                        <h3 style={{ marginBottom: 15 }}>Banka Teklifleri</h3>
                        {creditApplicationInfo.application_status.length === 0 && <p>Kullanıcının banka başvurusu bekleniyor..</p>}
                        {creditApplicationInfo.application_status.length > 0
                          && (
                            <TableContainer>
                              <Table aria-label="credit-application-documents">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCellHeadWithBorder align="left">
                                      Banka
                                    </StyledTableCellHeadWithBorder>
                                    <StyledTableCellHeadWithBorder align="left">
                                      Teklif Edilen Kredi Tutarı
                                    </StyledTableCellHeadWithBorder>
                                    <StyledTableCellHeadWithBorder align="left">
                                      Faiz Oranı
                                    </StyledTableCellHeadWithBorder>
                                    <StyledTableCellHeadWithBorder align="left">
                                      Vade Süresi
                                    </StyledTableCellHeadWithBorder>
                                    <StyledTableCellHeadWithBorder align="left">
                                      Ödeme Planı Dokümanı
                                    </StyledTableCellHeadWithBorder>
                                    <StyledTableCellHeadWithBorder align="left">
                                      Durum
                                    </StyledTableCellHeadWithBorder>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {creditApplicationInfo.application_status.map((creditProposal) => (
                                    creditProposal.hasOwnProperty('bank_proposal')
                                      ? (
                                        <TableRow key={creditProposal.id}>
                                          <StyledTableCellWithBorder align="left">
                                            <img src={creditProposal.bank_logo_url} alt={creditProposal.bank} style={{ width: '100px', height: 'auto' }} />
                                          </StyledTableCellWithBorder>
                                          <StyledTableCellWithBorder align="left">
                                            {`${creditProposal.bank_proposal.creditable_amount} ₺`}
                                          </StyledTableCellWithBorder>
                                          <StyledTableCellWithBorder align="left">
                                            {`%${creditProposal.bank_proposal.interest_rate}`}
                                          </StyledTableCellWithBorder>
                                          <StyledTableCellWithBorder align="left">
                                            {`${creditProposal.bank_proposal.credit_term} ay`}
                                          </StyledTableCellWithBorder>
                                          <StyledTableCellWithBorder align="left">
                                            <span style={{ textDecoration: 'underline' }}>İndir</span>
                                          </StyledTableCellWithBorder>
                                          <StyledTableCellWithBorder align="left">
                                            <Chip
                                              style={{
                                                height: '30px',
                                                fontSize: '0.9rem',
                                                backgroundColor: creditApplicationStatusLookup[creditProposal.status].colorCode,
                                                color: 'white'
                                              }}
                                              label={creditApplicationStatusLookup[creditProposal.status].text}
                                              size="medium"
                                            />
                                          </StyledTableCellWithBorder>
                                        </TableRow>
                                      )
                                      : (
                                        <TableRow key={creditProposal.id}>
                                          <StyledTableCellWithBorder align="left">
                                            <img src={creditProposal.bank_logo_url} alt={creditProposal.bank} style={{ width: '100px', height: 'auto' }} />
                                          </StyledTableCellWithBorder>
                                          <StyledTableCellWithBorder align="left">
                                            -
                                          </StyledTableCellWithBorder>
                                          <StyledTableCellWithBorder align="left">
                                            -
                                          </StyledTableCellWithBorder>
                                          <StyledTableCellWithBorder align="left">
                                            -
                                          </StyledTableCellWithBorder>
                                          <StyledTableCellWithBorder align="left">
                                            -
                                          </StyledTableCellWithBorder>
                                          <StyledTableCellWithBorder align="left">
                                            <Chip
                                              style={{
                                                height: '30px',
                                                fontSize: '0.9rem',
                                                backgroundColor: creditApplicationStatusLookup[creditProposal.status].colorCode,
                                                color: 'white'
                                              }}
                                              label={creditApplicationStatusLookup[creditProposal.status].text}
                                              size="medium"
                                            />
                                          </StyledTableCellWithBorder>
                                        </TableRow>
                                      )
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                      </div>
                    </CardContent>
                  </>
                )}
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default CustomerInfo;

/* eslint-disable max-len */
import React from 'react';
import 'reactjs-popup/dist/index.css';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'date-fns';
import {
  Info,
  Grid,
  Zap,
  DollarSign,
  FileText,
} from 'react-feather';
import classes from './maliyetWrapper.module.css';

const FeatherIcon = ({ icon: Icon, color }) => {
  return (
    <Icon color={color} />
  );
};

const items = [
  {
    title: 'Proje Bilgileri',
    icon: Info,
    href: 'project-info'
  },
  {
    title: 'Çatı Modelleme',
    icon: Grid,
    href: 'roof-modelling'
  },
  {
    title: 'Tüketim Bilgileri',
    icon: Zap,
    href: 'facility-consumption'
  },
  {
    title: 'Finansal & Malzeme',
    icon: DollarSign,
    href: 'material-cost'
  },
  {
    title: 'Özet & Rapor',
    icon: FileText,
    href: 'summary-report'
  }
];

const InternalRouterHorizontal = ({ projectId }) => {
  return (
    <div className={classes.leftBarWrapper}>
      <ul className={classes.leftBarList}>
        <div className={classes.leftBarContainer}>
          {items.map((item) => (
            <div className={classes.leftBarItem}>
              <li style={{ backgroundColor: window.location.pathname.includes(item.href) ? '#ffffff' : '#1b3b69' }} className={classes.leftBarItem2}>
                <Link to={`/projects/${projectId}/${item.href}`}>
                  <div className={classes.iconTextContainer}>
                    <FeatherIcon icon={item.icon} color={window.location.pathname.includes(item.href) ? '#1b3b69' : '#ffffff'} />
                    <span style={{
                      marginTop: '5px',
                      color: window.location.pathname.includes(item.href) ? '#1b3b69' : '#ffffff',
                      fontWeight: window.location.pathname.includes(item.href) ? 'bold' : 'normal'
                    }}
                    >
                      {item.title}
                    </span>
                  </div>
                </Link>
              </li>
            </div>
          ))}
        </div>
      </ul>
    </div>
  );
};

InternalRouterHorizontal.propTypes = {
  projectId: PropTypes.number
};

FeatherIcon.propTypes = {
  icon: PropTypes.elementType,
  color: PropTypes.string
};

export default InternalRouterHorizontal;

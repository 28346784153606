/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  makeStyles,
  withStyles,
  Grid,
  Container,
  Snackbar,
  Divider,
  Card,
  InputBase,
  TableCell,
  TableRow,
  TextField,
  Table,
  CardContent,
  TableBody,
  TableContainer,
  TableHead,
  IconButton,
  Select,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import axiosInstance from 'src/utils/axiosApi';
import MuiAlert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CitiesFilter from 'src/components/FilterListComp';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledDialogTitle = withStyles({
  root: {
    fontSize: 18,
    fontWeight: 'bold'
  }
})(DialogTitle);

const StyledIconButton = withStyles({
  root: {
    margin: 2,
    textTransform: 'none',
    fontSize: 16,
    padding: 8,
    backgroundColor: '#e2e5fa'
  }
})(IconButton);

const StyledIconButtonDelete = withStyles({
  root: {
    margin: 2,
    textTransform: 'none',
    fontSize: 16,
    backgroundColor: '#f7e2e2',
    color: '#ad3737',
    padding: 8
  }
})(IconButton);

const BootstrapSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins',
    borderBottom: 'none'
  },
}))(TableCell);

const StyledTableCellHead = withStyles(() => ({
  root: {
    padding: 10,
    fontFamily: 'Poppins',
    fontWeight: 'bold'
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: 0,
    paddingBottom: 0
  },
}));

const SubcontractorManagement = (props) => {
  const { t, } = useTranslation();
  const MUIclasses = useStyles();
  const { subcontractorId } = useParams();
  const { pageType } = props;
  const navigate = useNavigate();

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [subcontractorInfo, setSubcontractorInfo] = useState({
    name: '',
    website: '',
    email: '',
    phone: '',
  });
  const [subconAdminPreviews, setSubconAdminPreviews] = useState([]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const titles = {
    subcontractor: {
      name: t('SingleSubcontractorPage.subcontractorName'),
      website: t('SingleSubcontractorPage.subcontractorWebsite'),
      phone: t('SingleSubcontractorPage.subcontractorPhone'),
      email: t('SingleSubcontractorPage.subcontractorEmail'),
    },
    admin: {
      name: t('SingleSubcontractorPage.subcontractorContactPersonName'),
      surname: t('SingleSubcontractorPage.subcontractorContactPersonSurname'),
      phone: t('SingleSubcontractorPage.subcontractorContactPersonPhone'),
      email: t('SingleSubcontractorPage.subcontractorContactPersonEmail'),
    }
  };

  const placeholders = {
    subcontractor: {
      name: 'Özkardeşler Güneş Paneli Ltd. Şti.',
      website: 'www.solarvis.co',
      phone: '0555 555 55 55',
      email: 'info@solarvis.co',
    },
    admin: {
      name: 'Ahmet',
      surname: 'Yılmaz',
      phone: '0555 555 55 55',
      email: 'ahmetyilmaz@gmail.com',
    }
  };

  const [subcontractorAdminInfo, setSubcontractorAdminInfo] = useState({
    name: '',
    surname: '',
    phone: '',
    email: '',
  });

  const [subcontractorCities, setSubcontractorCities] = useState([]);
  const [availableCities, setAvailableCities] = useState([]);

  const changeSubcontractorCities = (filterType = 'cities', value) => {
    setSubcontractorCities(value);
  };

  const handleDelete = (userName) => {
    axiosInstance.post(`subcon/deactivate_admin`, {
      username: userName
    })
      .then((response) => {
        if (response.data.status) {
          window.location.reload();
          alert('İşleminiz başarılı bir şekilde gerçekleştirildi.');
        } else {
          alert('Böyle bir kayıt bulunamadı.');
        }
      })
      .catch((error) => {
        alert('Şu an bu işlemi gerçekleştiremiyoruz. Lütfen tekrar deneyin.');
        console.log(error);
      }).finally(() => {
        setOpen(false);
      });
  };

  const handleChangeSubconInfo = (event) => {
    // Prevents React from resetting its properties:
    event.persist();
    setSubcontractorInfo((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const handleChangeSubconAdminInfo = (event) => {
    // Prevents React from resetting its properties:
    event.persist();
    setSubcontractorAdminInfo((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const createUpdateSubconAuthorizedUser = () => {
    axiosInstance.post('subcon/create_update_admin', {
      username: subcontractorAdminInfo.email,
      isim: subcontractorAdminInfo.name,
      soy_isim: subcontractorAdminInfo.surname,
      telefon: subcontractorAdminInfo.phone,
    })
      .then((response) => {
        const { status } = response.data;
        if (status) {
          // After successful API call, redirect to subcon user list page - /settings/subcontractor-management
          const { data, message } = response.data;
          setSnackbarMessage(message);
          setSnackbarType('success');
          setSnackbarState(true);
          navigate('/settings/subcontractor-management', { replace: true });
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
        }
      })
      .catch((error) => {
        setSnackbarMessage(t('AlertMessage.generalError'));
        setSnackbarType('warning');
        setSnackbarState(true);
        console.log(error);
      });
  };

  // Integrate create or update subcontractor API call
  const createUpdateSubcontractor = () => {
    axiosInstance.post('subcon/add_update_subcon', {
      subcon_company_id: pageType === 'updateSubcontractor' ? subcontractorId : '00',
      name: subcontractorInfo.name,
      main_web: subcontractorInfo.website,
      phone: subcontractorInfo.phone,
      email: subcontractorInfo.email,
      region: subcontractorCities
    })
      .then((response) => {
        const { status } = response.data;
        if (status) {
          // After successful API call, redirect to subcon user list page - /settings/subcontractor-management
          const { data, message } = response.data;
          setSnackbarMessage(message);
          setSnackbarType('success');
          setSnackbarState(true);
          if (pageType === 'updateSubcontractor') {
            navigate('/settings/subcontractor-management', { replace: true });
          }
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
        }
      })
      .catch((error) => {
        setSnackbarMessage(t('AlertMessage.generalError'));
        setSnackbarType('warning');
        setSnackbarState(true);
        console.log(error);
      });

    if (pageType === 'newSubcontractor') {
      axiosInstance.post('subcon/create_update_admin', {
        username: subcontractorAdminInfo.email,
        subcon_admin_type: '',
        isim: subcontractorAdminInfo.name,
        soy_isim: subcontractorAdminInfo.surname,
        telefon: subcontractorAdminInfo.phone,
        is_company_superuser: false,
        is_subcon_superuser: true,
        subcon_company: subcontractorInfo.name,
      })
        .then((response) => {
          const { status } = response.data;
          if (status) {
            // After successful API call, redirect to subcon user list page - /settings/subcontractor-management
            const { data, message } = response.data;
            setSnackbarMessage(message);
            setSnackbarType('success');
            setSnackbarState(true);
            navigate('/settings/subcontractor-management', { replace: true });
          } else {
            setSnackbarMessage(response.data.message);
            setSnackbarType('warning');
            setSnackbarState(true);
          }
        })
        .catch((error) => {
          setSnackbarMessage(t('AlertMessage.generalError'));
          setSnackbarType('warning');
          setSnackbarState(true);
          console.log(error);
        });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  // Integrate fetch subcontractor data API and update state
  const fetchSubconData = () => {
    axiosInstance.get(`subcon/fetch_subcontractors?is_all=0&id=${subcontractorId}`)
      .then((response) => {
        const { status } = response.data;
        if (status) {
          const { data, message } = response.data;
          const subcontractorInfoX = data.subcontractors[0];
          const authorizedAdmin = subcontractorInfoX.authorized_person;
          setSubcontractorInfo({
            name: subcontractorInfoX.name,
            website: subcontractorInfoX.main_web,
            email: subcontractorInfoX.email,
            phone: subcontractorInfoX.phone,
          });
          setSubcontractorCities(subcontractorInfoX.cities);
          setSubcontractorAdminInfo({
            name: authorizedAdmin.isim,
            surname: authorizedAdmin.soy_isim,
            phone: authorizedAdmin.telefon,
            email: authorizedAdmin.email,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  const fetchSubconAdminData = () => {
    axiosInstance.get(`subcon/single_subcon_user_operations/fetch_users?subcontractor_id=${subcontractorId}`)
      .then((response) => {
        const { status } = response.data;
        if (status) {
          const { data, message } = response.data;
          setSubconAdminPreviews(data.admins);
        }
      })
      .catch((error) => console.log(error));
  };

  const fetchAvailableCities = () => {
    axiosInstance.get('subcon/fetch_available_cities')
      .then((response) => {
        const { status } = response.data;
        if (status) {
          const { data, message } = response.data;
          setAvailableCities(data.cities);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (pageType === 'updateSubcontractor') {
      fetchSubconData();
      fetchSubconAdminData();
    }
    fetchAvailableCities();
  }, []);

  return (
    <Page
      className={MUIclasses.root}
      title={`solarVis | ${t('SingleSubcontractorPage.pageTitle')}`}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            {pageType === 'newSubcontractor' && <h2 style={{ fontWeight: 'normal' }}>{t('SingleSubcontractorPage.addNewSubcontractor')}</h2>}
            {pageType === 'updateSubcontractor' && <h2 style={{ fontWeight: 'normal' }}>{t('SingleSubcontractorPage.updateSubcontractor')}</h2>}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Alert severity="info">
              {pageType === 'newSubcontractor' && <span>{t('SingleSubcontractorPage.newSubcontractorMessage')}</span>}
              {pageType === 'updateSubcontractor' && <span>{t('SingleSubcontractorPage.updateSubcontractorMessage')}</span>}
            </Alert>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card>
              <div style={{ padding: 16 }}>
                <h4>{t('SingleSubcontractorPage.subcontractorInfo')}</h4>
              </div>
              <Divider />
              <CardContent>
                <Table>
                  <TableBody>
                    {Object.keys(subcontractorInfo).map((infoKey) => (
                      <TableRow
                        key={infoKey}
                      >
                        <StyledTableCell style={{ width: '30%' }}>
                          <strong>{titles.subcontractor[infoKey]}</strong>
                        </StyledTableCell>
                        <StyledTableCell style={{ width: '100%' }}>
                          <TextField
                            // fullWidth
                            style={{ width: '100%' }}
                            name={infoKey}
                            onChange={(e) => handleChangeSubconInfo(e)}
                            required
                            value={subcontractorInfo[infoKey]}
                            placeholder={placeholders.subcontractor[infoKey]}
                            variant="outlined"
                            type="text"
                            size="small"
                            multiline
                          />
                        </StyledTableCell>
                      </TableRow>
                    ))}
                    <TableRow
                      key="subcontractorCities"
                    >
                      <StyledTableCell style={{ width: '30%' }}>
                        <strong>{t('SingleSubcontractorPage.subcontractorCities')}</strong>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '100%' }}>
                        <CitiesFilter
                          changeFilterFunction={changeSubcontractorCities}
                          filterValue={subcontractorCities}
                          filterType="cities"
                          page="subcontractor"
                          filteredAvailableCities={availableCities}
                        />
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          {pageType === 'updateSubcontractor' && (
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ textTransform: 'none' }}
                  startIcon={<SaveIcon />}
                  onClick={() => createUpdateSubcontractor()}
                >
                  <span>{t('SingleSubcontractorPage.updateSubcontractor')}</span>
                </Button>
              </div>
            </Grid>
          )}
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card>
              <div style={{ padding: 16 }}>
                <h4>{t('SingleSubcontractorPage.subcontractorContactInfo')}</h4>
              </div>
              <Divider />
              <CardContent>
                <Table>
                  <TableBody>
                    {Object.keys(subcontractorAdminInfo).map((infoKey) => (
                      <TableRow
                        key={infoKey}
                      >
                        <StyledTableCell style={{ width: '30%' }}>
                          <strong>{titles.admin[infoKey]}</strong>
                        </StyledTableCell>
                        <StyledTableCell style={{ width: '100%' }}>
                          <TextField
                            // fullWidth
                            style={{ width: '100%' }}
                            name={infoKey}
                            onChange={(e) => handleChangeSubconAdminInfo(e)}
                            required
                            value={subcontractorAdminInfo[infoKey]}
                            placeholder={placeholders.admin[infoKey]}
                            variant="outlined"
                            type="text"
                            size="small"
                            multiline
                          />
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          {pageType === 'updateSubcontractor' && (
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ textTransform: 'none' }}
                  startIcon={<SaveIcon />}
                  onClick={() => createUpdateSubconAuthorizedUser()}
                >
                  <span>{t('SingleSubcontractorPage.updateSubcontractorPerson')}</span>
                </Button>
              </div>
            </Grid>
          )}
          {pageType === 'newSubcontractor'
            && (
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<SaveIcon />}
                    onClick={() => createUpdateSubcontractor()}
                  >
                    <span>{t('SingleSubcontractorPage.addNewSubcontractor')}</span>
                  </Button>
                </div>
              </Grid>
            )}
          {pageType === 'updateSubcontractor'
            && (
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <Card>
                  <div style={{
                    padding: 16,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                  >
                    <h3 style={{ fontWeight: 'normal' }}>{t('SubcontractorManagementPage.staff')}</h3>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    </div>
                  </div>
                  <Divider />
                  <CardContent>
                    <TableContainer>
                      <Table aria-label="solar-packages-table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCellHead align="left">
                              {t('SubcontractorManagementPage.staffName')}
                            </StyledTableCellHead>
                            <StyledTableCellHead align="left">
                              {t('SubcontractorManagementPage.staffEmail')}
                            </StyledTableCellHead>
                            <StyledTableCellHead align="left">
                              {t('SubcontractorManagementPage.staffType')}
                            </StyledTableCellHead>
                            <StyledTableCellHead align="center"> </StyledTableCellHead>
                            <StyledTableCellHead align="center"> </StyledTableCellHead>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {subconAdminPreviews.map((adminUser) => (
                            <TableRow key={adminUser.admin_id}>
                              <StyledTableCell align="left">
                                {`${adminUser.isim} ${adminUser.soy_isim}`}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {adminUser.email}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {adminUser.type}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <div style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'space-around'
                                }}
                                >
                                  <Link to={`/settings/subcontractor-management/user/${adminUser.id}`}>
                                    <StyledIconButton aria-label="edit" color="primary">
                                      <EditIcon />
                                    </StyledIconButton>
                                  </Link>
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <div style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'space-around'
                                }}
                                >
                                  <StyledIconButtonDelete aria-label="delete" onClick={handleClickOpen}>
                                    <DeleteIcon />
                                  </StyledIconButtonDelete>
                                  <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                  >
                                    <StyledDialogTitle id="alert-dialog-title" disableTypography>Bu kaydı silmek istediğinize emin misiniz?</StyledDialogTitle>
                                    <DialogContent>
                                      <DialogContentText id="alert-dialog-description">
                                        Bu geri dönülemez bir işlemdir. Bu müşteri kaydı veri tabanımızdan silinecektir.
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button onClick={handleClose} color="primary" variant="contained">
                                        Geri Dön
                                      </Button>
                                      <Button onClick={() => handleDelete(adminUser.email)} color="primary" autoFocus>
                                        Sil
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </div>
                              </StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
            )}
        </Grid>
      </Container>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default SubcontractorManagement;

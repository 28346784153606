/* eslint-disable */
/* eslint-disable camelcase */
/* eslint-disable no-constant-condition */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'src/i18n';
import { Navigate, useNavigate } from 'react-router-dom';
import axiosInstance from 'src/utils/axiosApi';
import Spinner from 'src/components/Spinner/Spinner';
import {
  changeLogState,
} from 'src/services/users/userReducer';
import {
  changeAdminInfo,
  changeSubconCompanyInfo,
  changeLanguage
} from 'src/services/users/userReducer';

const UserRedirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [moduleCodes, setModuleCodes] = useState([]);
  const [companyType, setCompanyType] = useState('');

  const [redirectPath, setRedirectPath] = useState('');

  const {loggedIn} = useSelector((state) => state.users)

  const getAdminPermissions = () => {
    axiosInstance.get('crm/get_admin_permissions?format=json')
      .then((response) => {
        const { data, status, message } = response.data;
        if (status && message === 'User has permission to access the subcontractor module.') {
          const adminInfo = data.admin_info;
          dispatch(changeAdminInfo({
            userId: adminInfo.user_id,
            isCompanySuperUser: adminInfo.is_company_superuser,
            isSubconSuperUser: adminInfo.is_subcon_superuser,
            adminType: adminInfo.admin_type,
            isSubconCompanyUser: adminInfo.is_subcon_company_user,
          }));

          const subconCompanyInfo = adminInfo.subcon_company_info;
          if (subconCompanyInfo) {
            dispatch(changeSubconCompanyInfo(subconCompanyInfo));
          }
        }
      })
      .catch((error) => console.log(error));

    axiosInstance.get('crm/funnel/fetch?format=json')
      .then((response) => {
        if (response.data.case_code === "unauthorized_admin_url") {
          dispatch(changeLogState({ loggedIn: false }));
          navigate('/login');
        }

        const {
          avail_module_codes,
          company_type,
        } = response.data.data;

        setModuleCodes(avail_module_codes);
        setCompanyType(company_type);
      })
      .catch(() => {
        dispatch(changeLogState({ loggedIn: false }));
        navigate('/login');
      });
  };

  const getUserSettings = () => {
    axiosInstance.get('settings/user/fetch?format=json')
      .then((response) => {
        if (response.data.status) {
          const { data } = response.data;
          let langReceived = 'tr';
          if (data.language === 'EN') {
            langReceived = 'en';
          }
          dispatch(changeLanguage({ language: langReceived }));
          i18n.changeLanguage(langReceived);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getAdminPermissions();
    getUserSettings();
  }, []);

  useEffect(() => {
    if (moduleCodes.length > 0 && companyType !== '') {
      if (moduleCodes.includes('credit_application') && companyType === 'bank') {
        setRedirectPath('/credit-applications-crm/status-1');
      } else {
        setRedirectPath('/crm/status-all');
      }
    }
  }, [moduleCodes, companyType]);

  if (!loggedIn) {
    return (
      <Navigate to="/login" />
    );
  }

  return (
    <>
      {redirectPath ? <Navigate to={redirectPath} /> : <Spinner text="" />}
    </>
  );
};

export default UserRedirect;

/* eslint-disable no-constant-condition */
import React from 'react';
import axiosInstance from 'src/utils/axiosApi';
import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n';
import { useDispatch } from 'react-redux';
import { changeLanguage } from 'src/services/users/userReducer';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const StyledDialogTitle = withStyles({
  root: {
    fontSize: 20,
  }
})(DialogTitle);

export default function ConfSaveDialog(props) {
  const { t, } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    const langSend = props.language === 'tr' ? 'TR' : 'EN';
    if (props.forMailGonder) {
      axiosInstance.post('/settings/user/save?format=json', {
        mail_notify: props.mailGonder,
        language: langSend
      })
        .then((response) => {
          setOpen(false);
          if (response.data.status) {
            dispatch(changeLanguage({ language: props.language }));
            i18n.changeLanguage(props.language);
            window.location.reload();
            alert('İşleminiz başarılı bir şekilde gerçekleştirildi.');
          } else {
            alert('Şu an bu işlemi gerçekleştiremiyoruz. Lütfen tekrar deneyin.');
          }
        })
        .catch((error) => {
          setOpen(false);
          alert('Şu an bu işlemi gerçekleştiremiyoruz. Lütfen tekrar deneyin.');
          console.log(error);
        });
    }

    if (props.forPanelOran) {
      axiosInstance.post('/settings/company/save?format=json', {
        mw_alan: props.panelOran,
      })
        .then((response) => {
          setOpen(false);
          if (response.data.status) {
            window.location.reload();
            alert('İşleminiz başarılı bir şekilde gerçekleştirildi.');
          } else {
            alert('Şu an bu işlemi gerçekleştiremiyoruz. Lütfen tekrar deneyin.');
          }
        })
        .catch((error) => {
          setOpen(false);
          alert('Şu an bu işlemi gerçekleştiremiyoruz. Lütfen tekrar deneyin.');
          console.log(error);
        });
    }
  };

  return (
    <div>
      <Button
        color="primary"
        endIcon={<ArrowRightIcon />}
        size="small"
        variant="contained"
        onClick={handleClickOpen}
      >
        {t('ConfigurationPage.kaydetButon')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title" disableTypography>{t('ConfigurationPage.ayarlarıDegistirmeSorusu')}</StyledDialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('ConfigurationPage.ayarlarıDegistirmeMetni')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('ConfigurationPage.geriDonButonu')}
          </Button>
          <Button onClick={handleDelete} color="primary" variant="contained">
            {t('ConfigurationPage.kaydetButon')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfSaveDialog.propTypes = {
  language: PropTypes.string,
  mailGonder: PropTypes.bool,
  forMailGonder: PropTypes.number,
  forPanelOran: PropTypes.number,
  panelOran: PropTypes.number,
};

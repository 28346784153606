/* eslint-disable no-return-assign */
/* eslint-disable no-const-assign */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable new-cap */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import PrintIcon from '@material-ui/icons/Print';
import {
  makeStyles,
  Button
} from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import Page from 'src/components/Page';
import InternalRouterHorizontal from './internalRouterHorizontal';
import ReportContent from './reportContent';

// GiReceiveMoney

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: theme.spacing(3)
  },
  formControl: {
    margin: 0,
    width: '80%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    maxWidth: 500,
    minWidth: 400,
  },
}));

const ReportPage = ({ className, ...rest }) => {
  const { projectId } = useParams();
  const classes = useStyles();

  let componentRef = useRef();

  return (
    <>
      <Page
        className={classes.root}
        title="solarVis | Proje Özeti ve Rapor"
      >
        <InternalRouterHorizontal projectId={projectId} />
        {/* <ReportContent
          projectId={projectId}
          purpose="display"
        /> */}
        <ReactToPrint
          trigger={() => <Button style={{ position: 'fixed', zIndex: 999, right: '40px' }} variant="contained" color="primary" startIcon={<PrintIcon />}>Rapor Oluştur</Button>}
          content={() => componentRef}
        />
        <ReportContent
          projectId={projectId}
          purpose="print"
          printRef={(el) => (componentRef = el)}
        />
      </Page>
    </>
  );
};

ReportPage.propTypes = {
  className: PropTypes.string
};

export default ReportPage;

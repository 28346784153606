import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  totalPageNumbers: null,
  leadPerPage: 8,
  currentPage: 1
};

export const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    changeTotalPageNumbers: (state, action) => {
      state.totalPageNumbers = action.payload.totalPageNumbers;
    },
    changeLeadPerPage: (state, action) => {
      state.leadPerPage = action.payload.leadPerPage;
    },
    changeCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    resetState: () => initialState,
  }
});

// Action creators are generated for each case reducer function
export const {
  changeTotalPageNumbers,
  changeLeadPerPage,
  changeCurrentPage,
  resetState
} = filterSlice.actions;

export default filterSlice.reducer;

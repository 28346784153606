import { configureStore } from '@reduxjs/toolkit';
import filterReducer from 'src/services/products/filterReducer';
import maliyetReducer from 'src/services/products/maliyetReducer';
import projectReducer from 'src/services/products/projectReducer';
import userReducer from '../../services/users/userReducer';
import authReducer from '../../services/auth/authReducer';
import moduleReducer from '../../services/products/moduleReducer';

export default configureStore({
  reducer: {
    users: userReducer,
    filters: filterReducer,
    auth: authReducer,
    maliyet: maliyetReducer,
    project: projectReducer,
    module: moduleReducer
  }
});

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-prototype-builtins */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import axiosInstance from 'src/utils/axiosApi';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n';
import {
  Button,
  Dialog,
  DialogActions,
  FormControl,
  Select,
  InputBase,
  DialogTitle,
  IconButton,
  DialogContent,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  makeStyles,
  Snackbar
} from '@material-ui/core';
import Spinner from 'src/components/Spinner/Spinner';
import _ from 'lodash';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledIconButton = withStyles({
  root: {
    margin: 2,
    textTransform: 'none',
    fontSize: 16,
    backgroundColor: '#e1f3ff',
    color: '#1f7394',
    padding: 8
  }
})(IconButton);

const StyledDialogTitle = withStyles({
  root: {
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingBottom: 0
  }
})(DialogTitle);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: theme.spacing(3)
  },
  formControl: {
    margin: 0,
    minWidth: 100,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const BootstrapSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '200px'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '60%'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
  },
}))(TableCell);

const StyledTableCellSmall = withStyles(() => ({
  root: {
    padding: 8,
    width: '35%'
  },
}))(TableCell);

export default function AddItemDialog() {
  const { t, } = useTranslation();
  const [open, setOpen] = useState(false);
  const [kalemlerListesi, setKalemlerListesi] = useState([]);
  const [materialInfo, setMaterialInfo] = useState({
    material_category: 'solar_panel',
    human_readable_key: 'Solar Panel',
    manufacturer_company: '',
    material_id: '',
    adet_fiyat: '-',
    wattpeak: '',
    artis: 'adet'
  });

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [pageLoading, setPageLoading] = useState(false);

  const [allProducts, setAllProducts] = useState({});

  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /* const handleChangeInput = (event) => {
    setMaterialInfo({
      ...materialInfo,
      [event.target.name]: event.target.type ===
      'number' ? Number(event.target.value) : String(event.target.value)
    });
  }; */

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  const mapCategoryInfo = (category) => {
    const categoryInfo = {
      solar_panel: {
        wattpeak: '0',
        adet_fiyat: '-',
        artis: 'adet',
      },
      inverter: {
        wattpeak: '-',
        adet_fiyat: '0',
        artis: 'adet'
      },
      profit_margin: {
        wattpeak: '-',
        adet_fiyat: '0',
        artis: 'yuzde'
      },
      unexpected_cost: {
        wattpeak: '-',
        adet_fiyat: '0',
        artis: 'yuzde'
      },
      scada: {
        wattpeak: '-',
        adet_fiyat: '0',
        artis: 'sabit'
      },
      shipping_cost: {
        wattpeak: '-',
        adet_fiyat: '0',
        artis: 'sabit'
      },
      project_cost: {
        wattpeak: '-',
        adet_fiyat: '0',
        artis: 'sabit'
      },
    };
    if (!categoryInfo.hasOwnProperty(category)) {
      return {
        wattpeak: '0',
        adet_fiyat: '-',
        artis: 'dogrusal',
      };
    }
    return categoryInfo[category];
  };

  const handleChangeSelect = (event) => {
    if (event.target.name === 'material_category') {
      const firstMaterialManufacturer = Object.keys(allProducts[event.target.value].material_list_acc_to_manufacturer_company)[0];
      const firstMaterial = Object.keys(allProducts[event.target.value].material_list_acc_to_manufacturer_company[firstMaterialManufacturer])[0];
      const categorySpecificInfo = mapCategoryInfo(event.target.value);
      setMaterialInfo({
        ...materialInfo,
        ...categorySpecificInfo,
        material_category: event.target.value,
        material_id: firstMaterial.material_id,
        manufacturer_company: firstMaterialManufacturer,
      });
    } else {
      setMaterialInfo({
        ...materialInfo,
        [event.target.name]: event.target.type === 'number' ? Number(event.target.value) : String(event.target.value)
      });
    }
  };

  const fetchMaterialFields = async () => {
    setPageLoading(true);
    await axiosInstance.get(`malzeme/fetch_material_fields_api?language=${(localStorage.getItem('language') || String(i18n.language).split('-')[0]).toUpperCase()}`)
      .then((response) => {
        const tempList = [];
        const physicalCategoriesItems = response.data.physical_categories;
        const nonPhysicalCategoriesItems = response.data.nonphysical_categories;
        Object.keys(physicalCategoriesItems).map((itemType) => {
          tempList.push(
            {
              human_readable_key: physicalCategoriesItems[itemType].human_readable_key,
              material_category: itemType
            }
          );
        });
        Object.keys(nonPhysicalCategoriesItems).map((itemType) => {
          tempList.push(
            {
              human_readable_key: nonPhysicalCategoriesItems[itemType].human_readable_key,
              material_category: itemType
            }
          );
        });
        setKalemlerListesi(tempList);
      })
      .catch();

    // fetchMaterials
    await axiosInstance.get(`malzeme/CRUD_material/get_materials_api?material_ids=&material_categories=&manufacturer_companies=&language=${(localStorage.getItem('language') || String(i18n.language).split('-')[0]).toUpperCase()}`)
      .then((response) => {
        const firstMaterialManufacturer = Object.keys(response.data.all_products[materialInfo.material_category].material_list_acc_to_manufacturer_company)[0];
        const firstMaterial = Object.keys(response.data.all_products[materialInfo.material_category].material_list_acc_to_manufacturer_company[firstMaterialManufacturer])[0];
        setMaterialInfo((prevState) => ({
          ...prevState,
          material_id: firstMaterial.material_id,
          manufacturer_company: firstMaterialManufacturer,
        }));
        setAllProducts(response.data.all_products);
      })
      .catch();
    setPageLoading(false);
  };

  const handleSend = () => {
    axiosInstance.post('malzeme_veritabani/add?format=json', materialInfo)
      .then((response) => {
        setOpen(false);
        if (response.data.status) {
          window.location.reload();
          setSnackbarMessage(response.data.message);
          setSnackbarType('success');
          setSnackbarState(true);
          // alert('İşleminiz başarılı bir şekilde gerçekleştirildi.');
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
          // alert('Böyle bir kayıt bulunamadı.');
        }
      })
      .catch((error) => {
        setOpen(false);
        // alert('Şu an bu işlemi gerçekleştiremiyoruz. Lütfen tekrar deneyin.');
        setSnackbarMessage(t('AlertMessage.generalError'));
        setSnackbarType('warning');
        setSnackbarState(true);
        console.log(error);
      });
  };

  useEffect(() => {
    fetchMaterialFields();
  }, []);

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <StyledIconButton aria-label="add" onClick={handleClickOpen}>
          <AddCircleIcon />
        </StyledIconButton>
        <span style={{ marginLeft: '10px' }}>{t('MaterialsPage.addMaterialText')}</span>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title" disableTypography>{t('MaterialsPage.addMaterialText')}</StyledDialogTitle>
        {pageLoading && <><Spinner text={t('MaterialsPage.loadingMaterials')} /></>}
        {!pageLoading && (
        <DialogContent>
          <p style={{ padding: '8px 0' }}>
            {t('MaterialsPage.addMaterialDescription')}
          </p>
          {!(_.isEmpty(allProducts))
          && (
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCellSmall>{t('MaterialsPage.materialOrService')}</StyledTableCellSmall>
                <StyledTableCell align="left">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={materialInfo.material_category}
                      displayEmpty
                      onChange={handleChangeSelect}
                      label="Materyal Tipi"
                      notched
                      name="material_category"
                      input={<BootstrapSelect />}
                    >
                      {kalemlerListesi.map((item) => (
                        <MenuItem value={item.material_category}>
                          {item.human_readable_key}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </StyledTableCell>
              </TableRow>
              {['solar_panel',
                'inverter',
                'solar_cable',
                'construction_material',
                'electrical_panel',
                'scada',
              ].includes(materialInfo.material_category)
              && (
              <TableRow>
                <StyledTableCellSmall>{t('MaterialsPage.manufacturer')}</StyledTableCellSmall>
                <StyledTableCell align="left">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={materialInfo.manufacturer_company}
                      displayEmpty
                      onChange={handleChangeSelect}
                      label="Üretici Firma"
                      notched
                      name="manufacturer_company"
                      input={<BootstrapSelect />}
                    >
                      {Object.keys(allProducts[materialInfo.material_category].material_list_acc_to_manufacturer_company).map((item) => (
                        <MenuItem value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </StyledTableCell>
              </TableRow>
              )}
              {['solar_panel',
                'inverter',
                'solar_cable',
                'construction_material',
                'electrical_panel',
                'scada',
              ].includes(materialInfo.material_category)
              && (
              <TableRow>
                <StyledTableCellSmall>{t('MaterialsPage.model')}</StyledTableCellSmall>
                <StyledTableCell align="left">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={materialInfo.material_id}
                      displayEmpty
                      onChange={handleChangeSelect}
                      label="Model"
                      notched
                      name="material_id"
                      input={<BootstrapSelect />}
                    >
                      {allProducts[materialInfo.material_category].material_list_acc_to_manufacturer_company[materialInfo.manufacturer_company].map((product) => (
                        <MenuItem value={product.material_id}>
                          {product.product_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </StyledTableCell>
              </TableRow>
              )}
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCellSmall>{t('MaterialsPage.pcsPrice')} ($)</StyledTableCellSmall>
                <StyledTableCell align="left">
                  {materialInfo.adet_fiyat !== '-'
                  && (
                  <FormControl>
                    <BootstrapInput
                      name="adet_fiyat"
                      type="number"
                      // defaultValue={row.pieces}
                      value={materialInfo.adet_fiyat}
                      inputProps={{ min: 0, step: 1 }}
                      onChange={handleChangeSelect}
                      disabled={(materialInfo.material_category === 'solar_panel')}
                    />
                  </FormControl>
                  )}
                  {materialInfo.adet_fiyat === '-' && <span>-</span>}
                </StyledTableCell>
              </TableRow>
            </TableBody>
            <TableRow key="wattpeak">
              <StyledTableCellSmall align="left">/Wp ($)</StyledTableCellSmall>
              <StyledTableCell align="left">
                {materialInfo.wattpeak !== '-'
                && (
                <FormControl>
                  <BootstrapInput
                    name="wattpeak"
                    type="number"
                    // defaultValue={row.pieces}
                    value={materialInfo.wattpeak}
                    inputProps={{ min: 0, step: 0.01 }}
                    onChange={handleChangeSelect}
                    disabled={(materialInfo.material_category === 'inverter')}
                  />
                </FormControl>
                )}
                {materialInfo.wattpeak === '-' && <span>-</span>}
              </StyledTableCell>
            </TableRow>
            <TableRow key="artis">
              <StyledTableCellSmall align="left">{t('MaterialsPage.incerement')}</StyledTableCellSmall>
              <StyledTableCell align="left">{t(`MaterialsPage.${materialInfo.artis}`)}</StyledTableCell>
            </TableRow>
          </Table>
          )}
        </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('Button.back')}
          </Button>
          <Button onClick={handleSend} color="primary" variant="contained">
            {t('Button.add')}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

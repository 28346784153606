/* eslint-disable max-len */
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  makeStyles,
  CardContent,
  Grid,
  Container,
  Fab,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import 'reactjs-popup/dist/index.css';
import axiosInstance from 'src/utils/axiosApi';
import Page from 'src/components/Page';
import Spinner from 'src/components/Spinner/Spinner';
import { format } from 'date-fns';

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: 0,
    paddingBottom: 0
  },
  /* cardContentRoot: {
    paddingBottom: 0
  } */
}));

const ProjectsList = ({ className, ...rest }) => {
  const MUIclasses = useStyles();
  const [loading, setLoading] = useState(false);
  const [projectPreviews, setProjectPreviews] = useState([]);

  // const largeYerlesim = 3;

  const fetchProjectPreviews = async () => {
    setLoading(true);
    let tempProjectPreviews;
    await axiosInstance.get('designer/fetch_projects_preview?format=json')
      .then((response) => {
        tempProjectPreviews = response.data.data;
        /* setProjectPreviews(response.data.data); */
        /* setLoading(false); */
      })
      .catch((error) => console.log(error));

    const promises = tempProjectPreviews.map(async (preview) => {
      const previewImage = await axiosInstance.get(`designer/fetch_project_image?project_id=${preview.project_id}&format=json`, {
        responseType: 'blob'
      })
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'image/*' }
          );
          // console.log(response);
          // Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          return fileURL;
        })
        .catch((error) => console.log(error));
      return {
        ...preview,
        image_preview: previewImage
      };
    });
    const newPreviewObjects = await Promise.all(promises);
    setProjectPreviews(newPreviewObjects);
    setLoading(false);
  };

  const willMount = useRef(true);
  if (willMount.current) {
    fetchProjectPreviews();
    willMount.current = false;
  }

  if (loading) {
    return (
      <>
        <Spinner text="Projeler yükleniyor.." />
      </>
    );
  }

  return (
    <Page
      className={MUIclasses.root}
      title="solarVis | Projeler"
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <span style={{ fontWeight: 'bold' }}>Projeler</span>
          </Grid>
          {projectPreviews.map((project) => (
            <>
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <Card
                  className={clsx(MUIclasses.cardRoot, className)}
                  {...rest}
                >
                  <CardContent
                    className={clsx(MUIclasses.cardContentRoot, className)}
                  >
                    <StyledLink to={`${project.project_id}/project-info`}>
                      <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                        <div style={{
                          padding: '0px 10px', width: '10%', display: 'flex', alignItems: 'center'
                        }}
                        >
                          <img
                            style={{
                              width: '60px',
                              height: '60px',
                              borderRadius: '50%'
                            }}
                            src={project.image_preview}
                            alt={project.project_id}
                          />
                        </div>
                        <div style={{ padding: '0px 10px', fontWeight: 'bold', width: '25%' }}>
                          <p>{project.proje_ismi}</p>
                          <p>
                            {project.dc_guc}
                            kWp
                          </p>
                        </div>
                        <div style={{ padding: '0px 10px', width: '25%' }}>
                          <p>{project.acik_adres}</p>
                        </div>
                        <div style={{ padding: '0px 10px', width: '40%' }}>
                          <p>
                            Oluşturma Tarihi:
                            {format(new Date(project.created_at), 'dd/MM/yyyy HH:mm')}
                          </p>
                        </div>
                      </div>
                    </StyledLink>
                  </CardContent>
                </Card>
              </Grid>
            </>
          ))}
          <div style={{ position: 'fixed', right: '4.5rem', bottom: '4rem' }}>
            <Link to="new-project">
              <Fab color="primary" aria-label="add">
                <AddIcon />
              </Fab>
            </Link>
          </div>
        </Grid>
      </Container>
    </Page>
  );
};

ProjectsList.propTypes = {
  className: PropTypes.string
};

export default ProjectsList;

import React from 'react';
import { NavLink as RouterLink, Link as DefaultLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  List,
  Collapse,
  makeStyles
} from '@material-ui/core';
import {
  Users as UsersIcon,
  Settings as SettingsIcon,
  Box as BoxIcon,
  Percent as PercentIcon
} from 'react-feather';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  nested: {
    paddingLeft: theme.spacing(4),
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto',
    textAlign: 'left'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const NavItem = ({
  className,
  href,
  icon,
  title,
  collapsable,
  nestedItems,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const getIcon = (text) => {
    let iconTemp;
    if (text === 'users') {
      iconTemp = <UsersIcon className={classes.icon} size="20" />;
    } else if (text === 'box') {
      iconTemp = <BoxIcon className={classes.icon} size="20" />;
    } else if (text === 'settings') {
      iconTemp = <SettingsIcon className={classes.icon} size="20" />;
    } else if (text === 'percentage') {
      iconTemp = <PercentIcon className={classes.icon} size="20" />;
    }
    return iconTemp;
  };

  const items = (
    nestedItems.map((nestedLink) => (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            className={clsx(classes.nested, className)}
            disableGutters
            {...rest}
          >
            <DefaultLink
              to={nestedLink.href}
              state={{ data: nestedLink }}
              style={{ width: '100%' }}
            >
              <Button
                activeClassName={classes.active}
                className={classes.button}
                /* component={RouterLink}
                to={nestedLink.href} */
              >
                {/* {nestedLink.icon && getIcon(nestedLink.icon)} */}
                <span className={classes.title}>
                  {nestedLink.title}
                </span>
              </Button>
            </DefaultLink>
          </ListItem>
        </List>
      </Collapse>
    ))
  );

  return (
    <>
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        {...rest}
      >
        {collapsable
        && (
        <Button
          activeClassName={classes.active}
          className={classes.button}
          onClick={collapsable ? handleClick : null}
        >
          {icon && getIcon(icon)}
          <span className={classes.title}>
            {title}
          </span>
          {collapsable && (
            open ? <ExpandLess /> : <ExpandMore />
          )}
        </Button>
        )}
        {!collapsable
        && (
        <Button
          activeClassName={classes.active}
          className={classes.button}
          component={RouterLink}
          to={href}
          onClick={collapsable ? handleClick : null}
        >
          {icon && getIcon(icon)}
          <span className={classes.title}>
            {title}
          </span>
          {collapsable && (
            open ? <ExpandLess /> : <ExpandMore />
          )}
        </Button>
        )}
      </ListItem>
      {collapsable && items}
    </>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  collapsable: PropTypes.bool,
  nestedItems: PropTypes.object
};

export default NavItem;

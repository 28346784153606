/* eslint-disable */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable no-multi-assign */



import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
// import i18n from 'src/i18n';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardHeader,
  makeStyles,
  CardContent,
  Grid,
  Container,
  TextField,
  Divider,
  MenuItem,
  Select,
  InputBase,
  InputAdornment,
  IconButton,
  Snackbar,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  withStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import 'reactjs-popup/dist/index.css';
import Page from 'src/components/Page';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import 'date-fns';
import axiosInstance from 'src/utils/axiosApi';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import MuiAlert from '@material-ui/lab/Alert';
import Switch from '@material-ui/core/Switch';
import { useLovelySwitchStyles } from '@mui-treasury/styles/switch/lovely';
import ConfSaveDialog from 'src/components/ConfSaveDialog';

const StyledSelect = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
})(Select);

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    marginRight: 30,
    backgroundColor: 'whitesmoke',
    borderRadius: 4
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'whitesmoke',
    // border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      // borderColor: '#80bdff',
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: theme.spacing(3)
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
}));

const StyledDialogTitle = withStyles({
  root: {
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingBottom: 0
  }
})(DialogTitle);

/* const StyledButton = withStyles({
  root: {
    textDecoration: 'none'
  }
})(Button); */

const UserInfoPage = ({ className, ...rest }) => {
  const { t, } = useTranslation();

  const classes = useStyles();
  const lovelyStyles = useLovelySwitchStyles();

  const [open, setOpen] = React.useState(false);
  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const { language } = useSelector((state) => state.users);
  const [langLocalState, setLangLocalState] = useState(language);

  const handleChangeLanguage = (event) => {
    setLangLocalState(event.target.value);
  };

  const [passwords, setPasswords] = useState({
    currentPassword: '',
    showCurrentPassword: false,
    newPassword: '',
    showNewPassword: false,
    newPasswordConfirm: '',
    showNewPasswordConfirm: false,
  });

  const [mailNotify, setMailNotify] = useState(false);

  // const largeYerlesim = 3;

  async function getUserInfo() {
    await axiosInstance.get('settings/user/fetch?format=json')
      .then((response) => {
        if (response.data.status) {
          const data = response.data.data;
          setMailNotify(data.mail_notify);
        }
      })
      .catch((error) => console.log(error));
  }

  const willMount = useRef(true);
  if (willMount.current) {
    getUserInfo();
    willMount.current = false;
  }

  const handleChangePassword = (event) => {
    setPasswords({
      ...passwords,
      [event.target.name]: event.target.value
    });
  };

  const handleClickShowPassword = (prop) => {
    setPasswords({
      ...passwords,
      [prop]: !passwords[prop]
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangePw = () => {
    // http://api.localhost/settings/save?domain=localhost&variables=[mail_gonder|maliyet_x|maliyet_y|maliyet_z|mw_alan|panel_gucu|kayip_yuzdesi|co2_katsayi|agac_katsayi|komisyon_orani]
    axiosInstance.post('change_pw?format=json', {
      new_password: passwords.newPassword,
      new_password_confirm: passwords.newPasswordConfirm,
      current_password: passwords.currentPassword,
    })
      .then((response) => {
        setOpen(false);
        if (response.data.status) {
          setSnackbarMessage(response.data.message);
          setSnackbarType('success');
          setSnackbarState(true);
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
        }
      })
      .catch(() => {
        setOpen(false);
        setSnackbarMessage(t('AlertMessage.generalError'));
        setSnackbarType('warning');
        setSnackbarState(true);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  return (
    <Page
      className={classes.root}
      title={t('ConfigurationPage.pageTitle')}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          {/* <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card
              className={clsx(classes.cardRoot, className)}
              {...rest}
            >
              <CardHeader
                title={t('ConfigurationPage.sifreDegistir')}
              />
              <Divider />
              {/* <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={4}
                    lg={4}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label={t('ConfigurationPage.currentPassword')}
                      name="currentPassword"
                      onChange={handleChangePassword}
                      required
                      value={passwords.currentPassword}
                      variant="outlined"
                      type={passwords.showCurrentPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword('showCurrentPassword')}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {passwords.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    lg={4}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label={t('ConfigurationPage.newPassword')}
                      name="newPassword"
                      onChange={handleChangePassword}
                      required
                      value={passwords.newPassword}
                      variant="outlined"
                      type={passwords.showNewPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword('showNewPassword')}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {passwords.showNewPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    lg={4}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label={t('ConfigurationPage.newPasswordAgain')}
                      name="newPasswordConfirm"
                      onChange={handleChangePassword}
                      required
                      value={passwords.newPasswordConfirm}
                      variant="outlined"
                      type={passwords.showNewPasswordConfirm ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword('showNewPasswordConfirm')}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {passwords.showNewPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Box
                display="flex"
                justifyContent="flex-start"
                p={2}
              >
                <div>
                  <Button
                    color="primary"
                    endIcon={<ArrowRightIcon />}
                    size="small"
                    variant="contained"
                    onClick={handleClickOpen}
                  >
                    {t('ConfigurationPage.guncelle')}
                  </Button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <StyledDialogTitle id="alert-dialog-title" disableTypography>{t('ConfigurationPage.passwordChangedTitle')}</StyledDialogTitle>
                    <DialogContent>
                      <p style={{ padding: '8px 0' }}>
                        {t('ConfigurationPage.passwordChanged')}
                      </p>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        {t('ConfigurationPage.geriDonButonu')}
                      </Button>
                      <Button onClick={handleChangePw} color="primary" variant="contained">
                        {t('ConfigurationPage.guncelle')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </Box>
            </Card>
          </Grid> */}
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card
              className={clsx(classes.cardRoot, className)}
              {...rest}
            >
              <CardHeader
                title={t('ConfigurationPage.preferences')}
              />
              <Divider />
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={4}
                    lg={4}
                    xs={12}
                  >
                    <p style={{ fontSize: '14px', marginBottom: '5px' }}>{t('ConfigurationPage.language')}</p>
                    <StyledSelect
                      labelId="Change-language"
                      id="change-language"
                      value={langLocalState}
                      onChange={handleChangeLanguage}
                      label="Language"
                      variant="outlined"
                      input={<BootstrapInput />}
                    >
                      <MenuItem value="tr">
                        <img
                          style={{ width: 20, marginRight: 10 }}
                          alt="Turkey"
                          src="/static/images/turkey.svg"
                        />
                        Türkçe
                      </MenuItem>
                      <MenuItem value="en">
                        <img
                          style={{ width: 20, marginRight: 10 }}
                          alt="US"
                          src="/static/images/united-states.svg"
                        />
                        English
                      </MenuItem>
                    </StyledSelect>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    lg={4}
                    xs={12}
                  >
                    <p style={{ fontSize: '14px' }}>{t('ConfigurationPage.bilgilendirmeMail')}</p>
                    <Switch
                      classes={lovelyStyles}
                      checked={mailNotify}
                      onChange={(e) => setMailNotify(e.target.checked)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Box
                display="flex"
                justifyContent="flex-start"
                p={2}
              >
                <ConfSaveDialog
                  mailGonder={mailNotify}
                  language={langLocalState}
                  forMailGonder={1}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Page>
  );
};

UserInfoPage.propTypes = {
  className: PropTypes.string
};

export default UserInfoPage;

/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import axiosInstance from 'src/utils/axiosApi';
import { useTranslation } from 'react-i18next';
import Spinner from '../components/Spinner/Spinner';
import {
  Button,
  Dialog,
  DialogActions,
  FormControl,
  InputBase,
  DialogTitle,
  DialogContent,
  Table,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  makeStyles,
  Select,
  MenuItem,
  Snackbar
} from '@material-ui/core';
import Dropzone from 'react-dropzone-uploader';
import i18n from 'src/i18n';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import { useMediaQuery } from 'react-responsive';
import MuiAlert from '@material-ui/lab/Alert';
import PureCssSpinner from './Spinner/PureCssSpinner';
import GeneralAddDialog from './GeneralAddDialog2';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledDialog = withStyles({
  paper: {
    margin: 16,
    maxWidth: 900,
    minWidth: 300
  }
})(Dialog);

const StyledDialogTitle = withStyles({
  root: {
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingBottom: 0
  }
})(DialogTitle);

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: theme.spacing(3)
  },
  formControl: {
    margin: 0,
    minWidth: 100
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const BootstrapSelect = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    },
    width: '200px'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase);

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    },
    width: '100%'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      boxShadow: `${(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins'
  }
}))(TableCell);

const StyledTableCellSmall = withStyles(() => ({
  root: {
    padding: 8,
    width: '35%',
    fontFamily: 'Poppins'
  }
}))(TableCell);

export default function GeneralDialog(props) {
  const {
    dialogTitle,
    dialogContent,
    requestURL,
    reloadPageState,
    reloadPageFunc,
    requestMethod,
    openState,
    setOpenState,
    inputs,
    dontDisableButton,
    bodyData,
    changeDataFunction,
    externalFormData,
    setExternalFormData
  } = props;

  const { user_comment, ...excludedCommentKeyBodyData } = bodyData;

  const { t } = useTranslation();

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [uploadedFile, setUploadedFile] = useState(false);

  const [fileUploaded, setFileUploaded] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });

  const classes = useStyles();

  const handleClose = () => {
    setOpenState(false);
    setFileUploaded(false);
    setUploadedFiles([]);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  const fileParams = ({ file, meta }) => {
    return { url: 'https://httpbin.org/post' };
  };

  const getFilesFromEvent = e => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then(chosenFiles => {
        resolve(
          chosenFiles.map(f => {
            setUploadedFiles(prevState => [...prevState, f.fileObject]);
            return f.fileObject;
          })
        );
      });
    });
  };

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    if (status === 'done') {
      setFileUploaded(true);
    }
    return (
      <>
        <img style={{ width: '60%' }} src={previewUrl} alt={name} />
        <span style={{ marginTop: '45px' }}>
          <b>{name}</b> adlı dosya{' '}
          {fileUploaded
            ? 'yüklendi'
            : uploadedFile
            ? 'yükleniyor'
            : 'yüklenmeye hazır'}
          <br />
          {/* Loading spinner */}
          <p style={{ textAlign: 'center' }}>
            <PureCssSpinner />
          </p>
          {/* <p style={{ textAlign: 'center' }}>
            <b>{Math.round(percent) + '%'}</b>
          </p> */}
        </span>
      </>
    );
  };

  const selectFileInput = ({ accept, onFiles, files }) => {
    const textMsg = t('DocumentManagementPage.selectDocument');

    return (
      <>
        <label
          className="btn mt-4"
          style={{
            width: '50%',
            backgroundColor: '#cbddea',
            padding: '10px',
            cursor: 'pointer',
            borderRadius: '8px',
            textAlign: 'center'
          }}
        >
          {textMsg}
          <input
            style={{ display: 'none' }}
            type="file"
            accept={accept}
            multiple
            onChange={e => {
              getFilesFromEvent(e).then(chosenFiles => {
                onFiles(chosenFiles);
              });
            }}
          />
        </label>
        <span
          style={{ color: '#a6a6a5', fontSize: '13px', marginTop: '10px' }}
        >{`${t(
          'DocumentManagementPage.allowedDocumentExtensions'
        )}.pdf, .xlsx, .png, .jpg`}</span>
      </>
    );
  };

  const handleDeleteFiles = (files, allFiles) => {
    allFiles.forEach(f => f.remove());
    setUploadedFiles([]);
    setFileUploaded(false);
  };

  const handleSend = () => {
    if (requestMethod === 'POST') {
      axiosInstance
        .post(requestURL, bodyData)
        .then(response => {
          if (response.data.status) {
            setOpenState(false);
            setSnackbarMessage(response.data.message);
            setSnackbarType('success');
            setSnackbarState(true);
            reloadPageFunc(!reloadPageState);
            // alert('İşleminiz başarılı bir şekilde gerçekleştirildi.');
          } else {
            setSnackbarMessage(response.data.message);
            setSnackbarType('warning');
            setSnackbarState(true);
            // alert('Böyle bir kayıt bulunamadı.');
          }
        })
        .catch(error => {
          setOpenState(false);
          setSnackbarMessage(t('AlertMessage.generalError'));
          setSnackbarType('warning');
          setSnackbarState(true);
        });
    }
    if (requestMethod === 'POST+FILE') {
      // Then send files with using document category id
      setUploadedFile(true);
      uploadedFiles.map(file => {
        // console.log(file);
        const fileNameArray = file.name.split('.');
        const extension = fileNameArray[fileNameArray.length - 1];

        const body = new FormData();
        const jsonData = {
          file_document_category_id: bodyData.file_document_category_id,
          subcon_project_id: bodyData.subcon_project_id,
          user_comment: bodyData.user_comment
        };
        body.append('json_data_str', JSON.stringify(jsonData));
        body.append('file', file);

        axiosInstance
          .post(requestURL, body, {
            Headers: { contentType: 'multipart/form-data' }
          })
          .then(response => {
            if (response.data.status) {
              setSnackbarMessage(response.data.message);
              setSnackbarType('success');
              setSnackbarState(true);
              setOpenState(false);
              setUploadedFiles([]);
              setFileUploaded(true);

              setTimeout(() => {
                reloadPageFunc(!reloadPageState);
              }, 1500);

              // alert('İşleminiz başarılı bir şekilde gerçekleştirildi.');
            } else {
              setSnackbarMessage(response.data.message);
              setSnackbarType('warning');
              setSnackbarState(true);
              // alert('Böyle bir kayıt bulunamadı.');
            }
          })
          .catch(error => {
            // alert('Şu an bu işlemi gerçekleştiremiyoruz. Lütfen tekrar deneyin.');
            setSnackbarMessage(t('AlertMessage.generalError'));
            setSnackbarType('warning');
            setSnackbarState(true);
          })
          .finally(() => {
            setUploadedFile(false);
          });
        return file;
      });
    }

    if (requestMethod === 'FILE') {
      // send files with using document category id
      uploadedFiles.map(file => {
        // console.log(file);
        const fileNameArray = file.name.split('.');
        const extension = fileNameArray[fileNameArray.length - 1];

        const body = new FormData();
        const jsonData = {
          document_category_id: bodyData.document_category_id
        };
        body.append('json_data_str', JSON.stringify(jsonData));
        body.append('file', file);

        axiosInstance
          .post(requestURL, body, {
            Headers: { contentType: 'multipart/form-data' }
          })
          .then(response => {
            if (response.data.status) {
              setSnackbarMessage(response.data.message);
              setSnackbarType('success');
              setSnackbarState(true);
              reloadPageFunc(!reloadPageState);
              // alert('İşleminiz başarılı bir şekilde gerçekleştirildi.');
            } else {
              setSnackbarMessage(response.data.message);
              setSnackbarType('warning');
              setSnackbarState(true);
              // alert('Böyle bir kayıt bulunamadı.');
            }
          })
          .catch(error => {
            // alert('Şu an bu işlemi gerçekleştiremiyoruz. Lütfen tekrar deneyin.');
            setSnackbarMessage(t('AlertMessage.generalError'));
            setSnackbarType('warning');
            setSnackbarState(true);
            console.log(error);
          });
        return file;
      });
      setUploadedFiles([]);
      setFileUploaded(false);
      setOpenState(false);
    }

    if (requestMethod === 'BANK-PAYMENT-PLAN-UPLOAD') {
      // send files with using document category id
      uploadedFiles.map(file => {
        // console.log(file);
        const fileNameArray = file.name.split('.');
        const extension = fileNameArray[fileNameArray.length - 1];

        const body = new FormData();
        const jsonData = {
          credit_application_id: bodyData.credit_application_id,
          category_id: bodyData.category_id,
          lang:
            localStorage.getItem('language') === 'en' ||
            String(i18n.language).split('-')[0] === 'en'
              ? 'EN'
              : 'TR'
        };
        body.append('json_data_str', JSON.stringify(jsonData));
        body.append('file', file);

        setExternalFormData(body);

        axiosInstance
          .post(requestURL, body, {
            Headers: { contentType: 'multipart/form-data' }
          })
          .then(response => {
            if (response.data.status) {
              setSnackbarMessage(response.data.message);
              setSnackbarType('success');
              setSnackbarState(true);
              // alert('İşleminiz başarılı bir şekilde gerçekleştirildi.');
            } else {
              setSnackbarMessage(response.data.message);
              setSnackbarType('warning');
              setSnackbarState(true);
              // alert('Böyle bir kayıt bulunamadı.');
            }
          })
          .catch(error => {
            // alert('Şu an bu işlemi gerçekleştiremiyoruz. Lütfen tekrar deneyin.');
            setSnackbarMessage(t('AlertMessage.generalError'));
            setSnackbarType('warning');
            setSnackbarState(true);
            console.log(error);
          });
        return file;
      });
      setUploadedFiles([]);
      setFileUploaded(false);
      setOpenState(false);
    }

    if (requestMethod === 'FILE-CREDIT-APPLICATION') {
      // send files with using document category id
      uploadedFiles.map(file => {
        // console.log(file);
        const fileNameArray = file.name.split('.');
        const extension = fileNameArray[fileNameArray.length - 1];

        const body = new FormData();
        const jsonData = {
          musteri_id: bodyData.musteri_id,
          category_id: bodyData.category_id,
          lang:
            localStorage.getItem('language') === 'en' ||
            String(i18n.language).split('-')[0] === 'en'
              ? 'EN'
              : 'TR'
        };
        body.append('json_data_str', JSON.stringify(jsonData));
        body.append('file', file);

        axiosInstance
          .post(requestURL, body, {
            Headers: { contentType: 'multipart/form-data' }
          })
          .then(response => {
            if (response.data.status) {
              setSnackbarMessage(response.data.message);
              setSnackbarType('success');
              setSnackbarState(true);
              reloadPageFunc(!reloadPageState);
              // alert('İşleminiz başarılı bir şekilde gerçekleştirildi.');
            } else {
              setSnackbarMessage(response.data.message);
              setSnackbarType('warning');
              setSnackbarState(true);
              // alert('Böyle bir kayıt bulunamadı.');
            }
          })
          .catch(error => {
            // alert('Şu an bu işlemi gerçekleştiremiyoruz. Lütfen tekrar deneyin.');
            setSnackbarMessage(t('AlertMessage.generalError'));
            setSnackbarType('warning');
            setSnackbarState(true);
            console.log(error);
          });
        return file;
      });
      setUploadedFiles([]);
      setFileUploaded(false);
      setOpenState(false);
    }
  };

  return (
    <div>
      <StyledDialog
        open={openState}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title" disableTypography>
          {dialogTitle}
        </StyledDialogTitle>
        <DialogContent>
          <p style={{ padding: '8px 0' }}>{dialogContent}</p>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              {inputs.map(row => (
                <TableRow key={row.title}>
                  <StyledTableCellSmall
                    align="left"
                    style={{
                      borderBottom:
                        row === inputs[inputs.length - 1]
                          ? 'none'
                          : '1px solid rgba(224, 224, 224, 1)'
                    }}
                  >
                    {row.title}
                  </StyledTableCellSmall>
                  <StyledTableCell
                    align="left"
                    style={{
                      borderBottom:
                        row === inputs[inputs.length - 1]
                          ? 'none'
                          : '1px solid rgba(224, 224, 224, 1)'
                    }}
                  >
                    <FormControl style={{ minWidth: 100 }}>
                      {row.type === 'text' && (
                        <BootstrapInput
                          name={row.name}
                          type="text"
                          value={row.value}
                          multiline
                          style={{ width: '300px' }}
                          onChange={changeDataFunction}
                          disabled={row.name === 'document_category_name'}
                        />
                      )}
                      {row.type === 'select' && (
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={row.value}
                          name={row.name}
                          displayEmpty
                          onChange={changeDataFunction}
                          label={row.title}
                          input={<BootstrapSelect />}
                        >
                          {row.choices.map(choice => (
                            <MenuItem value={choice.value}>
                              {choice.title}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                      {row.type === 'file' && (
                        <Dropzone
                          onSubmit={handleDeleteFiles}
                          PreviewComponent={Preview}
                          SubmitButtonComponent={() => (
                            <Button
                              onClick={handleDeleteFiles}
                              style={{ marginTop: 24 }}
                              variant="contained"
                              color="primary"
                            >
                              {t('SingleCustomerPage.deleteUploadedDocument')}
                            </Button>
                          )}
                          // onChangeStatus={onFileChange}
                          InputComponent={selectFileInput}
                          getUploadParams={fileParams}
                          getFilesFromEvent={getFilesFromEvent}
                          // accept="/*"
                          maxFiles={1}
                          inputContent="Dosya Yükle"
                          styles={{
                            dropzone: {
                              width: isDesktopOrLaptop ? 600 : '100%',
                              height: 400,
                              justifyContent: 'center',
                              overflowX: 'hidden',
                              overflowY: 'scroll'
                            },
                            dropzoneActive: { borderColor: 'green' },
                            previewImage: {
                              maxHeight: '100px',
                              maxWidth: '300px'
                            },
                            preview: { minHeight: 'auto' }
                          }}
                        />
                      )}
                    </FormControl>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            style={{ textTransform: 'none' }}
          >
            {t('Button.back')}
          </Button>
          {!dontDisableButton && (
            <Button
              onClick={() => handleSend()}
              color="primary"
              variant="contained"
              style={{ textTransform: 'none' }}
              disabled={
                requestMethod === 'POST+FILE'
                  ? Object.values(excludedCommentKeyBodyData).some(
                      x => x === null || x === ''
                    ) || uploadedFile
                  : Object.values(bodyData).some(x => x === null || x === '')
              }
            >
              {t('Button.add')}
            </Button>
          )}
          {dontDisableButton && (
            <Button
              onClick={() => handleSend()}
              color="primary"
              variant="contained"
              style={{ textTransform: 'none' }}
            >
              {t('Button.add')}
            </Button>
          )}
        </DialogActions>
      </StyledDialog>
      <Snackbar
        open={snackbarState}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

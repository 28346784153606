/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import axiosInstance from 'src/utils/axiosApi';
import StepContent from '@material-ui/core/StepContent';
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import StepConnector from '@material-ui/core/StepConnector';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
  vertical: {
    marginLeft: 4,
  }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

export default function VerticalLinearStepper(props) {
  const { t, } = useTranslation();
  const classes = useStyles();
  const { activities } = props;

  // Aktiviteleri created_at değerine göre sırala:
  activities.sort((a, b) => {
    if (a.created_at > b.created_at) {
      return -1;
    }
    if (a.created_at < b.created_at) {
      return 1;
    }
    return 0;
  });

  return (
    <div className={classes.root}>
      <Stepper orientation="vertical" connector={<QontoConnector />}>
        {activities.map((activity) => (
          <Step key={activity.activity_id} active={true}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <span style={{ fontSize: '0.95rem' }}>{activity.notification_in_app_title}</span>
            </StepLabel>
            <StepContent style={{
              marginLeft: 4,
              borderLeft: '1px solid #784af4'
            }}
            >
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontSize: '0.875rem' }}>{activity.notification_in_app_detailed_description}</span>
                  {activity.notification_user_comment && <span style={{ fontSize: '0.875rem', marginTop: '5px' }}>{`Not: ${activity.notification_user_comment}`}</span>}
                  <span style={{ fontSize: '0.8rem', marginTop: '5px' }}>{`${activity.since_created_at_value} ${activity.since_created_at_unit} ${t('SingleCustomerPage.createdAgo')}`}</span>
                </div>
                <div style={{ minWidth: 'fit-content' }}>
                  {/* <Button
                    size="small"
                    variant="contained"
                    color="#535b87"
                    style={{
                      textTransform: 'none',
                      minWidth: '100px',
                      height: '40px',
                      marginLeft: '30px',
                    }}
                  >
                    Revize Talebi İste
                  </Button> */}
                  {'file_s3_path' in activity
                  && (
                    <a href={activity.file_s3_path} target="_blank" rel="noreferrer">
                      <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        style={{
                          textTransform: 'none',
                          minWidth: '100px',
                          height: '40px',
                          marginLeft: '30px'
                        }}
                      >
                        {t('SingleCustomerPage.downloadDocument')}
                      </Button>
                    </a>
                  )}
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

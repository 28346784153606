/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable dot-notation */
import React, { useState } from 'react';
import {
  makeStyles,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputBase,
  withStyles,
  Select,
  CardHeader,
  CardContent,
  Divider,
  Box,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Snackbar
} from '@material-ui/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import 'reactjs-popup/dist/index.css';
import 'date-fns';
import axiosInstance from 'src/utils/axiosApi';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
// import SecilenMalzemelerDialog from 'src/components/SecilenMalzemelerDilog';
import PropTypes from 'prop-types';
import Spinner from 'src/components/Spinner/Spinner';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: theme.spacing(3)
  },
  formControl: {
    margin: 0,
    width: '80%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const BootstrapSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins'
  },
}))(TableCell);

const StyledDialogTitle = withStyles({
  root: {
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingBottom: 0
  }
})(DialogTitle);

const DBItems = (props) => {
  const { t, } = useTranslation();
  const classes = useStyles();
  const { gucAralıgı, title } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Kullanıcının seçebileceği seçenekler.
  // Fetch yaptıktan sonra Select'lere option olarak veriyorum.
  const [secilebilecekler, setSecilebilecekler] = React.useState({});

  // Seçili olan itemler ilgili güç aralığı
  const [selectedMalzemeler, setSelectedMalzemeler] = React.useState({});
  // Seçili olan bütün güç aralığındaki itemler
  const [selectedSend, setSelectedSend] = React.useState({});

  const fetchSecilenMalzemeler = () => {
    setLoading(true);
    axiosInstance.get('/malzeme_secimleri/fetch?format=json')
      .then((response) => {
        // console.log(response.data.response[gucAralıgı]);
        setSelectedMalzemeler(response.data.response[gucAralıgı]);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const fetchSecilebilecekMalzemeler = () => {
    axiosInstance.get('/malzeme_secimleri/options?format=json')
      .then((response) => {
        // console.log(response.data.response);
        setSecilebilecekler(response.data.response);
      })
      .catch((error) => console.log(error));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  const updateSecilenMalzemeler = () => {
    const sendData = { [gucAralıgı]: selectedSend };
    axiosInstance.post('/malzeme_secimleri/update?format=json', sendData)
      .then((response) => {
        setOpen(false);
        if (response.data.status) {
          fetchSecilebilecekMalzemeler();
          fetchSecilenMalzemeler();
          setSnackbarMessage(response.data.message);
          setSnackbarType('success');
          setSnackbarState(true);
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
        }
      })
      .catch((error) => {
        setOpen(false);
        setSnackbarMessage(t('AlertMessage.generalError'));
        setSnackbarType('warning');
        setSnackbarState(true);
        console.log(error);
      });
  };

  const willMount = React.useRef(true);
  if (willMount.current) {
    fetchSecilebilecekMalzemeler();
    fetchSecilenMalzemeler();
    willMount.current = false;
  }

  const handleChangeSelect = (event) => {
    const kalem = event.target.name;
    secilebilecekler[kalem].map((panel) => {
      const panelAsArray = Object.entries(panel);
      const filtered = panelAsArray.filter(([, value]) => value === event.target.value);
      if (!(_.isEmpty(filtered))) {
        setSelectedMalzemeler({
          ...selectedMalzemeler,
          [event.target.name]: {
            marka_model: event.target.value,
            deger: panel.deger,
            adet_fiyat: panel.adet_fiyat,
            artis: panel.artis,
            wattpeak: panel.wattpeak,
            malzeme_id: panel.malzeme_id,
            human_readable_kalem: panel.human_readable_kalem,
          }
        });
        setSelectedSend({
          ...selectedSend,
          [event.target.name]: panel.malzeme_id
        });
      }
      return panelAsArray;
    });
  };

  const generateRows = () => {
    // console.log(Object.keys(selectedMalzemeler));
    // console.log(secilebilecekler);
    // Object.keys(totalOptions).map((key) => console.log(totalOptions.key.malzeme_id));
    // console.log(totalOptions[Object.keys(selectedMalzemeler)[0]]);
    // totalOptions[Object.keys(selectedMalzemeler)[0]].map((mal) => console.log(mal));
    return Object.keys(selectedMalzemeler).map((kalem) => {
      return (
        <TableRow key={kalem}>
          <StyledTableCell component="th" scope="row">
            {selectedMalzemeler[kalem].human_readable_kalem}
          </StyledTableCell>
          <StyledTableCell align="left">
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id={kalem}
                name={kalem}
                value={selectedMalzemeler[kalem].malzeme_id}
                displayEmpty
                onChange={handleChangeSelect}
                label={`${kalem} Seçimi`}
                notched
                input={<BootstrapSelect />}
              >
                {secilebilecekler[kalem]?.map((mal) => (
                  <MenuItem value={mal.malzeme_id}>
                    {mal.adet_fiyat === '-' && <span>{mal.marka_model} | ${mal.wattpeak}/Wp</span>}
                    {mal.adet_fiyat !== '-' && mal.artis !== 'yuzde' && <span>{mal.marka_model} | ${mal.adet_fiyat}</span>}
                    {mal.adet_fiyat !== '-' && mal.artis === 'yuzde' && <span>{mal.marka_model} | {mal.adet_fiyat}%</span>}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </StyledTableCell>
          <StyledTableCell align="left">{selectedMalzemeler[kalem].adet_fiyat}</StyledTableCell>
          <StyledTableCell align="left">{selectedMalzemeler[kalem].wattpeak}</StyledTableCell>
          <StyledTableCell align="left">{t(`MaterialsPage.${selectedMalzemeler[kalem].artis}`)}</StyledTableCell>
        </TableRow>
      );
    });
  };

  if (loading) {
    return (
      <>
        <Spinner text={t('MaterialsPage.loadingSelectedMaterials')} />
      </>
    );
  }

  return (
    <>
      <CardHeader
        title={`${title} - ${t('MaterialsPage.titleSelectedMaterials')}`}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={12}
            lg={12}
            xs={12}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{t('MaterialsPage.category')}</StyledTableCell>
                    <StyledTableCell>{t('MaterialsPage.brandModel')}</StyledTableCell>
                    <StyledTableCell align="left">{t('MaterialsPage.pcsPrice')} ($)</StyledTableCell>
                    <StyledTableCell align="left">/Wp ($)</StyledTableCell>
                    <StyledTableCell align="left">{t('MaterialsPage.incerement')}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {generateRows()}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            item
            md={12}
            lg={12}
            xs={12}
          >
            <Box
              display="flex"
              justifyContent="flex-end"
            >
              <div>
                <Button
                  color="primary"
                  endIcon={<ArrowRightIcon />}
                  size="small"
                  variant="contained"
                  onClick={handleClickOpen}
                >
                  {t('MaterialsPage.update')}
                </Button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <StyledDialogTitle id="alert-dialog-title" disableTypography>{t('ConfigurationPage.ayarlarıDegistirmeSorusu')}</StyledDialogTitle>
                  <DialogContent>
                    <p style={{ padding: '8px 0' }}>
                      {t('ConfigurationPage.firmaBilgileriDegistirmeMetni')}
                    </p>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      {t('ConfigurationPage.geriDonButonu')}
                    </Button>
                    <Button onClick={updateSecilenMalzemeler} color="primary" variant="contained">
                      {t('ConfigurationPage.kaydetButon')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DBItems;

DBItems.propTypes = {
  gucAralıgı: PropTypes.string,
  title: PropTypes.string
};

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import {
  changeLocation,
  changeCity,
  changeDistrict,
  changeAddressSelected,
  changeDetailedAddress,
} from 'src/services/products/projectReducer';
import {
  withStyles,
  TextField,
} from '@material-ui/core';
import {
  MapPin as MapPinIcon,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import classes from './UsePlacesAutoComplete.module.css';

const StyledTextField = withStyles(() => ({
  root: {
    fontFamily: 'Poppins',
    marginTop: '10px'
  },
}))(TextField);

export default function PlacesAutocomplete() {
  const dispatch = useDispatch();

  const {
    detailedAddress,
  } = useSelector((state) => state.project);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: 'tr'
      }
    },
    callbackName: 'initMap',
    debounce: 300,
  });

  const willMount = React.useRef(true);
  if (willMount.current) {
    if (detailedAddress) {
      setValue(detailedAddress);
    }
    willMount.current = false;
  }

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  /* React.useEffect(() => {
    const createScriptLoadMap = () => {
      const index = window.document.getElementsByTagName('script')[0];
      const script = window.document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyC8haRE39XSEpuxc8tR7s90FVhD3NxKUzg&libraries=places&callback=initMap';
      script.async = true;
      script.defer = true;
      index.parentNode.insertBefore(script, index);
    };
    createScriptLoadMap();
  }, []); */

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    // Tekrar adres seçimi yapıldığında alan, polygon noktaları, marker sıfırla!
    // dispatch(changeArea({ roofArea: 0 }));
    // dispatch(changePolygonPoints({ polygonPoints: null }));
    // dispatch(changeMarker({ markerOn: false }));

    // Store the detailed adress for the next time
    dispatch(changeDetailedAddress({ detailedAddress: description }));

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => {
        // console.log(results);
        results[0].address_components.forEach((addressObject) => {
          if (addressObject.types[0] === 'postal_code') {
            // dispatch(changePlaka({ plaka: addressObject.short_name.slice(0, 2) }));
          } else if (addressObject.types[0] === 'administrative_area_level_2') {
            if (String(addressObject.short_name) === 'null' || String(addressObject.short_name) === 'undefined') {
              dispatch(changeDistrict({ district: 'Merkez' }));
            } else {
              dispatch(changeDistrict({ district: String(addressObject.short_name) }));
            }
          } else if (addressObject.types[0] === 'administrative_area_level_1') {
            dispatch(changeCity({ city: String(addressObject.short_name) }));
          }
        });
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        console.log(lat, lng);
        dispatch(changeLocation({ latitude: lat, longitude: lng }));
        dispatch(changeAddressSelected({ addressSelected: true }));
      })
      .catch((error) => {
        console.log('😱 Error: ', error);
      });
  };

  const renderSuggestions = () => data.map((suggestion) => {
    const {
      id,
      /* structured_formatting: { mainText, secondaryText }, */
      description
    } = suggestion;

    console.log(description);

    return (
      <li className={classes.applyLi} key={id} onClick={handleSelect(suggestion)}>
        <div>
          <MapPinIcon strokeWidth={1} size={10} />
          <span
            style={{
              fontSize: '0.8rem',
              marginLeft: '5px'
            }}
          >
            {description}
          </span>
        </div>
      </li>
    );
  });

  return (
    <div>
      <StyledTextField
        name="adres"
        type="text"
        value={value}
        fullWidth
        id="adres"
        required
        onChange={handleInput}
        label="Adres"
        disabled={!ready}
      />
      {status === 'OK'
      && (
      <ul
        style={{
          position: 'absolute',
          backgroundColor: 'white',
          zIndex: '1',
          padding: '0 10px',
          border: '0.5px solid #373242',
          listStyleType: 'none',
          marginRight: '50px'
        }}
      >
        {renderSuggestions()}
      </ul>
      )}
    </div>
  );
}

/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import {
  makeStyles,
  Grid,
  Container,
  Card,
  CardContent,
  Divider,
  TextField,
  Table,
  TableCell,
  TableRow,
  withStyles,
  TableBody,
  InputBase,
  Select,
  MenuItem,
  Button,
  Snackbar,
  IconButton,
  Switch
} from '@material-ui/core';
import 'reactjs-popup/dist/index.css';
import HelpIcon from '@material-ui/icons/Help';
import SaveIcon from '@material-ui/icons/Save';
import Page from 'src/components/Page';
import axiosInstance from 'src/utils/axiosApi';
import Spinner from 'src/components/Spinner/Spinner';
import MuiAlert from '@material-ui/lab/Alert';
import _ from 'lodash';
import MaterialCard from './MaterialCard';
import PackageImageDialog from './PackageImage';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BootstrapSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {
    marginTop: 0,
    paddingBottom: 0
  },
  formControl: {
    minWidth: 110,
  },
  /* cardContentRoot: {
    paddingBottom: 0
  } */
}));

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins',
    borderBottom: 'none'
  },
}))(TableCell);

const PackageInfo = (props) => {
  const { t, } = useTranslation();
  const MUIclasses = useStyles();
  const [loading, setLoading] = useState(false);
  const { redirectedPackageId } = useParams();
  const { pageType } = props;

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Responsive Design Values
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  // const largeYerlesim = 3;

  const [packageInfo, setPackageInfo] = useState({
    packageId: 0,
    promotionId: 0,
    gridType: 'ON',
    packageName: '',
    packagePrice: '',
    currency: 'USD',
    info1: '',
    info2: '',
    info3: '',
    imagePreview: pageType === 'newPackage' ? 'https://solarvisbucket.s3.eu-central-1.amazonaws.com/media/public/default_images/default-mainPackage.png' : ''
  });

  const [materialsInfo, setMaterialsInfo] = useState({
    solar_panel: {
      material_id: 0,
      material_amount_type: 'item_count',
      material_amount_value: 10,
      image_preview: '',
      manufacturer_company: ''
    },
    inverter: {
      material_id: 0,
      material_amount_type: 'item_count',
      material_amount_value: 1,
      image_preview: '',
      manufacturer_company: ''
    },
    construction_material: {
      material_id: 0,
      material_amount_type: 'item_count',
      material_amount_value: 1,
      image_preview: '',
      manufacturer_company: ''
    },
    electrical_panel: {
      material_id: 0,
      material_amount_type: 'item_count',
      material_amount_value: 1,
      image_preview: '',
      manufacturer_company: ''
    },
    solar_cable: {
      material_id: 0,
      material_amount_type: 'length_m',
      material_amount_value: 50,
      image_preview: '',
      manufacturer_company: ''
    },
  });

  const [usablePromotions, setUsablePromotions] = useState([]);
  const [selectableMaterialDatabase, setSelectableMaterialDatabase] = useState([]);

  const createUpdatePackage = async () => {
    setLoading(true);
    let sendPackageId;
    let requestURL;
    if (pageType === 'newPackage') {
      sendPackageId = '00';
      requestURL = 'solar_package/create_solar_package';
    } else {
      sendPackageId = packageInfo.packageId;
      requestURL = 'solar_package/update_solar_package';
    }
    await axiosInstance.post(requestURL, {
      solar_package_id_to_update: sendPackageId,
      promotion_id: packageInfo.promotionId,
      grid_type: packageInfo.gridType,
      name: packageInfo.packageName,
      currency: packageInfo.currency,
      price: packageInfo.packagePrice,
      info1: packageInfo.info1,
      info2: packageInfo.info2,
      info3: packageInfo.info3,
      real_materials: Object.keys(materialsInfo).map((materialType) => {
        return {
          material_id: materialsInfo[materialType].material_id,
          material_amount_type: materialsInfo[materialType].material_amount_type,
          material_amount_value: materialsInfo[materialType].material_amount_value,
        };
      })
    })
      .then((response) => {
        if (response.data.status) {
          setSnackbarMessage(response.data.message);
          setSnackbarType('success');
          setSnackbarState(true);
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
        }
      })
      .catch((error) => {
        setSnackbarMessage(t('AlertMessage.generalError'));
        setSnackbarType('warning');
        setSnackbarState(true);
        console.log(error);
      });
    setLoading(false);
  };

  const fetchPackageInfo = () => {
    setLoading(true);
    const newUpdatedStateMaterial = {};
    axiosInstance.get(`solar_package/fetch_solar_packages?solar_package_ids=${redirectedPackageId}&grid_type=&language=TR`)
      .then((response) => {
        const packageInfoGet = response.data.data[0];
        packageInfoGet.materials.map((material) => {
          newUpdatedStateMaterial[material.material_category] = {
            material_id: material.solar_material_id,
            material_amount_type: material.material_amount_type,
            material_amount_value: material.material_amount_value,
            image_preview: material.package_material_image_presigned_url,
            manufacturer_company: material.manufacturer_company,
          };
        });
        console.log(newUpdatedStateMaterial);
        setPackageInfo({
          packageId: packageInfoGet.id,
          promotionId: packageInfoGet.is_promotion_available ? packageInfoGet.promotion_id : 0,
          gridType: packageInfoGet.grid_type,
          packageName: packageInfoGet.name,
          packagePrice: packageInfoGet.price,
          currency: packageInfoGet.currency,
          info1: packageInfoGet.info1,
          info2: packageInfoGet.info2,
          info3: packageInfoGet.info3,
          imagePreview: packageInfoGet.package_image_presigned_url,
        });
        setMaterialsInfo(newUpdatedStateMaterial);
      })
      .catch((error) => console.log(error));

    setLoading(false);
  };

  const fetchMaterialSelections = () => {
    axiosInstance.get('malzeme/CRUD_material/get_materials_api?language=TR&material_ids=&material_categories=&manufacturer_companies=')
      .then((response) => {
        if (pageType === 'newPackage') {
          Object.keys(response.data.all_products).map((materialType) => {
            if (['solar_panel', 'inverter', 'solar_cable', 'electrical_panel', 'construction_material'].includes(materialType)) {
              const firstMaterialManufacturer = Object.keys(response.data.all_products[materialType].material_list_acc_to_manufacturer_company)[0];
              const material_id = response.data.all_products[materialType].material_list_acc_to_manufacturer_company[firstMaterialManufacturer][0].material_id;
              setMaterialsInfo((prevState) => ({
                ...prevState,
                [materialType]: {
                  ...prevState[materialType],
                  material_id,
                  manufacturer_company: firstMaterialManufacturer,
                }
              }));
            }
          });
        }
        setSelectableMaterialDatabase(response.data.all_products);
        console.log(response.data.all_products);
      })
      .catch((error) => console.log(error));
  };

  const fetchUsablePromotions = () => {
    setLoading(true);
    axiosInstance.get('/home/promotion/fetch?is_all=1&only_public=1&format=json')
      .then((response) => {
        setUsablePromotions(response.data.data.promotions);
      })
      .catch((error) => console.log(error));
    setLoading(false);
  };

  const handleChangeMaterialInfo = (event, materialType) => {
    // Prevents React from resetting its properties:
    event.persist();

    setMaterialsInfo((prevState) => ({
      ...prevState,
      [materialType]: {
        ...prevState[materialType],
        [event.target.name]: event.target.value
      }
    }));
  };

  const handleChangePackageInfo = (event) => {
    // Prevents React from resetting its properties:
    event.persist();
    setPackageInfo((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.name === 'gridType' ? (event.target.checked ? 'ON' : 'OFF') : event.target.value
    }));
  };

  const handleChangePackageImage = (newValue) => {
    setPackageInfo((prevState) => ({
      ...prevState,
      imagePreview: newValue
    }));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  const willMount = useRef(true);
  if (willMount.current) {
    if (pageType === 'updatePackage') {
      fetchPackageInfo();
    }
    fetchUsablePromotions();
    fetchMaterialSelections();
    willMount.current = false;
  }

  if (loading) {
    return (
      <>
        {pageType === 'newPackage' && <Spinner text="" />}
        {pageType === 'updatePackage' && <Spinner text={t('SolarPackagesPage.loadingPackage')} />}
      </>
    );
  }

  const packageImage = (
    <>
      <img
        style={{
          width: '100%',
          maxHeight: 300,
          marginTop: '10px',
          padding: '5px',
          border: '#dfdfdf 0.5px solid',
          borderRadius: '10px'
        }}
        src={packageInfo.imagePreview}
        alt="xxx"
      />
    </>
  );

  return (
    <Page
      className={MUIclasses.root}
      title={t('SolarPackagesPage.titlePackageInfo')}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            {pageType === 'newPackage' && <h2 style={{ fontWeight: 'normal' }}>{t('SolarPackagesPage.createNewPackage')}</h2>}
            {pageType === 'updatePackage' && <h2 style={{ fontWeight: 'normal' }}>{t('SolarPackagesPage.packageInfo')}</h2>}
          </Grid>
          <Grid
            item
            lg={4}
            md={12}
            xl={4}
            xs={12}
          >
            <Card>
              {/* <CardHeader
                title="Paket Görseli"
              /> */}
              <div style={{ padding: 16 }}>
                <h3>{t('SolarPackagesPage.packageImage')}</h3>
              </div>
              <Divider />
              <CardContent style={{ position: 'relative' }}>
                {packageImage}
                <PackageImageDialog
                  packageId={packageInfo.packageId}
                  pageType={pageType}
                  handleChangePackageImage={handleChangePackageImage}
                />
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={8}
            xs={12}
          >
            <Card>
              <div style={{ padding: 16 }}>
                <h3>{t('SolarPackagesPage.packageInfos')}</h3>
              </div>
              <Divider />
              <CardContent>
                <Table>
                  <TableBody>
                    <TableRow
                      key="packageName"
                    >
                      <StyledTableCell>
                        <strong>{t('SolarPackagesPage.packageName')}</strong>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          fullWidth
                          name="packageName"
                          error={packageInfo.packageName === ''}
                          helperText={packageInfo.packageName === '' ? 'Bu alanı doldurmak zorunludur.' : ''}
                          onChange={handleChangePackageInfo}
                          required
                          value={packageInfo.packageName}
                          placeholder="Paket40"
                          variant="outlined"
                          type="text"
                          size="small"
                          style={{ maxWidth: 300 }}
                        />
                      </StyledTableCell>
                    </TableRow>
                    <TableRow
                      key="gridType"
                    >
                      <StyledTableCell>
                        <strong>{t('SolarPackagesPage.gridType')}</strong>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <span style={{ fontSize: isTabletOrMobile ? '0.85rem' : '1rem' }}>OFF-GRID</span>
                          <Switch
                            checked={packageInfo.gridType === 'ON'}
                            onChange={handleChangePackageInfo}
                            color="primary"
                            name="gridType"
                            inputProps={{ 'aria-label': 'gridType checkbox' }}
                          />
                          <span style={{ fontSize: isTabletOrMobile ? '0.85rem' : '1rem' }}>ON-GRID</span>
                          <a href="https://solarvis.co" target="_blank" rel="noreferrer">
                            <IconButton aria-label="question grid type">
                              <HelpIcon />
                            </IconButton>
                          </a>
                        </div>
                      </StyledTableCell>
                    </TableRow>
                    <TableRow
                      key="promotion"
                    >
                      <StyledTableCell>
                        <strong>{t('SolarPackagesPage.assignedPromotion')}</strong>
                      </StyledTableCell>
                      <StyledTableCell>
                        {usablePromotions.length === 0
                        && (
                          <span>{t('SolarPackagesPage.noPromotionsFound')}</span>
                        )}
                        {usablePromotions.length > 0
                        && (
                        <Select
                          labelId="currency-select"
                          id="promotionId"
                          name="promotionId"
                          value={packageInfo.promotionId}
                          displayEmpty
                          onChange={handleChangePackageInfo}
                          label="Promosyon Kodu"
                          input={<BootstrapSelect />}
                        >
                          <MenuItem value={0}>{t('SolarPackagePromotionsPage.noPromotionUsed')}</MenuItem>
                          {usablePromotions.map((promotion) => (
                            <MenuItem value={promotion.id}>{promotion.name}</MenuItem>
                          ))}
                        </Select>
                        )}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow
                      key="packagePrice"
                    >
                      <StyledTableCell>
                        <strong>{t('SolarPackagesPage.packagePrice')}</strong>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          fullWidth
                          name="packagePrice"
                          error={packageInfo.packagePrice === '' || packageInfo.packagePrice === 0}
                          helperText={(packageInfo.packagePrice === '' || packageInfo.packagePrice === 0) ? 'Bu alanı doldurmak zorunludur.' : ''}
                          onChange={handleChangePackageInfo}
                          required
                          value={packageInfo.packagePrice}
                          placeholder="7000"
                          variant="outlined"
                          type="number"
                          size="small"
                          style={{ maxWidth: 150 }}
                        />
                        <Select
                          labelId="currency-select"
                          id="currency"
                          name="currency"
                          value={packageInfo.currency}
                          displayEmpty
                          onChange={handleChangePackageInfo}
                          label="Para Birimi"
                          input={<BootstrapSelect />}
                          style={{ width: 100, marginLeft: 20 }}
                        >
                          <MenuItem value="TL">TL</MenuItem>
                          <MenuItem value="USD">USD</MenuItem>
                        </Select>
                      </StyledTableCell>
                    </TableRow>
                    <TableRow
                      key="info1"
                    >
                      <StyledTableCell>
                        <strong>{t('SolarPackagesPage.packageExp1')}</strong>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          fullWidth
                          name="info1"
                          onChange={handleChangePackageInfo}
                          required
                          value={packageInfo.info1}
                          placeholder="Bu paket yıllık ortalama 400 kWh üretir."
                          variant="outlined"
                          type="number"
                          size="small"
                          multiline
                        />
                      </StyledTableCell>
                    </TableRow>
                    <TableRow
                      key="info2"
                    >
                      <StyledTableCell>
                        <strong>{t('SolarPackagesPage.packageExp2')}</strong>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          fullWidth
                          name="info2"
                          onChange={handleChangePackageInfo}
                          required
                          value={packageInfo.info2}
                          placeholder="700-750 TL fatura için uygundur."
                          variant="outlined"
                          type="number"
                          size="small"
                          multiline
                        />
                      </StyledTableCell>
                    </TableRow>
                    <TableRow
                      key="info3"
                    >
                      <StyledTableCell>
                        <strong>{t('SolarPackagesPage.packageExp3')}</strong>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          fullWidth
                          name="info3"
                          onChange={handleChangePackageInfo}
                          required
                          value={packageInfo.info3}
                          placeholder="..."
                          variant="outlined"
                          type="number"
                          size="small"
                          multiline
                        />
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Card>
              <div style={{ padding: 16 }}>
                <h3>{t('SolarPackagesPage.packageMaterials')}</h3>
              </div>
              <Divider />
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  {!(_.isEmpty(selectableMaterialDatabase)) && Object.keys(materialsInfo).map((materialType) => (
                    <Grid
                      item
                      md={4}
                      lg={4}
                      xs={12}
                    >
                      <MaterialCard
                        selectableMaterials={selectableMaterialDatabase[materialType]}
                        materialType={materialType}
                        materialInfo={materialsInfo[materialType]}
                        changeMaterialFunction={handleChangeMaterialInfo}
                        pageType={pageType}
                        packageId={packageInfo.packageId}
                      />
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<SaveIcon />}
                onClick={() => createUpdatePackage()}
                disabled={(
                  !packageInfo.packageName
                  || !packageInfo.packagePrice
                  || !materialsInfo.solar_panel.material_amount_value
                  || !materialsInfo.inverter.material_amount_value
                  || !materialsInfo.solar_cable.material_amount_value
                )}
              >
                {pageType === 'newPackage' && <span>{t('SolarPackagesPage.createNewPackage')}</span>}
                {pageType === 'updatePackage' && <span>{t('SolarPackagesPage.updatePackage')}</span>}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default PackageInfo;
